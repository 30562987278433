import { AppState } from 'app/state/app/app.state'
import { ChangeDetectionStrategy, Component, OnInit, AfterViewChecked, Input, OnDestroy } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Observable, Subscription } from 'rxjs'
import { PageTransitionService } from 'app/services/page-transition.service'
import { Router } from '@angular/router'
import { Store, Select } from '@ngxs/store'
import * as moment from 'moment'
import * as _ from 'lodash'
import { SetActiveDay, SetActiveDate } from 'app/state'

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class CalendarHeaderComponent implements OnInit, OnDestroy, AfterViewChecked {
  private subs: Subscription[] = []
  @Input() availableDates: moment.Moment[]

  @Select(AppState.isSyncing)
  isSyncing$: Observable<any>
  isSyncing: boolean

  @Select(AppState.activeDate)
  activeDate$: Observable<any>
  activeDate: moment.Moment

  calendarBuilt: boolean
  calendarDays: Array<any>

  constructor(
    private store: Store,
  ) {
    // Not sure if needed
    moment.locale('en');
  }

  ngOnInit(): void {
    this.subs.push(
      this.activeDate$.subscribe((date: moment.Moment) => {
        if (!date) {
          return
        }
        this.activeDate = date
        if (!this.calendarBuilt || !this.dateInCalendar(date)) {
          this.calendarDays = []
          this.buildCalendar(date)
          this.calendarBuilt = true
        }
        this.updatecalendarDays(date)
        setTimeout(() => {
          this.scrollToActiveDay()
        }, 500)
      }),
      this.isSyncing$.subscribe((isSyncing) => {
        if (isSyncing) {
          this.calendarDays = []
        }
        if (this.isSyncing && !isSyncing) {
          // Sync is complete, rebuild
          this.buildCalendar(this.activeDate)
          this.calendarBuilt = true
          this.updatecalendarDays(this.activeDate)
          setTimeout(() => {
            this.scrollToActiveDay()
          }, 500)
        }
        this.isSyncing = isSyncing
      })
    )
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  dateInCalendar(date) {
    const index = _.findIndex(this.calendarDays, (day) => {
      return moment(day.date).isSame(date, 'd')
    })
    return index !== -1
  }

  ngAfterViewChecked(): void {
    this.scrollToActiveDay()
  }

  scrollToActiveDay(): void {
    const activeItem = document.getElementById('activeDate')
    if (activeItem) {
      activeItem.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
    }
  }

  prependMoreDates() {
    const earliestDate = this.calendarDays[0].date
    let preDays = moment(earliestDate).subtract(1, 'd')
    for (let i = 0; i < 24; i++) {
      this.calendarDays.unshift({
        day: preDays.format('ddd'),
        number: preDays.format('D'),
        active: false,
        date: preDays.format()
      })
      preDays = preDays.clone().subtract(1, 'd')
    }
  }

  appendMoreDates() {
    const latestDate = this.calendarDays[this.calendarDays.length - 1].date
    let nextDays = moment(latestDate).add(1, 'd')
    for (let i = 0; i < 24; i++) {
      this.calendarDays.push({
        day: nextDays.format('ddd'),
        number: nextDays.format('D'),
        active: false,
        date: nextDays.format()
      })
      nextDays = nextDays.clone().add(1, 'd')
    }
  }

  buildCalendar(date: moment.Moment) {
    const activeDay = moment(date)
    let preDays = activeDay.clone().subtract(1, 'day')
    for (let i = 0; i < 14; i++) {
      this.calendarDays.unshift({
        day: preDays.format('ddd'),
        number: preDays.format('D'),
        active: activeDay.isSame(preDays, 'd'),
        date: preDays.format(),
        hasWorkout: this.availableDates.findIndex(d => d.isSame(preDays, 'd')) > -1
      })
      preDays = preDays.clone().subtract(1, 'd')
    }

    let nextDays = activeDay.clone()
    for (let i = 0; i < 14; i++) {
      this.calendarDays.push({
        day: nextDays.format('ddd'),
        number: nextDays.format('D'),
        active: activeDay.isSame(nextDays, 'd'),
        date: nextDays.format(),
        hasWorkout: this.availableDates.findIndex(d => d.isSame(nextDays, 'd')) > -1
      })
      nextDays = nextDays.clone().add(1, 'd')
    }
  }

  updatecalendarDays(date: moment.Moment): void {
    this.calendarDays = _.map(this.calendarDays, (day) => {
      if (moment(day.date).isSame(date, 'd')) {
        day.active = true
      } else {
        day.active = false
      }
      return day
    })
  }

  onClickDay(dateString: string) {
    const date = moment(dateString)
    this.store.dispatch(new SetActiveDate(date.format()))
  }
}
