<div *ngIf="exercise" class="score-modal" fxLayout="column">
  <ng-container>
    <div class="modal-title" fxLayout="row">
      <div>{{ exercise.name }}</div>
      <div fxFlex></div>
      <div>{{ category }} Event Performance</div>
    </div>
    <mat-dialog-content
      fxFlex="grow"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div>
        <p>
          Enter your information for your
          <strong class="primary">{{ exercise.name }}</strong>
          {{ category }} event performance.
        </p>
        <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center">
          <mat-form-field fxFlex>
            <mat-label>Enter Your {{ unitLabel }}</mat-label>
            <input
              *ngIf="unitLabel !== 'Time'"
              type="text"
              matInput
              [(ngModel)]="isKnownScore"
              (ngModelChange)="updateScore(isKnownScore)"
              maxlength="5"
              inputmode="numeric"
              pattern="[0-9]*"
              digitOnly
            />
            <input
              *ngIf="unitLabel === 'Time'"
              type="text"
              matInput
              placeholder="mm:ss"
              [(ngModel)]="isKnownScore"
              (ngModelChange)="updateScore(isKnownScore)"
              type="text"
              maxlength="8"
              pattern="^((\d+:)?\d+)$"
            />
          </mat-form-field>
          <mat-radio-group
            fxFlex
            fxLayout="row"
            fxLayoutGap="25px"
            [(ngModel)]="isKnownGender"
            (ngModelChange)="updateScore(isKnownScore)"
          >
            <mat-radio-button value="MALE">Male</mat-radio-button>
            <mat-radio-button value="FEMALE">Female</mat-radio-button>
          </mat-radio-group>
          <mat-form-field>
            <mat-label>Age Group</mat-label>
            <mat-select
              [(ngModel)]="age_group"
              (ngModelChange)="updateScore(isKnownScore)"
            >
              <mat-option *ngFor="let group of ageGroups" [value]="group">{{
                group.toString()
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="shrink" class="exercise-score" fxLayout="column">
        <strong fxFlex fxLayoutAlign="center center"
          >Calculated Event Performance:</strong
        >
        <div class="exercise-score">
          <app-pft-cft-exercise-score
            fxFlex
            [category]="category.toLowerCase()"
            [score]="score"
            fxLayoutAlign="center center"
          >
          </app-pft-cft-exercise-score>
        </div>
        <p class="disclaimer">
          *Due to rounding decimals, the above calculated performance may differ
          from official performance by 1 point.
        </p>
        <p *ngIf="display5KRowMessage()" class="disclaimer">
          Only marines aged 46 years of age and older have the option of rowing
          5,000 meters (5,000m) as an alternative event for the three mile run.
        </p>
        <p *ngIf="display5KRowMessage()" class="disclaimer">
          Marines aged 45 years and younger have the option of rowing 5,000m as
          an alternative event to the three mile run due to an injury or if
          post-partum and if authorized by the PHCP and the CO/OIC.
        </p>
      </div>
    </mat-dialog-content>
  </ng-container>
  <mat-dialog-actions>
    <button mat-button (click)="cancelSelected()" fxFlex>Close</button>
    <button
      class="yes-button primary"
      mat-button
      (click)="submitSelected()"
      cdkFocusInitial
      fxFlex
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
