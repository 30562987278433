import { Routes } from '@angular/router'
import { PlaycardSummaryComponent } from './playcard-summary.component'

export const PlayCardSummaryRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: PlaycardSummaryComponent,
        data: { page: 'playcard-summary' },
      }
    ]
  }
]
