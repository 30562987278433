import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Actions, Select, ofActionSuccessful } from '@ngxs/store';
import { DocumentService } from 'app/services/document.service';
import { AppState } from 'app/state/app/app.state';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Set1RM } from 'app/state';
var RmChartComponent = /** @class */ (function () {
    function RmChartComponent(documentService, actions$) {
        var _this = this;
        this.documentService = documentService;
        this.actions$ = actions$;
        this.graph = {
            config: {
                displayModeBar: false,
                responsive: true,
            },
        };
        this.actions$
            .pipe(ofActionSuccessful(Set1RM))
            .subscribe(function () {
            _this._subscription.unsubscribe();
            _this.setEmptyLayout();
            _this.init();
        });
    }
    RmChartComponent.prototype.ngOnInit = function () {
        this.setEmptyLayout();
        this.init();
    };
    RmChartComponent.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.documentService.restoreData().toPromise()];
                    case 1:
                        _a.sent();
                        this.observations = this.documentService.store.observations;
                        this._subscription = this.selectedRM$.subscribe(function (exercise) {
                            if (exercise) {
                                _this.selectedRM = exercise;
                                // Find all observations with this exercise
                                var exerciseObservations = _this.observations.filter(function (o) {
                                    return o.document.result.exercise_id === exercise.id;
                                });
                                if (exerciseObservations.length > 0) {
                                    _this.setChartData(exerciseObservations);
                                    _this.setLayout();
                                }
                                else {
                                    _this.setEmptyLayout('No data for this exercise.');
                                }
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    RmChartComponent.prototype.setChartData = function (exerciseObservations) {
        // Sort by date
        var sortedObservations = _.orderBy(exerciseObservations, function (o) {
            return o.document.timestamp;
        }, 'asc');
        var data = {
            type: 'scatter',
            x: [],
            y: [],
            marker: {
                size: 12
            }
        };
        sortedObservations.forEach(function (o) {
            if (o.document.result.value) {
                data.x.push(moment(o.document.timestamp).format('MM/D/YY'));
                data.y.push(o.document.result.value);
            }
        });
        this.graph.data = [data];
    };
    RmChartComponent.prototype.setLayout = function () {
        if (!this.selectedRM) {
            this.setEmptyLayout();
        }
        else {
            this.graph.layout = {
                showlegend: false,
                height: 140,
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
                xaxis: {
                    showticklabels: true,
                    tickmode: 'array',
                    tickangle: 'auto',
                    tickfont: {
                        size: 10,
                        color: '#FFFFFF'
                    },
                    showgrid: false,
                },
                yaxis: {
                    automargin: true,
                    title: {
                        standoff: 10,
                        text: '1RM',
                    },
                    showticklabels: true,
                    tickfont: {
                        size: 10,
                        color: '#FFFFFF'
                    },
                    showgrid: true,
                    gridcolor: '#666',
                    gridwidth: 1,
                },
                margin: {
                    l: 20,
                    r: 20,
                    b: 40,
                    t: 5,
                },
                hoverlabel: {
                    bgcolor: '#666666',
                    font: { color: '#FFFFFF' }
                }
            };
        }
    };
    RmChartComponent.prototype.setEmptyLayout = function (text) {
        if (!text) {
            text = 'No exercise selected.';
        }
        this.graph.data = [];
        this.graph.layout = {
            height: 140,
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)',
            xaxis: {
                visible: false
            },
            yaxis: {
                visible: false
            },
            annotations: [{
                    text: text,
                    xref: 'paper',
                    yref: 'paper',
                    showarrow: false,
                    font: {
                        size: 20
                    }
                }],
            hoverlabel: {
                bgcolor: '#666666',
                font: { color: '#FFFFFF' }
            }
        };
    };
    RmChartComponent.prototype.ngOnDestroy = function () {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    };
    tslib_1.__decorate([
        Select(AppState.selectedRM),
        tslib_1.__metadata("design:type", Observable)
    ], RmChartComponent.prototype, "selectedRM$", void 0);
    return RmChartComponent;
}());
export { RmChartComponent };
