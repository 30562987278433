import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core'
import { Store, Select } from '@ngxs/store'
import {
  SyncWorkoutSessions,
  InitMain,
  ChangeActiveDate,
  SetActiveDay,
  SetActiveDate,
  SetActiveToDefault,
  OnlineStatusChange,
  SetMainTabIndex,
} from 'app/state/app/app.actions'
import { Observable, Subscription } from 'rxjs'
import { AppState } from 'app/state/app/app.state'
import { NgxSpinnerService } from 'ngx-spinner'
import * as moment from 'moment'
import * as _ from 'lodash'
import { MatDialog, MatCalendarCellCssClasses, MatTabChangeEvent } from '@angular/material'
import { Router } from '@angular/router'
import { PageTransitionService, PAGETRANSITIONS } from 'app/services/page-transition.service'
import { RestService } from 'app/services/rest.service'
import { DocumentService } from 'app/services/document.service'
import { trigger, transition, animate, style } from '@angular/animations'
import { SyncLoggingService, LogLevel } from 'fitforce-logging';
import { PlanDocumentUtils, Workout } from 'fitforce-document-sync'

const DEFAULT_SPINNER_TEXT = 'Syncing plans...'
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ]
})

export class MainComponent implements OnInit {
  spinnerText = DEFAULT_SPINNER_TEXT

  sessionForDay: any
  totalTime: number
  activeDateDay: string
  activeDate: string
  activeDateDate: Date;
  activeDayMonth: string
  isToday: boolean
  calendarVisible: boolean
  objectKeys = Object.keys;
  isOnline = true
  onlineSub: Subscription

  @Select(AppState.mainTabIndex)
  tabIndex$: Observable<number>
  tabIndex = 0

  @Select(AppState.isSyncing)
  isSyncing$: Observable<any>

  @Select(AppState.unsyncedWorkoutSessions)
  unsyncedWorkoutSessions$: Observable<Array<string>>

  @Select(AppState.organizedDays)
  organizedDays$: Observable<any>

  @Select(AppState.dayToWorkout)
  dayToWorkout$: Observable<any>
  dayToWorkout: any = {};
  availableDates = [];

  constructor(
    private router: Router,
    private _cdr: ChangeDetectorRef,
    private documentService: DocumentService,
    private spinner: NgxSpinnerService,
    private store: Store,
    private dialog: MatDialog,
    private pageTransitionService: PageTransitionService,
    private restService: RestService,
    private syncLog: SyncLoggingService,
  ) { }

  ngOnInit() {
    this.calendarVisible = false

    this.tabIndex$.subscribe(index => {
      this.tabIndex = index || 0
    })

    this.isSyncing$.subscribe(isSyncing => {
      if (isSyncing) {
        this.spinnerText = DEFAULT_SPINNER_TEXT
        this.spinner.show()
      } else {
        this.spinner.hide()
      }
    })

    this.organizedDays$.subscribe((days) => {
      const activeDate = moment(this.store.selectSnapshot(AppState.activeDate))
      this.isToday = activeDate.isSame(moment(), 'day')
      this.activeDate = activeDate.format('DD-MMM-YYYY')
      this.activeDateDay = activeDate.format('dddd')
      this.activeDateDate = activeDate.toDate()
      this.activeDayMonth = activeDate.format('MMMM YYYY')
    })

    this.dayToWorkout$.subscribe(value => {
      this.dayToWorkout = value;
      this.availableDates = value ? Object.keys(value).map(key => moment(key)).sort((a, b) => a.isBefore(b) ? -1 : 1) : [];
    })

    this.store.dispatch(new InitMain()).subscribe(() => {
      if (this.restService.isOnline) {
        this.store.dispatch(new SyncWorkoutSessions())
      }
    })

    this.onlineSub = this.restService.isOnlineSubscription().subscribe(isOnline => {
      this.isOnline = isOnline
      this.store.dispatch(new OnlineStatusChange(isOnline))
    })

    // this.syncLog.logStream.subscribe(message => {
    //   if (message && message.logLevel === LogLevel.INFO) {
    //     this.spinnerText = message.message;
    //     this._cdr.detectChanges()
    //   }
    // })

    this.store.dispatch(new SetMainTabIndex(0))
  }

  async openPlayCard(group, plan, day) {
    try {
      // Make sure plan playcard is available, if not, fetch data again and *then* change
      await this.documentService.restoreData().toPromise()
      const planDocument = _.find(this.documentService.store.planDocuments, { key: plan.key })
      const workoutDocument = _.find(planDocument.document.workouts, { id: day.key })
    } catch (e) {
      this.spinnerText = 'Syncing missing exercises...'
      await this.store.dispatch(new InitMain(true)).toPromise()
    }
    this.store.dispatch(new SetActiveDay(group, plan, day)).subscribe(() => {
      this.spinnerText = DEFAULT_SPINNER_TEXT
      this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT)
      this.router.navigate(['playcard-summary'])
    })
  }

  openHistory(sessionKey) {
    this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT)
    this.router.navigate(['exercise', 'complete', sessionKey])
  }

  hasPrevDay() {
    const index = this.availableDates.findIndex(date => date.isSame(moment(this.activeDate)));
    const next = index - 1;
    return next > -1 && next < this.availableDates.length;
  }

  hasNextDay() {
    const index = this.availableDates.findIndex(date => date.isSame(moment(this.activeDate)));
    const next = index + 1;
    return next > -1 && next < this.availableDates.length;
  }

  onPreviousWeek() {
    this.store.dispatch(new ChangeActiveDate(true));
  }

  onNextWeek() {
    this.store.dispatch(new ChangeActiveDate());
  }

  onToday() {
    this.store.dispatch(SetActiveToDefault);
    this.calendarVisible = false;
  }

  onToggleCalendar() {
    this.calendarVisible = !!!this.calendarVisible
  }

  onCalendarSelect(selectedDate) {
    this.store.dispatch(new SetActiveDate(moment(selectedDate).format())).subscribe(() => {
      this.onToggleCalendar()
      this.calendarVisible = false
    })
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (this.dayToWorkout[moment(date).format()]) {
        return 'has-workouts'
      } else {
        return ''
      }
    }
  }

  onClickCard(group, workout) {
    if (workout.workoutSessions.length > 0) {
      this.openHistory(workout.workoutSessions[0].key || workout.workoutSessions[0].id)
    } else if (!workout.day.isRestricted) {
      this.openPlayCard(group, workout.plan, workout.day)
    }
  }

  onSelectedTabChange(event: MatTabChangeEvent) {
    const index = event.index
    this.store.dispatch(new SetMainTabIndex(index))
  }

  getPhaseColor(workout: any) {
    if (!workout.phase.color && workout.phase.exercise_program_phase_id) {
      if (this.documentService.store.exerciseProgram) {
        const foundPhase = this.documentService.store.exerciseProgram.document.phases.find(
          p => p.id === workout.phase.exercise_program_phase_id
        );
        if (foundPhase) {
          return foundPhase.color;
        }
      }
    } else if (workout.phase) {
      return workout.phase.color;
    }
    return 'white';
  }
}
