import { Plan, Group, Day } from 'app/models'
import { UserProfilePftCftDataStat } from 'app/models/user-profile.model';

export class SetMainTabIndex {
  static readonly type = '[App] Set Main Tab Index'
  constructor(public index: number) {}
}
export class OnlineStatusChange {
  static readonly type = '[App] Online Status Change'
  constructor(public isOnline: boolean) {}
}
export class JoinGroup {
  static readonly type = '[App] Join Group'
  constructor(public group: any) {}
}

export class LeaveGroup {
  static readonly type = '[App] Leave Group'
  constructor(public group: Group) {}
}

export class InitMain {
  static readonly type = '[App] Init Main'
  constructor(public force = false) {}
}

export class SetActiveDate {
  static readonly type = '[App] Set Active Date'
  constructor(public dateString: string) {}
}
export class ChangeActiveDate {
  static readonly type = '[App] Change Active Date'
  constructor(public previous?: boolean) {}
}

export class FetchWorkoutForDay {
  static readonly type = '[App] Fetch Workout for Day'
  constructor() {}
}

export class SetActiveDay {
  static readonly type = '[App] Set Active Pay'
  constructor(public group: Group, public plan: Plan, public day: Day) {}
}

export class SetSyncStatus {
  static readonly type = '[App] Set Sync Status'
  constructor(public isSyncing: boolean, public isSuccess?: boolean) {}
}

export class ResetMain {
  static readonly type = '[App] Reset Main'
}

export class Set1RM {
  static readonly type = '[App] Set 1RM'
  constructor(public oneRM: any, public statIndex?: number) {}
}

export class SetPft {
  static readonly type = '[App] Set PFT'
  constructor(public pft: UserProfilePftCftDataStat, public pftIndex?: number) {}
}

export class SetCft {
  static readonly type = '[App] Set CFT'
  constructor(public cft: UserProfilePftCftDataStat, public cftIndex?: number) {}
}

export class SetPftScore {
  static readonly type = '[App] Set PFT Score'
  constructor(public pftScore: number) {}
}

export class SetCftScore {
  static readonly type = '[App] Set CFT Score'
  constructor(public cftScore: number) {}
}

export class AddUnsyncedWorkoutSession {
  static readonly type = '[App] Add Unsynced Workout Session'
  constructor(public key: string) {}
}

export class SyncWorkoutSessions {
  static readonly type = '[App] Sync Workout Sessions'
}

export class SyncPftCftScores {
  static readonly type = '[App] Sync PFT/CFT Scores'
}

export class ClearActiveGroup {
  static readonly type = '[App] Clear Active Group'
}

export class SetActiveToDefault {
  static readonly type = '[App] Set Active To Default'
}

export class SetActiveStatsTab {
  static readonly type = '[App] Set Active Stat Tab'
  constructor(public tab: string) {}
}

export class SetSelectedRM {
  static readonly type = '[App] Set Active Selected RM'
  constructor(public rm: any) {}
}

export class SetShowNotification {
  static readonly type = '[App] Show Notification'
  constructor(public show: any) { }
}

export class SetUserGender {
  static readonly type = '[App] Set UserProfile Gender'
  constructor(public gender: string) {}
}

export class SetUserAgeGroup {
  static readonly type = '[App] Set UserProfile Age Group'
  constructor(public ageGroup: string) {}
}
