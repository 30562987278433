import * as _ from 'lodash'

import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material'
import { Select, Store } from '@ngxs/store'

import { AppState } from 'app/state/app/app.state'
import { AuthState } from 'app/state/auth/auth.state'
import { DataService } from 'app/services/data.service'
import { Group } from 'app/models'
import { JoinGroup } from 'app/state'
import { Observable } from 'rxjs'
import { debounce } from 'lodash'
import { distinct } from 'rxjs/operators'

@Component({
  selector: 'app-programs-public',
  templateUrl: './programs-public.component.html',
  styleUrls: ['./programs-public.component.scss']
})
export class ProgramsPublicComponent implements OnInit {
  private debouncedOnSearchEvent = debounce(this.onSearchEvent.bind(this), 500)

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator

  groups: Array<Group>
  pageSize = 10
  currentPage = 1
  totalPages: number
  totalGroups: number
  searchText: string
  pageIndex = 0
  fetched: boolean
  isFetching: boolean

  isOnline = false

  pageEvent: PageEvent

  @Select(AppState.isOnline)
  isOnline$: Observable<any>

  @Select(AppState.joinedGroups)
  joinedGroups$: Observable<any>

  constructor(
    private store: Store,
    private dataService: DataService,
  ) { }

  ngOnInit() {
    this.fetched = false
    this.groups = []
    this.isOnline$.subscribe(isOnline => {
      this.isOnline = isOnline
    })
    this.joinedGroups$.pipe(distinct()).subscribe(groups => {
      // Only run on subsequent requests
      if (this.fetched) {
        this.fetchPublicGroups(this.currentPage, '')
      }
    })
    this.fetchPublicGroups(this.currentPage, '')
    this.fetched = true
  }

  onPageEvent($event) {
    this.pageIndex = $event.pageIndex
    this.fetchPublicGroups(this.pageIndex + 1, this.searchText)
  }

  debounceOnSearchEvent(searchText) {
    this.searchText = searchText
    this.debouncedOnSearchEvent()
  }

  onSearchEvent() {
    if (this.paginator) {
      this.paginator.pageIndex = 0
    }
    this.pageIndex = 0
    this.fetchPublicGroups(this.pageIndex + 1, this.searchText)
  }

  async fetchPublicGroups(pageNumber: number, searchText = '') {
    this.isFetching = true
    const response = await this.dataService.fetchPublicGroups(pageNumber, this.pageSize, searchText)
    this.totalPages = response.totalPages
    this.totalGroups = response.total
    this.currentPage = response.currentPage
    this.groups = response.groups
    this.isFetching = false
  }

  onRequestJoin(group: any) {
    this.store.dispatch(new JoinGroup(group)).subscribe(() => {
      this.searchText = ''
    })
  }
}
