import { Injectable } from '@angular/core';
import { Group } from '../models/group.model';
import { Plan } from '../models/plan.model';

@Injectable()
export class GroupDataService {
  public selectedGroup: Group;
  public selectedPlan: Plan;

  constructor() { }

}
