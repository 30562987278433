import { ResourcesComponent } from './resources/resources.component';
var ɵ0 = { page: 'resources' }, ɵ1 = { page: 'resources' };
export var ResourcesRoutes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: ResourcesComponent,
                data: ɵ0,
            }
        ]
    },
    {
        path: 'resources',
        children: [
            {
                path: '',
                component: ResourcesComponent,
                data: ɵ1,
            }
        ]
    }
];
export { ɵ0, ɵ1 };
