import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from './components/login/login.component'
import { AuthGuardService as AuthGuard } from './services/auth-guard.service'
import { MainRoutes } from './modules/main/main.routing'
import { PlayCardSummaryRoutes } from './modules/playcard-summary/playcard-summary.routing'
import { ExerciseRoutes } from './modules/exercise/exercise.routing'
import { GroupManagementRoutes } from './modules/group-management/group-management.routing'
import { ResourcesRoutes } from './modules/resources/resources.routing'
import { TotalFitnessSurveyResultComponent } from './modules/resources/total-fitness-survey-result/total-fitness-survey-result.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'main',
    children: MainRoutes,
    canActivate: [AuthGuard]
  },
  {
    path: 'playcard-summary',
    children: PlayCardSummaryRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'exercise',
    children: ExerciseRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { page: 'login' },
  },
  {
    path: 'group',
    children: GroupManagementRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'resources',
    children: ResourcesRoutes,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'main',
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {}
