import * as tslib_1 from "tslib";
// tslint:disable-next-line: max-line-length
import { DocumentRestService, DocumentSyncService, ExerciseProgramDocument, ObservationDocument, PlanDocument, PlanMetadata, WorkoutSessionDocument, } from 'fitforce-document-sync';
import { Subject, forkJoin, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { OfflineService } from './offline.service';
import { SyncLoggingService } from 'fitforce-logging';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "fitforce-document-sync";
import * as i2 from "./offline.service";
import * as i3 from "fitforce-logging";
import * as i4 from "./auth.service";
var DocumentService = /** @class */ (function (_super) {
    tslib_1.__extends(DocumentService, _super);
    function DocumentService(documentRestService, offline, syncLog, authService) {
        var _this = _super.call(this, documentRestService, offline, syncLog) || this;
        _this.authService = authService;
        _this.planUpdates$ = new Subject();
        _this.documentRestService.baseUrl = environment.api_url;
        _this.store = {
            exerciseProgram: undefined,
            planDocuments: undefined,
            planMetadata: [],
            workoutSessionDocuments: [],
            observations: undefined,
            surveyTemplateDocuments: []
        };
        _this.planUpdates.subscribe(function (res) {
            _this.planUpdates$.next(res);
        });
        return _this;
    }
    DocumentService.prototype.pollServer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.offline.isOnline()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.authService.fetchLoginStatus()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Fetches all documents for the current user, saves to offline database, and restores service state.
     */
    DocumentService.prototype.fetchDocuments = function (groupUUIDs) {
        var _this = this;
        // use offline-first-data.service to pull exercise program & plan list for current user and save to offline db
        if (!groupUUIDs || groupUUIDs.length === 0) {
            return this.pullExercisePrograms().pipe(concatMap(function () { return _this.pullWorkoutSessions(); }), concatMap(function () { return _this.restoreData(); }));
        }
        return this.pull(groupUUIDs).pipe(concatMap(function () {
            // get all plan metadata from offline db and if any metadata exists, pull plan documents from server
            return _this.offline.getAll(PlanMetadata).pipe(concatMap(function (metadata) {
                if (metadata && metadata.length > 0) {
                    var requests = metadata.map(function (plan) {
                        return _this.pullPlans([plan.uuid]);
                    });
                    return forkJoin(requests);
                }
                else {
                    return of(null);
                }
            }));
        }), concatMap(function () {
            // pull all workout session docs for the current user and save offline
            return forkJoin(_this.pullWorkoutSessions(), _this.pullObservations());
        }), 
        // restore service state from offline db
        concatMap(function () { return _this.restoreData(); }));
    };
    DocumentService.prototype.pushDocuments = function () {
        return forkJoin(this.pushWorkoutSessions(), this.pushObservations()).pipe(map(function () { return null; }));
    };
    DocumentService.prototype.insertObservationDocument = function (observationDocument) {
        return this.offline.save(ObservationDocument, observationDocument);
    };
    DocumentService.prototype.insertWorkoutSessionDocument = function (workout) {
        return this.offline.save(WorkoutSessionDocument, workout);
    };
    DocumentService.prototype.insertSurveyTemplateDocument = function (surveyTemplateDocument) {
        this.store.surveyTemplateDocuments.push(surveyTemplateDocument);
    };
    // TODO: add more state management here
    /**
     * Restores service state from offline db
     */
    DocumentService.prototype.restoreData = function () {
        return forkJoin(this.restoreExerciseProgram(), this.restorePlanMetadata(), this.restorePlans(), this.restoreWorkoutSessions(), this.restoreObservations());
    };
    /**
     * Restores exercise program document to service state.
     */
    DocumentService.prototype.restoreExerciseProgram = function () {
        var _this = this;
        return this.offline
            .getAll(ExerciseProgramDocument)
            .pipe(map(function (exercisePrograms) {
            _this.store.exerciseProgram =
                exercisePrograms || exercisePrograms.length > 0
                    ? exercisePrograms[0]
                    : null;
            return null;
        }));
    };
    /**
     * Restores plan metadata to service state.
     */
    DocumentService.prototype.restorePlanMetadata = function () {
        var _this = this;
        return this.offline.getAll(PlanMetadata).pipe(map(function (planMetadata) {
            _this.store.planMetadata = planMetadata;
            return null;
        }));
    };
    /**
     * Restores plan documents to service state.
     */
    DocumentService.prototype.restorePlans = function () {
        var _this = this;
        return this.offline.getAll(PlanDocument).pipe(map(function (planDocuments) {
            _this.store.planDocuments = planDocuments;
            return null;
        }));
    };
    DocumentService.prototype.restoreWorkoutSessions = function () {
        var _this = this;
        return this.offline
            .getAll(WorkoutSessionDocument)
            .pipe(map(function (docs) {
            _this.store.workoutSessionDocuments = docs;
            return null;
        }));
    };
    DocumentService.prototype.restoreObservations = function () {
        var _this = this;
        return this.offline.getAll(ObservationDocument).pipe(map(function (docs) {
            _this.store.observations = docs;
            return null;
        }));
    };
    DocumentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentService_Factory() { return new DocumentService(i0.ɵɵinject(i1.DocumentRestService), i0.ɵɵinject(i2.OfflineService), i0.ɵɵinject(i3.SyncLoggingService), i0.ɵɵinject(i4.AuthService)); }, token: DocumentService, providedIn: "root" });
    return DocumentService;
}(DocumentSyncService));
export { DocumentService };
