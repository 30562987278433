import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { WorkoutState } from 'app/state/workout/workout.state';

@Component({
  selector: 'app-tier-details-dialog',
  templateUrl: './tier-details-dialog.component.html',
  styleUrls: ['./tier-details-dialog.component.scss']
})
export class TierDetailsDialogComponent implements OnInit {
  tierName: string
  notes: string

  constructor (
    private store: Store,
    public dialogRef: MatDialogRef<TierDetailsDialogComponent>
  ) {}

  ngOnInit() {
    const currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession)
    const currentWorkoutIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex)
    const tier = currentWorkoutSession.workout.tiers[currentWorkoutIndex]
    this.tierName = tier.name
    this.notes = tier.notes
  }

  cancelSelected() {
    this.dialogRef.close({
      success: false
    })
  }
}
