import * as tslib_1 from "tslib";
import { OfflineService as OfflineFirstOfflineService, DatabaseType } from 'fitforce-offline-first';
import { DocumentDatabaseService } from 'fitforce-document-sync';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "fitforce-document-sync";
// tslint:enable: max-line-length
var OfflineService = /** @class */ (function (_super) {
    tslib_1.__extends(OfflineService, _super);
    function OfflineService(documentDatabaseService) {
        var _this = _super.call(this) || this;
        _this.documentDatabaseService = documentDatabaseService;
        _this.documentDatabaseService.connect();
        return _this;
    }
    OfflineService.prototype.database = function (model) {
        if (model.databaseType === DatabaseType.Document) {
            return this.documentDatabaseService;
        }
        else {
            // TODO: add other databases
        }
    };
    OfflineService.prototype.getRecord = function (model, where) {
        var _this = this;
        return from((this.database(model)).connect().then(function () {
            return (_this.database(model)).get(model, where);
        }));
    };
    OfflineService.prototype.getAll = function (model) {
        var _this = this;
        return from((this.database(model)).connect().then(function () {
            return (_this.database(model)).getAll(model);
        }));
    };
    OfflineService.prototype.clear = function (model) {
        var _this = this;
        return from((this.database(model)).connect().then(function () {
            return (_this.database(model)).clear(model);
        }));
    };
    OfflineService.prototype.query = function (model, where) {
        return from((this.database(model)).query(model, where));
    };
    OfflineService.prototype.queryUndeleted = function (model, where) {
        return from((this.database(model)).query(model, where)).pipe(map(function (records) {
            return records.filter(function (record) {
                return isNil(record.deletedAt);
            });
        }));
    };
    OfflineService.prototype.save = function (model, records) {
        var _this = this;
        return from((this.database(model)).connect().then(function () {
            return (_this.database(model)).insert(model, records, true);
        }));
    };
    OfflineService.prototype.delete = function (model, record) {
        var _this = this;
        return from((this.database(model)).connect().then(function () {
            return (_this.database(model)).delete(model, record);
        }));
    };
    OfflineService.prototype.deleteByKey = function (model, key) {
        var _this = this;
        return from((this.database(model)).connect().then(function () {
            return (_this.database(model)).deleteByKey(model.objectStoreName, key);
        }));
    };
    OfflineService.prototype.count = function (model) {
        var _this = this;
        return from((this.database(model)).connect().then(function () {
            return (_this.database(model)).count(model.objectStoreName);
        }));
    };
    OfflineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OfflineService_Factory() { return new OfflineService(i0.ɵɵinject(i1.DocumentDatabaseService)); }, token: OfflineService, providedIn: "root" });
    return OfflineService;
}(OfflineFirstOfflineService));
export { OfflineService };
