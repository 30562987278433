import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ChangeDetectorRef, OnChanges, OnInit } from '@angular/core';
import { SetUserAgeGroup, SetUserGender, } from 'app/state/app/app.actions';
import { AgeRange } from '../../services/pft-cft-rubric.service';
import { AppState } from 'app/state/app/app.state';
import { DataService } from '../../services/data.service';
import { DocumentService } from 'app/services/document.service';
import { MatDialog } from '@angular/material';
import { PftCftCalcDialogComponent } from '../pft-cft-calc-dialog/pft-cft-calc-dialog.component';
import { Store } from '@ngxs/store';
var noCategoryId = '00000000-0000-0000-0000-000000000000';
var pftCategory = 'PFT Scores';
var cftCategory = 'CFT Scores';
var PftCftScoresComponent = /** @class */ (function () {
    function PftCftScoresComponent(documentService, dataService, store, dialog, cd) {
        this.documentService = documentService;
        this.dataService = dataService;
        this.store = store;
        this.dialog = dialog;
        this.cd = cd;
        this.subs = [];
        // Default no category
        this.pftExercises = {
            id: 'pft',
            name: pftCategory,
            exercises: [],
        };
        this.cftExercises = {
            id: 'cft',
            name: cftCategory,
            exercises: [],
        };
        this.exercises = [];
        this.allExercises = [];
        this.pftFilteredExercises = [];
        this.cftFilteredExercises = [];
        this.totalPftScore = undefined;
        this.totalCftScore = undefined;
        this.categories = [];
        this.minPftCount = 2;
        this.maxPftCount = 3;
        this.minCftCount = 1;
        this.maxCftCount = 3;
    }
    PftCftScoresComponent.prototype.ngOnInit = function () {
        var _this = this;
        var userProfile = this.store.selectSnapshot(AppState.userProfile);
        if (userProfile && userProfile.scores) {
            this.userProfileScoreData = _.cloneDeep(userProfile.scores);
        }
        else {
            this.userProfileScoreData = {
                exercises: [],
                gender: '',
                age_group: undefined,
            };
        }
        this.documentService.restoreData().subscribe(function () {
            var observations = _this.documentService.store.observations;
            _this.categories =
                _this.documentService.store.exerciseProgram.document.exercise_categories;
            _this.pftCategoryId = _this.categories.find(function (cat) { return cat.name === 'PFT'; }).id;
            _this.cftCategoryId = _this.categories.find(function (cat) { return cat.name === 'CFT'; }).id;
            _this.allExercises =
                _this.documentService.store.exerciseProgram.document.exercises;
            var totalPftScore = 0;
            var totalPftUndefined = true;
            var totalCftScore = 0;
            var totalCftUndefined = true;
            _this.allExercises.forEach(function (exercise, ei) {
                // if the exercise is a pft exercise
                if ((exercise.exercise_categories &&
                    exercise.exercise_categories.indexOf(_this.pftCategoryId) > -1) ||
                    (exercise.categories &&
                        exercise.categories.indexOf(_this.pftCategoryId) > -1)) {
                    // Does the user have pft scores?
                    if (userProfile.scores) {
                        var userExerciseIndex = userProfile.scores.exercises.findIndex(function (e) {
                            return e.exercise_id === exercise.id;
                        });
                        if (userExerciseIndex !== -1) {
                            var pftScore = userProfile.scores.exercises[userExerciseIndex]['pft']['score'];
                            if (pftScore !== NaN && pftScore !== 'NaN') {
                                totalPftUndefined = false;
                                exercise['pft']['score'] = pftScore;
                            }
                            var rawScore = userProfile.scores.exercises[userExerciseIndex]['pft']['raw'];
                            if (rawScore !== NaN && rawScore !== 'NaN') {
                                exercise['pft']['raw'] = rawScore;
                            }
                        }
                        _this.pftExercises.exercises.push(exercise);
                    }
                }
                // if the exercise is a cft exercise
                if ((exercise.exercise_categories &&
                    exercise.exercise_categories.indexOf(_this.cftCategoryId) > -1) ||
                    (exercise.categories &&
                        exercise.categories.indexOf(_this.cftCategoryId) > -1)) {
                    // Does the user have cft scores?
                    if (userProfile.scores) {
                        var userExerciseIndex = userProfile.scores.exercises.findIndex(function (e) {
                            return e.exercise_id === exercise.id;
                        });
                        if (userExerciseIndex !== -1) {
                            var cftScore = userProfile.scores.exercises[userExerciseIndex]['cft']['score'];
                            if (cftScore !== NaN && cftScore !== 'NaN') {
                                totalCftUndefined = false;
                                exercise['cft']['score'] = cftScore;
                            }
                            var rawScore = userProfile.scores.exercises[userExerciseIndex]['cft']['raw'];
                            if (rawScore !== NaN && rawScore !== 'NaN') {
                                exercise['cft']['raw'] = rawScore;
                            }
                        }
                        _this.cftExercises.exercises.push(exercise);
                    }
                }
            });
            _this.pftFilteredExercises = _this.pftExercises.exercises;
            _this.cftFilteredExercises = _this.cftExercises.exercises;
        });
        this.calcTotalPftScore();
        this.calcTotalCftScore();
    };
    PftCftScoresComponent.prototype.ngOnChanges = function () { };
    PftCftScoresComponent.prototype.refreshExercises = function (value) {
        var pftExercises = _.cloneDeep(this.pftExercises.exercises);
        var cftExercises = _.cloneDeep(this.cftExercises.exercises);
        if (value) {
            this.pftFilteredExercises = pftExercises.filter(function (ex) { return ex.name.toLowerCase().indexOf(value.toLowerCase()) > -1; });
            this.cftFilteredExercises = cftExercises.filter(function (ex) { return ex.name.toLowerCase().indexOf(value.toLowerCase()) > -1; });
        }
    };
    PftCftScoresComponent.prototype.trackByFn = function (itemKey) {
        return itemKey;
    };
    PftCftScoresComponent.prototype.onExerciseSelected = function (exercise) {
        // console.log('exercise selected: ', exercise);
        // TODO: Add SetSelectedPft or SetSelectedCft to store.actions
        // this.store.dispatch(new SetSelectedRM(exercise))
    };
    PftCftScoresComponent.prototype.openCalcCft = function (exercise) {
        var _this = this;
        var categoryKey = 'CFT';
        if (!exercise) {
            return;
        }
        var dialogRef = this.dialog.open(PftCftCalcDialogComponent, {
            maxWidth: '100vw',
            width: '80%',
            height: '55vh',
            data: {
                exercise: exercise,
                category: categoryKey,
                gender: this.userProfileScoreData.gender,
                age_group: this.getAgeGroupEnum(),
            },
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res && res.success === true) {
                if (res['age_group']) {
                    if (_this.userProfileScoreData) {
                        _this.userProfileScoreData.age_group = res['age_group'];
                        _this.store.dispatch(new SetUserAgeGroup(_this.userProfileScoreData.age_group));
                    }
                }
                if (res['gender']) {
                    if (_this.userProfileScoreData) {
                        _this.userProfileScoreData.gender = res['gender'];
                        _this.store.dispatch(new SetUserGender(_this.userProfileScoreData.gender));
                    }
                }
                // TODO: Update userProfileScoreData on user profile by adding an action
                var index = _this.cftFilteredExercises.indexOf(exercise);
                if (res['exercise'] &&
                    res['exercise']['cft'] !== undefined &&
                    res['exercise']['cft'] !== null) {
                    exercise['cft']['score'] = res['exercise']['cft']['score'];
                    exercise['cft']['raw'] = res['exercise']['cft']['raw'];
                    if (index > -1) {
                        _this.cftFilteredExercises[index] = exercise;
                    }
                    _this.calcTotalCftScore();
                    var newCft = {
                        exercise_id: res['exercise']['id'],
                        type: 'cft',
                        score: res['exercise']['cft']['score'],
                        raw: res['exercise']['cft']['raw'],
                    };
                }
                //   const userProfile = this.store.selectSnapshot(AppState.userProfile)
                //   const statIndex = userProfile.stats.exercises.findIndex(ex => ex.exercise_id === exercise.id);
                //   let action;
                //   if (statIndex > -1) {
                //     action = new Set1RM(new1RM, statIndex);
                //   } else {
                //     action = new Set1RM(new1RM);
                //   }
                //   this.store.dispatch([
                //     action
                //   ]).subscribe(() => {
                //     const categoryIndex = this.exercises.findIndex(cat => cat.id === categoryKey);
                //     const exerciseIndex = this.exercises[categoryIndex].exercises.findIndex(ex => ex.id === exercise.id);
                //     this.exercises[categoryIndex].exercises[exerciseIndex]['1rm'] = new1RM
                //     this.refreshExercises(this.search.value);
                //   })
                // }
            }
            else if (res && res.success === false) {
                // TODO: Update userProfileScoreData on user profile by adding an action
                var index = _this.cftFilteredExercises.indexOf(exercise);
                if (res['exercise'] &&
                    res['exercise']['cft'] !== undefined &&
                    res['exercise']['cft'] !== null) {
                    exercise['cft']['score'] = res['exercise']['cft']['score'];
                    exercise['cft']['raw'] = res['exercise']['cft']['raw'];
                    if (index > -1) {
                        _this.cftFilteredExercises[index] = exercise;
                    }
                    _this.calcTotalCftScore();
                    var newCft = {
                        exercise_id: res['exercise']['id'],
                        type: 'cft',
                        score: res['exercise']['cft']['score'],
                        raw: res['exercise']['cft']['raw'],
                    };
                }
            }
        });
    };
    PftCftScoresComponent.prototype.getAgeGroupEnum = function () {
        if (!this.userProfileScoreData && !this.userProfileScoreData.age_group) {
            return AgeRange.AGE_17_20;
        }
        switch (this.userProfileScoreData.age_group) {
            case AgeRange.AGE_17_20:
                return AgeRange.AGE_17_20;
            case AgeRange.AGE_21_25:
                return AgeRange.AGE_21_25;
            case AgeRange.AGE_26_30:
                return AgeRange.AGE_26_30;
            case AgeRange.AGE_31_35:
                return AgeRange.AGE_31_35;
            case AgeRange.AGE_36_40:
                return AgeRange.AGE_36_40;
            case AgeRange.AGE_41_45:
                return AgeRange.AGE_41_45;
            case AgeRange.AGE_46_50:
                return AgeRange.AGE_46_50;
            default:
                return AgeRange.AGE_51;
        }
    };
    PftCftScoresComponent.prototype.openCalcPft = function (exercise) {
        var _this = this;
        var categoryKey = 'PFT';
        if (!exercise) {
            return;
        }
        var dialogRef = this.dialog.open(PftCftCalcDialogComponent, {
            maxWidth: '100vw',
            width: '85%',
            height: '70vh',
            data: {
                exercise: exercise,
                category: categoryKey,
                gender: this.userProfileScoreData.gender,
                age_group: this.getAgeGroupEnum(),
            },
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res && res.success === true) {
                // console.log('dialog result', res);
                if (res['age_group']) {
                    if (_this.userProfileScoreData) {
                        _this.userProfileScoreData.age_group = res['age_group'];
                        _this.store.dispatch(new SetUserAgeGroup(_this.userProfileScoreData.age_group));
                    }
                }
                if (res['gender']) {
                    if (_this.userProfileScoreData) {
                        _this.userProfileScoreData.gender = res['gender'];
                        _this.store.dispatch(new SetUserGender(_this.userProfileScoreData.gender));
                    }
                }
                // TODO: Update userProfileScoreData on user profile by adding an action
                var index = _this.pftFilteredExercises.indexOf(exercise);
                if (res['exercise'] &&
                    res['exercise']['pft'] !== undefined &&
                    res['exercise']['pft'] !== null) {
                    exercise['pft'] = res['exercise']['pft'];
                    exercise['pft']['score'] = res['exercise']['pft']['score'];
                    exercise['pft']['raw'] = res['exercise']['pft']['raw'];
                    if (index > -1) {
                        _this.pftFilteredExercises[index] = exercise;
                    }
                    _this.calcTotalPftScore();
                    var newPft = {
                        exercise_id: res['exercise']['id'],
                        type: 'pft',
                        score: res['exercise']['pft']['score'],
                        raw: res['exercise']['pft']['raw'],
                    };
                }
                // this.saveScoreObservationDocs();
                //   const userProfile = this.store.selectSnapshot(AppState.userProfile)
                //   const statIndex = userProfile.stats.exercises.findIndex(ex => ex.exercise_id === exercise.id);
                //   let action;
                //   if (statIndex > -1) {
                //     action = new Set1RM(new1RM, statIndex);
                //   } else {
                //     action = new Set1RM(new1RM);
                //   }
                //   this.store.dispatch([
                //     action
                //   ]).subscribe(() => {
                //     const categoryIndex = this.exercises.findIndex(cat => cat.id === categoryKey);
                //     const exerciseIndex = this.exercises[categoryIndex].exercises.findIndex(ex => ex.id === exercise.id);
                //     this.exercises[categoryIndex].exercises[exerciseIndex]['1rm'] = new1RM
                //     this.refreshExercises(this.search.value);
                //   })
                // }
            }
            else if (res && res.success === false) {
                console.log('PFT dialog result', res);
                // TODO: Update userProfileScoreData on user profile by adding an action
                var index = _this.pftFilteredExercises.indexOf(exercise);
                if (res['exercise'] &&
                    res['exercise']['pft'] !== undefined &&
                    res['exercise']['pft'] !== null) {
                    exercise['pft'] = res['exercise']['pft'];
                    exercise['pft']['score'] = res['exercise']['pft']['score'];
                    exercise['pft']['raw'] = res['exercise']['pft']['raw'];
                    if (index > -1) {
                        _this.pftFilteredExercises[index] = exercise;
                    }
                    _this.calcTotalPftScore();
                    var newPft = {
                        exercise_id: res['exercise']['id'],
                        type: 'pft',
                        score: res['exercise']['pft']['score'],
                        raw: res['exercise']['pft']['raw'],
                    };
                }
            }
        });
    };
    PftCftScoresComponent.prototype.calcTotalPftScore = function () {
        var totalScore = 0;
        var isUndefined = true;
        var pftCount = 0;
        for (var i = 0; i < this.pftFilteredExercises.length; ++i) {
            var exercise = this.pftFilteredExercises[i];
            if (exercise['pft'] === undefined || exercise['pft'] === null) {
                continue;
            }
            if (exercise['pft']['score'] === undefined ||
                exercise['pft']['score'] === null ||
                exercise['pft']['score'] === 'NaN') {
                continue;
            }
            isUndefined = false;
            if (exercise['pft']['score'] === 0 || exercise['pft']['score'] === '0') {
                totalScore = 0;
                ++pftCount;
                break;
            }
            else {
                totalScore += exercise['pft']['score'];
                ++pftCount;
            }
        }
        if (pftCount < this.minPftCount || pftCount > this.maxPftCount || isUndefined) {
            this.totalPftScore = undefined;
        }
        else {
            this.totalPftScore = totalScore;
        }
        if (this.totalPftScore === 0) {
            this.pftMessage = this.getFailingMessage('PFT');
        }
        else {
            this.pftMessage = '';
        }
    };
    PftCftScoresComponent.prototype.getFailingMessage = function (category) {
        return "Your total " + category + " event performance is 0 because you did not pass the minimum requirements of at least one exercise.";
    };
    PftCftScoresComponent.prototype.calcTotalCftScore = function () {
        var totalScore = 0;
        var isUndefined = true;
        var cftCount = 0;
        for (var i = 0; i < this.cftFilteredExercises.length; ++i) {
            var exercise = this.cftFilteredExercises[i];
            if (exercise['cft'] === undefined || exercise['cft'] === null) {
                continue;
            }
            if (exercise['cft']['score'] === undefined ||
                exercise['cft']['score'] === null ||
                exercise['cft']['score'] === 'NaN') {
                continue;
            }
            isUndefined = false;
            if (exercise['cft']['score'] === 0 || exercise['cft']['score'] === '0') {
                totalScore = 0;
                ++cftCount;
                break;
            }
            else {
                totalScore += exercise['cft']['score'];
                ++cftCount;
            }
        }
        if (cftCount < this.minCftCount || cftCount > this.maxCftCount || isUndefined) {
            this.totalCftScore = undefined;
        }
        else {
            this.totalCftScore = totalScore;
        }
        if (this.totalCftScore === 0) {
            this.cftMessage = this.getFailingMessage('CFT');
        }
        else {
            this.cftMessage = '';
        }
    };
    PftCftScoresComponent.prototype.saveScoreObservationDocs = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pftObservationDocument, cftObservationDocument;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.calcTotalPftScore();
                        this.calcTotalCftScore();
                        pftObservationDocument = this.dataService.generatePftCftScoreObservation(this.totalPftScore, 'PFT');
                        cftObservationDocument = this.dataService.generatePftCftScoreObservation(this.totalCftScore, 'CFT');
                        return [4 /*yield*/, this.dataService.storeObservationDocument(pftObservationDocument)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.dataService.postScoreDocument(pftObservationDocument)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.dataService.storeObservationDocument(cftObservationDocument)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.dataService.postScoreDocument(cftObservationDocument)];
                    case 4:
                        _a.sent();
                        this.dataService.pftCftChartReset();
                        return [2 /*return*/];
                }
            });
        });
    };
    PftCftScoresComponent.prototype.disableCftAdd = function (item) {
        var hasScore = function (exercises, name, filter, id) {
            return name.includes(filter) &&
                exercises.filter(function (ex) {
                    return ex.name.includes(filter) && ex.cft && ex.cft.raw && ex.id !== id;
                }).length > 0;
        };
        var hasBeenScored = this.cftFilteredExercises.filter(function (ex) { return ex.name === item.name && ex.cft && ex.cft.raw; }).length > 0;
        var notMaxExercises = this.cftFilteredExercises.filter(function (ex) { return (ex.cft && ex.cft.raw); }).length < this.maxCftCount;
        if (!notMaxExercises && !hasBeenScored) {
            return true;
        }
        if (item.exercise_categories.includes(this.cftCategoryId)) {
            if (hasScore(this.cftFilteredExercises, item.name, 'MTC', item.id) ||
                hasScore(this.cftFilteredExercises, item.name, 'MANUF', item.id)) {
                return true;
            }
        }
        // if item has an altitude alternate version, disable other non-altitude or altitude options
        // (whichever is opposite of one with the score)
        if (!item.name.includes('AL')) {
            if (item.name.includes('altitude')) {
                if (this.cftFilteredExercises.filter(function (ex) {
                    return (!ex.name.includes('AL') && !ex.name.includes('altitude') && ex.cft && ex.cft.raw && ex.id !== item.id);
                }).length === 0) {
                    return false;
                }
                return true;
            }
            else {
                if (this.cftFilteredExercises.filter(function (ex) {
                    return (!ex.name.includes('AL') && ex.name.includes('altitude') && ex.cft && ex.cft.raw && ex.id !== item.id);
                }).length === 0) {
                    return false;
                }
                return true;
            }
        }
        return false;
    };
    PftCftScoresComponent.prototype.disablePftAdd = function (item) {
        var _this = this;
        var hasScore = function (name, filter) {
            return item.name === name &&
                _this.pftFilteredExercises.filter(function (ex) { return ex.name === filter && ex.pft && ex.pft.raw; }).length > 0;
        };
        var hasBeenScored = this.pftFilteredExercises.filter(function (ex) { return ex.name === item.name && ex.pft && ex.pft.raw; }).length > 0;
        var notMaxExercises = this.pftFilteredExercises.filter(function (ex) { return (ex.pft && ex.pft.raw); }).length < this.maxPftCount;
        if (!notMaxExercises && !hasBeenScored) {
            return true;
        }
        if (item.exercise_categories.includes(this.pftCategoryId)) {
            if (hasScore('Pull-ups', 'Push-ups') ||
                hasScore('Push-ups', 'Pull-ups')) {
                return true;
            }
            if (hasScore('Crunches', 'Plank') || hasScore('Plank', 'Crunches')) {
                return true;
            }
            if (hasScore('3-mile run', '3-mile run (altitude)') ||
                hasScore('3-mile run (altitude)', '3-mile run') ||
                hasScore('3-mile run', '5K Row (altitude)') ||
                hasScore('3-mile run (altitude)', '5K Row')) {
                return true;
            }
            if (hasScore('5K Row', '5K Row (altitude)') ||
                hasScore('5K Row (altitude)', '5K Row') ||
                hasScore('5K Row', '3-mile run (altitude)') ||
                hasScore('5K Row (altitude)', '3-mile run')) {
                return true;
            }
        }
        return false;
    };
    PftCftScoresComponent.prototype.clearPft = function () {
        this.pftFilteredExercises.forEach(function (ex) {
            ex.pft = undefined;
        });
        this.calcTotalPftScore();
    };
    PftCftScoresComponent.prototype.clearCft = function () {
        this.cftFilteredExercises.forEach(function (ex) {
            ex.cft = undefined;
        });
        this.calcTotalCftScore();
    };
    return PftCftScoresComponent;
}());
export { PftCftScoresComponent };
