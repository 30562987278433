import * as _ from 'lodash'
import * as moment from 'moment'
import 'moment-timezone'
import { v4 as uuid } from 'uuid'
import { DatabaseService } from '../services/database.service'

export class DatabaseRecord {
  static objectStoreName: string

  public key: string
  private _foreignKeys = new Map()
  private _foreignRecords = new Map()

  public static apiRoute(filters?: any): string {
    return
  }

  protected static parseDate(date: string): moment.Moment {
    return moment.tz(date, 'Etc/UTC')
  }

  protected static getRecords (objectStoreName: string, keys: string[]): Promise<DatabaseRecord[]> {
    return new Promise((resolve, reject) => {
      // console.log('KEYS')
      if (_.isArray(keys)) {
        resolve([]) // TODO
      }
    })
  }

  constructor(data: {
    key?: string,
    id?: string,
  } = {}
  ) {
    this.key = data.key || data.id || uuid()
  }

  toJSON(fields?: string[]) {
    const object = {
      key: this.key
    }

    if (_.isNil(fields)) {
      fields = []
    }

    _.each(fields, (field) => {
      let value = this[field]

      // look at the private cache of foreign records if we never loaded records
      // if (_.isNil(value) && !_.isNil(this['_' + field])) {
      //   value =
      // }

      if (!_.isNil(value)) {
        if (moment.isMoment(value)) {
          value = value.toDate()
        } else if (_.isArray(value) && _.size(value) > 0 && value[0] instanceof DatabaseRecord) {
          value = _.map(value, (record) => record.key)
        }

        object[field] = value
      }
    })

    return object
  }

  loadAssociations(db: DatabaseService) {
    const promises = []

    this._foreignKeys.forEach((keys: any[], typeClass) => {
      const load = db.getRecords(typeClass, keys).then((records) => {
        this[typeClass.objectStoreName] = records
      })

      promises.push(load)
    })

    return Promise.all(promises)
  }

  getRecords(typeClass: DatabaseRecordType) {
    return this.getForeignRecords(typeClass)
  }

  /**
   * Given the name of the foreign table, return the cached foreign record keys
   */
  protected getForeignRecords(typeClass: DatabaseRecordType) {
    return this._foreignRecords.get(typeClass)
  }

  protected getForeignKeys(typeClass: DatabaseRecordType): string[] {
    const records = this._foreignKeys.get(typeClass)
    if (!records || records.length === 0) {
      return []
    } else {
      return records
    }
  }

  protected setForiegnRecords(typeClass: DatabaseRecordType, records: Array<string | DatabaseRecord>) {
    if (_.isArray(records) && _.size(records) > 0) {
      const keys: string[] = []
      const dbRecords: DatabaseRecord[] = this._foreignRecords.get(typeClass) || []

      for (const record of records) {
        if (_.isString(record)) {
          keys.push(record)
        } else if (record instanceof DatabaseRecord) {
          dbRecords.push(record)
          keys.push(record.key)
        }
      }

      this._foreignKeys.set(typeClass, keys)
      this._foreignRecords.set(typeClass, dbRecords)
    }
  }
}

export type DatabaseRecordType = typeof DatabaseRecord
