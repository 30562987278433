import * as tslib_1 from "tslib";
import { DatabaseRecord } from '../classes/database-record.class';
import { PlanExercise } from './plan-excercises.model';
var PlanBlock = /** @class */ (function (_super) {
    tslib_1.__extends(PlanBlock, _super);
    function PlanBlock(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.name = data['name'];
        _this.isCustom = data['isCustom'];
        _this.columns = data['columns'];
        _this.notes = data['notes'];
        _this.tier = data['tier'];
        _this.plannedExercises = data['plannedExercises'] || [];
        _this.isComplete = data['isComplete'] || false;
        _this.planDayUUID = data['planDayUUID'];
        return _this;
    }
    Object.defineProperty(PlanBlock.prototype, "plannedExercises", {
        get: function () {
            return this.getForeignKeys(PlanExercise);
        },
        set: function (records) {
            this.setForiegnRecords(PlanExercise, records);
        },
        enumerable: true,
        configurable: true
    });
    PlanBlock.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, [
            'name',
            'isCustom',
            'columns',
            'notes',
            'tier',
            'plannedExercises',
            'isComplete',
            'distanceCompleted',
            'timeCompleted',
            'loadCompleted',
            'repsCompleted',
            'planDayUUID',
        ]);
    };
    PlanBlock.objectStoreName = 'planBlocks';
    return PlanBlock;
}(DatabaseRecord));
export { PlanBlock };
