import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DataService } from 'app/services/data.service';
import { PAGETRANSITIONS, PageTransitionService } from 'app/services/page-transition.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { PlanDocumentUtils } from 'fitforce-document-sync';
import { DocumentService } from 'app/services/document.service';
var WorkoutHistoryComponent = /** @class */ (function () {
    function WorkoutHistoryComponent(router, dataService, documentService, pageTransitionService) {
        this.router = router;
        this.dataService = dataService;
        this.documentService = documentService;
        this.pageTransitionService = pageTransitionService;
    }
    WorkoutHistoryComponent.prototype.ngOnInit = function () {
        this.loadSessions();
    };
    WorkoutHistoryComponent.prototype.loadSessions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var documents, filteredSessions;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getAllWorkoutSessionDocuments()];
                    case 1:
                        documents = _a.sent();
                        filteredSessions = _.filter(documents, function (doc) {
                            var currentDate = moment().startOf('day').hour(12);
                            if (doc.document.end_time) {
                                var end_time = moment(doc.document.end_time);
                                if (currentDate.diff(end_time, 'months') <= 3) {
                                    return true;
                                }
                            }
                        });
                        return [4 /*yield*/, this.documentService.restoreData().toPromise()];
                    case 2:
                        _a.sent();
                        this.sessions = _.orderBy(filteredSessions, function (s) { return s.document.end_time; }, 'desc').map(function (m) {
                            var doc = m.document;
                            doc.total_load = Math.floor(doc.rpe * (doc.completion_time_ms / 1000 / 60)) || 0;
                            doc.end_time_pretty = moment(doc.end_time).format('MMMM DD, YYYY');
                            // Used for historic sessions too
                            if (!doc.phase) {
                                var planDocument = _this.documentService.store.planDocuments.find(function (planDoc) {
                                    return planDoc.document.id === doc.plan.id;
                                });
                                // Unusual issue where a planDocument won't exist for a key/id
                                if (planDocument) {
                                    var workout = planDocument.document.workouts.find(function (workoutDoc) {
                                        return workoutDoc.id === doc.workout.id;
                                    });
                                    var phase = PlanDocumentUtils.findPhase(planDocument.document.phases, {
                                        day: workout.schedule.day[0], week: workout.schedule.week[0]
                                    });
                                    if (phase) {
                                        doc.phase = {
                                            uuid: phase.id,
                                            name: phase.name,
                                            color: phase.color
                                        };
                                    }
                                }
                            }
                            return doc;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkoutHistoryComponent.prototype.onClickCard = function (session) {
        this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT);
        this.router.navigate(['exercise', 'complete', session.id]);
    };
    return WorkoutHistoryComponent;
}());
export { WorkoutHistoryComponent };
