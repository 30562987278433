import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TabIndex } from './classes/alert';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { SetShowNotification } from 'app/state';
import { AppState } from 'app/state/app/app.state';
import { Observable } from 'rxjs';
var AlertsComponent = /** @class */ (function () {
    function AlertsComponent(dialog, store, router) {
        this.dialog = dialog;
        this.store = store;
        this.router = router;
        this.alerts = [
            {
                message: 'Need mental health care? Get it now!',
                url: 'https://www.tricare.mil/CoveredServices/Mental/GettingMHCare',
            },
            {
                message: 'Want to prevent injury? Here\'s how.',
                url: 'https://www.hprc-online.org/physical-fitness/injury-prevention',
            },
            {
                message: 'See recommended readings for Marines.',
                url: 'https://www.fitness.marines.mil/Portals/211/Resiliency%20Reading%20List_updated%2014AUG2020_1.pdf',
            },
            {
                message: 'Assess your spiritual fitness on a weekly basis.',
                url: 'https://www.fitness.marines.mil/Resilience/Spiritual-Fitness/',
            },
            {
                message: 'Listen to the Defense Health Agency\'s Military Meditation Coach Podcast.',
                url: 'https://podcasts.apple.com/us/podcast/military-meditation-coach-podcast/id1313813296?mt=2',
            },
            {
                message: 'How are you feeling today? Learn how to manage it.',
                url: 'https://health.mil/Military-Health-Topics/Total-Force-Fitness/Psychological-Fitness',
            },
            {
                message: 'Need a better night\'s sleep? Listen to the DHA\'s sleep podcast.',
                url: 'https://podcasts.apple.com/us/podcast/a-better-nights-sleep/id1309347648?mt=2',
            },
            {
                message: 'Get involved with the MCCS and volunteer today!',
                url: 'https://usmc-mccs.org/index.cfm/services/activities/volunteering/',
            },
            {
                message: 'Don\'t forget to complete any workouts assigned to you today.',
                tabIndex: TabIndex.WORKOUTS,
            },
            {
                message: 'Check and see how you\'re progressing in your workouts!',
                tabIndex: TabIndex.STATS,
            },
        ];
    }
    AlertsComponent.prototype.generateAlertIndex = function () {
        if (this.alerts && this.alerts.length > 0) {
            return Math.floor(Math.random() * (this.alerts.length));
        }
        return -1;
    };
    AlertsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showNotification$.subscribe(function (showNotification) {
            _this.showNotification = showNotification;
        });
    };
    AlertsComponent.prototype.showAlert = function () {
        this.store.dispatch(new SetShowNotification(false));
        var alertIndex = this.generateAlertIndex();
        if (alertIndex > -1) {
            this.selectedAlert = this.alerts[alertIndex];
            if (this.selectedAlert) {
                this.openDialog(this.selectedAlert);
            }
        }
    };
    AlertsComponent.prototype.openDialog = function (selectedAlert) {
        var dialogData = {
            alert: selectedAlert
        };
        var dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '350vw',
            height: '30vh',
            data: dialogData
        });
    };
    tslib_1.__decorate([
        Select(AppState.showNotification),
        tslib_1.__metadata("design:type", Observable)
    ], AlertsComponent.prototype, "showNotification$", void 0);
    return AlertsComponent;
}());
export { AlertsComponent };
