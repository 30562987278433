import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { SyncWorkoutSessions, InitMain, ChangeActiveDate, SetActiveDay, SetActiveDate, SetActiveToDefault, OnlineStatusChange, SetMainTabIndex, } from 'app/state/app/app.actions';
import { Observable } from 'rxjs';
import { AppState } from 'app/state/app/app.state';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { PageTransitionService, PAGETRANSITIONS } from 'app/services/page-transition.service';
import { RestService } from 'app/services/rest.service';
import { DocumentService } from 'app/services/document.service';
import { SyncLoggingService } from 'fitforce-logging';
var DEFAULT_SPINNER_TEXT = 'Syncing plans...';
var MainComponent = /** @class */ (function () {
    function MainComponent(router, _cdr, documentService, spinner, store, dialog, pageTransitionService, restService, syncLog) {
        this.router = router;
        this._cdr = _cdr;
        this.documentService = documentService;
        this.spinner = spinner;
        this.store = store;
        this.dialog = dialog;
        this.pageTransitionService = pageTransitionService;
        this.restService = restService;
        this.syncLog = syncLog;
        this.spinnerText = DEFAULT_SPINNER_TEXT;
        this.objectKeys = Object.keys;
        this.isOnline = true;
        this.tabIndex = 0;
        this.dayToWorkout = {};
        this.availableDates = [];
    }
    MainComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.calendarVisible = false;
        this.tabIndex$.subscribe(function (index) {
            _this.tabIndex = index || 0;
        });
        this.isSyncing$.subscribe(function (isSyncing) {
            if (isSyncing) {
                _this.spinnerText = DEFAULT_SPINNER_TEXT;
                _this.spinner.show();
            }
            else {
                _this.spinner.hide();
            }
        });
        this.organizedDays$.subscribe(function (days) {
            var activeDate = moment(_this.store.selectSnapshot(AppState.activeDate));
            _this.isToday = activeDate.isSame(moment(), 'day');
            _this.activeDate = activeDate.format('DD-MMM-YYYY');
            _this.activeDateDay = activeDate.format('dddd');
            _this.activeDateDate = activeDate.toDate();
            _this.activeDayMonth = activeDate.format('MMMM YYYY');
        });
        this.dayToWorkout$.subscribe(function (value) {
            _this.dayToWorkout = value;
            _this.availableDates = value ? Object.keys(value).map(function (key) { return moment(key); }).sort(function (a, b) { return a.isBefore(b) ? -1 : 1; }) : [];
        });
        this.store.dispatch(new InitMain()).subscribe(function () {
            if (_this.restService.isOnline) {
                _this.store.dispatch(new SyncWorkoutSessions());
            }
        });
        this.onlineSub = this.restService.isOnlineSubscription().subscribe(function (isOnline) {
            _this.isOnline = isOnline;
            _this.store.dispatch(new OnlineStatusChange(isOnline));
        });
        // this.syncLog.logStream.subscribe(message => {
        //   if (message && message.logLevel === LogLevel.INFO) {
        //     this.spinnerText = message.message;
        //     this._cdr.detectChanges()
        //   }
        // })
        this.store.dispatch(new SetMainTabIndex(0));
    };
    MainComponent.prototype.openPlayCard = function (group, plan, day) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var planDocument, workoutDocument, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 4]);
                        // Make sure plan playcard is available, if not, fetch data again and *then* change
                        return [4 /*yield*/, this.documentService.restoreData().toPromise()];
                    case 1:
                        // Make sure plan playcard is available, if not, fetch data again and *then* change
                        _a.sent();
                        planDocument = _.find(this.documentService.store.planDocuments, { key: plan.key });
                        workoutDocument = _.find(planDocument.document.workouts, { id: day.key });
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        this.spinnerText = 'Syncing missing exercises...';
                        return [4 /*yield*/, this.store.dispatch(new InitMain(true)).toPromise()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.store.dispatch(new SetActiveDay(group, plan, day)).subscribe(function () {
                            _this.spinnerText = DEFAULT_SPINNER_TEXT;
                            _this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT);
                            _this.router.navigate(['playcard-summary']);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MainComponent.prototype.openHistory = function (sessionKey) {
        this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT);
        this.router.navigate(['exercise', 'complete', sessionKey]);
    };
    MainComponent.prototype.hasPrevDay = function () {
        var _this = this;
        var index = this.availableDates.findIndex(function (date) { return date.isSame(moment(_this.activeDate)); });
        var next = index - 1;
        return next > -1 && next < this.availableDates.length;
    };
    MainComponent.prototype.hasNextDay = function () {
        var _this = this;
        var index = this.availableDates.findIndex(function (date) { return date.isSame(moment(_this.activeDate)); });
        var next = index + 1;
        return next > -1 && next < this.availableDates.length;
    };
    MainComponent.prototype.onPreviousWeek = function () {
        this.store.dispatch(new ChangeActiveDate(true));
    };
    MainComponent.prototype.onNextWeek = function () {
        this.store.dispatch(new ChangeActiveDate());
    };
    MainComponent.prototype.onToday = function () {
        this.store.dispatch(SetActiveToDefault);
        this.calendarVisible = false;
    };
    MainComponent.prototype.onToggleCalendar = function () {
        this.calendarVisible = !!!this.calendarVisible;
    };
    MainComponent.prototype.onCalendarSelect = function (selectedDate) {
        var _this = this;
        this.store.dispatch(new SetActiveDate(moment(selectedDate).format())).subscribe(function () {
            _this.onToggleCalendar();
            _this.calendarVisible = false;
        });
    };
    MainComponent.prototype.dateClass = function () {
        var _this = this;
        return function (date) {
            if (_this.dayToWorkout[moment(date).format()]) {
                return 'has-workouts';
            }
            else {
                return '';
            }
        };
    };
    MainComponent.prototype.onClickCard = function (group, workout) {
        if (workout.workoutSessions.length > 0) {
            this.openHistory(workout.workoutSessions[0].key || workout.workoutSessions[0].id);
        }
        else if (!workout.day.isRestricted) {
            this.openPlayCard(group, workout.plan, workout.day);
        }
    };
    MainComponent.prototype.onSelectedTabChange = function (event) {
        var index = event.index;
        this.store.dispatch(new SetMainTabIndex(index));
    };
    MainComponent.prototype.getPhaseColor = function (workout) {
        if (!workout.phase.color && workout.phase.exercise_program_phase_id) {
            if (this.documentService.store.exerciseProgram) {
                var foundPhase = this.documentService.store.exerciseProgram.document.phases.find(function (p) { return p.id === workout.phase.exercise_program_phase_id; });
                if (foundPhase) {
                    return foundPhase.color;
                }
            }
        }
        else if (workout.phase) {
            return workout.phase.color;
        }
        return 'white';
    };
    tslib_1.__decorate([
        Select(AppState.mainTabIndex),
        tslib_1.__metadata("design:type", Observable)
    ], MainComponent.prototype, "tabIndex$", void 0);
    tslib_1.__decorate([
        Select(AppState.isSyncing),
        tslib_1.__metadata("design:type", Observable)
    ], MainComponent.prototype, "isSyncing$", void 0);
    tslib_1.__decorate([
        Select(AppState.unsyncedWorkoutSessions),
        tslib_1.__metadata("design:type", Observable)
    ], MainComponent.prototype, "unsyncedWorkoutSessions$", void 0);
    tslib_1.__decorate([
        Select(AppState.organizedDays),
        tslib_1.__metadata("design:type", Observable)
    ], MainComponent.prototype, "organizedDays$", void 0);
    tslib_1.__decorate([
        Select(AppState.dayToWorkout),
        tslib_1.__metadata("design:type", Observable)
    ], MainComponent.prototype, "dayToWorkout$", void 0);
    return MainComponent;
}());
export { MainComponent };
