import * as _ from 'lodash'

import { AddNewSet, ChangeExercise, DeleteSet, EditSet, ExecuteSet, SaveComments, Set1RM, SetExecutionsRM } from 'app/state'
import { Component, OnInit } from '@angular/core'
import { ExerciseProgramDocumentUtils, ResourceType, getDefaultParams } from 'fitforce-document-sync'
import { MatDialog, MatSnackBar } from '@angular/material'

import { AppState } from 'app/state/app/app.state'
import { DocumentService } from 'app/services/document.service'
import { EditSetDialogComponent } from '../edit-set-dialog/edit-set-dialog.component'
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component'
import { RMCalcDialogComponent } from '../../rmcalc-dialog/rmcalc-dialog.component'
import { RMCalcSetDialogComponent } from '../../rmcalc-set-dialog/rmcalc-set-dialog.component'
import { SetsRepsLoadPipe } from 'app/pipes/sets-reps-load.pipe'
import { Store } from '@ngxs/store'
import { VideoDialogComponent } from 'app/modules/video-dialog/video-dialog.component'
import { WorkoutSession } from 'app/models/workout-session-document.model'
import { WorkoutState } from 'app/state/workout/workout.state'

@Component({
  selector: 'app-subexercise',
  templateUrl: './subexercise.component.html',
  styleUrls: ['./subexercise.component.scss']
})
export class SubexerciseComponent implements OnInit {
  currentWorkoutSession: WorkoutSession
  tier: any
  tierRow: any
  rowNoteVisible = false
  currentWorkoutIndex: number
  currentRowIndex: number
  exerciseVideo: any
  hasNext: boolean
  hasPrev: boolean
  hasRM = {
    oneRM: {},
    index: undefined
  }
  setDetails: string
  toggleStatus: number
  setParams: any

  hasTimer = false;
  timerInterval = undefined;
  timerState: 'on' | 'off' = 'off';
  timerOrientation: 'up' | 'down' = 'down';
  timerStart = 0;
  timerMillis = 0;
  timerFinished = false;
  get timer(): { mins: string, secs: string } {
    let mins = 0;
    let secs = 0;
    if (this.timerOrientation === 'up') {
      mins = Math.floor(this.timerMillis / 1000 / 60);
      secs = Math.floor(this.timerMillis / 1000) - (mins * 60);
    } else {
      const adjusted = this.timerOrientation === 'down' ? this.timerStart - this.timerMillis : this.timerMillis;
      mins = Math.floor(adjusted / 1000 / 60);
      secs = Math.floor((adjusted - (mins * 1000 * 60)) / 1000);
    }

    return { mins: mins.toString().padStart(2, '0'), secs: secs.toString().padStart(2, '0') }
  }

  constructor(
    private store: Store,
    private documentService: DocumentService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private setRepsLoadPipe: SetsRepsLoadPipe,
  ) { }

  ngOnInit() {
    this.loadExercise()
  }

  async loadExercise() {
    this.process1RM()
    if (this.hasRM.hasOwnProperty('index') && this.hasRM.index >= 0) {
      await this.store.dispatch(new SetExecutionsRM(this.hasRM.oneRM)).toPromise()
    }
    this.currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession)
    this.currentWorkoutIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex)
    this.currentRowIndex = this.store.selectSnapshot(WorkoutState.currentRowIndex)
    this.tier = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex]
    this.tierRow = _.cloneDeep(this.tier.rows[this.currentRowIndex])

    await this.documentService.restoreData().toPromise()
    this.updateArrows(
      this.currentWorkoutIndex,
      this.currentRowIndex,
      this.currentWorkoutSession.workout.tiers.length,
      this.tier.rows.length
    )

    if (this.tierRow) {
      const exerciseReference = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex].exercise;
      const exercise = exerciseReference ? this.documentService.store.exerciseProgram.document.exercises.find(
        ex => ex.id === exerciseReference.exercise_program_id
      ) : undefined;
      if (exercise) {
        this.setParams = ExerciseProgramDocumentUtils.getPossibleParamsForExercise(
          this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex].exercise.exercise_program_id,
          this.documentService.store.exerciseProgram.document
        )
      } else {
        this.setParams = getDefaultParams();
      }

      // if (this.tierRow.notes) {
      //   this.rowNoteVisible = true
      // } else {
      //   this.rowNoteVisible = false
      // }

      this.checkToggleStatus()
      this.documentService.restoreData().subscribe(() => {
        this.exerciseVideo = this.getExerciseVideo()
      })
      this.getSetsRepsLoad()

      if (this.setParams.find(param => param.param === 'time')) {
        this.hasTimer = true;
        this.timerOrientation = 'up'
        this.timerStart = 0;
      } else if (this.setParams.find(param => param.param === 'timer') && this.tierRow.parameters['timer']) {
        this.timerOrientation = 'down'
        this.hasTimer = true;
        const _param = this.setParams.find(param => param.param === 'timer');
        this.timerStart = _param.unit === 'minutes'
          ? Math.floor(Number(this.tierRow.parameters[_param.param]) * 1000 * 60)
          : Math.floor(Number(this.tierRow.parameters[_param.param]) * 1000);
      } else {
        this.hasTimer = false;
      }
    }

    // tslint:disable
    this.timerInterval && clearInterval(this.timerInterval)
    // tslint:enable
    this.timerMillis = 0;
    this.timerState = 'off'
    this.timerFinished = false;
  }

  process1RM() {
    const currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession)
    const currentWorkoutIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex)
    const currentRowIndex = this.store.selectSnapshot(WorkoutState.currentRowIndex)
    const tier = currentWorkoutSession.workout.tiers[currentWorkoutIndex]
    const tierRow = _.cloneDeep(tier.rows[currentRowIndex])
    const userProfile = this.store.selectSnapshot(AppState.userProfile)
    let index = -1
    // If tierRow is undefined..
    if (tierRow) {
      index = userProfile.stats.exercises.findIndex(e => {
        return e.exercise_id === tierRow.exercise.exercise_program_id
      })
    }
    if (index !== -1) {
      this.hasRM = {
        oneRM: userProfile.stats.exercises[index],
        index: index
      }
    } else {
      this.hasRM = {
        oneRM: {},
        index: undefined
      }
    }
  }

  updateArrows(currentWorkoutIndex, currentRowIndex, tiersLength, rowsLength) {
    this.hasPrev = true
    this.hasNext = true
    if (currentRowIndex === 0 && currentWorkoutIndex === 0) {
      this.hasPrev = false
    }
    if (currentRowIndex >= rowsLength - 1 && currentWorkoutIndex >= tiersLength - 1) {
      this.hasNext = false
    }
  }

  inSetParams(param: string) {
    if (this.setParams) {
      return this.setParams.some(p => p.param === param)
    }
    return true
  }

  getSetsRepsLoad() {
    this.setDetails = this.setRepsLoadPipe.transform({
      setParams: this.setParams,
      currentWorkoutSession: this.currentWorkoutSession,
      currentWorkoutIndex: this.currentWorkoutIndex,
      currentRowIndex: this.currentRowIndex,
      hasSet: true,
      joinCharacter: ' | '
    })
  }

  checkToggleStatus() {
    let totalComplete = 0
    let toggleStatus = 0
    for (const execution of this.tierRow.executions) {
      if (execution.complete_time) {
        totalComplete += 1
      }
    }
    if (totalComplete >= 1) {
      toggleStatus = 1
    }
    if (totalComplete === this.tierRow.executions.length) {
      toggleStatus = 2
    }
    this.toggleStatus = toggleStatus
  }

  isExecuted(setIndex: number) {
    if (this.tierRow.executions[setIndex] && this.tierRow.executions[setIndex].complete_time) {
      return true
    }
    return false
  }

  onExecuteSet(setIndex) {
    this.store.dispatch(new ExecuteSet(setIndex, this.tierRow.executions[setIndex].parameter_values)).subscribe(() => {
      this.loadExercise()
    })
  }

  onAddNewSet($event) {
    // Use param values from last set
    const paramValues = _.last(this.tierRow.executions)['parameter_values']
    this.store.dispatch(new AddNewSet(paramValues)).subscribe(() => {
      this.loadExercise()
    })
  }

  onDeleteSet($event) {
    // Use param values from last set
    const paramValues = _.last(this.tierRow.executions)['parameter_values']
    this.store.dispatch(new DeleteSet(paramValues)).subscribe(() => {
      this.loadExercise()
    })
  }

  getExerciseVideo() {
    const exerciseId = this.tierRow.exercise.exercise_program_id
    const exerciseDetails = _.find(this.documentService.store.exerciseProgram.document.exercises, { id: exerciseId })
    if (exerciseDetails && exerciseDetails.resources && exerciseDetails.resources.length > 0) {
      return _.find(exerciseDetails.resources, { type: ResourceType.Video })
    }
    return
  }

  openVideo() {
    this.dialog.open(VideoDialogComponent, {
      panelClass: 'modal-workout-video',
      maxWidth: '100vw',
      width: '100%',
      data: {
        title: this.exerciseVideo.title,
        videoId: this.exerciseVideo.asset_id.replace('video:', ''),
      },
    })
  }

  openNotesDialog() {
    const dialogRef = this.dialog.open(NotesDialogComponent, {
      maxWidth: '100vw',
      width: '80%',
      data: {
        isTierNote: true,
      }
    })
  }

  openCommentsDialog() {
    const dialogRef = this.dialog.open(NotesDialogComponent, {
      maxWidth: '100vw',
      width: '80%',
      data: {
        isTierNote: false,
      }
    })

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.success) {
        this.store.dispatch(new SaveComments(res.notes)).subscribe(() => {
          this.snackBar.open('Saved notes.', undefined, {
            duration: 2500
          })
        })
      }
    })
  }

  openEditSet(setIndex: number) {
    const tierIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex)
    const rowIndex = this.store.selectSnapshot(WorkoutState.currentRowIndex)
    const dialogRef = this.dialog.open(EditSetDialogComponent, {
      id: setIndex.toString(),
      maxWidth: '100vw',
      width: '80%',
      data: {
        tierIndex,
        rowIndex,
        setIndex
      }
    })

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.success) {
        this.tierRow.executions[setIndex].parameter_values = res.setParamValues
        this.store.dispatch(new EditSet(
          tierIndex,
          rowIndex,
          setIndex,
          res.setParamValues,
          this.tierRow.executions[setIndex].complete_time,
          true
        )).subscribe(() => {
          this.loadExercise()
        })
      }
    })
  }

  openCalc1RM() {
    const dialogRef = this.dialog.open(RMCalcDialogComponent, {
      maxWidth: '100vw',
      width: '80%',
      data: {
        hasRM: this.hasRM
      }
    })

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.success) {
        if (res['1rm']) {
          const new1RM = {
            'exercise_id': this.tierRow.exercise.exercise_program_id,
            '1rm': res['1rm'],
            'units': 'lbs'
          }
          if (!res['rmManual']) {
            this.dialog.open(RMCalcSetDialogComponent, {
              maxWidth: '100vw',
              width: '80%',
              data: {
                exerciseName: this.tierRow.exercise.name,
                results: res
              }
            })
          }
          this.store.dispatch([
            new SetExecutionsRM(new1RM, true),
            new Set1RM(new1RM, this.hasRM.index),
          ]).subscribe(() => {
            this.loadExercise()
          })
        }
      }
    })
  }

  changeExercise(prev = false) {
    // disable arrows if not checked
    if (prev || this.hasNext) {
      this.store.dispatch(new ChangeExercise(prev)).subscribe(() => {
        this.toggleStatus = 0
        this.loadExercise()
      })
    }
  }

  changeTier(event) {
    this.loadExercise()
  }

  toggleAllSets(checked: boolean) {
    if (checked) {
      this.toggleStatus = 0
    } else {
      this.toggleStatus = 2
    }
    this.tierRow.executions.forEach((v, i) => {
      if (!checked && !v.complete_time) {
        this.onExecuteSet(i)
      } else if (checked && v.complete_time) {
        this.onExecuteSet(i)
      }
    })
  }

  isValidLoad(number) {
    return !_.isNaN(_.parseInt(number)) && (_.parseInt(number) >= 0)
  }

  onToggleRowNote() {
    this.rowNoteVisible = !this.rowNoteVisible
  }

  startTimer() {
    this.timerInterval = setInterval(() => {
      if (this.timerOrientation === 'down' && this.timerMillis >= this.timerStart) {
        this.timerFinished = true;
      } else {
        this.timerMillis += 1000
      }
    }, 1000);
    this.timerState = 'on';
  }

  pauseTimer() {
    // tslint:disable
    this.timerInterval && clearInterval(this.timerInterval);
    // tslint:enable
    this.timerState = 'off';
  }

  logTime() {
    this.timerState = 'off';
    // tslint:disable
    this.timerInterval && clearInterval(this.timerInterval);
    // tslint:enable

    const tierIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex)
    const rowIndex = this.store.selectSnapshot(WorkoutState.currentRowIndex)

    const param = this.setParams.find(p => p.param === 'time');
    const time = param.unit === 'minutes' ? this.timerMillis / 1000 / 60 : this.timerMillis / 1000;

    this.store.dispatch(new EditSet(
      tierIndex,
      rowIndex,
      0,
      { time: time.toFixed(3) },
      this.tierRow.executions.length > 0 ? this.tierRow.executions[0].complete_time : undefined,
      true
    )).subscribe(() => {
      this.loadExercise()
    })
  }
}
