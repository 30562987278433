import { PlaycardSummaryComponent } from './playcard-summary.component';
var ɵ0 = { page: 'playcard-summary' };
export var PlayCardSummaryRoutes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: PlaycardSummaryComponent,
                data: ɵ0,
            }
        ]
    }
];
export { ɵ0 };
