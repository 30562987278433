import { Injectable } from '@angular/core'
import { Router, CanActivate } from '@angular/router'
import { AuthService } from './auth.service'
import { Store } from '@ngxs/store'
import { AuthState } from 'app/state/auth/auth.state'
import { Navigate } from '@ngxs/router-plugin'
import { PageTransitionService, PAGETRANSITIONS } from './page-transition.service'
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public store: Store, public auth: AuthService, public router: Router, private pageTransitionService: PageTransitionService) {}

  canActivate(): boolean {
    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated)
    if (!this.auth.isAuthenticated()) {
      this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.TOP)
      this.router.navigate(['/login'])
      // this.store.dispatch(new Navigate(['/login']))
    }
    return isAuthenticated
  }

  // canActivate(): boolean {
  //   if (!this.auth.isAuthenticated()) {
  //     this.router.navigate(['login'])
  //     return false
  //   }
  //   return true
  // }
}
