import * as _ from 'lodash'
import * as moment from 'moment'

import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import {
  AddUnsyncedWorkoutSession,
  ChangeActiveDate,
  ClearActiveGroup,
  FetchWorkoutForDay,
  InitMain,
  JoinGroup,
  LeaveGroup,
  OnlineStatusChange,
  ResetMain,
  Set1RM,
  SetActiveDate,
  SetActiveDay,
  SetActiveStatsTab,
  SetActiveToDefault,
  SetCft,
  SetCftScore,
  SetMainTabIndex,
  SetPft,
  SetPftScore,
  SetSelectedRM,
  SetShowNotification,
  SetSyncStatus,
  SetUserAgeGroup,
  SetUserGender,
  SyncPftCftScores,
  SyncWorkoutSessions
} from './app.actions'
import { Day, Group, Plan } from 'app/models'
import { Observable, of } from 'rxjs'
import { ObservationDocument, PlanDocumentUtils } from 'fitforce-document-sync'

import { AppStateModel } from './app.model'
import { AuthService } from 'app/services/auth.service'
import { AuthState } from '../auth/auth.state'
import { DataService } from 'app/services/data.service'
import { DocumentService } from 'app/services/document.service'
import { RestService } from 'app/services/rest.service'
import { Router } from '@angular/router'
import { UserProfile } from 'app/models/user-profile.model'
import { produce } from 'immer'
import { tap } from 'rxjs/operators'

@State<AppStateModel>({
  name: 'app',
  defaults: {
    tabView: 'main',
    unsyncedWorkoutSessions: [],
    activeStatsTab: 'workouts',
    mainTabIndex: 0,
    showNotification: true
  }
})
export class AppState {
  @Selector()
  static isOnline(state: AppStateModel) {
    return state.isOnline
  }

  @Selector()
  static isSyncing(state: AppStateModel) {
    return state.isSyncing
  }

  @Selector()
  static lastSyncDate(state: AppStateModel) {
    return state.lastSyncDate
  }

  @Selector()
  static activeGroupSelected(state: AppStateModel) {
    return state.activeGroup
  }

  @Selector()
  static activePlanSelected(state: AppStateModel) {
    return state.activePlan
  }

  @Selector()
  static activeDaySelected(state: AppStateModel) {
    return state.activeDay
  }

  @Selector()
  static userProfile(state: AppStateModel) {
    return state.userProfile
  }

  @Selector()
  static unsyncedWorkoutSessions(state: AppStateModel) {
    return state.unsyncedWorkoutSessions
  }

  @Selector()
  static activeDate(state: AppStateModel) {
    return state.activeDate
  }

  @Selector()
  static organizedDays(state: AppStateModel) {
    return state.organizedDays
  }

  @Selector()
  static dayToWorkout(state: AppStateModel) {
    return state.dayToWorkout
  }

  @Selector()
  static joinedGroups(state: AppStateModel) {
    return state.joinedGroups
  }

  @Selector()
  static activeStatsTab(state: AppStateModel) {
    return state.activeStatsTab
  }

  @Selector()
  static mainTabIndex(state: AppStateModel) {
    return state.mainTabIndex
  }

  @Selector()
  static selectedRM(state: AppStateModel) {
    return state.selectedRM
  }

  @Selector()
  static showNotification(state: AppStateModel) {
    return state.showNotification
  }

  constructor(
    private store: Store,
    private authService: AuthService,
    private dataService: DataService,
    private documentService: DocumentService,
    private router: Router,
  ) {}

  @Action(SetMainTabIndex)
  setMainTabIndex(
    { getState, setState }: StateContext<AppStateModel>,
    { index }: SetMainTabIndex
  ) {
    const draftState = produce(getState(), draft => {
      draft.mainTabIndex = index
    })
    setState(draftState)
  }

  @Action(OnlineStatusChange)
  onlineStatusChange(
    { getState, setState }: StateContext<AppStateModel>,
    { isOnline }: OnlineStatusChange
  ) {
    const draftState = produce(getState(), draft => {
      draft.isOnline = isOnline
    })
    setState(draftState)
  }

  @Action(ClearActiveGroup)
  clearActiveGroup(
    { getState, setState }: StateContext<AppStateModel>
  ) {
    const draftState = produce(getState(), draft => {
      draft.activeDate = undefined
      draft.activeGroup = undefined
      draft.activePlan = undefined
      draft.activeDay = undefined
      draft.organizedDays = undefined
      draft.dayToWorkout = {}
      draft.availableGroupPlans = undefined
      draft.availablePlanDays = undefined
      draft.joinedGroups = undefined
    })
    setState(draftState)
  }

  @Action(JoinGroup)
  async joinGroup(
    { getState, setState, dispatch }: StateContext<AppStateModel>,
    { group }: JoinGroup
  ) {
    await dispatch(new SetSyncStatus(true)).toPromise()
    const activeUser = this.store.selectSnapshot(AuthState.activeUser)
    await this.dataService.groupRequestInvite(group.uuid, activeUser.email).toPromise()
    await dispatch(new InitMain(true)).toPromise()
  }

  @Action(LeaveGroup)
  async leaveGroup(
    { getState, setState, dispatch }: StateContext<AppStateModel>,
    { group }: LeaveGroup
  ) {
    await dispatch(new SetSyncStatus(true)).toPromise()
    const activeUser = this.store.selectSnapshot(AuthState.activeUser)
    await this.dataService.groupRequestLeave(group.key, activeUser.uuid).toPromise()
    await this.dataService.deleteGroup(group)
    const draftState = produce(getState(), draft => {
      _.remove(draft.joinedGroups, { key: group.key })
    })
    setState(draftState)

    await dispatch(new InitMain(false)).toPromise()
  }

  @Action(InitMain)
  async initMain(
    { getState, setState, dispatch }: StateContext<AppStateModel>,
    { force }: InitMain
  ) {
    const start = performance.now();

    let state = getState()
    // Check login status
    let isLoggedIn = false
    try {
      isLoggedIn = await this.authService.fetchLoginStatus()
      if (!isLoggedIn) {
        await this.router.navigate(['login', { error: true }])
      }
    } catch {
      await this.router.navigate(['login', { error: true }])
    }
    const metaData: any = await this.dataService.fetchData()
    const joinedGroups = metaData.groups
    const groups = _.sortBy(joinedGroups, 'name')
    const draftState = produce(getState(), draft => {
      draft.joinedGroups = groups
    })
    setState(draftState)
    if (force || !state.lastSyncDate || moment(state.lastSyncDate).add(1, 'day').isBefore()) {
      await dispatch(new SetSyncStatus(true)).toPromise()
      await dispatch(new ResetMain()).toPromise()
      // Get new State
      state = getState()
      let userProfile = await this.createUserProfile().toPromise()
      if (!state.userProfile || userProfile.user.uuid !== state.userProfile.user.uuid) {
        // Replace userProfile only if the ids are different
        // Save the sets!
        const userProfileDraft = produce(getState(), draft => {
          draft.userProfile = userProfile
        })
        setState(userProfileDraft)
      } else {
        userProfile = state.userProfile
      }
      await this.dataService.syncWorkoutSessionsLocal().toPromise()
      const observations = await this.dataService.loadObservations()
      const observationDraftState = produce(getState(), draft => {
        draft.userProfile.stats.exercises = observations
      })
      setState(observationDraftState)
    }
    // populate day to workout map
    const workouts: Day[] = await this.dataService.getAllWorkouts();
    const workoutMap = {}
    workouts.forEach(workout => {
      workoutMap[workout.date.format()] = true;
    })
    state = getState();
    setState({ ...state, dayToWorkout: workoutMap })
    state = getState()

    if (state.joinedGroups) {
      if (!state.activeDate) {
        await dispatch(SetActiveToDefault).toPromise()
      }
      await dispatch(new FetchWorkoutForDay()).toPromise()
    } else {
      await dispatch(new SetSyncStatus(false, false)).toPromise()
    }

    console.log('SYNC: ' + (performance.now() - start) / 1000);
  }

  @Action(SetActiveToDefault)
  async setToDefault(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    // automatically set the active date to the current date
    const today = moment();
    await ctx.dispatch(new SetActiveDate(today.format())).toPromise();
  }

  @Action(ChangeActiveDate)
  changeActiveDate(
    { getState, setState, dispatch }: StateContext<AppStateModel>,
    { previous }: ChangeActiveDate
  ) {
    const width = window.innerWidth
    const daysToScroll =
      width > 325 ? 7 :
      width > 275 ? 5 :
      width > 225 ? 3 : 1
    const draftState = produce(getState(), draft => {
      const date = moment(draft.activeDate)
      if (previous) {
        date.subtract(daysToScroll, 'day')
      } else {
        date.add(daysToScroll, 'day')
      }
      draft.activeDate = date
    })
    setState(draftState)
    return dispatch(new FetchWorkoutForDay())
  }

  @Action(SetActiveDate)
  setActiveDate(
    { getState, setState, dispatch }: StateContext<AppStateModel>,
    { dateString }: SetActiveDate
  ) {
    const draftState = produce(getState(), draft => {
      draft.activeDate = moment(dateString)
    })
    setState(draftState)
    return dispatch(new FetchWorkoutForDay())
  }

  @Action(FetchWorkoutForDay)
  async fetchWorkoutForDay(
    { getState, setState, dispatch }: StateContext<AppStateModel>,
    { }: FetchWorkoutForDay
  ) {
    const state = getState()
    const date = state.activeDate || moment()
    const days: Array<Day> = await this.dataService.getWorkouts(date)
    // Organize by group
    const organizedDays = {}
    for (const day of days) {
      const plan: Plan = await this.dataService.getPlan(day.planUUID)

      // Add to groups thi splan belongs to
      const groups: Array<Group> = await this.dataService.getUserGroups().toPromise()
      for (const group of groups) {
        if (group.plans && group.plans.indexOf(plan.key) !== -1) {
          let hasUnsyncedSessions = false
          if (!organizedDays[group.key]) {
            organizedDays[group.key] = {
              group: group,
              days: [],
              hasUnsyncedSessions: false
            }
          }

          // Add total load for day
          if (!this.documentService.store.planDocuments) {
            await this.documentService.restoreData().toPromise()
          }
          const planDocument = _.find(this.documentService.store.planDocuments, { key: plan.key })
          const workoutDocument = _.find(planDocument.document.workouts, { id:  day.key })
          let totalLoad = 'N/A'
          if (workoutDocument.est_rpe > -1 && workoutDocument.est_completion_time_ms > -1) {
            totalLoad = (workoutDocument.est_rpe * (workoutDocument.est_completion_time_ms / 1000 / 60)).toString()
          }

          // Add workoutSessions for day
          const workoutSessions = await this.dataService.getWorkoutSessionDocumentsForDay(group.key, plan.key, day.key, day.version)
          const unsyncedSessions =  state.unsyncedWorkoutSessions
          if (unsyncedSessions.length > 0 && workoutSessions.length > 0) {
            _.each(unsyncedSessions, (sessionKey) => {
              const index = _.findIndex(workoutSessions, { key: sessionKey })
              if (index !== -1) {
                workoutSessions[index].unsynced = true
                hasUnsyncedSessions = true
              }
            })
          }

          // Add Phases
          const phase = PlanDocumentUtils.findPhase(planDocument.document.phases, {
            day: workoutDocument.schedule.day[0], week: workoutDocument.schedule.week[0]
          })

          organizedDays[group.key].days.push({
            plan,
            day,
            workoutSessions,
            hasUnsyncedSessions,
            totalLoad,
            phase
          })
        }
      }
    }


    const draftState = produce(getState(), draft => {
      draft.activeDate = date
      draft.organizedDays = organizedDays
    })
    setState(draftState)
    return await dispatch(new SetSyncStatus(false, true)).toPromise()
  }

  @Action(SetActiveDay)
  async setActiveDay(
    { getState, setState, dispatch }: StateContext<AppStateModel>,
    { group, plan, day }: SetActiveDay
  ) {
    const draftState = produce(getState(), draft => {
      draft.activeGroup = group
      draft.activePlan = plan
      draft.activeDay = day
    })
    setState(draftState)
  }

  createUserProfile(): Observable<UserProfile> {
    const activeUser = this.store.selectSnapshot(AuthState.activeUser)
    const user = {
      uuid: activeUser.uuid,
      first_name: activeUser.firstName,
      last_name: activeUser.lastName,
      rank: activeUser.rank,
      email: activeUser.email,
    }
    const exerciseProgramId = this.documentService.store.exerciseProgram && this.documentService.store.exerciseProgram.key
    const userProfile: UserProfile = {
      type: 'UserProfile',
      version: 1,
      exercise_program_id: exerciseProgramId,
      exercise_program_version: 1,
      user: user,
      stats: {
        exercises: []
      },
      scores: {
        exercises: [],
        gender: undefined,
        age_group: undefined
      }
    }
    return of(userProfile)
  }

  @Action(ResetMain)
  resetMain(
    { getState, setState }: StateContext<AppStateModel>,
  ) {
    const state = produce(getState(), draft => {
      draft.activeGroup = undefined
      draft.activePlan = undefined
      draft.activeDay = undefined
      draft.availablePlanDays = undefined
      draft.organizedDays = undefined
      draft.dayToWorkout = {}
      draft.joinedGroups = []
    })
    return of([]).pipe(tap(() => {
      setState(state)
    }))
  }

  @Action(SetSyncStatus)
  setSyncStatus(
    { getState, setState }: StateContext<AppStateModel>,
    { isSyncing, isSuccess }: SetSyncStatus
  ) {

    const state = produce(getState(), draft => {
      draft.isSyncing = isSyncing
      if (isSyncing) {
        draft.lastSyncAttempt = moment()
      }
      if (isSuccess === true) {
        draft.lastSyncDate = moment()
      }
    })

    setState(state)
  }

  @Action(Set1RM)
  async set1RM(
    { getState, setState }: StateContext<AppStateModel>,
    { oneRM, statIndex }: Set1RM
  ) {
    const draftState = produce(getState(), draft => {
      if (statIndex >= 0) {
        draft.userProfile.stats.exercises[statIndex] = oneRM
      } else {
        draft.userProfile.stats.exercises.push(oneRM)
      }
    })
    setState(draftState)

    // Build new observation
    let observationDocument: ObservationDocument
    if (oneRM.max_set) {
      observationDocument = this.dataService.generateMaxSetObservation(oneRM)
    } else {
      observationDocument = this.dataService.generate1RMObservation(oneRM)
    }
    await this.dataService.storeObservationDocument(observationDocument)

    // Sync observation document
    const state = getState()
    if (state.isOnline) {
      this.dataService.pushDocuments()
    }
  }

  @Action(SetPft)
  async setPft(
    { getState, setState}: StateContext<AppStateModel>,
    { pft, pftIndex }: SetPft
  ) {
    const draftState = produce(getState(), draft => {
      if (draft.userProfile.scores) {
        if (pftIndex >= 0) {
          draft.userProfile.scores.exercises[pftIndex] = pft;
        } else {
          draft.userProfile.scores.exercises.push(pft);
        }
      } else {
        draft.userProfile.scores = {
          exercises: [pft],
          gender: undefined,
          age_group: undefined
        };
      }
    })
    setState(draftState);
  }

  @Action(SetCft)
  async setCft(
    { getState, setState}: StateContext<AppStateModel>,
    { cft, cftIndex }: SetCft
  ) {
    const draftState = produce(getState(), draft => {
      if (draft.userProfile.scores) {
        if (cftIndex >= 0) {
          draft.userProfile.scores.exercises[cftIndex] = cft;
        } else {
          draft.userProfile.scores.exercises.push(cft);
        }
      } else {
        draft.userProfile.scores = {
          exercises: [cft],
          gender: undefined,
          age_group: undefined
        };
      }
    })
    setState(draftState);
  }

  @Action(SetPftScore)
  async setPftScore(
    { pftScore }: SetPftScore
  ) {
    const observationDocument: ObservationDocument = this.dataService.generatePftCftScoreObservation(pftScore, 'PFT');
    await this.dataService.storeObservationDocument(observationDocument);
    await this.dataService.postScoreDocument(observationDocument);
  }

  @Action(SetCftScore)
  async setCftScore(
    { cftScore }: SetCftScore
  ) {
    const observationDocument: ObservationDocument = this.dataService.generatePftCftScoreObservation(cftScore, 'CFT');
    await this.dataService.storeObservationDocument(observationDocument);
    await this.dataService.postScoreDocument(observationDocument);
  }

  @Action(SyncPftCftScores)
  async syncPftCftScores(
    { getState }: StateContext<AppStateModel>
  ) {
    const state = getState();
    if (state.isOnline) {
      this.dataService.pushDocuments();
      // await this.dataService.postPftCftScoreObservationDocuments();
    }
  }

  @Action(SetUserGender)
  async setUserGender(
    { getState, setState }: StateContext<AppStateModel>,
    { gender }: SetUserGender
  ) {
    const draftState = produce(getState(), draft => {
      if (gender) {
        if (draft.userProfile.scores) {
          draft.userProfile.scores.gender = gender;
        } else {
          draft.userProfile.scores = {
            exercises: [],
            gender: gender,
            age_group: undefined
          }
        }
      }
    });
    setState(draftState);
  }

  @Action(SetUserAgeGroup)
  async setUserAgeGroup(
    { getState, setState }: StateContext<AppStateModel>,
    { ageGroup }: SetUserAgeGroup
  ) {
    const draftState = produce(getState(), draft => {
      if (ageGroup) {
        if (draft.userProfile.scores) {
          draft.userProfile.scores.age_group = ageGroup;
        } else {
          draft.userProfile.scores = {
            exercises: [],
            gender: undefined,
            age_group: ageGroup
          };
        }
      }
    });
    setState(draftState);
  }

  @Action(AddUnsyncedWorkoutSession)
  addUnsyncedWorkoutSession(
    { getState, setState }: StateContext<AppStateModel>,
    { key }: AddUnsyncedWorkoutSession
  ) {
    const state = produce(getState(), draft => {
      if (draft.unsyncedWorkoutSessions.indexOf(key) === -1) {
        draft.unsyncedWorkoutSessions.push(key)
      }
    })
    setState(state)
  }

  @Action(SyncWorkoutSessions)
  async syncWorkoutSessions(
    { getState, setState, dispatch }: StateContext<AppStateModel>
  ) {
    // Also syncs all Observations
    await this.dataService.pushDocuments()
    const state = getState()
    const unsyncedSessions = _.clone(state.unsyncedWorkoutSessions)
    if (unsyncedSessions.length > 0) {
      const syncWorkoutSessionDocuments = await this.dataService.syncWorkoutSessionDocuments(unsyncedSessions)
      syncWorkoutSessionDocuments.forEach((doc) => {
        const index = unsyncedSessions.indexOf(doc.key, 0);
        if (index !== -1) {
          unsyncedSessions.splice(index, 1);
        }
      })
      const productDraft = produce(getState(), draft => {
        draft.unsyncedWorkoutSessions = unsyncedSessions
      })
      setState(productDraft)
      if (syncWorkoutSessionDocuments) {
        await dispatch(new FetchWorkoutForDay()).toPromise()
      }
    }
  }

  @Action(SetActiveStatsTab)
  setActiveStatsTab(
    { getState, setState }: StateContext<AppStateModel>,
    { tab }: SetActiveStatsTab
  ) {
    const state = getState()
    const draftState = produce(state, draft => {
      draft.activeStatsTab = tab || 'workouts'
    })
    setState(draftState)
  }

  @Action(SetSelectedRM)
  setSelectedItem(
    { getState, setState }: StateContext<AppStateModel>,
    { rm }: SetSelectedRM
  ) {
    const state = getState()
    const draftState = produce(state, draft => {
      draft.selectedRM = rm
    })
    setState(draftState)
  }

  @Action(SetShowNotification)
  SetShowNotification(
    { getState, setState }: StateContext<AppStateModel>,
    { show }: SetShowNotification
  ) {
    const state = getState()
    const draftState = produce(state, draft => {
      draft.showNotification = show
    })
    setState(draftState)
  }

}
