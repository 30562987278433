import * as _ from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';

export interface ISetRepsLoadPipe {
  currentWorkoutSession: any;
  currentWorkoutIndex: number;
  currentRowIndex: number;
  setParams: any;
  hasSet?: boolean;
  execution?: any;
  oneRM?: any;
  oneRMIndex?: number;
  loadPercentage?: any;
  joinCharacter?: any;
}
@Pipe({
  name: 'getSetsRepsLoad',
  pure: true,
})
export class SetsRepsLoadPipe implements PipeTransform {
  constructor() {}

  transform(params: ISetRepsLoadPipe): any {
    const currentWorkoutSession: any = params.currentWorkoutSession;
    const currentWorkoutIndex = params.currentWorkoutIndex;
    const currentRowIndex = params.currentRowIndex;
    const execution = params.execution;
    const setParams = params.setParams;
    const loadPercentage = params.loadPercentage;
    const joinCharacter = params.joinCharacter || ' ';
    const oneRM = params.oneRM;
    const oneRMIndex = params.oneRMIndex;

    const hasSet = params.hasSet || false;
    if (setParams) {
      const details = [
        '', // Sets
        '', // Reps
        '', // Load
      ];
      const parameterSelections =
        currentWorkoutSession.workout.tiers[currentWorkoutIndex].rows[
          currentRowIndex
        ].parameters;
      const sortedSetParams = _.sortBy(setParams, (p) => {
        if (p) {
          return p.param;
        }
      });
      sortedSetParams.forEach((param) => {
        if (!param) {
          return;
        }
        if (param.param === 'sets' && hasSet) {
          if (isNaN(parameterSelections.sets)) {
            details[0] = parameterSelections.sets;
          } else if (parameterSelections.sets > 1) {
            details[0] = parameterSelections.sets + ' Sets';
          } else if (parameterSelections.sets === -1) {
            details[0] = '1 Set';
          } else {
            details[0] = parameterSelections.sets + ' Set';
          }
        } else if (param.param === 'reps') {
          if (isNaN(parameterSelections.reps)) {
            details[1] = parameterSelections.reps;
          } else if (parameterSelections.reps === -1) {
            details[1] = '';
          } else if (execution && execution.parameter_values.reps) {
            details[1] = execution.parameter_values.reps;
          }
          if (details[1]) {
            if (param.unit && param.unit.toUpperCase() !== 'N/A') {
              details[1] +=
                ' ' + param.unit.charAt(0).toUpperCase() + param.unit.slice(1);
            } else {
              details[1] += ' Reps';
            }
          }
        } else if (param.param === 'load') {
          let detail = parameterSelections[param.param];
          if (execution && execution.parameter_values.load) {
            detail = execution.parameter_values.load;
          }
          if (
            oneRMIndex &&
            oneRMIndex !== -1 &&
            oneRM &&
            oneRM['1rm'] &&
            oneRM['1rm'] !== -1
          ) {
            if (param.unit === 'percent' || parameterSelections.load) {
              const val = Math.ceil(
                (parameterSelections['load'] / 100) * oneRM['1rm']
              );
              detail = val + 'lbs';
            } else {
              detail = oneRM['1rm'] + 'lbs';
            }
          } else if (
            execution &&
            execution.parameter_values['1rm'] &&
            execution.parameter_values['1rm'] > -1 &&
            execution.parameter_values['load'] &&
            execution.parameter_values['load'] > -1 &&
            !execution.parameter_values['manual']
          ) {
            detail =
              Math.ceil(loadPercentage * execution.parameter_values['1rm']) +
              'lbs Load';
          } else if (param.unit && param.unit.toUpperCase() !== 'N/A') {
            if (param.unit === 'percent') {
              if (execution && execution.parameter_values.load) {
                detail += 'lbs Load';
              } else {
                detail += '% Load';
              }
            } else {
              detail +=
                ' ' + param.unit.charAt(0).toUpperCase() + param.unit.slice(1);
            }
          } else {
            detail += '% Load';
          }
          if (
            detail &&
            parameterSelections[param.param] &&
            parameterSelections[param.param] !== -1
          ) {
            details[2] = detail;
          }
        } else {
          // if param exists in parameterSelections
          if (param.param in parameterSelections) {
            let paramSelection = parameterSelections[param.param];
            if (execution && execution.parameter_values[param.param]) {
              paramSelection = execution.parameter_values[param.param];
            }
            let detail = '';
            if (param.param === 'distance') {
              if (param.unit && param.unit.toUpperCase() !== 'N/A') {
                detail =
                  paramSelection +
                  ' ' +
                  param.unit.charAt(0).toUpperCase() +
                  param.unit.slice(1);
              } else {
                detail = paramSelection + ' Distance';
              }
            }
            if (param.param === 'hold') {
              if (param.unit && param.unit.toUpperCase() !== 'N/A') {
                detail =
                  paramSelection +
                  ' ' +
                  param.unit.charAt(0).toUpperCase() +
                  param.unit.slice(1);
              } else {
                // Time param may already have units
                if (detail.indexOf('sec') === -1) {
                  detail = paramSelection + ' Hold';
                }
              }
            }
            if (param.param === 'time') {
              const mins = Math.floor(paramSelection / 60);
              const secs = paramSelection - mins * 60;
              detail = `${mins} Minutes ${secs} Seconds`;
            }
            if (param.param === 'timer') {
              if (param.unit && param.unit.toUpperCase() !== 'N/A') {
                detail =
                  paramSelection +
                  ' ' +
                  param.unit.charAt(0).toUpperCase() +
                  param.unit.slice(1);
              } else {
                detail = paramSelection + ' Timer';
              }
            }
            if (param.param === 'rpe') {
              if (param.unit && param.unit.toUpperCase() !== 'N/A') {
                detail =
                  paramSelection +
                  ' ' +
                  param.unit.charAt(0).toUpperCase() +
                  param.unit.slice(1);
              } else {
                detail = paramSelection + ' RPE';
              }
            }

            if (detail && paramSelection && paramSelection !== -1) {
              details.push(detail);
            }
          }
        }
      });
      return details.filter(Boolean).join(joinCharacter);
    }
  }
}
