<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content style="padding: 0">
  <div *ngIf="trustedUrl">
    <iframe
    [src]="trustedUrl"
    width="100%" height="360" frameborder="0" allowtransparency allowfullscreen>
    </iframe>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close fxFlex>Close</button>
</mat-dialog-actions>
