import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '../custom-material/shared.module'
import { RMCalcSetDialogComponent } from './rmcalc-set-dialog.component'

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    RMCalcSetDialogComponent
  ],
  entryComponents: [RMCalcSetDialogComponent]
})
export class RMCalcSetDialogModule { }
