import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { SharedModule } from '../custom-material/shared.module'
import { NgxSpinnerModule } from 'ngx-spinner'
import { GroupManagementRoutes } from './group-management.routing'
import { GroupManagementComponent } from './group-management.component'
import { GroupViewComponent } from './group-view/group-view.component'
import { HeaderModule } from '../header/header.module'

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(GroupManagementRoutes),
    NgxSpinnerModule,
    HeaderModule,
  ],
  declarations: [
    GroupManagementComponent,
    GroupViewComponent,
  ],
  entryComponents: [
  ],
  exports: [
    GroupManagementComponent,
  ]
})
export class GroupManagementModule { }
