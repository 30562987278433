import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent {
  message: string

  constructor (
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  cancelSelected() {
    this.dialogRef.close({
      success: false
    })
  }

  submitSelected() {
    if (!this.message) {
      this.cancelSelected()
      return
    }

    this.dialogRef.close({
      success: true,
      message: this.message,
    })
  }
}
