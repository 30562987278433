import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noNegative'
})
export class NoNegativePipe implements PipeTransform {
  transform(payload: any): any {
   if (!payload || payload === -1) {
     return ''
   }
   return payload
  }
}
