<div #panel class="group-management-screen" fxLayout="column" fxLayoutAlign="space-between stretch">
  <ngx-spinner
    bdColor = "rgba(51, 51, 51, 0.8)"
    size = "large"
    color = "#fff"
    type = "ball-atom">
    <p>Syncing groups and plans...</p>
  </ngx-spinner>
  <h2 *ngIf="noAvailableGroups">No available public groups.</h2>
  <h4 *ngIf="availableGroups.length > 0">Available</h4>
  <mat-card class="group-cards" fxLayout="row wrap" *ngFor="let group of availableGroups">
    <div class="info" fxFlex>
      {{ group.name }}
    </div>
    <div class="arrowRight">
      <div *ngIf="isOnline" class="group-button join-group" fxLayout="row" fxLayoutAlign="center">
        <button mat-raised-button color="primary" (click)="requestJoin(group)">Join</button>
      </div>
    </div>
  </mat-card>
  <h4 *ngIf="joinedGroups.length > 0">Joined</h4>
  <mat-card class="group-cards" fxLayout="row" fxLayoutGap="10px" *ngFor="let group of joinedGroups">
    <div class="info" fxFlex>
      {{ group.name }}
    </div>
    <div class="arrowRight">
      <div *ngIf="isOnline" class="group-button leave-group" fxLayout="row" fxLayoutAlign="center">
        <button mat-raised-button color="warn" (click)="openLeaveDialog(group)">Leave</button>
      </div>
    </div>
  </mat-card>
</div>
