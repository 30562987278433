<div class="update-profile-dialog">
  <h1 mat-dialog-title>Update Profile</h1>
  <div mat-dialog-content>
    <form fxFlex="100" fxLayout="column" [formGroup]="profile">
      <mat-form-field>
        <mat-select formControlName="sex" placeholder="Sex">
          <mat-option value="Male">Male</mat-option>
          <mat-option value="Female">Female</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="picker"
          placeholder="Date of Birth"
          formControlName="dob"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-label>Height</mat-label>
      <div fxFlex fxLayout="row">
        <mat-form-field fxFlex="50">
          <input
            matInput
            type="number"
            placeholder="ft"
            formControlName="heightFt"
            min="0"
          />
        </mat-form-field>
        <mat-form-field fxFlex="50">
          <input
            matInput
            type="number"
            placeholder="in"
            formControlName="heightIn"
            min="0"
            max="11"
          />
        </mat-form-field>
      </div>
      <mat-form-field>
        <input
          min="0"
          matInput
          type="number"
          placeholder="Weight (lbs)"
          formControlName="weight"
        />
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
    <button mat-button (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" (click)="close(true)" [disabled]="!profile.valid">
      Submit
    </button>
  </div>  
</div>