/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./training-load-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/angular-plotly.js/angular-plotly.js.ngfactory";
import * as i3 from "angular-plotly.js";
import * as i4 from "@angular/common";
import * as i5 from "./training-load-chart.component";
import * as i6 from "../../services/data.service";
var styles_TrainingLoadChartComponent = [i0.styles];
var RenderType_TrainingLoadChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TrainingLoadChartComponent, data: {} });
export { RenderType_TrainingLoadChartComponent as RenderType_TrainingLoadChartComponent };
function View_TrainingLoadChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "plotly-plot", [], null, null, null, i2.View_PlotComponent_0, i2.RenderType_PlotComponent)), i1.ɵprd(512, null, i3.PlotlyService, i3.PlotlyService, []), i1.ɵdid(3, 1032192, null, 0, i3.PlotComponent, [i3.PlotlyService, i1.IterableDiffers, i1.KeyValueDiffers], { data: [0, "data"], layout: [1, "layout"], config: [2, "config"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.graph.data; var currVal_1 = _co.graph.layout; var currVal_2 = _co.graph.config; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_TrainingLoadChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainingLoadChartComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.graph; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TrainingLoadChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-training-load-chart", [], null, null, null, View_TrainingLoadChartComponent_0, RenderType_TrainingLoadChartComponent)), i1.ɵdid(1, 114688, null, 0, i5.TrainingLoadChartComponent, [i6.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TrainingLoadChartComponentNgFactory = i1.ɵccf("app-training-load-chart", i5.TrainingLoadChartComponent, View_TrainingLoadChartComponent_Host_0, {}, {}, []);
export { TrainingLoadChartComponentNgFactory as TrainingLoadChartComponentNgFactory };
