import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '../../services/auth.service'
import { DodNoticeAndConsentBannerDialogComponent } from '../dod-notice-and-consent-banner-dialog/dod-notice-and-consent-banner-dialog.component'
import { environment } from 'environments/environment'
import { InformedConsentDialogComponent } from '../informed-consent-dialog/informed-consent-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { Store } from '@ngxs/store'
import { Signin } from 'app/state'
import { VerifyAccountDialogComponent } from '../verify-account-dialog/verify-account-dialog.component'
import { PageTransitionService, PAGETRANSITIONS } from 'app/services/page-transition.service'
import { switchMap } from 'rxjs/operators'
import { MatSnackBar } from '@angular/material'
import { RestService } from 'app/services/rest.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string
  password: string
  errorMsg: string

  tou_url = '';
  pp_url = '';

  registerPath = environment.registerUrl || '/account/'
  forgotPath = environment.forgotUrl || '/account/forgot/'

  version = environment.version;

  constructor (
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private store: Store,
    private _snackBar: MatSnackBar,
    private pageTransitionService: PageTransitionService,
    private restService: RestService,
  ) {}

  ngOnInit() {
    this.restService.getConfiguration().subscribe(config => {
      this.tou_url = config['TOU_URL'];
      this.pp_url = config['PP_URL'];
    });
    if (localStorage.getItem('midas-token')) {
      this.authService.fetchLoginStatus().then(loggedIn => {
        if (loggedIn) {
          this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT)
          this.router.navigate(['/main'])
        }
      })
    }
    this.route.paramMap.subscribe(params => {
      const isError = Boolean(params.get('error'))
      if (isError) {
        this._snackBar.open('Login token expired.', 'Dismiss', {
          duration: 4000,
        })
      }
    })
  }

  login(username, password) {
    this.dialog.open(DodNoticeAndConsentBannerDialogComponent, {
      disableClose: true,
      closeOnNavigation: false
    }).afterClosed().subscribe(res => {
      if (res === true) {
        if (environment.hideConsent === 'true') {
          this.authenticateAndSync(username, password);
        } else {
          this.dialog.open(InformedConsentDialogComponent, {
            disableClose: true,
            closeOnNavigation: false,
          }).afterClosed().subscribe(result => {
            if (result === true) {
              this.authenticateAndSync(username, password);
            }
          })
        }
      }
    });
  }

  authenticateAndSync(username, password) {
    this.store.dispatch(new Signin(username, password)).subscribe(
      () => {
        this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT)
        this.router.navigate(['/main'])
      },
      (err) => {
        if (err.status === 403) {
          this.dialog.open(VerifyAccountDialogComponent, {
            data: {
              email: this.username,
            },
          })
        } else if (err.status === 404) {
          this.errorMsg = 'Incorrect username or password.'
        } else if (err.status === 423) {
          this.errorMsg = 'Account blocked due to failed login attempts. Please reset your password to gain access to your account.'
        } else {
          this.errorMsg = 'Server encountered an error.'
        }
      }
    )
  }
}
