import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { WorkoutState } from 'app/state/workout/workout.state';
import { DocumentService } from 'app/services/document.service';
var EditCircuitDialogComponent = /** @class */ (function () {
    function EditCircuitDialogComponent(store, documentService, dialogRef, data) {
        this.store = store;
        this.documentService = documentService;
        this.dialogRef = dialogRef;
        this.data = data;
    }
    EditCircuitDialogComponent.prototype.ngOnInit = function () {
        this.loadData();
    };
    EditCircuitDialogComponent.prototype.loadData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentWorkoutSession, currentWorkoutIndex;
            return tslib_1.__generator(this, function (_a) {
                this.isCircuit = false;
                currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession);
                currentWorkoutIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex);
                if (this.data && this.data.tierIndex >= 0) {
                    currentWorkoutIndex = this.data.tierIndex;
                    this.tierIndex = this.data.tierIndex;
                }
                this.tier = currentWorkoutSession.workout.tiers[currentWorkoutIndex];
                if (this.tier.circuit.executions.length > 0 && this.tier.circuit.executions[0].num_rounds >= 0) {
                    this.totalRoundsCompleted = this.tier.circuit.executions[0].num_rounds;
                }
                else if (this.tier.circuit.num_rounds === -1) {
                    this.totalRoundsCompleted = 0;
                }
                else {
                    this.totalRoundsCompleted = this.tier.circuit.num_rounds;
                }
                if (this.tier.circuit && this.tier.circuit.num_rounds && this.tier.circuit.num_rounds > 0) {
                    this.isCircuit = true;
                }
                return [2 /*return*/];
            });
        });
    };
    EditCircuitDialogComponent.prototype.onSave = function () {
        this.dialogRef.close({
            success: true,
            num_rounds: this.totalRoundsCompleted,
        });
    };
    EditCircuitDialogComponent.prototype.onClose = function () {
        this.dialogRef.close({
            success: false
        });
    };
    return EditCircuitDialogComponent;
}());
export { EditCircuitDialogComponent };
