import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import * as _ from 'lodash'
import { Group, Plan } from 'app/models'
import { DataService } from 'app/services/data.service'
import { Router } from '@angular/router'
import { PageTransitionService, PAGETRANSITIONS } from 'app/services/page-transition.service'

@Component({
  selector: 'app-change-plan-dialog',
  templateUrl: './change-plan-dialog.component.html',
  styleUrls: ['./change-plan-dialog.component.scss']
})
export class ChangePlanDialogComponent implements OnInit {
  selectedGroup: Group
  selectedPlan: Plan
  groups: Group[]
  plans: Plan[]

  constructor(
    public dialogRef: MatDialogRef<ChangePlanDialogComponent>,
    public dataService: DataService,
    public router: Router,
    private pageTransitionService: PageTransitionService,
  ) {
    this.dataService.getUserGroups().subscribe(groups => {
      this.groups = _.sortBy(groups, 'name')
      if (this.selectedGroup) {
        this.loadPlans()
      }
    })
  }

  loadPlans() {
    if (this.selectedGroup) {
      this.dataService.getPlans(this.selectedGroup.plans).subscribe((plans: Plan[]) => {
        this.plans = _.sortBy(plans, 'name')
      })
    }
  }

  ngOnInit() { }

  cancelSelected() {
    this.dialogRef.close({
      success: false
    })
  }

  openGroupManagement() {
    this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT)
    this.router.navigate(['group'])
    this.dialogRef.close()
  }

  submitSelected() {
    this.dialogRef.close({
      success: true,
      selectedGroup: this.selectedGroup,
      selectedPlan: this.selectedPlan,
    })
  }
}
