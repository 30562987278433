import { GroupManagementComponent } from './group-management.component';
import { GroupViewComponent } from './group-view/group-view.component';
var ɵ0 = { page: 'groups' }, ɵ1 = { page: 'groupView' };
export var GroupManagementRoutes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: GroupManagementComponent,
                data: ɵ0,
            }, {
                path: ':groupId',
                component: GroupViewComponent,
                data: ɵ1,
            },
        ]
    }
];
export { ɵ0, ɵ1 };
