export class Alert {
    message: string;
    url?: string;
    route?: string;
    tabIndex?: TabIndex
}

export enum TabIndex {
    WORKOUTS, PROGRAMS, STATS, RESOURCES
}

export interface AlertDialogData {
    alert: Alert;
}
