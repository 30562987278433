import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Action, Selector, State, Store } from '@ngxs/store';
import { AddUnsyncedWorkoutSession, ChangeActiveDate, ClearActiveGroup, FetchWorkoutForDay, InitMain, JoinGroup, LeaveGroup, OnlineStatusChange, ResetMain, Set1RM, SetActiveDate, SetActiveDay, SetActiveStatsTab, SetActiveToDefault, SetCft, SetCftScore, SetMainTabIndex, SetPft, SetPftScore, SetSelectedRM, SetShowNotification, SetSyncStatus, SetUserAgeGroup, SetUserGender, SyncPftCftScores, SyncWorkoutSessions } from './app.actions';
import { of } from 'rxjs';
import { PlanDocumentUtils } from 'fitforce-document-sync';
import { AppStateModel } from './app.model';
import { AuthService } from 'app/services/auth.service';
import { AuthState } from '../auth/auth.state';
import { DataService } from 'app/services/data.service';
import { DocumentService } from 'app/services/document.service';
import { Router } from '@angular/router';
import { produce } from 'immer';
import { tap } from 'rxjs/operators';
var AppState = /** @class */ (function () {
    function AppState(store, authService, dataService, documentService, router) {
        this.store = store;
        this.authService = authService;
        this.dataService = dataService;
        this.documentService = documentService;
        this.router = router;
    }
    AppState.isOnline = function (state) {
        return state.isOnline;
    };
    AppState.isSyncing = function (state) {
        return state.isSyncing;
    };
    AppState.lastSyncDate = function (state) {
        return state.lastSyncDate;
    };
    AppState.activeGroupSelected = function (state) {
        return state.activeGroup;
    };
    AppState.activePlanSelected = function (state) {
        return state.activePlan;
    };
    AppState.activeDaySelected = function (state) {
        return state.activeDay;
    };
    AppState.userProfile = function (state) {
        return state.userProfile;
    };
    AppState.unsyncedWorkoutSessions = function (state) {
        return state.unsyncedWorkoutSessions;
    };
    AppState.activeDate = function (state) {
        return state.activeDate;
    };
    AppState.organizedDays = function (state) {
        return state.organizedDays;
    };
    AppState.dayToWorkout = function (state) {
        return state.dayToWorkout;
    };
    AppState.joinedGroups = function (state) {
        return state.joinedGroups;
    };
    AppState.activeStatsTab = function (state) {
        return state.activeStatsTab;
    };
    AppState.mainTabIndex = function (state) {
        return state.mainTabIndex;
    };
    AppState.selectedRM = function (state) {
        return state.selectedRM;
    };
    AppState.showNotification = function (state) {
        return state.showNotification;
    };
    AppState.prototype.setMainTabIndex = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var index = _b.index;
        var draftState = produce(getState(), function (draft) {
            draft.mainTabIndex = index;
        });
        setState(draftState);
    };
    AppState.prototype.onlineStatusChange = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var isOnline = _b.isOnline;
        var draftState = produce(getState(), function (draft) {
            draft.isOnline = isOnline;
        });
        setState(draftState);
    };
    AppState.prototype.clearActiveGroup = function (_a) {
        var getState = _a.getState, setState = _a.setState;
        var draftState = produce(getState(), function (draft) {
            draft.activeDate = undefined;
            draft.activeGroup = undefined;
            draft.activePlan = undefined;
            draft.activeDay = undefined;
            draft.organizedDays = undefined;
            draft.dayToWorkout = {};
            draft.availableGroupPlans = undefined;
            draft.availablePlanDays = undefined;
            draft.joinedGroups = undefined;
        });
        setState(draftState);
    };
    AppState.prototype.joinGroup = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState, dispatch = _a.dispatch;
        var group = _b.group;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var activeUser;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, dispatch(new SetSyncStatus(true)).toPromise()];
                    case 1:
                        _c.sent();
                        activeUser = this.store.selectSnapshot(AuthState.activeUser);
                        return [4 /*yield*/, this.dataService.groupRequestInvite(group.uuid, activeUser.email).toPromise()];
                    case 2:
                        _c.sent();
                        return [4 /*yield*/, dispatch(new InitMain(true)).toPromise()];
                    case 3:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppState.prototype.leaveGroup = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState, dispatch = _a.dispatch;
        var group = _b.group;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var activeUser, draftState;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, dispatch(new SetSyncStatus(true)).toPromise()];
                    case 1:
                        _c.sent();
                        activeUser = this.store.selectSnapshot(AuthState.activeUser);
                        return [4 /*yield*/, this.dataService.groupRequestLeave(group.key, activeUser.uuid).toPromise()];
                    case 2:
                        _c.sent();
                        return [4 /*yield*/, this.dataService.deleteGroup(group)];
                    case 3:
                        _c.sent();
                        draftState = produce(getState(), function (draft) {
                            _.remove(draft.joinedGroups, { key: group.key });
                        });
                        setState(draftState);
                        return [4 /*yield*/, dispatch(new InitMain(false)).toPromise()];
                    case 4:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppState.prototype.initMain = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState, dispatch = _a.dispatch;
        var force = _b.force;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var start, state, isLoggedIn, _c, metaData, joinedGroups, groups, draftState, userProfile_1, userProfileDraft, observations_1, observationDraftState, workouts, workoutMap;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        start = performance.now();
                        state = getState();
                        isLoggedIn = false;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 5, , 7]);
                        return [4 /*yield*/, this.authService.fetchLoginStatus()];
                    case 2:
                        isLoggedIn = _d.sent();
                        if (!!isLoggedIn) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.router.navigate(['login', { error: true }])];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        _c = _d.sent();
                        return [4 /*yield*/, this.router.navigate(['login', { error: true }])];
                    case 6:
                        _d.sent();
                        return [3 /*break*/, 7];
                    case 7: return [4 /*yield*/, this.dataService.fetchData()];
                    case 8:
                        metaData = _d.sent();
                        joinedGroups = metaData.groups;
                        groups = _.sortBy(joinedGroups, 'name');
                        draftState = produce(getState(), function (draft) {
                            draft.joinedGroups = groups;
                        });
                        setState(draftState);
                        if (!(force || !state.lastSyncDate || moment(state.lastSyncDate).add(1, 'day').isBefore())) return [3 /*break*/, 14];
                        return [4 /*yield*/, dispatch(new SetSyncStatus(true)).toPromise()];
                    case 9:
                        _d.sent();
                        return [4 /*yield*/, dispatch(new ResetMain()).toPromise()
                            // Get new State
                        ];
                    case 10:
                        _d.sent();
                        // Get new State
                        state = getState();
                        return [4 /*yield*/, this.createUserProfile().toPromise()];
                    case 11:
                        userProfile_1 = _d.sent();
                        if (!state.userProfile || userProfile_1.user.uuid !== state.userProfile.user.uuid) {
                            userProfileDraft = produce(getState(), function (draft) {
                                draft.userProfile = userProfile_1;
                            });
                            setState(userProfileDraft);
                        }
                        else {
                            userProfile_1 = state.userProfile;
                        }
                        return [4 /*yield*/, this.dataService.syncWorkoutSessionsLocal().toPromise()];
                    case 12:
                        _d.sent();
                        return [4 /*yield*/, this.dataService.loadObservations()];
                    case 13:
                        observations_1 = _d.sent();
                        observationDraftState = produce(getState(), function (draft) {
                            draft.userProfile.stats.exercises = observations_1;
                        });
                        setState(observationDraftState);
                        _d.label = 14;
                    case 14: return [4 /*yield*/, this.dataService.getAllWorkouts()];
                    case 15:
                        workouts = _d.sent();
                        workoutMap = {};
                        workouts.forEach(function (workout) {
                            workoutMap[workout.date.format()] = true;
                        });
                        state = getState();
                        setState(tslib_1.__assign({}, state, { dayToWorkout: workoutMap }));
                        state = getState();
                        if (!state.joinedGroups) return [3 /*break*/, 19];
                        if (!!state.activeDate) return [3 /*break*/, 17];
                        return [4 /*yield*/, dispatch(SetActiveToDefault).toPromise()];
                    case 16:
                        _d.sent();
                        _d.label = 17;
                    case 17: return [4 /*yield*/, dispatch(new FetchWorkoutForDay()).toPromise()];
                    case 18:
                        _d.sent();
                        return [3 /*break*/, 21];
                    case 19: return [4 /*yield*/, dispatch(new SetSyncStatus(false, false)).toPromise()];
                    case 20:
                        _d.sent();
                        _d.label = 21;
                    case 21:
                        console.log('SYNC: ' + (performance.now() - start) / 1000);
                        return [2 /*return*/];
                }
            });
        });
    };
    AppState.prototype.setToDefault = function (ctx) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var state, today;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        state = ctx.getState();
                        today = moment();
                        return [4 /*yield*/, ctx.dispatch(new SetActiveDate(today.format())).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppState.prototype.changeActiveDate = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState, dispatch = _a.dispatch;
        var previous = _b.previous;
        var width = window.innerWidth;
        var daysToScroll = width > 325 ? 7 :
            width > 275 ? 5 :
                width > 225 ? 3 : 1;
        var draftState = produce(getState(), function (draft) {
            var date = moment(draft.activeDate);
            if (previous) {
                date.subtract(daysToScroll, 'day');
            }
            else {
                date.add(daysToScroll, 'day');
            }
            draft.activeDate = date;
        });
        setState(draftState);
        return dispatch(new FetchWorkoutForDay());
    };
    AppState.prototype.setActiveDate = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState, dispatch = _a.dispatch;
        var dateString = _b.dateString;
        var draftState = produce(getState(), function (draft) {
            draft.activeDate = moment(dateString);
        });
        setState(draftState);
        return dispatch(new FetchWorkoutForDay());
    };
    AppState.prototype.fetchWorkoutForDay = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState, dispatch = _a.dispatch;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var state, date, days, organizedDays, days_1, days_1_1, day, plan, groups, _loop_1, this_1, groups_1, groups_1_1, group, e_1_1, e_2_1, draftState;
            var e_2, _c, e_1, _d;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        state = getState();
                        date = state.activeDate || moment();
                        return [4 /*yield*/, this.dataService.getWorkouts(date)
                            // Organize by group
                        ];
                    case 1:
                        days = _e.sent();
                        organizedDays = {};
                        _e.label = 2;
                    case 2:
                        _e.trys.push([2, 15, 16, 17]);
                        days_1 = tslib_1.__values(days), days_1_1 = days_1.next();
                        _e.label = 3;
                    case 3:
                        if (!!days_1_1.done) return [3 /*break*/, 14];
                        day = days_1_1.value;
                        return [4 /*yield*/, this.dataService.getPlan(day.planUUID)
                            // Add to groups thi splan belongs to
                        ];
                    case 4:
                        plan = _e.sent();
                        return [4 /*yield*/, this.dataService.getUserGroups().toPromise()];
                    case 5:
                        groups = _e.sent();
                        _loop_1 = function (group) {
                            var hasUnsyncedSessions_1, planDocument, workoutDocument, totalLoad, workoutSessions_1, unsyncedSessions, phase;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(group.plans && group.plans.indexOf(plan.key) !== -1)) return [3 /*break*/, 4];
                                        hasUnsyncedSessions_1 = false;
                                        if (!organizedDays[group.key]) {
                                            organizedDays[group.key] = {
                                                group: group,
                                                days: [],
                                                hasUnsyncedSessions: false
                                            };
                                        }
                                        if (!!this_1.documentService.store.planDocuments) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this_1.documentService.restoreData().toPromise()];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2:
                                        planDocument = _.find(this_1.documentService.store.planDocuments, { key: plan.key });
                                        workoutDocument = _.find(planDocument.document.workouts, { id: day.key });
                                        totalLoad = 'N/A';
                                        if (workoutDocument.est_rpe > -1 && workoutDocument.est_completion_time_ms > -1) {
                                            totalLoad = (workoutDocument.est_rpe * (workoutDocument.est_completion_time_ms / 1000 / 60)).toString();
                                        }
                                        return [4 /*yield*/, this_1.dataService.getWorkoutSessionDocumentsForDay(group.key, plan.key, day.key, day.version)];
                                    case 3:
                                        workoutSessions_1 = _a.sent();
                                        unsyncedSessions = state.unsyncedWorkoutSessions;
                                        if (unsyncedSessions.length > 0 && workoutSessions_1.length > 0) {
                                            _.each(unsyncedSessions, function (sessionKey) {
                                                var index = _.findIndex(workoutSessions_1, { key: sessionKey });
                                                if (index !== -1) {
                                                    workoutSessions_1[index].unsynced = true;
                                                    hasUnsyncedSessions_1 = true;
                                                }
                                            });
                                        }
                                        phase = PlanDocumentUtils.findPhase(planDocument.document.phases, {
                                            day: workoutDocument.schedule.day[0], week: workoutDocument.schedule.week[0]
                                        });
                                        organizedDays[group.key].days.push({
                                            plan: plan,
                                            day: day,
                                            workoutSessions: workoutSessions_1,
                                            hasUnsyncedSessions: hasUnsyncedSessions_1,
                                            totalLoad: totalLoad,
                                            phase: phase
                                        });
                                        _a.label = 4;
                                    case 4: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _e.label = 6;
                    case 6:
                        _e.trys.push([6, 11, 12, 13]);
                        groups_1 = (e_1 = void 0, tslib_1.__values(groups)), groups_1_1 = groups_1.next();
                        _e.label = 7;
                    case 7:
                        if (!!groups_1_1.done) return [3 /*break*/, 10];
                        group = groups_1_1.value;
                        return [5 /*yield**/, _loop_1(group)];
                    case 8:
                        _e.sent();
                        _e.label = 9;
                    case 9:
                        groups_1_1 = groups_1.next();
                        return [3 /*break*/, 7];
                    case 10: return [3 /*break*/, 13];
                    case 11:
                        e_1_1 = _e.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 13];
                    case 12:
                        try {
                            if (groups_1_1 && !groups_1_1.done && (_d = groups_1.return)) _d.call(groups_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 13:
                        days_1_1 = days_1.next();
                        return [3 /*break*/, 3];
                    case 14: return [3 /*break*/, 17];
                    case 15:
                        e_2_1 = _e.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 17];
                    case 16:
                        try {
                            if (days_1_1 && !days_1_1.done && (_c = days_1.return)) _c.call(days_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 17:
                        draftState = produce(getState(), function (draft) {
                            draft.activeDate = date;
                            draft.organizedDays = organizedDays;
                        });
                        setState(draftState);
                        return [4 /*yield*/, dispatch(new SetSyncStatus(false, true)).toPromise()];
                    case 18: return [2 /*return*/, _e.sent()];
                }
            });
        });
    };
    AppState.prototype.setActiveDay = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState, dispatch = _a.dispatch;
        var group = _b.group, plan = _b.plan, day = _b.day;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var draftState;
            return tslib_1.__generator(this, function (_c) {
                draftState = produce(getState(), function (draft) {
                    draft.activeGroup = group;
                    draft.activePlan = plan;
                    draft.activeDay = day;
                });
                setState(draftState);
                return [2 /*return*/];
            });
        });
    };
    AppState.prototype.createUserProfile = function () {
        var activeUser = this.store.selectSnapshot(AuthState.activeUser);
        var user = {
            uuid: activeUser.uuid,
            first_name: activeUser.firstName,
            last_name: activeUser.lastName,
            rank: activeUser.rank,
            email: activeUser.email,
        };
        var exerciseProgramId = this.documentService.store.exerciseProgram && this.documentService.store.exerciseProgram.key;
        var userProfile = {
            type: 'UserProfile',
            version: 1,
            exercise_program_id: exerciseProgramId,
            exercise_program_version: 1,
            user: user,
            stats: {
                exercises: []
            },
            scores: {
                exercises: [],
                gender: undefined,
                age_group: undefined
            }
        };
        return of(userProfile);
    };
    AppState.prototype.resetMain = function (_a) {
        var getState = _a.getState, setState = _a.setState;
        var state = produce(getState(), function (draft) {
            draft.activeGroup = undefined;
            draft.activePlan = undefined;
            draft.activeDay = undefined;
            draft.availablePlanDays = undefined;
            draft.organizedDays = undefined;
            draft.dayToWorkout = {};
            draft.joinedGroups = [];
        });
        return of([]).pipe(tap(function () {
            setState(state);
        }));
    };
    AppState.prototype.setSyncStatus = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var isSyncing = _b.isSyncing, isSuccess = _b.isSuccess;
        var state = produce(getState(), function (draft) {
            draft.isSyncing = isSyncing;
            if (isSyncing) {
                draft.lastSyncAttempt = moment();
            }
            if (isSuccess === true) {
                draft.lastSyncDate = moment();
            }
        });
        setState(state);
    };
    AppState.prototype.set1RM = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var oneRM = _b.oneRM, statIndex = _b.statIndex;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var draftState, observationDocument, state;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        draftState = produce(getState(), function (draft) {
                            if (statIndex >= 0) {
                                draft.userProfile.stats.exercises[statIndex] = oneRM;
                            }
                            else {
                                draft.userProfile.stats.exercises.push(oneRM);
                            }
                        });
                        setState(draftState);
                        if (oneRM.max_set) {
                            observationDocument = this.dataService.generateMaxSetObservation(oneRM);
                        }
                        else {
                            observationDocument = this.dataService.generate1RMObservation(oneRM);
                        }
                        return [4 /*yield*/, this.dataService.storeObservationDocument(observationDocument)
                            // Sync observation document
                        ];
                    case 1:
                        _c.sent();
                        state = getState();
                        if (state.isOnline) {
                            this.dataService.pushDocuments();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppState.prototype.setPft = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var pft = _b.pft, pftIndex = _b.pftIndex;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var draftState;
            return tslib_1.__generator(this, function (_c) {
                draftState = produce(getState(), function (draft) {
                    if (draft.userProfile.scores) {
                        if (pftIndex >= 0) {
                            draft.userProfile.scores.exercises[pftIndex] = pft;
                        }
                        else {
                            draft.userProfile.scores.exercises.push(pft);
                        }
                    }
                    else {
                        draft.userProfile.scores = {
                            exercises: [pft],
                            gender: undefined,
                            age_group: undefined
                        };
                    }
                });
                setState(draftState);
                return [2 /*return*/];
            });
        });
    };
    AppState.prototype.setCft = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var cft = _b.cft, cftIndex = _b.cftIndex;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var draftState;
            return tslib_1.__generator(this, function (_c) {
                draftState = produce(getState(), function (draft) {
                    if (draft.userProfile.scores) {
                        if (cftIndex >= 0) {
                            draft.userProfile.scores.exercises[cftIndex] = cft;
                        }
                        else {
                            draft.userProfile.scores.exercises.push(cft);
                        }
                    }
                    else {
                        draft.userProfile.scores = {
                            exercises: [cft],
                            gender: undefined,
                            age_group: undefined
                        };
                    }
                });
                setState(draftState);
                return [2 /*return*/];
            });
        });
    };
    AppState.prototype.setPftScore = function (_a) {
        var pftScore = _a.pftScore;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var observationDocument;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        observationDocument = this.dataService.generatePftCftScoreObservation(pftScore, 'PFT');
                        return [4 /*yield*/, this.dataService.storeObservationDocument(observationDocument)];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.dataService.postScoreDocument(observationDocument)];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppState.prototype.setCftScore = function (_a) {
        var cftScore = _a.cftScore;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var observationDocument;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        observationDocument = this.dataService.generatePftCftScoreObservation(cftScore, 'CFT');
                        return [4 /*yield*/, this.dataService.storeObservationDocument(observationDocument)];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.dataService.postScoreDocument(observationDocument)];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppState.prototype.syncPftCftScores = function (_a) {
        var getState = _a.getState;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var state;
            return tslib_1.__generator(this, function (_b) {
                state = getState();
                if (state.isOnline) {
                    this.dataService.pushDocuments();
                    // await this.dataService.postPftCftScoreObservationDocuments();
                }
                return [2 /*return*/];
            });
        });
    };
    AppState.prototype.setUserGender = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var gender = _b.gender;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var draftState;
            return tslib_1.__generator(this, function (_c) {
                draftState = produce(getState(), function (draft) {
                    if (gender) {
                        if (draft.userProfile.scores) {
                            draft.userProfile.scores.gender = gender;
                        }
                        else {
                            draft.userProfile.scores = {
                                exercises: [],
                                gender: gender,
                                age_group: undefined
                            };
                        }
                    }
                });
                setState(draftState);
                return [2 /*return*/];
            });
        });
    };
    AppState.prototype.setUserAgeGroup = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var ageGroup = _b.ageGroup;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var draftState;
            return tslib_1.__generator(this, function (_c) {
                draftState = produce(getState(), function (draft) {
                    if (ageGroup) {
                        if (draft.userProfile.scores) {
                            draft.userProfile.scores.age_group = ageGroup;
                        }
                        else {
                            draft.userProfile.scores = {
                                exercises: [],
                                gender: undefined,
                                age_group: ageGroup
                            };
                        }
                    }
                });
                setState(draftState);
                return [2 /*return*/];
            });
        });
    };
    AppState.prototype.addUnsyncedWorkoutSession = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var key = _b.key;
        var state = produce(getState(), function (draft) {
            if (draft.unsyncedWorkoutSessions.indexOf(key) === -1) {
                draft.unsyncedWorkoutSessions.push(key);
            }
        });
        setState(state);
    };
    AppState.prototype.syncWorkoutSessions = function (_a) {
        var getState = _a.getState, setState = _a.setState, dispatch = _a.dispatch;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var state, unsyncedSessions, syncWorkoutSessionDocuments, productDraft;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: 
                    // Also syncs all Observations
                    return [4 /*yield*/, this.dataService.pushDocuments()];
                    case 1:
                        // Also syncs all Observations
                        _b.sent();
                        state = getState();
                        unsyncedSessions = _.clone(state.unsyncedWorkoutSessions);
                        if (!(unsyncedSessions.length > 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.dataService.syncWorkoutSessionDocuments(unsyncedSessions)];
                    case 2:
                        syncWorkoutSessionDocuments = _b.sent();
                        syncWorkoutSessionDocuments.forEach(function (doc) {
                            var index = unsyncedSessions.indexOf(doc.key, 0);
                            if (index !== -1) {
                                unsyncedSessions.splice(index, 1);
                            }
                        });
                        productDraft = produce(getState(), function (draft) {
                            draft.unsyncedWorkoutSessions = unsyncedSessions;
                        });
                        setState(productDraft);
                        if (!syncWorkoutSessionDocuments) return [3 /*break*/, 4];
                        return [4 /*yield*/, dispatch(new FetchWorkoutForDay()).toPromise()];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AppState.prototype.setActiveStatsTab = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var tab = _b.tab;
        var state = getState();
        var draftState = produce(state, function (draft) {
            draft.activeStatsTab = tab || 'workouts';
        });
        setState(draftState);
    };
    AppState.prototype.setSelectedItem = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var rm = _b.rm;
        var state = getState();
        var draftState = produce(state, function (draft) {
            draft.selectedRM = rm;
        });
        setState(draftState);
    };
    AppState.prototype.SetShowNotification = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var show = _b.show;
        var state = getState();
        var draftState = produce(state, function (draft) {
            draft.showNotification = show;
        });
        setState(draftState);
    };
    tslib_1.__decorate([
        Action(SetMainTabIndex),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetMainTabIndex]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState.prototype, "setMainTabIndex", null);
    tslib_1.__decorate([
        Action(OnlineStatusChange),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, OnlineStatusChange]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState.prototype, "onlineStatusChange", null);
    tslib_1.__decorate([
        Action(ClearActiveGroup),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState.prototype, "clearActiveGroup", null);
    tslib_1.__decorate([
        Action(JoinGroup),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, JoinGroup]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "joinGroup", null);
    tslib_1.__decorate([
        Action(LeaveGroup),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, LeaveGroup]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "leaveGroup", null);
    tslib_1.__decorate([
        Action(InitMain),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, InitMain]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "initMain", null);
    tslib_1.__decorate([
        Action(SetActiveToDefault),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "setToDefault", null);
    tslib_1.__decorate([
        Action(ChangeActiveDate),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ChangeActiveDate]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState.prototype, "changeActiveDate", null);
    tslib_1.__decorate([
        Action(SetActiveDate),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetActiveDate]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState.prototype, "setActiveDate", null);
    tslib_1.__decorate([
        Action(FetchWorkoutForDay),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, FetchWorkoutForDay]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "fetchWorkoutForDay", null);
    tslib_1.__decorate([
        Action(SetActiveDay),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetActiveDay]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "setActiveDay", null);
    tslib_1.__decorate([
        Action(ResetMain),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState.prototype, "resetMain", null);
    tslib_1.__decorate([
        Action(SetSyncStatus),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetSyncStatus]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState.prototype, "setSyncStatus", null);
    tslib_1.__decorate([
        Action(Set1RM),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Set1RM]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "set1RM", null);
    tslib_1.__decorate([
        Action(SetPft),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetPft]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "setPft", null);
    tslib_1.__decorate([
        Action(SetCft),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetCft]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "setCft", null);
    tslib_1.__decorate([
        Action(SetPftScore),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [SetPftScore]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "setPftScore", null);
    tslib_1.__decorate([
        Action(SetCftScore),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [SetCftScore]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "setCftScore", null);
    tslib_1.__decorate([
        Action(SyncPftCftScores),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "syncPftCftScores", null);
    tslib_1.__decorate([
        Action(SetUserGender),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetUserGender]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "setUserGender", null);
    tslib_1.__decorate([
        Action(SetUserAgeGroup),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetUserAgeGroup]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "setUserAgeGroup", null);
    tslib_1.__decorate([
        Action(AddUnsyncedWorkoutSession),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddUnsyncedWorkoutSession]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState.prototype, "addUnsyncedWorkoutSession", null);
    tslib_1.__decorate([
        Action(SyncWorkoutSessions),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AppState.prototype, "syncWorkoutSessions", null);
    tslib_1.__decorate([
        Action(SetActiveStatsTab),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetActiveStatsTab]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState.prototype, "setActiveStatsTab", null);
    tslib_1.__decorate([
        Action(SetSelectedRM),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetSelectedRM]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState.prototype, "setSelectedItem", null);
    tslib_1.__decorate([
        Action(SetShowNotification),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetShowNotification]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState.prototype, "SetShowNotification", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "isOnline", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "isSyncing", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "lastSyncDate", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "activeGroupSelected", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "activePlanSelected", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "activeDaySelected", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "userProfile", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "unsyncedWorkoutSessions", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "activeDate", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "organizedDays", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "dayToWorkout", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "joinedGroups", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "activeStatsTab", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "mainTabIndex", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "selectedRM", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AppStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppState, "showNotification", null);
    AppState = tslib_1.__decorate([
        State({
            name: 'app',
            defaults: {
                tabView: 'main',
                unsyncedWorkoutSessions: [],
                activeStatsTab: 'workouts',
                mainTabIndex: 0,
                showNotification: true
            }
        }),
        tslib_1.__metadata("design:paramtypes", [Store,
            AuthService,
            DataService,
            DocumentService,
            Router])
    ], AppState);
    return AppState;
}());
export { AppState };
