import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { PageTransitionService, PAGETRANSITIONS } from './page-transition.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor (private router: Router, private pageTransitionService: PageTransitionService) {}

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('auth') > -1 || req.url.indexOf(environment.externalSupportUrl) > -1) {
      return next.handle(req)
    }

    // Clone the request to add the new header.
    const username = localStorage.getItem('midas-username')
    const authToken = localStorage.getItem('midas-token')

    if (authToken) {
      const authReq = req.clone({
        headers: req.headers
          .set('username', username)
          .set('Authorization', 'Bearer ' + authToken)
          .set('X-Application', 'mobile')
          .set('Pragma', 'no-cache')
          .set('Cache-Control', 'no-cache')
          .set('If-Modified-Since', 'Tue, 01 Jan 1980 1:00:00 GMT')
      })

      // Pass on the cloned request instead of the original request.
      return next.handle(authReq)
        .pipe(tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              localStorage.removeItem('midas-token')
              localStorage.removeItem('midas-username')
              localStorage.removeItem('midas-userUUID')
              localStorage.removeItem('midas-currentUser')
              this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.TOP)
              this.router.navigate(['login'])
            }
          }
        }))
    } else {
      const headeredReq = req.clone({
        headers: req.headers
          .set('X-Application', 'mobile')
      })
      return next.handle(headeredReq)
    }
  }
}
