import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, ReplaySubject, BehaviorSubject, of, forkJoin, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from './rest.service';
import { DatabaseService } from './database.service';
import { Day, Group, Plan, User, WorkoutSession, PlanBlock, PlanExercise } from '../models';
import { AuthService } from './auth.service';
import { PlanDocument, PlanDocumentUtils, Workout, DocumentModelFactoryService, DocumentModelType, Observation, ObservationDocument, MaxSetResult, SurveyResult, ResourceType, Phase, Measure, } from 'fitforce-document-sync';
import { DocumentService } from './document.service';
import { WorkoutSessionDBDocument, WorkoutSessionDocument } from 'app/models/workout-session-document.model';
import { v4 as uuid } from 'uuid';
import { SurveyTemplateDocument } from 'app/models/survey-template-document/survey-template-document.model';
import { ResourceService } from '../modules/resources/resource.service';
var DataService = /** @class */ (function () {
    function DataService(database, restService, authService, documentService, factory, resourceService) {
        this.database = database;
        this.restService = restService;
        this.authService = authService;
        this.documentService = documentService;
        this.factory = factory;
        this.resourceService = resourceService;
        this.fetchingMetaData$ = new BehaviorSubject(false);
        this.planDaysSyncPct$ = new ReplaySubject(1);
        this.planDaysPagesTotal = 0;
        this.planDaysPagesLeft = 0;
        this.pftCftChartResetSubject = new Subject();
        this.pftCftChartReset$ = this.pftCftChartResetSubject.asObservable();
    }
    DataService.prototype.syncWorkoutSessionsLocal = function () {
        var _this = this;
        return this.documentService.restoreData().pipe(map(function () {
            var objectStoreName = WorkoutSessionDocument.objectStoreName;
            var workoutSessionDocuments = _this.documentService.store.workoutSessionDocuments;
            if (workoutSessionDocuments.length > 0) {
                var documentObs = workoutSessionDocuments.map(function (session) {
                    return _this.database.put(objectStoreName, session, session.key);
                });
                return forkJoin(documentObs);
            }
            else {
                return of([]);
            }
        }));
    };
    DataService.prototype.setDefaultGroup = function (groups) {
        if (!this.selectedGroup) {
            this.selectedGroup = _.first(_.sortBy(groups, 'name'));
            if (this.selectedGroup.plans) {
                this.selectedPlan = _.first(this.selectedGroup.plans);
            }
        }
    };
    DataService.prototype.getUserGroups = function () {
        return this.getGroups().pipe(map(function (groups) {
            /* Groups are joined by default */
            // groups = _.filter(groups, group => this.isJoined(group))
            return groups;
        }));
    };
    DataService.prototype.isJoined = function (group) {
        return _.includes(group.users, this.authService.getUserUUID());
    };
    DataService.prototype.fetchData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var groups, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchJoinedGroups()];
                    case 1:
                        groups = _a.sent();
                        return [4 /*yield*/, this.insertData(groups)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        });
    };
    DataService.prototype.fetchJoinedGroups = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var groups;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchPagedGroups('Group/search/joined')];
                    case 1:
                        groups = _a.sent();
                        return [2 /*return*/, groups];
                }
            });
        });
    };
    DataService.prototype.fetchPagedGroups = function (route, all) {
        if (all === void 0) { all = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var groups, fetchNextPage, params, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        groups = [];
                        fetchNextPage = true;
                        params = {
                            query: '',
                            sort: 'ASC',
                            limitPerPage: 50,
                            pageNumber: 1
                        };
                        _a.label = 1;
                    case 1:
                        if (!fetchNextPage) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.restService.get(route, params, true).toPromise()];
                    case 2:
                        res = _a.sent();
                        groups.push.apply(groups, tslib_1.__spread(res.groups));
                        if (res.hasNextPage) {
                            params.pageNumber += 1;
                        }
                        else {
                            fetchNextPage = false;
                        }
                        if (!all) {
                            fetchNextPage = false;
                        }
                        return [3 /*break*/, 1];
                    case 3: return [2 /*return*/, groups];
                }
            });
        });
    };
    DataService.prototype.fetchPublicGroups = function (pageNumber, limitPerPage, searchText) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limitPerPage === void 0) { limitPerPage = 10; }
        if (searchText === void 0) { searchText = ''; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var route, params;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        route = 'Group/search/available';
                        params = {
                            query: searchText,
                            sort: 'ASC',
                            limitPerPage: limitPerPage,
                            pageNumber: pageNumber
                        };
                        return [4 /*yield*/, this.restService.get(route, params, true).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DataService.prototype.fetchMetaData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var metaData, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restService.get('fetchMetadata').toPromise()];
                    case 1:
                        metaData = _a.sent();
                        return [4 /*yield*/, this.insertData(metaData.groups, false)];
                    case 2:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        });
    };
    DataService.prototype.insertData = function (groups, clearDB) {
        if (clearDB === void 0) { clearDB = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mapped;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        if (!clearDB) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.clearDatabase()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.fetchGroupDocuments(groups)];
                    case 4:
                        mapped = _a.sent();
                        return [4 /*yield*/, this.database.insert(mapped.groups)];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.database.insert(mapped.plans, true)];
                    case 6:
                        _a.sent();
                        return [4 /*yield*/, this.database.insert(mapped.users)];
                    case 7:
                        _a.sent();
                        return [2 /*return*/, mapped];
                }
            });
        });
    };
    DataService.prototype.clearDatabase = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.database.clear(Day)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.database.clear(Plan)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.database.clear(Group)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DataService.prototype.fetchGroupDocuments = function (rawGroups) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var metaData, joinedGroups, groupUUIDs, groups;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        metaData = {
                            groups: [],
                            plans: [],
                            users: [],
                        };
                        joinedGroups = _.filter(rawGroups, function (group) {
                            if (!group.hasOwnProperty('members')) {
                                group.members = group.users;
                            }
                            // return _.some(group.members, members => members.uuid === this.authService.getUserUUID())
                            // rawGroups should only contain joined groups
                            return true;
                        });
                        groupUUIDs = joinedGroups.map(function (group) { return group.uuid; });
                        return [4 /*yield*/, this.documentService.fetchDocuments(groupUUIDs).toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.mapv2Plans(rawGroups)];
                    case 2:
                        groups = _a.sent();
                        metaData = this.parseGroups(groups);
                        return [2 /*return*/, metaData];
                }
            });
        });
    };
    DataService.prototype.syncSingleGroup = function (group) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var metaData, groups;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        metaData = {
                            groups: [],
                            plans: [],
                            users: [],
                        };
                        return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        if (!group.key) {
                            group.key = group.uuid;
                        }
                        return [4 /*yield*/, this.documentService.fetchDocuments([group.key]).toPromise()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.mapv2Plans([group])];
                    case 3:
                        groups = _a.sent();
                        metaData = this.parseGroups(groups);
                        return [4 /*yield*/, this.database.insert(metaData.groups)];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.database.insert(metaData.plans, true)];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.database.insert(metaData.users)];
                    case 6:
                        _a.sent();
                        return [2 /*return*/, metaData];
                }
            });
        });
    };
    DataService.prototype.saveWorkoutSessionDocument = function (workoutSession) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.database.insert([workoutSession], true)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.documentService.insertWorkoutSessionDocument(workoutSession).toPromise()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DataService.prototype.syncWorkoutSessionDocuments = function (workoutSessionKeys) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var syncedSessions, workoutSessions, workoutSessions_2, workoutSessions_2_1, workoutSession, doc, e_1, e_2_1;
            var e_2, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        syncedSessions = [];
                        return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.database.getRecordsByKeys(WorkoutSessionDocument.objectStoreName, workoutSessionKeys)];
                    case 2:
                        workoutSessions = _b.sent();
                        _b.label = 3;
                    case 3:
                        _b.trys.push([3, 10, 11, 12]);
                        workoutSessions_2 = tslib_1.__values(workoutSessions), workoutSessions_2_1 = workoutSessions_2.next();
                        _b.label = 4;
                    case 4:
                        if (!!workoutSessions_2_1.done) return [3 /*break*/, 9];
                        workoutSession = workoutSessions_2_1.value;
                        _b.label = 5;
                    case 5:
                        _b.trys.push([5, 7, , 8]);
                        doc = _.cloneDeep(workoutSession.document);
                        return [4 /*yield*/, this.restService.post("WorkoutSessionDocument/" + workoutSession.key, {
                                document: JSON.stringify(doc)
                            }, true).toPromise()];
                    case 6:
                        _b.sent();
                        syncedSessions.push(workoutSession);
                        return [3 /*break*/, 8];
                    case 7:
                        e_1 = _b.sent();
                        if (e_1.status === 409) {
                            syncedSessions.push(workoutSession);
                        }
                        else {
                            // console.log('did not sync', workoutSession)
                        }
                        return [3 /*break*/, 8];
                    case 8:
                        workoutSessions_2_1 = workoutSessions_2.next();
                        return [3 /*break*/, 4];
                    case 9: return [3 /*break*/, 12];
                    case 10:
                        e_2_1 = _b.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 12];
                    case 11:
                        try {
                            if (workoutSessions_2_1 && !workoutSessions_2_1.done && (_a = workoutSessions_2.return)) _a.call(workoutSessions_2);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 12: return [2 /*return*/, syncedSessions];
                }
            });
        });
    };
    DataService.prototype.getAllWorkoutSessionDocuments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.database.getAll(WorkoutSessionDocument.objectStoreName)];
                }
            });
        });
    };
    DataService.prototype.removeProp = function (obj, propToDelete) {
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (typeof obj[property] === 'object') {
                    this.removeProp(obj[property], propToDelete);
                }
                else {
                    if (property === propToDelete && obj[property] === true) {
                        delete obj[property];
                    }
                }
            }
        }
        return obj;
    };
    DataService.prototype.getWorkoutSessionDocumentsForDay = function (groupKey, planKey, dayKey, dayVersion) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var records, filtered;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.database.getAll(WorkoutSessionDocument.objectStoreName)];
                    case 2:
                        records = _a.sent();
                        filtered = _.filter(records, {
                            document: {
                                group: {
                                    uuid: groupKey
                                },
                                plan: {
                                    id: planKey
                                },
                                workout: {
                                    id: dayKey,
                                    version: dayVersion,
                                },
                                workout_complete: true
                            }
                        });
                        return [2 /*return*/, filtered];
                }
            });
        });
    };
    DataService.prototype.getWorkoutSessionDocumentsForPlan = function (groupKey, planKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var records, filtered;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.database.getAll(WorkoutSessionDocument.objectStoreName)];
                    case 2:
                        records = _a.sent();
                        filtered = _.filter(records, {
                            document: {
                                group: {
                                    uuid: groupKey
                                },
                                plan: {
                                    id: planKey
                                },
                                workout_complete: true
                            }
                        });
                        return [2 /*return*/, filtered];
                }
            });
        });
    };
    DataService.prototype.getWorkoutSessionDocument = function (key) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var record;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.database.getByKey(WorkoutSessionDocument.objectStoreName, key)];
                    case 2:
                        record = _a.sent();
                        return [2 /*return*/, record.document];
                }
            });
        });
    };
    DataService.prototype.parseGroups = function (groups) {
        var _this = this;
        var allGroups = [];
        var allPlans = [];
        var allUsers = [];
        _.each(groups, function (groupJSON) {
            var groupPlans = [];
            if (groupJSON.plans) {
                groupPlans = _this.createGroupPlans(groupJSON.plans);
            }
            // TODO: is this needed? the response doesn't contain members or owners now
            var groupUsers = _this.createGroupUsers(groupJSON.members);
            var groupOwners = _this.createGroupUsers(groupJSON.owners);
            var group = _this.createGroup(groupJSON, groupOwners, groupUsers, groupPlans);
            allGroups.push(group);
            allPlans = _.unionBy(allPlans, groupPlans, 'key');
            allUsers = _.unionBy(allUsers, tslib_1.__spread(groupUsers, groupOwners), 'key');
        });
        var response = {
            groups: allGroups,
            plans: allPlans,
            users: allUsers
        };
        return response;
    };
    DataService.prototype.getAllWorkouts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allDays, filteredDays;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getRecords(Day).toPromise()];
                    case 1:
                        allDays = _a.sent();
                        filteredDays = _.filter(allDays, function (day) {
                            if ((day.isRestricted || day.isComplete)) {
                                return true;
                            }
                            return false;
                        });
                        return [2 /*return*/, filteredDays];
                }
            });
        });
    };
    DataService.prototype.getWorkouts = function (date) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allDays, filteredDays;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getRecords(Day).toPromise()];
                    case 1:
                        allDays = _a.sent();
                        filteredDays = _.filter(allDays, function (day) {
                            if (day.date.isSame(date, 'day') && (day.isRestricted || day.isComplete)) {
                                return true;
                            }
                            return false;
                        });
                        return [2 /*return*/, filteredDays];
                }
            });
        });
    };
    DataService.prototype.getWorkout = function (planKey, dayKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var day;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getRecord(Day, { key: dayKey, planUUID: planKey })];
                    case 1:
                        day = _a.sent();
                        return [2 /*return*/, day];
                }
            });
        });
    };
    DataService.prototype.createGroup = function (groupJSON, owners, users, plans) {
        var group = new Group({
            id: groupJSON.uuid,
            name: groupJSON.name,
            private: groupJSON.private,
            owners: _.map(owners, 'key'),
            plans: plans,
            users: users,
            created: groupJSON.created_on_timestamp,
        });
        return group;
    };
    DataService.prototype.createGroupUsers = function (data) {
        var users = [];
        _.each(data, function (user) {
            users.push(new User({
                id: user.uuid,
                email: user.email,
                firstName: user.firstName || user.firstname,
                lastName: user.lastName || user.lastname,
                rank: user.rank,
                isAdmin: user.isAdmin || false,
            }));
        });
        return users;
    };
    DataService.prototype.createGroupPlans = function (data) {
        var plans = [];
        _.each(data, function (plan) {
            var planToAdd = plan;
            if (!(plan instanceof Plan)) {
                planToAdd = new Plan({
                    id: plan.id,
                    name: plan.name,
                    startDate: plan.startDate,
                    endDate: plan.endDate,
                    version: plan.version
                });
            }
            plans.push(planToAdd);
        });
        return plans;
    };
    DataService.prototype.fetchInitialDays = function (plans) {
        var fromDate = moment().format('MM/DD/YYYY');
        var toDates = plans.map(function (plan) { return moment(plan.endDate); });
        var toDate = moment.max(toDates).format('MM/DD/YYYY');
        var planKeys = _.map(plans, function (plan) { return plan.key; });
        return this.fetchPlanDays(planKeys, fromDate, toDate, 1, 50);
    };
    DataService.prototype.mapv2Plans = function (metaGroups) {
        var _this = this;
        return new Promise(function (resolve) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var plans, mappableData, planDays;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                plans = [];
                mappableData = {
                    planDays: []
                };
                _.each(this.documentService.store.planDocuments, function (planDocument, i) {
                    var allExercises = _this.documentService.store.exerciseProgram.document.exercises;
                    var document = planDocument.document;
                    var startDate = moment(new Date(document.start_date));
                    plans.push({
                        id: planDocument.key,
                        name: document.name,
                        startDate: startDate,
                        endDate: startDate.clone().add(document.num_weeks, 'w').subtract(1, 'd')
                    });
                    document.workouts.forEach(function (workout) {
                        var day = PlanDocumentUtils.getDate(startDate, workout.schedule.day[0], workout.schedule.week[0]);
                        var planBlocks = [];
                        workout.tiers.forEach(function (tier) {
                            var plannedExercises = [];
                            tier.rows.forEach(function (row) {
                                var blockExercise = {
                                    uuid: row.exercise_id,
                                    name: row.exercise_name,
                                    sets: row.parameter_selections['sets'],
                                    distance: row.parameter_selections['distance'],
                                    reps: row.parameter_selections['reps'],
                                    time: row.parameter_selections['time'],
                                    load: row.parameter_selections['load'],
                                    hold: row.parameter_selections['hold'],
                                    regression: row.parameter_selections['regression'],
                                    rowNum: row.order,
                                    notes: row.notes,
                                };
                                // Find the exercise metadata
                                var exerciseMetaData = _.find(allExercises, { id: row.exercise_id });
                                if (exerciseMetaData && exerciseMetaData.resources) {
                                    // Which should always exist
                                    if (exerciseMetaData.resources.length > 0) {
                                        var video = _.clone(_.find(exerciseMetaData.resources, { type: ResourceType.Video }));
                                        // Format to meet our needs
                                        if (video) {
                                            video.assetId = video.asset_id;
                                            blockExercise['video'] = video;
                                        }
                                    }
                                }
                                plannedExercises.push(blockExercise);
                            });
                            plannedExercises.sort(function (a, b) {
                                return a.rowNum - b.rowNum;
                            });
                            planBlocks.push({
                                uuid: tier.id,
                                name: tier.name,
                                isCustom: !!workout.workout_type_id,
                                // columns: tier.columns,
                                notes: tier.notes,
                                tier: tier.order,
                                plannedExercises: plannedExercises,
                                planDayUUID: workout.id,
                            });
                        });
                        var newDay = {
                            uuid: workout.id,
                            planUUID: planDocument.uuid,
                            date: day,
                            isComplete: workout.complete,
                            blocks: planBlocks,
                            version: workout.version,
                        };
                        if (workout.est_completion_time_ms) {
                            newDay['est_completion_time_ms'] = workout.est_completion_time_ms;
                        }
                        if (workout.restrict_label_id) {
                            newDay['isRestricted'] = !!workout.restrict_label_id;
                            newDay['restrictLabel'] = {
                                id: workout.restrict_label_id,
                                name: workout.name
                            };
                        }
                        else {
                            newDay['workoutType'] = {
                                id: workout.workout_type_id,
                                name: workout.name,
                            };
                        }
                        mappableData.planDays.push(newDay);
                    });
                });
                planDays = this.mapPlanDayData(mappableData);
                // Map Plan to Group
                _.each(this.documentService.store.planMetadata, function (planMetaData) {
                    planMetaData.publishedToGroups.forEach(function (group) {
                        if (metaGroups) {
                            if (metaGroups[0]) {
                                if (metaGroups[0].key && !metaGroups[0].uuid) {
                                    metaGroups[0].uuid = metaGroups[0].key;
                                }
                                if (metaGroups[0].uuid && !metaGroups[0].key) {
                                    metaGroups[0].key = metaGroups[0].uuid;
                                }
                            }
                            var gp = _.findIndex(metaGroups, { uuid: group.uuid });
                            if (gp !== -1) {
                                var planIndex = _.findIndex(plans, { id: planMetaData.key });
                                if (planIndex !== -1) {
                                    plans[planIndex].version = 2;
                                    if (!metaGroups[gp].plans) {
                                        metaGroups[gp].plans = [];
                                    }
                                    metaGroups[gp].plans = tslib_1.__spread(metaGroups[gp].plans, [new Plan(plans[planIndex])]);
                                }
                            }
                        }
                    });
                });
                resolve(metaGroups);
                this.insertAllPlanDays(planDays).then(function () {
                    resolve(metaGroups);
                });
                return [2 /*return*/];
            });
        }); });
    };
    DataService.prototype.updateSyncPct = function () {
        var percent = 100;
        if (this.planDaysPagesTotal !== 0) {
            percent = Math.floor((this.planDaysPagesLeft / this.planDaysPagesTotal) * 100);
        }
        this.planDaysSyncPct$.next(percent);
    };
    DataService.prototype.fetchPlanDays = function (planKeys, fromDate, toDate, pageNumber, limitPerPage) {
        if (limitPerPage === void 0) { limitPerPage = 50; }
        return this.restService.post('fetchPlanDays', {
            plans: planKeys,
            from_date: fromDate,
            to_date: toDate,
            limitPerPage: limitPerPage,
            pageNumber: pageNumber
        });
    };
    DataService.prototype.mapPlanDayData = function (data) {
        var allDays = [];
        // const start = performance.now();
        _.each(data.planDays, function (planDay) {
            var dayLoad = 0;
            var loadCount = 0;
            var planBlocks = [];
            _.each(_.sortBy(planDay.blocks, 'tier'), function (planBlock) {
                var blockExercises = [];
                _.each(planBlock.plannedExercises, function (planExercise) {
                    blockExercises.push(new PlanExercise({
                        id: planExercise.uuid,
                        name: planExercise.name,
                        sets: planExercise.sets,
                        distance: planExercise.distance,
                        time: planExercise.time,
                        load: planExercise.load,
                        reps: planExercise.reps,
                        hold: planExercise.hold,
                        rowNum: planExercise.rowNum,
                        notes: planExercise.notes,
                        regression: planExercise.regression,
                        video: planExercise.video,
                    }));
                    if (planBlock.tier === 3) {
                        loadCount++;
                        dayLoad += parseInt(planExercise.load, 10);
                    }
                });
                planBlocks.push(new PlanBlock({
                    id: planBlock.uuid,
                    name: planBlock.name,
                    isCustom: planBlock.isCustom,
                    columns: planBlock.columns,
                    notes: planBlock.notes,
                    tier: planBlock.tier,
                    plannedExercises: blockExercises,
                    planDayUUID: planDay.uuid,
                }));
            });
            var newDay = new Day({
                id: planDay.uuid,
                planUUID: planDay.planUUID,
                date: planDay.date,
                isComplete: planDay.isComplete,
                isRestricted: planDay.isRestricted,
                restrictLabel: planDay.restrictLabel,
                workoutType: planDay.workoutType,
                blocks: planBlocks,
                est_completion_time_ms: planDay.est_completion_time_ms,
                load: Math.trunc(dayLoad / loadCount),
                version: planDay.version,
            });
            allDays.push(newDay);
        });
        // console.log((performance.now() - start) / 1000);
        return allDays;
    };
    DataService.prototype.insertAllPlanDays = function (allDays) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.database.insert(allDays, true)];
                }
            });
        });
    };
    DataService.prototype.getAllSurveyTemplateDocuments = function (allSurveys) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.database.getRecords(SurveyTemplateDocument, allSurveys)];
                }
            });
        });
    };
    DataService.prototype.insertAllSurveyTemplateDocuments = function (allSurveys) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.database.insert(allSurveys, true)];
                }
            });
        });
    };
    DataService.prototype.insertSurveyTemplateDocumentLocal = function (surveyTemplateDocument) {
        this.documentService.insertSurveyTemplateDocument(surveyTemplateDocument);
        return this.syncSurveyTemplateDocumentsLocal();
    };
    DataService.prototype.syncSurveyTemplateDocumentsLocal = function () {
        var _this = this;
        return this.documentService.restoreData().pipe(map(function () {
            var objectStoreName = SurveyTemplateDocument.objectStoreName;
            var surveyTemplateDocuments = _this.documentService.store.surveyTemplateDocuments;
            if (surveyTemplateDocuments.length > 0) {
                var surveyDocObs = surveyTemplateDocuments.map(function (surveyTemplateDoc) {
                    return _this.database.put(objectStoreName, surveyTemplateDoc, surveyTemplateDoc.key);
                });
                return forkJoin(surveyDocObs);
            }
            else {
                return of([]);
            }
        }));
    };
    DataService.prototype.setLastSyncAttempt = function () {
        var time = moment().unix();
        localStorage.setItem('last-sync-attempt', time);
        return time;
    };
    DataService.prototype.getLastSyncAttempt = function () {
        return localStorage.getItem('last-sync-attempt');
    };
    DataService.prototype.getLastSyncTime = function () {
        return localStorage.getItem('last-sync');
    };
    DataService.prototype.setLastSyncTime = function () {
        localStorage.setItem('last-sync', moment().unix());
    };
    DataService.prototype.syncWorkoutSessions = function () {
        var _this = this;
        return new Promise(function (resWorkout, rejWorkout) {
            _this.postWorkoutSessionDocuments().then(function () {
                return _this.database.query(WorkoutSession, { isSynced: false, isPreview: false });
            }).then(function (workoutSessions) {
                return new Promise(function (res, reject) {
                    if (_.size(workoutSessions) > 0) {
                        _.each(workoutSessions, function (session, i) {
                            workoutSessions[i].requestSurvey = false;
                            workoutSessions[i].isSynced = true;
                        });
                        // Sync and remove version 1 workouts
                        var v1Sessions = workoutSessions.filter(function (session) { return session.version !== 2; });
                        if (v1Sessions) {
                            _this.restService.post('syncWorkoutSession', { workouts: v1Sessions }).subscribe(function (data) {
                                _.each(workoutSessions, function (session, i) {
                                    // Delete v1 workout sessions that have successfully synced
                                    if (!session.version || session.version !== 2) {
                                        delete workoutSessions[i];
                                        _this.database.delete(WorkoutSession.objectStoreName, session.key);
                                    }
                                });
                                // Remove deleted elements (undefined)
                                _.compact(workoutSessions);
                                resWorkout(workoutSessions);
                            }, function (error) {
                                resWorkout(workoutSessions);
                            });
                        }
                        else {
                            resWorkout(workoutSessions);
                        }
                    }
                    else {
                        // There are no workouts
                        resWorkout([]);
                    }
                });
            }).catch(function (err) {
                resWorkout([]);
            });
        });
    };
    DataService.prototype.groupRequestInvite = function (groupId, email) {
        // emails expect to be an array
        return this.restService.post("Group/" + groupId + "/invite", { emails: [email] }, true);
    };
    DataService.prototype.groupRequestLeave = function (groupId, userId) {
        return this.restService.delete("Group/" + groupId + "/members/" + userId, true);
    };
    /**
     * Gets the groups available to the current user
     *
     * For workout leaders, this will be a list.
     * For trainees, this will likely be a single group.
     */
    DataService.prototype.getGroups = function () {
        return this.getRecords(Group);
    };
    DataService.prototype.getGroup = function (key) {
        return this.getRecord(Group, key);
    };
    DataService.prototype.getPlanExercise = function (key) {
        return this.getRecord(PlanExercise, key);
    };
    DataService.prototype.getPlans = function (plans) {
        if (!plans || plans.length === 0) {
            return of([]);
        }
        return this.getRecordsByKeys(Plan, plans);
    };
    DataService.prototype.getDays = function (plan) {
        if (!plan || !plan.key) {
            return of([]);
        }
        return this.getRecords(Day, { planUUID: plan.key });
    };
    DataService.prototype.getDaysAsync = function (plan) {
        return this.getRecordsAsync(Day, 'date', { planUUID: plan.key });
    };
    DataService.prototype.getOwnersInfo = function (owners) {
        return this.getRecordsByKeys(User, owners);
    };
    DataService.prototype.getWorkoutPreview = function (plan, day) {
        var options = {
            append: {
                planId: plan.key,
                planDayId: day.key,
            }
        };
        return this.getRecords(PlanBlock, { planDay: day, options: options });
    };
    DataService.prototype.getPlanBlocks = function (planDay, planBlocks) {
        return this.getRecordsByKeys(PlanBlock, _.map(planBlocks, function (planBlock) { return [planDay.key, planBlock]; }));
    };
    DataService.prototype.getPlannedExercisesInBlock = function (planExercises) {
        return this.getRecordsByKeys(PlanExercise, planExercises);
    };
    DataService.prototype.getAllWorkoutSessions = function () {
        // Version 1 is no longer displayed
        return this.getRecords(WorkoutSession, { isPreview: false, version: 2 });
    };
    DataService.prototype.getWorkoutSession = function (key) {
        if (_.isEmpty(key)) {
            throw new Error('data.getWorkoutSession requires a key');
        }
        return this.getRecord(WorkoutSession, key);
    };
    DataService.prototype.getCompletedWorkoutSessionsByDay = function (planUUID, planDayUUID) {
        return this.getRecords(WorkoutSession, {
            planUUID: planUUID, planDayUUID: planDayUUID, isComplete: true, isDeprecated: false
        });
    };
    DataService.prototype.deprecateWorkoutSessions = function (workoutSessions) {
        workoutSessions.forEach(function (workoutSession, i) {
            workoutSessions[i].isDeprecated = true;
        });
        return this.database.insert(workoutSessions, true);
    };
    DataService.prototype.getCompletedWorkoutSessions = function (group, plan) {
        return this.getRecords(WorkoutSession, {
            groupUUID: group.key, planUUID: plan.key, isComplete: true, isDeprecated: false
        });
    };
    DataService.prototype.createWorkoutSessionDocument = function (group, leaders, planDocument, planMetaDataDoc, workout, activeUser) {
        var currentUser;
        if (activeUser) {
            currentUser = activeUser;
        }
        else {
            currentUser = this.authService.getCurrentUser();
        }
        var planDoc = planDocument.document;
        var groupLeaders = [];
        leaders.forEach(function (user) {
            groupLeaders.push({
                uuid: user.key,
                first_name: user.firstName,
                last_name: user.lastName,
                rank: user.rank,
                email: user.email
            });
        });
        var exerciseCount = 0;
        var tiers = [];
        workout.tiers.forEach(function (workoutTier) {
            var tier = {
                id: workoutTier.id,
                name: workoutTier.name,
                notes: workoutTier.notes,
                order: workoutTier.order,
                complete: false,
                circuit: workoutTier.circuit ? {
                    num_rounds: workoutTier.circuit.num_rounds,
                    rest_time_ms: workoutTier.circuit.rest_time_ms,
                    executions: [],
                } : undefined,
                rows: []
            };
            if (tier.circuit) {
                tier.circuit.executions = [];
            }
            var rows = [];
            var isTierCounted = false;
            workoutTier.rows.forEach(function (tierRow) {
                if (!tier.circuit || !isTierCounted) {
                    isTierCounted = true;
                    exerciseCount++;
                }
                // Normalize sets to be a number
                var defaultSets = parseInt(tierRow.parameter_selections['sets'], 10);
                var executions = [];
                var paramValues = _.cloneDeep(tierRow.parameter_selections);
                delete paramValues['sets'];
                if (!defaultSets || defaultSets === -1) {
                    defaultSets = 1;
                }
                // Check if load % is provided, should not be included in executions as the load is irrelevant
                if (paramValues['load']) {
                    paramValues['load'] = undefined;
                }
                for (var i = 0; i < defaultSets; i++) {
                    executions.push({
                        parameter_values: paramValues
                    });
                }
                var row = {
                    exercise: {
                        exercise_program_id: tierRow.exercise_id,
                        name: tierRow.exercise_name,
                        exercise_categories: [],
                    },
                    notes: tierRow.notes,
                    order: tierRow.order,
                    executions: executions,
                    start_time: null,
                    end_time: null,
                    parameters: tierRow.parameter_selections
                };
                rows.push(row);
            });
            rows.sort(function (a, b) {
                return a.order - b.order;
            });
            tier.rows = rows;
            tiers.push(tier);
        });
        var workoutSession = {
            id: uuid(),
            type: 'WorkoutSession',
            exercise_program_id: planDoc.exercise_program_id,
            start_time: moment().format(),
            workout_complete: false,
            leaders: groupLeaders,
            attendees: [{
                    uuid: currentUser.uuid,
                    email: currentUser.email,
                }],
            group: {
                uuid: group.key || group.uuid,
                name: group.name,
            },
            plan: {
                id: planDoc.id,
                name: planDoc.name,
                num_weeks: planDoc.num_weeks,
                start_date: planDoc.start_date,
                creator: {
                    uuid: planMetaDataDoc.createdByUser.uuid,
                    email: planMetaDataDoc.createdByUser.email,
                },
                notes: planMetaDataDoc.notes,
                version: planDoc.version,
            },
            workout: {
                id: workout.id,
                name: workout.name,
                workout_type_id: workout.workout_type_id,
                version: workout.version || -1,
                tiers: tiers,
            },
            group_size: group.users.length,
            attendance_count: 1,
            total_tiers: tiers.length,
            completed_tiers: 1,
            total_exercises: exerciseCount,
            completed_exercises: 1,
            est_completion_time_ms: workout.est_completion_time_ms || -1,
            est_rpe: workout.est_rpe || -1,
        };
        // If there is a phase, add it to the session
        var phase = PlanDocumentUtils.findPhase(planDocument.document.phases, {
            day: workout.schedule.day[0], week: workout.schedule.week[0]
        });
        if (phase) {
            workoutSession.phase = {
                uuid: phase.id,
                name: phase.name,
                color: phase.color
            };
        }
        return workoutSession;
    };
    DataService.prototype.endWorkoutSession = function (workoutSession) {
        workoutSession.workout_complete = true;
        workoutSession.end_time = moment().format();
        return this.database.update(workoutSession);
    };
    DataService.prototype.postWorkoutSessionDocuments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var workoutSessions;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.database.query(WorkoutSession, { isSynced: false, isPreview: false, version: 2 })];
                    case 2:
                        workoutSessions = _a.sent();
                        workoutSessions.forEach(function (workoutSession) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var workoutSessionDocumentDB, _a;
                            return tslib_1.__generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _b.trys.push([0, 4, , 5]);
                                        return [4 /*yield*/, this.database.get(WorkoutSessionDBDocument, workoutSession.key)];
                                    case 1:
                                        workoutSessionDocumentDB = _b.sent();
                                        return [4 /*yield*/, this.restService.post("WorkoutSessionDocument/" + workoutSession.key, {
                                                // set space to 0 force no formatting
                                                document: JSON.stringify(workoutSessionDocumentDB, null, 0)
                                            }, true).toPromise()];
                                    case 2:
                                        _b.sent();
                                        workoutSession.requestSurvey = false;
                                        workoutSession.isSynced = true;
                                        return [4 /*yield*/, this.database.update(workoutSession)];
                                    case 3:
                                        _b.sent();
                                        return [2 /*return*/];
                                    case 4:
                                        _a = _b.sent();
                                        return [2 /*return*/];
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    DataService.prototype.isScoreDocument = function (doc) {
        if (doc && doc.document && doc.document.measure && doc.document.measure.parameters) {
            if (doc.document.measure.parameters.length > 0) {
                var parameters = doc.document.measure.parameters[0];
                if (parameters.type === 'score') {
                    return true;
                }
            }
        }
        return false;
    };
    DataService.prototype.getScoreType = function (doc) {
        if (doc && doc.document && doc.document.result) {
            var result = doc.document.result;
            if (result) {
                return result.type;
            }
        }
        return '';
    };
    DataService.prototype.postPftCftScoreObservationDocuments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var observationDocuments, _a, _b, scoreObservationDocuments;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _c.sent();
                        _b = (_a = Array).from;
                        return [4 /*yield*/, this.documentService.store.observations.values()];
                    case 2:
                        observationDocuments = _b.apply(_a, [_c.sent()]);
                        scoreObservationDocuments = [];
                        return [4 /*yield*/, observationDocuments.forEach(function (doc) {
                                if (_this.isScoreDocument(doc)) {
                                    scoreObservationDocuments.push(doc);
                                    _this.postScoreDocument(doc);
                                }
                            })];
                    case 3:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DataService.prototype.postScoreDocument = function (doc) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var scoreType, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isScoreDocument(doc)) {
                            return [2 /*return*/];
                        }
                        if (!(doc && doc.document && doc.document.id)) return [3 /*break*/, 2];
                        scoreType = this.getScoreType(doc);
                        url = 'ObservationDocument/';
                        switch (scoreType) {
                            case 'PFT':
                                url += 'pft/' + doc.document.id;
                                break;
                            case 'CFT':
                                url += 'cft/' + doc.document.id;
                                break;
                            default:
                                return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.restService.post(url, {
                                document: JSON.stringify(doc.document, null, 0)
                            }, true).toPromise()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    DataService.prototype.getOrCreateWorkoutSession = function (group, plan, day, isGroupWorkout, isPreview, sessionDateTime, planBlocks, plannedExercises, workoutSessionDocumentKey) {
        var _this = this;
        return new Observable(function (observer) {
            // Find a session that was created but not started
            _this.getRecords(WorkoutSession, {
                key: workoutSessionDocumentKey
            }).subscribe(function (session) {
                if (_.size(session) === 0) {
                    _this.createWorkoutSession(group, plan, day, isGroupWorkout, isPreview, sessionDateTime, planBlocks, plannedExercises, workoutSessionDocumentKey).then(function (newSession) {
                        // console.log('New session created', newSession)
                        observer.next(newSession);
                        observer.complete();
                    });
                }
                else {
                    // console.log('Found session', session)
                    var workoutSession_1 = _.first(session);
                    // update session date time if modified by date picker
                    workoutSession_1.sessionDateTime = sessionDateTime;
                    _this.update(workoutSession_1).then(function () {
                        observer.next(workoutSession_1);
                        observer.complete();
                    });
                }
            });
        });
    };
    DataService.prototype.createWorkoutSession = function (group, plan, day, isGroupWorkout, isPreview, sessionDateTime, planBlocks, plannedExercises, workoutSessionDocumentKey) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var sessionBlocks = [];
            _.each(planBlocks, function (block, i) {
                var sessionExercises = [];
                _.each(plannedExercises[block.key], function (exercise, j) {
                    sessionExercises.push({
                        plannedExerciseUUID: exercise.key,
                        isComplete: exercise.isComplete,
                        setsCompleted: exercise.setsCompleted || '',
                        distanceCompleted: exercise.distanceCompleted || '',
                        timeCompleted: exercise.timeCompleted || '',
                        loadCompleted: exercise.loadCompleted || '',
                        repsCompleted: exercise.repsCompleted || '',
                    });
                });
                sessionBlocks.push({
                    name: block.name,
                    blockUUID: block.key,
                    isComplete: block.isComplete,
                    plannedExercises: sessionExercises,
                });
            });
            var workoutSession = {
                groupUUID: group.key,
                planUUID: plan.key,
                planDayUUID: day.key,
                isGroupWorkout: isGroupWorkout,
                blocks: sessionBlocks,
                sessionDateTime: sessionDateTime,
                name: day.name,
            };
            if (workoutSessionDocumentKey) {
                workoutSession.key = workoutSessionDocumentKey;
                workoutSession.version = 2;
            }
            if (isPreview) {
                workoutSession.isPreview = isPreview;
            }
            var newSession = new WorkoutSession(workoutSession);
            _this.database.insert([newSession])
                .then(function () {
                resolve(workoutSession);
            }, function (err) {
                reject(err);
            });
        });
    };
    DataService.prototype.markSessionComplete = function (workoutSession, result) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // We remove the session attempt so we can retry at least once after a workoutSession is marked complete
                        localStorage.removeItem('last-sync-attempt');
                        workoutSession.isComplete = true;
                        workoutSession.requestSurvey = result;
                        return [4 /*yield*/, this.resetSessionSurveyRequests(result)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.database.update(workoutSession)];
                }
            });
        });
    };
    DataService.prototype.resetSessionSurveyRequests = function (requestSurvey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var workoutSessions, workoutSessions_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!requestSurvey) {
                            return [2 /*return*/, Promise.resolve(true)];
                        }
                        return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.database.getAll(WorkoutSession)];
                    case 2:
                        workoutSessions = _a.sent();
                        _.each(workoutSessions, function (session, i) {
                            workoutSessions[i].requestSurvey = false;
                        });
                        workoutSessions_1 = workoutSessions;
                        return [2 /*return*/, this.database.insert(workoutSessions_1, true)];
                }
            });
        });
    };
    DataService.prototype.getPlan = function (key) {
        if (_.isEmpty(key)) {
            throw new Error('data.getPlan requires a key');
        }
        return this.getRecord(Plan, key);
    };
    DataService.prototype.getUsers = function (users) {
        return this.getRecordsByKeys(User, users);
    };
    DataService.prototype.getDay = function (key) {
        if (_.isEmpty(key)) {
            throw new Error('data.getDay requires a key');
        }
        return this.getRecord(Day, key);
    };
    DataService.prototype.deleteGroup = function (group) {
        return this.delete(group);
    };
    DataService.prototype.update = function (record) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.database.connect().then(function () {
                _this.database.update(record).then(function () {
                    resolve(record);
                    // sync
                }).catch(reject);
            }).catch(reject);
        });
    };
    DataService.prototype.delete = function (record) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var objectStoreName, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.database.connect()];
                    case 1:
                        _a.sent();
                        objectStoreName = record.constructor.objectStoreName;
                        return [4 /*yield*/, this.database.delete(objectStoreName, record.key)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 3:
                        e_3 = _a.sent();
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DataService.prototype.getRecord = function (typeClass, key) {
        var _this = this;
        var table = typeClass.objectStoreName;
        return new Promise(function (resolve, reject) {
            _this.database.connect().then(function () {
                // console.log('data.getRecord', table, key)
                _this.database.get(typeClass, key).then(function (record) {
                    resolve(record);
                    // if you're also online, re-async with the web service too
                    // if (this.restService.isOnline()) {
                    //   this.restService.getRecords(table)
                    //     .subscribe((records) => {
                    //       // convert raw objects to DatabaseRecord objects
                    //       records = records.map(convertTo(typeClass))
                    //
                    //       // save these records to the offline database
                    //       this.database.insert(table, records).then(() => {
                    //         resolve(records)
                    //       })
                    //     })
                    // }
                }).catch(reject);
            }).catch(reject);
        });
    };
    DataService.prototype.getRecordsAsync = function (typeClass, indexName, where) {
        return this.database.getRecordsAsync(typeClass, indexName, where);
    };
    DataService.prototype.getRecords = function (typeClass, filters, clearOnSync) {
        var _this = this;
        return new Observable(function (observer) {
            _this.database.connect()
                .then(function () {
                if (filters) {
                    return _this.database.query(typeClass, filters);
                }
                else {
                    return _this.database.getAll(typeClass);
                }
            })
                .then(function (records) {
                observer.next(records);
                observer.complete();
            })
                .catch(function (err) {
                observer.error(err);
                observer.complete();
            });
        });
    };
    DataService.prototype.getRecordsByKeys = function (typeClass, filters) {
        var _this = this;
        return new Observable(function (observer) {
            _this.database.connect()
                .then(function () {
                return _this.database.getRecords(typeClass, filters);
            })
                .then(function (records) {
                observer.next(records);
                observer.complete();
            })
                .catch(function (err) {
                observer.error(err);
                observer.complete();
            });
        });
    };
    DataService.prototype.generatePftCftScoreObservation = function (score, type) {
        var observation = this.factory.build(DocumentModelType.Observation);
        var observationDocument = new ObservationDocument({
            id: observation.id,
            document: observation,
            version: 1
        });
        observationDocument.userUUID = this.authService.getUserUUID();
        observationDocument.document.result = {
            type: type,
            score: score
        };
        var measure = this.documentService.store.exerciseProgram.document.measures.find(function (m) { return m.name === type; });
        if (!measure) {
            measure = {
                name: type.toUpperCase(),
                description: '',
                tags: [
                    'Fitness',
                    'Evaluation'
                ],
                parameters: [{
                        property: 'value',
                        type: 'score',
                        required: true,
                        unit: 'N/A'
                    }]
            };
        }
        observationDocument.document.measure = measure;
        observationDocument.markCreated();
        return observationDocument;
    };
    DataService.prototype.generate1RMObservation = function (oneRM) {
        var observation = this.factory.build(DocumentModelType.Observation);
        var observationDocument = new ObservationDocument({
            id: observation.id,
            document: observation,
            version: 1
        });
        observationDocument.userUUID = this.authService.getUserUUID();
        observationDocument.document.result = {
            exercise_id: oneRM.exercise_id,
            value: +oneRM['1rm'],
            reps: 1,
        };
        var measure = this.documentService.store.exerciseProgram.document.measures.find(function (m) { return m.name === '1RM'; });
        observationDocument.document.measure = measure;
        observationDocument.markCreated();
        return observationDocument;
    };
    DataService.prototype.generateMaxSetObservation = function (oneRM) {
        var observation = this.factory.build(DocumentModelType.Observation);
        var observationDocument = new ObservationDocument({
            id: observation.id,
            document: observation,
            version: 1
        });
        observationDocument.userUUID = this.authService.getUserUUID();
        observationDocument.document.result = {
            exercise_id: oneRM.exercise_id,
            value: +oneRM['max_set'],
            time_limit: 120000,
        };
        var measure = this.documentService.store.exerciseProgram.document.measures.find(function (m) { return m.name === 'Max Set'; });
        observationDocument.document.measure = measure;
        observationDocument.markCreated();
        return observationDocument;
    };
    DataService.prototype.generateSurveyObservation = function (workoutSessionId, measure, response) {
        var observation = this.factory.build(DocumentModelType.Observation);
        var observationDocument = new ObservationDocument({
            id: observation.id,
            document: observation,
            version: 1
        });
        observationDocument.document.measure = measure;
        observationDocument.document.result = {
            response: response,
            workout_session: workoutSessionId
        };
        observationDocument.markCreated();
        return observationDocument;
    };
    DataService.prototype.pushDocuments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.documentService.pushDocuments().toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DataService.prototype.storeObservationDocument = function (observationDocument) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.documentService.insertObservationDocument(observationDocument).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, observationDocument];
                }
            });
        });
    };
    DataService.prototype.getPftScoreDocuments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userUuid, token, result, reqs;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userUuid = this.authService.getUserUUID();
                        token = 'Bearer ' + this.authService.getToken();
                        return [4 /*yield*/, this.restService.get("ObservationDocument/pft/user/" + userUuid, { 'Authorization': token }, true).toPromise()];
                    case 1:
                        result = _a.sent();
                        if (!result.documents) return [3 /*break*/, 3];
                        reqs = result.documents.map(function (docUuid) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var response;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.restService.get("ObservationDocument/pft/" + docUuid, { 'Authorization': token }, true).toPromise()];
                                    case 1:
                                        response = _a.sent();
                                        return [2 /*return*/, { document: JSON.parse(response.document) }];
                                }
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(reqs)];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [2 /*return*/, []];
                }
            });
        });
    };
    DataService.prototype.getCftScoreDocuments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userUuid, token, result, reqs;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userUuid = this.authService.getUserUUID();
                        token = 'Bearer ' + this.authService.getToken();
                        return [4 /*yield*/, this.restService.get("ObservationDocument/cft/user/" + userUuid, { 'Authorization': token }, true).toPromise()];
                    case 1:
                        result = _a.sent();
                        if (!result.documents) return [3 /*break*/, 3];
                        reqs = result.documents.map(function (docUuid) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var response;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.restService.get("ObservationDocument/cft/" + docUuid, { 'Authorization': token }, true).toPromise()];
                                    case 1:
                                        response = _a.sent();
                                        return [2 /*return*/, { document: JSON.parse(response.document) }];
                                }
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(reqs)];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [2 /*return*/, []];
                }
            });
        });
    };
    DataService.prototype.getPftScoreDocument = function (docUuid) {
        var token = 'Bearer ' + this.authService.getToken();
        var pftScoreDocument = null;
        this.restService.get("ObservationDocument/pft/" + docUuid, { 'Authorization': token }, true).subscribe(function (doc) {
            pftScoreDocument = doc.document;
        });
        return pftScoreDocument;
    };
    DataService.prototype.pftCftChartReset = function () {
        this.pftCftChartResetSubject.next();
    };
    DataService.prototype.loadObservations = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var observations, sortedObservations, exercises, _loop_1, sortedObservations_1, sortedObservations_1_1, observation;
            var e_4, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.documentService.restoreData().toPromise()];
                    case 1:
                        _b.sent();
                        observations = this.documentService.store.observations;
                        sortedObservations = _.orderBy(observations, function (o) { return o.createdAt; }, ['desc']);
                        exercises = [];
                        _loop_1 = function (observation) {
                            var result = observation.document.result;
                            if (result) {
                                var statIndex = exercises.findIndex(function (ex) { return ex.exercise_id === result.exercise_id; });
                                if (statIndex === -1) {
                                    var exercise = {
                                        exercise_id: result.exercise_id,
                                    };
                                    // Bugfix 2020-08-04 (Measure can be undefined)
                                    if (observation.document.measure) {
                                        if (observation.document.measure.name === '1RM') {
                                            exercise['1rm'] = result.value;
                                            exercise['units'] = 'lbs';
                                        }
                                        if (observation.document.measure.name === 'Max Set') {
                                            exercise['max_set'] = result.value;
                                            exercise['units'] = 'lbs';
                                        }
                                    }
                                    exercises.push(exercise);
                                }
                            }
                        };
                        try {
                            for (sortedObservations_1 = tslib_1.__values(sortedObservations), sortedObservations_1_1 = sortedObservations_1.next(); !sortedObservations_1_1.done; sortedObservations_1_1 = sortedObservations_1.next()) {
                                observation = sortedObservations_1_1.value;
                                _loop_1(observation);
                            }
                        }
                        catch (e_4_1) { e_4 = { error: e_4_1 }; }
                        finally {
                            try {
                                if (sortedObservations_1_1 && !sortedObservations_1_1.done && (_a = sortedObservations_1.return)) _a.call(sortedObservations_1);
                            }
                            finally { if (e_4) throw e_4.error; }
                        }
                        return [2 /*return*/, exercises];
                }
            });
        });
    };
    DataService.prototype.updateUserProfile = function (userId, profile) {
        return this.restService.post("User/" + userId, profile, true);
    };
    return DataService;
}());
export { DataService };
function convertTo(typeClass, assumedProperties) {
    return function (data) {
        var record = new typeClass(data);
        return record;
    };
}
