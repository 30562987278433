import { Component, OnInit } from '@angular/core'
import { DocumentService } from 'app/services/document.service'
import * as _ from 'lodash'
import { Store } from '@ngxs/store'
import { AppState } from 'app/state/app/app.state'
import { RMCalcDialogComponent } from 'app/modules/rmcalc-dialog/rmcalc-dialog.component'
import { RMCalcSetDialogComponent } from 'app/modules/rmcalc-set-dialog/rmcalc-set-dialog.component'
import { SetExecutionsRM, Set1RM, SetSelectedRM } from 'app/state'
import { MatDialog } from '@angular/material'
import { FormControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { MaxsetDialogComponent } from 'app/modules/maxset-dialog/maxset-dialog.component'

const noCategoryId = '00000000-0000-0000-0000-000000000000'
const weightedResistanceCategory = 'Weighted Resistance';
const bodyweightMovementsCategory = 'Bodyweight Movements';
function flattenExercises(exercises: any[]) {
  return _.flatten(exercises.sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  }).sort((a, b) => {
    if (a.name === bodyweightMovementsCategory) {
      return -2;
    } else if (a.name === weightedResistanceCategory) {
      return -3;
    } else if (a.id === noCategoryId) {
      return 2;
    } else {
      return 0;
    }
  }).map(cat => {
    const exerciseList = cat.exercises.filter(ex => cat.name === weightedResistanceCategory ||
      cat.name === bodyweightMovementsCategory ||
      ex.parameters.hasOwnProperty('load')
    ).sort((a, b) => a.name < b.name ? -1 : 1).map((ex, index) => {
      return {
        categoryId: cat.id,
        exerciseIndex: index,
        type: cat.name === bodyweightMovementsCategory ? 'bodyweightExercise' : 'exercise',
        ...ex
      }
    });

    if (exerciseList.length > 0) {
      return [
        {
          name: cat.name,
          type: cat.name === bodyweightMovementsCategory ?  'bodyweightCategory' : 'category'
        },
        ...exerciseList
      ]
    } else {
      return [];
    }
  }));
}

@Component({
  selector: 'app-tab-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {
  private subs: Subscription[] = [];
  // Default no category
  exercises = [{
    id: noCategoryId,
    name: 'No Category',
    exercises: []
  }]
  filteredExercises = [];
  search: FormControl;
  constructor(
    private documentService: DocumentService,
    private store: Store,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    const userProfile = this.store.selectSnapshot(AppState.userProfile)

    this.documentService.restoreData().subscribe(() => {
      const observations = this.documentService.store.observations
      const allExercises = this.documentService.store.exerciseProgram.document.exercises
      allExercises.forEach((exercise, ei) => {
        // Does it have a 1rm?
        const userExerciseIndex = userProfile.stats.exercises.findIndex(e => {
          return e.exercise_id === exercise.id
        })

        if (userExerciseIndex !== -1) {
          exercise['1rm'] = userProfile.stats.exercises[userExerciseIndex]
        }

        // Get all the categories
        if (exercise.exercise_categories && exercise.exercise_categories.length > 0) {
          exercise.exercise_categories.forEach((categoryKey, ci) => {

            const category = _.find(this.documentService.store.exerciseProgram.document.exercise_categories, { id: categoryKey })
            const categoryIndex = this.exercises.findIndex(cat => cat.id === categoryKey);
            if (categoryIndex > -1) {
              if (!this.exercises[categoryIndex].exercises.find(ex => ex.id === exercise.id)) {
                this.exercises[categoryIndex].exercises.push(exercise);
              }
            } else {
              if (category !== undefined) {
                this.exercises.push({
                  id: category.id,
                  name: category.name,
                  exercises: [
                    exercise
                  ]
                });
              }
            }
          })
        } else {
          this.exercises[0].exercises.push(exercise)
        }
      })

      this.filteredExercises = flattenExercises(this.exercises);
    })

    this.search = new FormControl(undefined);
    this.subs.push(
      this.search.valueChanges.pipe(debounceTime(500)).subscribe(value => {
        this.refreshExercises(value);
      })
    );
  }

  refreshExercises(value?: string) {
    const exercises = _.cloneDeep(this.exercises);
    if (value) {
      exercises.forEach(cat => {
        cat.exercises = cat.exercises.filter(ex => ex.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
      })
    }

    this.filteredExercises = flattenExercises(exercises.filter(cat => cat.exercises.length > 0));
  }

  openCalc1RM(categoryKey, exercise) {
    const dialogRef = this.dialog.open(RMCalcDialogComponent, {
      maxWidth: '100vw',
      width: '80%',
      data: {
        exercise: exercise,
        justStats: true
      }
    })

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.success) {
        if (res['1rm']) {
          const new1RM = {
            'exercise_id': exercise.id,
            '1rm': res['1rm'],
            'max_set': exercise['1rm'] ? (exercise['1rm']['max_set'] || undefined) : undefined,
            'units': 'lbs'
          }
          if (!res['rmManual']) {
            this.dialog.open(RMCalcSetDialogComponent, {
              maxWidth: '100vw',
              width: '80%',
              data: {
                exerciseName: exercise.name,
                results: res
              }
            })
          }

          const userProfile = this.store.selectSnapshot(AppState.userProfile)
          const statIndex = userProfile.stats.exercises.findIndex(ex => ex.exercise_id === exercise.id);
          let action;
          if (statIndex > -1) {
            action = new Set1RM(new1RM, statIndex);
          } else {
            action = new Set1RM(new1RM);
          }

          this.store.dispatch([
            action
          ]).subscribe(() => {
            const categoryIndex = this.exercises.findIndex(cat => cat.id === categoryKey);
            const exerciseIndex = this.exercises[categoryIndex].exercises.findIndex(ex => ex.id === exercise.id);
            this.exercises[categoryIndex].exercises[exerciseIndex]['1rm'] = new1RM
            this.refreshExercises(this.search.value);
          })
        }
      }
    })
  }

  openMaxSet(categoryKey, exercise) {
    const dialogRef = this.dialog.open(MaxsetDialogComponent, {
      maxWidth: '100vw',
      width: '80%',
      data: {
        exercise: exercise
      }
    })

    dialogRef.afterClosed().subscribe(res => {
      if (res && res['1rm']) {
        const new1RM = {
          'exercise_id': exercise.id,
          '1rm': exercise['1rm'] ? (exercise['1rm']['1rm'] || undefined) : undefined,
          'max_set': res['1rm']['max_set'],
          'units': 'lbs'
        }

        const userProfile = this.store.selectSnapshot(AppState.userProfile)
        const statIndex = userProfile.stats.exercises.findIndex(ex => ex.exercise_id === exercise.id);
        let action;
        if (statIndex > -1) {
          action = new Set1RM(new1RM, statIndex);
        } else {
          action = new Set1RM(new1RM);
        }

        this.store.dispatch([
          action
        ]).subscribe(() => {
          const categoryIndex = this.exercises.findIndex(cat => cat.id === categoryKey);
          const exerciseIndex = this.exercises[categoryIndex].exercises.findIndex(ex => ex.id === exercise.id);
          this.exercises[categoryIndex].exercises[exerciseIndex]['1rm'] = new1RM
          this.refreshExercises(this.search.value);
        })
      }
    })
  }

  trackByFn(itemKey: any) {
    return itemKey
  }

  onExerciseSelected(exercise) {
    this.store.dispatch(new SetSelectedRM(exercise))
  }
}
