import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { AuthService } from 'app/services/auth.service';
import { ActiveUser } from 'app/state/auth/auth.model';
import { AuthState } from 'app/state/auth/auth.state';
import { environment } from 'environments/environment';
import { last } from 'rxjs/operators';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss']
})
export class ProfileDialogComponent implements OnInit {
  firstName: string;
  lastName: string;
  rank: string;
  email: string;
  hasProfilePic: boolean;
  profilePicture: any;
  profilePictureRequest: any;
  imageError: string;

  unapprovedEmail: boolean;
  approvedDomains = '';

  @Select(AuthState.activeUser) activeUser$;
  activeUser: ActiveUser;

  requestAccountPath = environment.requestUrl || '/account/request';

  approvedEmailsRegex = new RegExp('[^\s@]+@(aptima.com)');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProfileDialogComponent>,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) { }

  ngOnInit() {
    this.authService.getApprovedDomains().subscribe(() => {
      this.approvedDomains = this.authService.approvedEmailDomains.join(', ');
      this.approvedEmailsRegex = new RegExp('[^\\s@]+@(' + this.authService.approvedEmailDomains.join('|') + ')');
    });

    this.activeUser$.subscribe((u: ActiveUser) => {
      this.activeUser = u;
      this.firstName = u.firstName;
      this.lastName = u.lastName;
      this.rank = u.rank;
      this.email = u.email;
      this.hasProfilePic = u.hasProfilePic;
      this.authService.getUserImage(this.activeUser.uuid).pipe(last()).subscribe(pp => {
        if (pp && pp.profilePicture) {
          this.profilePicture = pp.profilePicture;
        }
      });
      this.unapprovedEmail = !this.isApprovedEmail(this.email, u);
    })
  }

  isApprovedEmail(email, user?) {
    if (user && user.email_domain_approved) {
      return true;
    }
    let approved = false;
    for (const e of this.approvedDomains) {
      if (email.includes(e)) {
        approved = true;
      }
    }
    return approved;
  }

  applySubstitutions(url) {
    // const myUrl = url.replace(/{{HOSTNAME}}/gi, 'midas.aptima.com');
    const myUrl = url.replace(/{{HOSTNAME}}/gi, location.hostname);
    return myUrl;
  }

  onSave() {
    const result = {
      firstname: this.firstName,
      lastname: this.lastName,
      rank: this.rank ? this.rank : '',
      email: this.activeUser.email === this.email ? undefined : this.email
    };

    if (this.profilePictureRequest) {
      result['profilePicture'] = {
        requestData: this.profilePictureRequest,
        imageDataUrl: this.profilePicture
      };
    }

    this.saveProfileUpdates(result).subscribe(() => {
      this.dialogRef.close();
      if (this.email !== result.email) {
        this.router.navigate(['login']);
      }
    })
  }


  readUrl(event) {
    if (event.target.files && event.target.files[0]) {
      // console.log(event.target.files[0]);
      if (event.target.files[0].type !== 'image/jpg' &&
        event.target.files[0].type !== 'image/png' &&
        event.target.files[0].type !== 'image/jpeg') {
        this.imageError = 'The image must of of file type .jpg, .jpeg, or .png';

      } else {
        this.imageError = '';
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.profilePicture = this.sanitizer.bypassSecurityTrustUrl(e.target.result);
        };

        reader.readAsDataURL(event.target.files[0]);

        this.profilePictureRequest = event.target.files[0];
      }
    }
  }


  /**
   * Update the profile info of the user, including profile picture.
   *
   * @param  {} userData - data to update
   */
  saveProfileUpdates(userData) {
    return this.authService.saveActiveUser(`/api/User/${this.activeUser.uuid}`, userData);
  }
}

