<content>
  <header>
    <div class="exercise-time">
      <div>TIME ELAPSED</div>
      <div class="subtext">{{ timeElapsed }}</div>
    </div>
    <div fxFlex class="exercise-count">
      <div>STEP</div>
      <div class="subtext">{{ currentExerciseCount$ | async }} of {{ this.currentWorkoutSession.total_exercises }}</div>
    </div>
    <div class="exercise-end">
      <button mat-raised-button color="warn" (click)="endWorkout()">FINISH</button>
    </div>
  </header>
  <main>
    <app-subexercise></app-subexercise>
  </main>
</content>
