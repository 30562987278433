export enum SurveyResultColor {
    GOOD = 'GOOD',
    OKAY = 'OKAY',
    LOW = 'LOW',
    VERY_LOW = 'VERY_LOW'
}

export class SurveyResult {
    id: SurveyResultColor;
    label: string;
    color: string;
}

export class SurveySectionResult {
    id: string;
    label: string;
    score: number;
    result: SurveyResult;
}

export class SurveyAdditionalResource {
    id: string;
    label: string;
    text: string[];
    box1Title: string;
    photo1: string;
    box2Links: SurveyLink[];
    photo2: string;
    box3Quote: string;
}

export class SurveyLink {
    text: string;
    href: string;
}
