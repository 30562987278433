import * as _ from 'lodash';
import * as moment from 'moment';
import { DatabaseRecord } from '../classes/database-record.class';
import { PlanExercise } from './plan-excercises.model';

export class PlanBlock extends DatabaseRecord {
  static objectStoreName = 'planBlocks'

  name: string
  isCustom: string
  columns: string
  notes: string
  tier: number
  isComplete: boolean
  distanceCompleted: string
  timeCompleted: string
  loadCompleted: string
  repsCompleted: string

  // Needed to keep track of 'default' blocks
  planDayUUID: string

  constructor (data: {} = {}) {
    super(data)
    this.name = data['name']
    this.isCustom = data['isCustom']
    this.columns = data['columns']
    this.notes = data['notes']
    this.tier = data['tier']
    this.plannedExercises = data['plannedExercises'] || []
    this.isComplete = data['isComplete'] || false
    this.planDayUUID = data['planDayUUID']
  }

  get plannedExercises () {
    return this.getForeignKeys(PlanExercise)
  }

  set plannedExercises (records: any[]) {
    this.setForiegnRecords(PlanExercise, records)
  }

  toJSON () {
    return super.toJSON([
      'name',
      'isCustom',
      'columns',
      'notes',
      'tier',
      'plannedExercises',
      'isComplete',
      'distanceCompleted',
      'timeCompleted',
      'loadCompleted',
      'repsCompleted',
      'planDayUUID',
    ])
  }
}
