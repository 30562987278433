import { WorkoutSession } from 'app/models/workout-session-document.model'

export class WorkoutStateUtils {
  static completionStats(workoutSession: WorkoutSession) {
    const stats = {
      complete: false,
      attendance_count: workoutSession.attendees.length,
      total_tiers: 0,
      completed_tiers: 0,
      total_exercises: 0,
      completed_exercises: 0,
      training_load: 0,
      est_training_load: 0,
      completedTierIndces: []
    }

    for (let i = 0; i < workoutSession.workout.tiers.length; i++) {
      const tier = workoutSession.workout.tiers[i]
      stats.total_tiers++
      if (tier.circuit && tier.circuit.executions) {
        if (tier.circuit.executions.length > 0) {
          if (tier.circuit.executions[0].num_rounds >= tier.circuit.num_rounds) {
            stats.completed_tiers++
            stats.completedTierIndces.push(i)
          }
        } else {
          stats.completed_tiers++
          stats.completedTierIndces.push(i)
        }
        // We don't care about total_exercises here
      } else {
        if (tier.rows.length > 0) {
          let tierExercises = 0
          let tierExercisesComplete = 0
          for (const row of tier.rows) {
            stats.total_exercises++
            tierExercises++
            const totalSets = row.parameters && row.parameters.sets ? row.parameters.sets : 0
            let completedSets = 0
            if (row.executions.length > 0) {
              for (const execution of row.executions) {
                if (execution.complete_time) {
                  completedSets++
                }
              }
            }
            if (completedSets >= totalSets) {
              stats.completed_exercises++
              tierExercisesComplete++
            }
          }
          if (tierExercisesComplete >= tierExercises) {
            stats.completed_tiers++
            stats.completedTierIndces.push(i)
          }
        } else {
          stats.completed_tiers++
          stats.completedTierIndces.push(i)
        }
      }
    }
    if (stats.total_tiers === stats.completed_tiers) {
      stats.complete = true
    }
    if (workoutSession.rpe !== undefined && workoutSession.completion_time_ms) {
      stats.training_load = workoutSession.rpe * workoutSession.completion_time_ms / 1000 / 60
    }
    if (workoutSession.est_rpe !== undefined && workoutSession.est_rpe > -1
      && workoutSession.est_completion_time_ms && workoutSession.est_completion_time_ms > -1) {
      stats.est_training_load = workoutSession.est_rpe * workoutSession.est_completion_time_ms / 1000 / 60
    }
    return stats
  }
}
