import * as _ from 'lodash'
import { Injectable } from '@angular/core'
import { DatabaseRecord, DatabaseRecordType } from '../classes/database-record.class'
import { Observable } from 'rxjs/internal/Observable';
import { Observer } from 'rxjs/internal/types';

@Injectable()
export class DatabaseService {
  static version = 25
  static dbName = 'fitforce'

  protected objectStores = {
    'users': { keyPath : 'key' },
    'plans': { keyPath: 'key' },
    'planDays': {
      keyPath: ['planUUID', 'key'],
      indices: [
        { key: 'date', unique: false },
        { key: 'planUUID', unique: false },
      ],
    },
    'planBlocks': {
      keyPath: ['planDayUUID', 'key']
    },
    'plannedExercises': { keyPath: 'key' },
    'groups': { keyPath: 'key' },
    'workoutSessions': { keyPath: 'key' },
    'workoutPreviews': { keyPath: 'key' },
    'workoutSessionDocuments': { keyPath: 'key' },
    'offlineErrors': { keyPath: 'key' },
    'surveyTemplateDocument': { keyPath: 'key'},
    'surveyTemplates': { keyPath: 'key'},
    'surveyDocument': {keyPath: 'key'},
    'surveyChoices': { keyPath: 'key' },
    'surveyQuestions': { keyPath: 'key' },
  }

  private seed = {
    // 'users': seedUsers,
    // 'planDays': seedDays.concat(seedDays2),
    // 'groups': seedGroups,
    // 'plans': seedPlans,
    // 'workoutSessions': seedWorkoutSessions,
    // 'workoutPreviews': {},
  }

  db: any
  planDayMigrations: Array<any> = []

  public connect (): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.db) {
        // if we're already connected, just resolve
        return resolve()
      }

      const request = window.indexedDB.open(DatabaseService.dbName, DatabaseService.version)

      request.onsuccess = (event) => {
        // console.log('db.connect complete', event)
        this.db = (<any> event.target).result
        this.db.onerror = this.onDatabaseError.bind(this)

        this.seedDatabase().then(resolve, reject)
        // resolve()
      }

      request.onerror = (event) => {
        // console.log('db.connect fail', event)
        reject(event)
      }

      request.onupgradeneeded = (event) => {
        this.onUpgradeNeeded(event)
      }
    })
  }

  public getVersion () {
    return this.db.version
  }

  public get(recordType: DatabaseRecordType = DatabaseRecord, where: string | object): Promise<DatabaseRecord> {
    if (typeof where === 'string' || Array.isArray(where)) {
      return this.getByKey(recordType, where)
    } else {
      return this.queryOne(recordType, where)
    }
  }

  public getByKey(recordType: DatabaseRecordType | string, key: any): Promise<DatabaseRecord> {
    let objectStoreName: string
    if (typeof recordType === 'string') {
      objectStoreName = recordType
    } else {
      objectStoreName = recordType.objectStoreName
    }
    return new Promise((resolve, reject) => {
      // console.log('db.get', objectStoreName, 'by key', key)
      const transaction = this.transaction(objectStoreName, resolve, reject)
      const objectStore = transaction.objectStore(objectStoreName)
      const request = objectStore.get(key)

      request.onerror = (event) => {
        // console.log('db.get error', event)
        reject(event)
      }

      request.onsuccess = (event) => {
        // console.log('db.get success', event.target.result)
        if (typeof recordType !== 'string') {
          const record = new recordType(event.target.result)

          record.loadAssociations(this).then(() => {
            resolve(record)
          }).catch(reject)
        } else {
          resolve(event.target.result)
        }
      }
    })
  }

  private queryOne(recordType: DatabaseRecordType, where: object): Promise<DatabaseRecord> {
    // console.log('db.queryOne', recordType.objectStoreName, 'by', where)
    return this.query(recordType, where).then((records: DatabaseRecord[]) => {
      const record = _.first(records)
      // console.log('db.queryOne', recordType.objectStoreName, 'found', record)
      return record
    })
  }

  public query(recordType: DatabaseRecordType, where: object): Promise<DatabaseRecord[]> {
    // console.log('db.query', recordType.objectStoreName, 'by', where)
    return this.getAll(recordType).then((records: DatabaseRecord[]) => {
      const whereFilter = {}
      _.each(where, (value: any, key: string) => {
        if (value instanceof DatabaseRecord) {
          whereFilter[key] = value.key;
        } else {
          whereFilter[key] = value;
        }
      });
      records = _.filter(records, whereFilter) as DatabaseRecord[];
      // console.log('db.query', recordType.objectStoreName, 'found', records, 'using filter', whereFilter)
      return records
    })
  }

  public getRecordsAsync (recordType: DatabaseRecordType, indexName: string, where?: object) {
    const objectStoreName = recordType.objectStoreName
    // console.log('db.getAll', objectStoreName)
    const transaction = this.db.transaction([objectStoreName], 'readonly')
    const objectStore = transaction.objectStore(objectStoreName)
    return new Observable((observer: Observer<any>) => {
      if (!objectStore) {
        observer.error(new Error('Object store does not exist: ' + objectStoreName))
        return
      }
      // Default index name to key
      if (!indexName) {
        indexName = 'key';
      }
      const index = objectStore.index(indexName);
      const request: IDBRequest = index.openCursor();

      // Success
      request.onsuccess = (event: Event) => {
        // Steps through all the values in the object store
        const cursor: IDBCursorWithValue = (<IDBRequest>event.target).result;
        if (cursor) {
          if (!_.isUndefined(where)) {
            _.each(where, (whereParam: any, key: string) => {
              let filterString = whereParam
              if (whereParam instanceof DatabaseRecord) {
                filterString = whereParam.key;
              }
              // If the index matches with the filter key
              if (cursor.value[key] === filterString) {
                observer.next(cursor.value);
              }
            });
          } else {
            observer.next(cursor.value);
          }
          cursor.continue();
        } else {
          observer.complete();
        }
      }
      // Error
      request.onerror = (event: Event) => {
        // console.log('IndexedDB service: ' + (<IDBRequest>event.target).error.name);
        observer.error((<IDBRequest>event.target).error.name);
      }
    })
  }

  public getRecordsByKeys ( objectStoreName, keys: string[]) {
    const getAll = _.map(keys, (key) => {
      return this.get(objectStoreName, key)
    })
    return Promise.all(getAll)
  }

  public getRecords (recordType: DatabaseRecordType, keys: string[]) {
    const objectStoreName = recordType.objectStoreName

    const getAll = _.map(keys, (key) => {
      return this.get(recordType, key)
    })
    // console.log('db.query', recordType.objectStoreName, 'by', keys)
    return Promise.all(getAll)
  }

  public getAll(recordType: DatabaseRecordType | string): Promise<DatabaseRecord[]> {
    return new Promise((resolve, reject) => {
      let objectStoreName: string
      if (typeof recordType === 'string') {
        objectStoreName = recordType
      } else {
        objectStoreName = recordType.objectStoreName
      }
      // console.log('db.getAll', objectStoreName)
      const transaction = this.transaction(objectStoreName, resolve, reject)
      const objectStore = transaction.objectStore(objectStoreName)

      if (!objectStore) {
        reject(new Error('Object store does not exist: ' + objectStoreName))
        return
      }

      let request
      try {
        request = objectStore.getAll()
      } catch (ex) {
        reject(ex)
        return
      }

      request.onerror = (event) => {
        // console.log('db.getAll', objectStoreName, 'error', event)
        reject(event)
      }

      request.onsuccess = (event) => {
        // console.log('db.getAll', objectStoreName, 'success', event.target.result)



        if (typeof recordType !== 'string') {
          const records = _.map(event.target.result, (record) => {
            return new recordType(record)
          })
          resolve(records)
        } else {
          resolve(event.target.result)
        }
      }
    })
  }

  /**
   * Insert records into the datastore.
   *
   * @param {string} objectStoreName Object store name (table name).
   * @param {DatabaseRecord[]} records Database records to insert.
   * @param {boolean} [forceUpdate=false] Set to true to force an update if the record already exists.
   */
  public insert(records: DatabaseRecord | DatabaseRecord[], forceUpdate?: boolean, filter?: any) {
    return new Promise((resolve, reject) => {
      // console.log('INSERT:', records)
      records = _.toArray(records)

      if (records.length === 0) {
        resolve()
        return
      }

      // We do the reverse storage like our API
      if (filter && filter['options'] && filter['options']['append']) {
        // console.log(filter.options)
        _.forEach(records, (record, i) => {
          _.extend(record, filter.options.append)
        })
      }
      // console.log('INSERT:', records)
      const objectStoreName = (_.first(records).constructor as DatabaseRecordType).objectStoreName

      // console.log('db.insert', objectStoreName)
      const transaction = this.transaction(objectStoreName, resolve, reject)
      const objectStore = transaction.objectStore(objectStoreName)
      const promises = _.map(records, (record: DatabaseRecord) => {
        const obj = record.toJSON()
        const request = forceUpdate ? objectStore.put(obj) : objectStore.add(obj)

        request.onerror = function (event) {
          // console.log('db.insert', objectStoreName, 'error', event)
          reject(event)
        }

        request.onsuccess = function (event) {
          const key = event.target.result
          // console.log('db.insert', objectStoreName, 'success', key, obj)
          resolve(event)
        }
      })

      Promise.all(promises).then(resolve, reject)
    })
  }

  async put (objectStoreName: string,  record: any, key: string) {
    return new Promise((resolve, reject) => {
      const transaction = this.transaction(objectStoreName, resolve, reject)
      const objectStore = transaction.objectStore(objectStoreName)
      objectStore.put(record)
    })
  }

  public update (record: DatabaseRecord) {
    return new Promise((resolve, reject) => {
      const objectStoreName = (record.constructor as DatabaseRecordType).objectStoreName
      // console.log('db.update', objectStoreName)
      const transaction = this.transaction(objectStoreName, resolve, reject)
      const objectStore = transaction.objectStore(objectStoreName)
      const request = objectStore.put(record.toJSON())

       request.onerror = (event) => {
        //  console.log('db.update error', event)
         reject(event)
       }

       request.onsuccess = (event) => {
        //  console.log('db.update success', event)
         resolve()
       }
    })
  }

  public delete (objectStoreName: string, key: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // console.log('db.delete', objectStoreName)
      const transaction = this.transaction(objectStoreName, resolve, reject)
      const objectStore = transaction.objectStore(objectStoreName)
      const request = objectStore.delete(key)

      request.onerror = (event) => {
        // console.log('db.delete error', event)
        reject(event)
      }

      request.onsuccess = (event) => {
        // console.log('db.delete success', event)
        resolve()
      }
    })
  }

  public clear (recordType: DatabaseRecordType): Promise<void> {
    return new Promise((resolve, reject) => {
      const objectStoreName = recordType.objectStoreName
      // console.log('db.clear', objectStoreName)
      const transaction = this.transaction(objectStoreName, resolve, reject)
      const objectStore = transaction.objectStore(objectStoreName)
      const request = objectStore.clear()

      request.onerror = (event) => {
        // console.log('db.clear error', event)
        reject(event)
      }

      request.onsuccess = (event) => {
        // console.log('db.clear success', event)
        resolve()
      }
    })
  }

  private transaction (objectStoreName, resolve, reject) {
    let transaction

    try {
      transaction = this.db.transaction([objectStoreName], 'readwrite')
    } catch (ex) {
      if (ex.name === 'NotFoundError') {
        // console.log('Need to bump DatabaseService.version')
        reject(ex)
      } else {
        // console.log('db.transaction error', ex.name)
        reject(ex)
      }
    }

    transaction.oncomplete = (event) => {
      // console.log('db.transaction complete', event)
      // resolve()
    }

    transaction.onerror = (event) => {
      // console.log('db.transaction fail', event.target.error)
      reject(event)
    }

    return transaction
  }

  private onUpgradeNeeded (event) {
    // console.log('db.onUpgradeNeeded')
    this.db = event.target.result
    const transaction = event.target.transaction;

    _.each(_.keys(this.objectStores), (objectStoreName) => {
      const objectStoreParams = this.objectStores[objectStoreName]
      // console.log('creating datastore:', objectStoreName)
      const objectStore = this.createObjectStore(objectStoreName)

      if (objectStore) {
        // Add additional indices
        if (!_.isUndefined(objectStoreParams.indices)) {
          _.each(objectStoreParams.indices, (index) => {
            // console.log('creating index on:', objectStoreName, index.key);
            objectStore.createIndex(index.key, index.key, { unique: index.unique });
          })
        }
      } else {
        if (event.oldVersion > 1 &&
          event.oldVersion < 24 &&
          objectStoreName === 'planDays'
        ) {
          const migraterequest = transaction.objectStore(objectStoreName, 'readonly').getAll();
          migraterequest.onsuccess = (e) => {
            this.planDayMigrations = e.target.result
            if (this.planDayMigrations.length > 0) {
              this.connect().then(() => {
                const store = transaction.objectStore('planDays')
                for (let i = 0; i < this.planDayMigrations.length; ++i) {
                  store.add(this.planDayMigrations[i])
                }
                transaction.oncomplete = () => {
                  // console.log('Migration of planDays complete')
                }
              })
            }
          }
          this.db.deleteObjectStore(objectStoreName);
          this.createObjectStore(objectStoreName)
        }
      }
    })
  }

  private seedDatabase () {
    const stores = _.keys(this.objectStores)

    const promises = _.filter(_.map(stores, (objectStoreName) => {
      const records = this.seed[objectStoreName]
      if (records && records.length > 0) {
        return this.insert(records, true)
      }
    }))

    return Promise.all(promises)
  }

  private createObjectStore (objectStoreName) {
    if (!_.includes(this.db.objectStoreNames, objectStoreName)) {
      return this.db.createObjectStore(objectStoreName, this.objectStores[objectStoreName])
    } else {
      return null
    }
  }

  private onDatabaseError (event) {
    // console.log('Database error:', event.target.error)
  }
}
