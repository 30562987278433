import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { DocumentService } from 'app/services/document.service';
import { WorkoutState } from 'app/state/workout/workout.state';
import * as _ from 'lodash';
import { SaveComments, StartCircuit, StopCircuit, SetCircuitRounds, ChangeExercise, EditSet } from 'app/state';
import { VideoDialogComponent } from 'app/modules/video-dialog/video-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component';
import { AppState } from 'app/state/app/app.state';
import { ExerciseProgramDocumentUtils, getDefaultParams, ResourceType } from 'fitforce-document-sync';
import { EditCircuitDialogComponent } from '../edit-circuit-dialog/edit-circuit-dialog.component';
import { SetsRepsLoadPipe } from 'app/pipes/sets-reps-load.pipe';
import { EditSetDialogComponent } from '../edit-set-dialog/edit-set-dialog.component';
var SubexerciseCircuitComponent = /** @class */ (function () {
    function SubexerciseCircuitComponent(store, dialog, documentService, snackBar, setRepsLoadPipe) {
        this.store = store;
        this.dialog = dialog;
        this.documentService = documentService;
        this.snackBar = snackBar;
        this.setRepsLoadPipe = setRepsLoadPipe;
        this.changeTier = new EventEmitter();
    }
    SubexerciseCircuitComponent.prototype.ngOnInit = function () {
        this.loadExercise();
    };
    SubexerciseCircuitComponent.prototype.loadExercise = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var numRounds;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Reset circuits
                        this.numRoundsComplete = 0;
                        this.numRoundsMax = 5;
                        return [4 /*yield*/, this.documentService.restoreData().toPromise()];
                    case 1:
                        _a.sent();
                        this.currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession);
                        this.currentWorkoutIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex);
                        this.tier = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex];
                        if (!this.tier.circuit) {
                            return [2 /*return*/];
                        }
                        this.onStartCircuit();
                        if (this.tier.circuit.num_rounds && this.tier.circuit.num_rounds > 0) {
                            this.numRoundsMax = this.tier.circuit.num_rounds;
                        }
                        if (this.tier.circuit.executions.length > 0) {
                            numRounds = this.tier.circuit.executions[0].num_rounds;
                            if (numRounds >= 0) {
                                if (numRounds && numRounds > -1) {
                                    this.numRoundsComplete = numRounds;
                                }
                            }
                        }
                        this.updateArrows();
                        return [2 /*return*/];
                }
            });
        });
    };
    SubexerciseCircuitComponent.prototype.tierRunStats = function () {
        var stats = [];
        if (this.tier.circuit.num_rounds !== -1) {
            stats.push(this.tier.circuit.num_rounds + " Rounds");
        }
        if (this.tier.circuit.rest_time_ms !== -1) {
            var restTime = this.tier.circuit.rest_time_ms / 1000;
            stats.push(restTime + "s Rest");
        }
        return stats.join(' | ');
    };
    SubexerciseCircuitComponent.prototype.onStartCircuit = function () {
        this.store.dispatch(new StartCircuit());
    };
    SubexerciseCircuitComponent.prototype.onCompleteCircuit = function () {
        var _this = this;
        this.store.dispatch(new StopCircuit());
        this.dialog.open(EditCircuitDialogComponent).afterClosed().subscribe(function (result) {
            if (result && result.success) {
                _this.isCircuitComplete = true;
                _this.onNextTier();
            }
        });
    };
    SubexerciseCircuitComponent.prototype.openVideo = function (video) {
        this.dialog.open(VideoDialogComponent, {
            panelClass: 'modal-workout-video',
            maxWidth: '100vw',
            width: '100%',
            data: {
                title: video.title,
                videoId: video.asset_id.replace('video:', ''),
            },
        });
    };
    SubexerciseCircuitComponent.prototype.getExerciseVideo = function (tierRow) {
        var exerciseId = tierRow.exercise.exercise_program_id;
        var exerciseDetails = _.find(this.documentService.store.exerciseProgram.document.exercises, { id: exerciseId });
        if (exerciseDetails && exerciseDetails.resources && exerciseDetails.resources.length > 0) {
            return _.find(exerciseDetails.resources, { type: ResourceType.Video });
        }
        return;
    };
    SubexerciseCircuitComponent.prototype.openCommentsDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(NotesDialogComponent, {
            maxWidth: '100vw',
            width: '80%',
            data: {
                isTierNote: false,
                isCircuit: true,
            }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res && res.success) {
                _this.store.dispatch(new SaveComments(res.notes, _this.currentWorkoutIndex, -1)).subscribe(function () {
                    _this.snackBar.open('Saved notes.', undefined, {
                        duration: 2500
                    });
                });
            }
        });
    };
    SubexerciseCircuitComponent.prototype.increaseNumberRoundsComplete = function () {
        if (!this.numRoundsComplete) {
            this.numRoundsComplete = 0;
        }
        this.numRoundsComplete += 1;
        if (this.numRoundsComplete > this.numRoundsMax) {
            this.numRoundsComplete = 0;
        }
        this.store.dispatch(new SetCircuitRounds(this.numRoundsComplete, this.currentWorkoutIndex));
    };
    SubexerciseCircuitComponent.prototype.updateArrows = function () {
        this.hasPrevTier = true;
        this.hasNextTier = true;
        if (this.currentWorkoutIndex === 0) {
            this.hasPrevTier = false;
        }
        if (this.currentWorkoutIndex >= this.currentWorkoutSession.workout.tiers.length - 1) {
            this.hasNextTier = false;
        }
    };
    SubexerciseCircuitComponent.prototype.getSetsRepsLoad = function (tierRow, rowIndex) {
        var exercise = this.documentService.store.exerciseProgram.document.exercises.find(function (ex) { return ex.id === tierRow.exercise.exercise_program_id; });
        var setParams = getDefaultParams();
        if (exercise) {
            setParams = ExerciseProgramDocumentUtils.getPossibleParamsForExercise(tierRow.exercise.exercise_program_id, this.documentService.store.exerciseProgram.document);
        }
        // Check if Load exists
        var index = -1;
        var userProfile = this.store.selectSnapshot(AppState.userProfile);
        var oneRM;
        var oneRMIndex;
        if (tierRow) {
            index = userProfile.stats.exercises.findIndex(function (e) {
                return e.exercise_id === tierRow.exercise.exercise_program_id;
            });
        }
        if (index !== -1) {
            oneRM = userProfile.stats.exercises[index];
            oneRMIndex = index;
        }
        var parameterSelections = tierRow.parameters;
        return this.setRepsLoadPipe.transform({
            setParams: setParams,
            currentWorkoutSession: this.currentWorkoutSession,
            currentWorkoutIndex: this.currentWorkoutIndex,
            currentRowIndex: rowIndex,
            oneRM: oneRM,
            oneRMIndex: oneRMIndex,
            loadPercentage: parameterSelections['load'],
            hasSet: false,
            joinCharacter: ' | ',
            execution: tierRow.executions[0]
        });
    };
    SubexerciseCircuitComponent.prototype.openEditSet = function (rowIndex) {
        var _this = this;
        var setIndex = 0;
        var tierIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex);
        var dialogRef = this.dialog.open(EditSetDialogComponent, {
            id: setIndex.toString(),
            maxWidth: '100vw',
            width: '80%',
            data: {
                tierIndex: tierIndex,
                rowIndex: rowIndex,
                setIndex: setIndex
            }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res && res.success) {
                _this.store.dispatch(new EditSet(tierIndex, rowIndex, setIndex, res.setParamValues, undefined, true)).subscribe(function () {
                    _this.loadExercise();
                });
            }
        });
    };
    SubexerciseCircuitComponent.prototype.onNextTier = function () {
        var _this = this;
        this.store.dispatch(new ChangeExercise(false)).subscribe(function () {
            _this.loadExercise();
            _this.changeTier.emit(false);
        });
    };
    SubexerciseCircuitComponent.prototype.onPrevTier = function () {
        var _this = this;
        this.store.dispatch(new ChangeExercise(true)).subscribe(function () {
            _this.loadExercise();
            _this.changeTier.emit(true);
        });
    };
    return SubexerciseCircuitComponent;
}());
export { SubexerciseCircuitComponent };
