
<ng-container *ngIf="exercise">
  <div class="modal-title" fxLayout="row">
    <div>{{ exercise.exercise.name }} 1 Rep Max</div>
    <div fxFlex></div>
    <div>Use</div>
  </div>
  <mat-dialog-content>
    <div class="rm-known" fxLayout="row">
      <p><b>I know my 1RM</b></p>
      <div fxFlex></div>
      <p><mat-checkbox [(ngModel)]="isRMKnown" (change)="onCheckboxChange($event, true)"></mat-checkbox></p>
    </div>
    <mat-form-field class="full-width">
      <input type="number" matInput placeholder="Weight (lbs)"
        [(ngModel)]="isRMKnownWeight"
        (ngModelChange)="checkKnownRMBox()"
        maxlength="5"
        inputmode="numeric"
        pattern="[0-9]*"
        digitOnly>
    </mat-form-field>
    <mat-divider></mat-divider>
    <div class="rm-unknown" fxLayout="row">
      <p><b>I don't know my 1RM</b></p>
      <div fxFlex></div>
      <p><mat-checkbox [(ngModel)]="isRMUnknown" (change)="onCheckboxChange($event, false)"></mat-checkbox></p>
    </div>
    <p>Enter in your most recent weight and reps for {{ exercise.exercise.name }}</p>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <mat-form-field fxFlex="45%">
        <input type="number" matInput placeholder="Weight (lbs)"
          [(ngModel)]="isRMUnknownWeight"
          (ngModelChange)="checkUnknownRMBox()"
          maxlength="5"
          inputmode="numeric"
          pattern="[0-9]*"
          digitOnly>
      </mat-form-field>
      <mat-form-field fxFlex="45%">
        <input type="number" matInput placeholder="Reps"
          [(ngModel)]="isRMUnknownReps"
          (ngModelChange)="checkUnknownRMBox()"
          maxlength="5"
          inputmode="numeric"
          pattern="[0-9]*"
          digitOnly>
      </mat-form-field>
    </div>
  </mat-dialog-content>
</ng-container>
<mat-dialog-actions>
  <button mat-button (click)="cancelSelected()" fxFlex>Close</button>
  <button class="yes-button" mat-button (click)="submitSelected()" cdkFocusInitial fxFlex>Save</button>
</mat-dialog-actions>
