import * as _ from 'lodash'
import { Observable, fromEvent, merge, throwError, of } from 'rxjs'
import { share, map, catchError } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'

const SERVER_DATE_FORMAT = 'MM/DD/YYYY';

@Injectable()
export class RestService {
  constructor (private http: HttpClient) { }

  isOnline () {
    const online = !!window.navigator.onLine
    // console.log('Currently marked as ' + (online ? 'online' : 'offline'))
    return online
  }

  isOnlineSubscription() {
    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      Observable.create(sub => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
    }

  /**
   * Generic data retrieval method to get data from the server
   */
  getRecords (route, filters?) {
    let ob

    if (filters && filters['options'] && filters['options']['params']) {
      ob = this.postRecord(route, filters.options.params)
      .pipe(map((res: any) => {
        return res[route] || res
      }))
      .pipe(share())
    } else {
      ob = this.get('/' + route)
        .pipe(map((res: any) => {
          return res[route] || res
        }))
        .pipe(share())
    }

    ob.subscribe(
      () => {},
      this.handleError.bind(this)
    )

    return ob
  }

  postRecord (route, data) {
    return this.post('/' + route, data)
  }

  submitFeedbackExternal(feedback: string, firstName: string, lastName: string, email: string, rank?: string): Observable<void> {
    if (!feedback || !firstName || !lastName || !email) {
      return of(null);
    }

    let name = rank ? rank + ' ' : '';
    name += firstName + ' ' + lastName;

    return this.http.post(`${environment.externalSupportUrl}requests.json`, {
      request: {
        requester: {
          name,
          email,
        },
        subject: 'FitForce Feedback - Mobile',
        comment: {
          body: feedback,
        }
      }
    }).pipe(
      map(() => null),
      catchError(e => {
        return this.handleError(e);
      }),
    );
  }

  getConfiguration() {
    return this.get('Configuration/', undefined, true);
  }

  public get (route: string, params?: any, root?: boolean): Observable<any> {
    return this.http.get(this.buildRoute(route, root), { params })
  }

  public post (route: string, data: any, root?: boolean): Observable<any>  {
    return this.http.post(this.buildRoute(route, root), data)
  }

  public put (route: string, data: any, root?: boolean): Observable<any>  {
    return this.http.put(this.buildRoute(route, root), data)
  }

  public delete (route: string, root?: boolean): Observable<any> {
    return this.http.delete(this.buildRoute(route, root))
  }

  private buildRoute (route: string, root = false) {
    function applySubstitutions(url) {
      return url.replace(/{{HOSTNAME}}/gi, location.hostname)
    }
    if (root) {
      return applySubstitutions(environment.api_url) + 'api/' + route
    }
    return applySubstitutions(environment.api_url) + 'api/Mobile/' + route
  }

  private handleError (e) {
    return throwError(e.message || 'Server error')
  }
}
