<div fxLayout="column" fxLayoutGap="10px" class="cards">
  <ng-container *ngIf="sessions && sessions.length > 0; else noSessions">
    <cdk-virtual-scroll-viewport itemSize="10" class="history-list">
      <div *cdkVirtualFor="let session of sessions" fxLayout="row" fxLayoutGap="10px">
        <mat-card
          class="card-spacer"
          (click)="onClickCard(session)"
          fxLayout="row" fxLayoutAlign="center center" fxFlex>
          <div fxFlex="1 1" fxLayout="column">
            <div class="workout-name">{{ session.workout.name }}</div>
            <div *ngIf="session.phase" class="phase-name" fxLayoutAlign="left center">
              <mat-icon class="phase-dot" [ngClass]="session.phase.color || 'white'">fiber_manual_record</mat-icon>
              <div>{{ session.phase.name }}</div>
            </div>
            <div class="plan-name">{{ session.plan.name }}</div>
            <div class="workout-date">{{ session.end_time_pretty }}</div>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
              <div class="card-footer-label">Duration</div>
              <div class="card-footer-value">{{ session.completion_time_ms | msToTime }}</div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
              <div class="card-footer-label">TL</div>
              <div class="card-footer-value">
                {{ session.total_load }}</div>
            </div>
          </div>
        </mat-card>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-container>
  <ng-template #noSessions>
    <div class="no-plan-days">
      <div fxLayoutAlign="center center"><h4>No sessions in last 3 months</h4></div>
    </div>
  </ng-template>
</div>
