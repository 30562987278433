import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-informed-consent-dialog',
  templateUrl: './informed-consent-dialog.component.html',
  styleUrls: ['./informed-consent-dialog.component.scss'],
})
export class InformedConsentDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<InformedConsentDialogComponent>) {}

  ngOnInit() { }

  confirm() {
    this.dialogRef.close(true);
  }

  reject() {
    this.dialogRef.close(false);
  }
}
