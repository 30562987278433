import * as _ from 'lodash'

import { ClearActiveGroup, InitMain, JoinGroup, LeaveGroup } from 'app/state'
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { PAGETRANSITIONS, PageTransitionService } from 'app/services/page-transition.service'

import { AuthService } from 'app/services/auth.service'
import { DataService } from 'app/services/data.service'
import { Group } from 'app/models'
import { LeaveDialogComponent } from './leave-dialog/leave-dialog.component'
import { MatDialog } from '@angular/material'
import { NgxSpinnerService } from 'ngx-spinner'
import { RestService } from 'app/services/rest.service'
import { Router } from '@angular/router'
import { Store } from '@ngxs/store'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-group-management',
  templateUrl: './group-management.component.html',
  styleUrls: ['./group-management.component.scss']
})

export class GroupManagementComponent implements OnInit, OnDestroy {
  @ViewChild('panel', { read: ElementRef, static: false }) public panel: ElementRef<any>;

  isOnline = false
  onlineSub: Subscription
  userUUID: string
  noAvailableGroups = false
  availableGroups: Group[] = []
  joinedGroups: Group[] = []
  lastPositionY: number

  constructor(
    private router: Router,
    private store: Store,
    private dataService: DataService,
    private authService: AuthService,
    private restService: RestService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private pageTransitionService: PageTransitionService,
  ) { }

  ngOnInit() {
    this.userUUID = this.authService.getUserUUID()
    this.onlineSub = this.restService.isOnlineSubscription().subscribe(isOnline => this.isOnline = isOnline)
    this.fetchPublicGroups()
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const position = $event.target['scrollingElement'].scrollTop
    if (position !== 0) {
      this.lastPositionY = position
    }
  }

  resetGroupList() {
    this.noAvailableGroups = false
    this.availableGroups = []
    this.joinedGroups = []
  }

  scrollToPosition() {
    if (!this.panel || this.lastPositionY < 20) {
      return
    }
    const scrollToTop = window.setInterval(() => {
      if (window.pageYOffset === 0) {
        window.scrollTo(0, this.lastPositionY - 20)
      } else {
          window.clearInterval(scrollToTop);
      }
  }, 16);
  }

  fetchPublicGroups() {
    this.resetGroupList()
    this.dataService.getGroups().subscribe(groups => {
      groups = _.sortBy(groups, 'name')
      _.each(groups, group => {
        if (this.isJoined(group)) {
          this.joinedGroups.push(group)
        } else {
          if (!group.private) {
            this.availableGroups.push(group)
          }
        }
      })
      // If there are no available groups, give an error
      if (!this.joinedGroups.length && !this.availableGroups.length) {
        this.noAvailableGroups = true
      } else {
        // Load from last position we have
        if (this.lastPositionY) {
          this.scrollToPosition()
        }
      }
    })
  }

  isJoined(group: Group): boolean {
    return _.includes(group.users, this.userUUID)
  }

  async requestJoin(group) {
    await this.spinner.show();
    this.dataService.groupRequestInvite(group.key, this.authService.getUsername()).subscribe(res => {
      let alreadyInvitedIndex
      group.users = _.map(res.members, (v, i) => {
        if (v.uuid === this.authService.getUserUUID()) {
          alreadyInvitedIndex = i
        }

        return v.uuid
      })
      if (alreadyInvitedIndex !== -1) {
        group.users.push(res.members[alreadyInvitedIndex])
      }

      this.dataService.update(group).then(() => {
        // Resync data for the plans
        this.store.dispatch(new JoinGroup(group)).subscribe(() => {
          this.fetchPublicGroups()
          this.spinner.hide();
        })
      })
    })
  }

  async requestLeave(group: Group) {
    await this.spinner.show();
    this.dataService.groupRequestLeave(group.key, this.authService.getUserUUID()).subscribe(res => {
      group.users = _.map(res.members, (v, i) => {
        return v.uuid
      })
      this.deleteFromGroup(group, this.authService.getUserUUID())
      this.store.dispatch(new LeaveGroup(group))
    })
  }

  deleteFromGroup(group: Group, userId: string) {
    _.remove(group.users, user => {
      return user === userId
    })

    this.dataService.update(group).then(() => {
      this.fetchPublicGroups()
      this.spinner.hide()
    })
  }

  openLeaveDialog(group: Group) {
    const dialogRef = this.dialog.open(LeaveDialogComponent, {
      panelClass: 'modal-leaveConfirm',
    })

    dialogRef.afterClosed().subscribe(isLeaving => {
      if (isLeaving) {
        this.requestLeave(group)
      }
    })
  }

  ngOnDestroy(): void {
    if (this.onlineSub) {
      this.onlineSub.unsubscribe()
    }
  }
}
