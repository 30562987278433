import {
  Plan,
  WorkoutSession,
} from 'fitforce-document-sync';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AtpDataService {
  planId: string;

  constructor(
    private http: HttpClient,
  ) {}

  getPlanId(): Observable<string> {
    return this.http.get<{plan_id: string}>(`${environment.atp_gateway_url}/plan`).pipe(
      map((response) => {
        this.planId = response.plan_id;
        localStorage.setItem('atpPlanId', this.planId);

        return response.plan_id;
      })
    );
  }

  postPlan(plan: Plan): Observable<void> {
    return this.http
      .post(`${environment.atp_gateway_url}/plan`, {
        plan: plan,
      })
      .pipe(map((response) => null));
  }

  postWorkoutSessions(
    plan: Plan,
    wkts: WorkoutSession[],
    userId: string,
  ): Observable<Plan> {
    const lastWorkout = plan.workouts.find(wkt => wkt.id === wkts[wkts.length - 1].workout.id);

    return this.http
      .post(`${environment.atp_gateway_url}/workout`, {
        workouts: wkts,
        user_id: userId,
        plan_id: this.planId,
        // week & day number of assigned date of most recent (last) workout session
        week: lastWorkout.schedule.week[0],
        day: lastWorkout.schedule.day[0],
      })
      .pipe(map((response) => response['plan'] as Plan));
  }
}
