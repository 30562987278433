<div fxFlex fxLayout="column">
  <h1 fxLayoutAlign="center center" mat-dialog-title>Confirmation</h1>
  <div mat-dialog-content>
    <p>
      There is an inherent risk in any exercise that, while providing some health benefits,
      it can also cause unknown health issues. Application or reliance on the techniques,
      advice, ideas, and suggestions of any person associated with FFD or this Site are at
      your sole discretion and risk.
    </p>
    <p>
      Never disregard professional medical advice or delay
      in seeking it because of something you have read on the Site. If you think you may
      have a medical emergency, call your doctor or 911 immediately. FFD does not recommend
      or endorse any specific tests, physicians, products, procedures, opinions, or other
      information that may be mentioned on the Site.  Reliance on any information provided
      by FFD, FFD employees or staff, others appearing on the Site at the invitation of
      FFD, or other visitors to the Site is solely at your own risk.
    </p>
    <p>
      The content of this Site may contain general information relating to certain medical conditions and their
      treatment. Such information is provided for informational purposes only and is not
      intended to replace or substitute for advice provided by a physician or other medical.
      You should not use the information provided on this Site for diagnosing a health
      problem or disease. Prior to participating in any program, workout, training, or
      other activity discussed on this Site, you should seek the advice of your physician
      or other qualified health-care professional. You understand that the exercises and
      programs discussed on this Site can be strenuous and should be scaled or done in moderation.
    </p>
  </div>
  <div mat-dialog-actions fxLayout="row" fxFlexAlign="center center">
    <button fxFlex mat-raised-button color="primary" (click)="confirm()">Accept</button>
    <button fxFlex mat-raised-button color="secondary" (click)="reject()">Reject</button>
  </div>
</div>
