import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment-timezone';
import { v4 as uuid } from 'uuid';
var DatabaseRecord = /** @class */ (function () {
    function DatabaseRecord(data) {
        if (data === void 0) { data = {}; }
        this._foreignKeys = new Map();
        this._foreignRecords = new Map();
        this.key = data.key || data.id || uuid();
    }
    DatabaseRecord.apiRoute = function (filters) {
        return;
    };
    DatabaseRecord.parseDate = function (date) {
        return moment.tz(date, 'Etc/UTC');
    };
    DatabaseRecord.getRecords = function (objectStoreName, keys) {
        return new Promise(function (resolve, reject) {
            // console.log('KEYS')
            if (_.isArray(keys)) {
                resolve([]); // TODO
            }
        });
    };
    DatabaseRecord.prototype.toJSON = function (fields) {
        var _this = this;
        var object = {
            key: this.key
        };
        if (_.isNil(fields)) {
            fields = [];
        }
        _.each(fields, function (field) {
            var value = _this[field];
            // look at the private cache of foreign records if we never loaded records
            // if (_.isNil(value) && !_.isNil(this['_' + field])) {
            //   value =
            // }
            if (!_.isNil(value)) {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                else if (_.isArray(value) && _.size(value) > 0 && value[0] instanceof DatabaseRecord) {
                    value = _.map(value, function (record) { return record.key; });
                }
                object[field] = value;
            }
        });
        return object;
    };
    DatabaseRecord.prototype.loadAssociations = function (db) {
        var _this = this;
        var promises = [];
        this._foreignKeys.forEach(function (keys, typeClass) {
            var load = db.getRecords(typeClass, keys).then(function (records) {
                _this[typeClass.objectStoreName] = records;
            });
            promises.push(load);
        });
        return Promise.all(promises);
    };
    DatabaseRecord.prototype.getRecords = function (typeClass) {
        return this.getForeignRecords(typeClass);
    };
    /**
     * Given the name of the foreign table, return the cached foreign record keys
     */
    DatabaseRecord.prototype.getForeignRecords = function (typeClass) {
        return this._foreignRecords.get(typeClass);
    };
    DatabaseRecord.prototype.getForeignKeys = function (typeClass) {
        var records = this._foreignKeys.get(typeClass);
        if (!records || records.length === 0) {
            return [];
        }
        else {
            return records;
        }
    };
    DatabaseRecord.prototype.setForiegnRecords = function (typeClass, records) {
        var e_1, _a;
        if (_.isArray(records) && _.size(records) > 0) {
            var keys = [];
            var dbRecords = this._foreignRecords.get(typeClass) || [];
            try {
                for (var records_1 = tslib_1.__values(records), records_1_1 = records_1.next(); !records_1_1.done; records_1_1 = records_1.next()) {
                    var record = records_1_1.value;
                    if (_.isString(record)) {
                        keys.push(record);
                    }
                    else if (record instanceof DatabaseRecord) {
                        dbRecords.push(record);
                        keys.push(record.key);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (records_1_1 && !records_1_1.done && (_a = records_1.return)) _a.call(records_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            this._foreignKeys.set(typeClass, keys);
            this._foreignRecords.set(typeClass, dbRecords);
        }
    };
    return DatabaseRecord;
}());
export { DatabaseRecord };
