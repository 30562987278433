import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { SharedModule } from '../custom-material/shared.module'
import { NgxSpinnerModule } from 'ngx-spinner'
import { ExerciseComponent } from './exercise.component'
import { ExerciseRoutes } from './exercise.routing'
import { SubexerciseComponent } from './subexercise/subexercise.component'
import { VideoDialogModule } from '../video-dialog/video-dialog.module'
import { CompleteComponent } from './complete/complete.component'
import { NotesDialogComponent } from './notes-dialog/notes-dialog.component'
import { EditSetDialogComponent } from './edit-set-dialog/edit-set-dialog.component'
import { RMCalcDialogComponent } from '../rmcalc-dialog/rmcalc-dialog.component'
import { DigitOnlyModule } from '@uiowa/digit-only'
import { RMCalcSetDialogComponent } from '../rmcalc-set-dialog/rmcalc-set-dialog.component'
import { IsPercentagePipe } from './pipes/is-percentage.pipe'
import { SetsRepsLoadPipe } from '../../pipes/sets-reps-load.pipe'
import { CalcLoadPipe } from './pipes/calc-load.pipe'
import { RMCalcDialogModule } from '../rmcalc-dialog/rmcalc-dialog.module'
import { RMCalcSetDialogModule } from '../rmcalc-set-dialog/rmcalc-set-dialog.module'
import { TierDetailsDialogComponent } from './tier-details-dialog/tier-details-dialog.component'
import { NoNegativePipe } from './pipes/no-negative.pipe'
import { ExerciseSetItemComponent } from './exercise-set-item/exercise-set-item.component'
import { SubexerciseCircuitComponent } from './subexercise-circuit/subexercise-circuit.component'
import { EditCircuitDialogComponent } from './edit-circuit-dialog/edit-circuit-dialog.component';
import { PreworkoutSurveyComponent } from './preworkout-survey/preworkout-survey.component'
import { GetExerciseVideoPipe } from 'app/pipes/get-exercise-video.pipe';
import { PreworkoutSurveyQuestionComponent } from './preworkout-survey/preworkout-survey-question/preworkout-survey-question.component'

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(ExerciseRoutes),
    NgxSpinnerModule,
    VideoDialogModule,
    DigitOnlyModule,
    RMCalcDialogModule,
    RMCalcSetDialogModule,
  ],
  declarations: [
    ExerciseComponent,
    SubexerciseComponent,
    SubexerciseCircuitComponent,
    CompleteComponent,
    NotesDialogComponent,
    EditSetDialogComponent,
    IsPercentagePipe,
    CalcLoadPipe,
    NoNegativePipe,
    TierDetailsDialogComponent,
    ExerciseSetItemComponent,
    EditCircuitDialogComponent,
    PreworkoutSurveyComponent,
    GetExerciseVideoPipe,
    SetsRepsLoadPipe,
    PreworkoutSurveyQuestionComponent,
  ],
  entryComponents: [
    NotesDialogComponent,
    EditSetDialogComponent,
    TierDetailsDialogComponent,
    EditCircuitDialogComponent,
  ],
  providers: [
    SetsRepsLoadPipe
  ]
})
export class ExerciseModule { }
