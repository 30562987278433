/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./group-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/common";
import * as i9 from "../../header/header.component.ngfactory";
import * as i10 from "../../header/header.component";
import * as i11 from "@angular/router";
import * as i12 from "@ngxs/store";
import * as i13 from "../../../services/page-transition.service";
import * as i14 from "../../../services/database.service";
import * as i15 from "fitforce-document-sync";
import * as i16 from "@angular/material/dialog";
import * as i17 from "../../../services/auth.service";
import * as i18 from "../../../services/rest.service";
import * as i19 from "@angular/material/snack-bar";
import * as i20 from "../../../services/data.service";
import * as i21 from "../../../services/document.service";
import * as i22 from "../../../../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i23 from "ngx-spinner";
import * as i24 from "./group-view.component";
var styles_GroupViewComponent = [i0.styles];
var RenderType_GroupViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GroupViewComponent, data: {} });
export { RenderType_GroupViewComponent as RenderType_GroupViewComponent };
function View_GroupViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "group-button join-group"], ["fxLayout", "row"], ["fxLayoutAlign", "center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.requestJoin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Request to Join"]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center"; _ck(_v, 2, 0, currVal_1); var currVal_4 = "primary"; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 4).disabled || null); var currVal_3 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_GroupViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Request Pending"]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center"; _ck(_v, 2, 0, currVal_1); }, null); }
function View_GroupViewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["<", ">"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.email; _ck(_v, 1, 0, currVal_0); }); }
function View_GroupViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", " ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupViewComponent_5)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = _v.context.$implicit.email; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.rank; var currVal_1 = _v.context.$implicit.firstName; var currVal_2 = _v.context.$implicit.lastName; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_GroupViewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "group-button leave-group"], ["fxLayout", "row"], ["fxLayoutAlign", "center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["color", "warn"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLeaveDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Leave Group"]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center"; _ck(_v, 2, 0, currVal_1); var currVal_4 = "warn"; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 4).disabled || null); var currVal_3 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_GroupViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "group-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupViewComponent_2)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupViewComponent_3)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Leader"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupViewComponent_4)), i1.ɵdid(10, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Marines"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupViewComponent_6)), i1.ɵdid(16, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((!_co.isJoined() && !_co.isRequested) && _co.isOnline); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isRequested; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.owners; _ck(_v, 10, 0, currVal_3); var currVal_5 = (_co.isJoined() && _co.isOnline); _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.group.name; _ck(_v, 2, 0, currVal_0); var currVal_4 = (_co.group.users ? _co.group.users.length : "0"); _ck(_v, 14, 0, currVal_4); }); }
function View_GroupViewComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fetching..."]))], null, null); }
export function View_GroupViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i9.View_HeaderComponent_0, i9.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i10.HeaderComponent, [i11.Router, i12.Store, i8.Location, i13.PageTransitionService, i14.DatabaseService, i15.DocumentDatabaseService, i16.MatDialog, i17.AuthService, i18.RestService, i19.MatSnackBar, i20.DataService, i21.DocumentService], { hasGoBack: [0, "hasGoBack"], hasLogout: [1, "hasLogout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "ngx-spinner", [["bdColor", "rgba(51, 51, 51, 0.8)"], ["color", "#fff"], ["size", "large"], ["type", "ball-atom"]], null, null, null, i22.View_NgxSpinnerComponent_0, i22.RenderType_NgxSpinnerComponent)), i1.ɵdid(3, 770048, null, 0, i23.NgxSpinnerComponent, [i23.NgxSpinnerService, i1.ChangeDetectorRef], { bdColor: [0, "bdColor"], size: [1, "size"], color: [2, "color"], type: [3, "type"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Syncing group plans..."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupViewComponent_1)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loadingData", 2]], null, 0, null, View_GroupViewComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "rgba(51, 51, 51, 0.8)"; var currVal_3 = "large"; var currVal_4 = "#fff"; var currVal_5 = "ball-atom"; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.group; var currVal_7 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_6, currVal_7); }, null); }
export function View_GroupViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-group-view", [], null, null, null, View_GroupViewComponent_0, RenderType_GroupViewComponent)), i1.ɵdid(1, 245760, null, 0, i24.GroupViewComponent, [i11.Router, i11.ActivatedRoute, i12.Store, i20.DataService, i17.AuthService, i18.RestService, i16.MatDialog, i23.NgxSpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GroupViewComponentNgFactory = i1.ɵccf("app-group-view", i24.GroupViewComponent, View_GroupViewComponent_Host_0, {}, {}, []);
export { GroupViewComponentNgFactory as GroupViewComponentNgFactory };
