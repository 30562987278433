<header fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
    <button mat-icon-button *ngIf="hasGoBack" (click)="onGoBack()"><mat-icon>arrow_back</mat-icon></button>
    <div class="nav-title">
        FitForce
    </div>

    <div fxFlex></div>
    <app-alerts></app-alerts>
    <button mat-icon-button [matMenuTriggerFor]="mainMenu"><mat-icon>more_vert</mat-icon></button>
    <mat-menu #mainMenu="matMenu">
      <button mat-menu-item *ngIf="hasRefresh && isOnline" (click)="onRefreshData()">
        <mat-icon>refresh</mat-icon>
        <span>Refresh</span>
      </button>
      <button mat-menu-item (click)="openAboutDialog()">
        <mat-icon>info</mat-icon>
        <span>About</span>
      </button>
      <button mat-menu-item (click)="openFeedbackDialog()">
        <mat-icon>feedback</mat-icon>
        <span>Feedback</span>
      </button>
      <button mat-menu-item (click)="openChangePasswordDialog()">
        <mat-icon>lock</mat-icon>
        <span>Change Password</span>
      </button>
      <button mat-menu-item (click)="openProfileDialog()">
        <mat-icon>person</mat-icon>
        <span>User Profile</span>
      </button>
      <!-- <button mat-menu-item (click)="openSupport()">
        <mat-icon>help</mat-icon>
        <span>Support</span>
      </button> -->
      <button mat-menu-item *ngIf="hasLogout" (click)="onLogout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Log Out</span>
      </button>
    </mat-menu>
</header>
