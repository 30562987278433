/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./programs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "./programs-joined/programs-joined.component.ngfactory";
import * as i5 from "./programs-joined/programs-joined.component";
import * as i6 from "@ngxs/store";
import * as i7 from "../../services/data.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "./programs-public/programs-public.component.ngfactory";
import * as i10 from "./programs-public/programs-public.component";
import * as i11 from "./programs.component";
var styles_ProgramsComponent = [i0.styles];
var RenderType_ProgramsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgramsComponent, data: {} });
export { RenderType_ProgramsComponent as RenderType_ProgramsComponent };
export function View_ProgramsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "programs-container"], ["fxLayout", "column"], ["fxLayoutAlign", "space-between stretch"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-programs-joined", [], null, null, null, i4.View_ProgramsJoinedComponent_0, i4.RenderType_ProgramsJoinedComponent)), i1.ɵdid(4, 114688, null, 0, i5.ProgramsJoinedComponent, [i6.Store, i7.DataService, i8.MatDialog], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-programs-public", [], null, null, null, i9.View_ProgramsPublicComponent_0, i9.RenderType_ProgramsPublicComponent)), i1.ɵdid(6, 114688, null, 0, i10.ProgramsPublicComponent, [i6.Store, i7.DataService], null, null)], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-between stretch"; _ck(_v, 2, 0, currVal_1); _ck(_v, 4, 0); _ck(_v, 6, 0); }, null); }
export function View_ProgramsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-programs", [], null, null, null, View_ProgramsComponent_0, RenderType_ProgramsComponent)), i1.ɵdid(1, 114688, null, 0, i11.ProgramsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgramsComponentNgFactory = i1.ɵccf("app-programs", i11.ProgramsComponent, View_ProgramsComponent_Host_0, {}, {}, []);
export { ProgramsComponentNgFactory as ProgramsComponentNgFactory };
