import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { Select, Store } from '@ngxs/store';
import { AppState } from 'app/state/app/app.state';
import { DataService } from 'app/services/data.service';
import { JoinGroup } from 'app/state';
import { Observable } from 'rxjs';
import { debounce } from 'lodash';
import { distinct } from 'rxjs/operators';
var ProgramsPublicComponent = /** @class */ (function () {
    function ProgramsPublicComponent(store, dataService) {
        this.store = store;
        this.dataService = dataService;
        this.debouncedOnSearchEvent = debounce(this.onSearchEvent.bind(this), 500);
        this.pageSize = 10;
        this.currentPage = 1;
        this.pageIndex = 0;
        this.isOnline = false;
    }
    ProgramsPublicComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fetched = false;
        this.groups = [];
        this.isOnline$.subscribe(function (isOnline) {
            _this.isOnline = isOnline;
        });
        this.joinedGroups$.pipe(distinct()).subscribe(function (groups) {
            // Only run on subsequent requests
            if (_this.fetched) {
                _this.fetchPublicGroups(_this.currentPage, '');
            }
        });
        this.fetchPublicGroups(this.currentPage, '');
        this.fetched = true;
    };
    ProgramsPublicComponent.prototype.onPageEvent = function ($event) {
        this.pageIndex = $event.pageIndex;
        this.fetchPublicGroups(this.pageIndex + 1, this.searchText);
    };
    ProgramsPublicComponent.prototype.debounceOnSearchEvent = function (searchText) {
        this.searchText = searchText;
        this.debouncedOnSearchEvent();
    };
    ProgramsPublicComponent.prototype.onSearchEvent = function () {
        if (this.paginator) {
            this.paginator.pageIndex = 0;
        }
        this.pageIndex = 0;
        this.fetchPublicGroups(this.pageIndex + 1, this.searchText);
    };
    ProgramsPublicComponent.prototype.fetchPublicGroups = function (pageNumber, searchText) {
        if (searchText === void 0) { searchText = ''; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isFetching = true;
                        return [4 /*yield*/, this.dataService.fetchPublicGroups(pageNumber, this.pageSize, searchText)];
                    case 1:
                        response = _a.sent();
                        this.totalPages = response.totalPages;
                        this.totalGroups = response.total;
                        this.currentPage = response.currentPage;
                        this.groups = response.groups;
                        this.isFetching = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ProgramsPublicComponent.prototype.onRequestJoin = function (group) {
        var _this = this;
        this.store.dispatch(new JoinGroup(group)).subscribe(function () {
            _this.searchText = '';
        });
    };
    tslib_1.__decorate([
        Select(AppState.isOnline),
        tslib_1.__metadata("design:type", Observable)
    ], ProgramsPublicComponent.prototype, "isOnline$", void 0);
    tslib_1.__decorate([
        Select(AppState.joinedGroups),
        tslib_1.__metadata("design:type", Observable)
    ], ProgramsPublicComponent.prototype, "joinedGroups$", void 0);
    return ProgramsPublicComponent;
}());
export { ProgramsPublicComponent };
