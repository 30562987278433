<content *ngIf="workoutSession">
  <header fxLayout="column">
    <h2>{{ workoutSession.workout.name }}</h2>
    <div *ngIf="timeElapsed" fxLayout="row" fxLayoutGap=".1em" fxLayoutAlign="center center">
      <mat-form-field class="input-time" fxFlex="20">
        <label>Minutes</label>
        <input matInput type="number" [ngModel]="timeMinutes" (input)="onTimeChange($event, 'minutes')" [disabled]="isHistory">
      </mat-form-field>
      <div class="input-time-spacer">:</div>
      <mat-form-field class="input-time" fxFlex="20">
        <label>Seconds</label>
        <input matInput type="number" [ngModel]="timeSeconds" (input)="onTimeChange($event, 'seconds')" [disabled]="isHistory">
      </mat-form-field>
    </div>
    <div>
      <p>How was your workout?</p>
      <div fxLayoutAlign="center center"><span class="rep-score">{{ rpeScore }}</span>&nbsp;&nbsp;({{ repStrings[rpeScore] }})</div>
      <p></p>
      <mat-slider
        class="rpe-slider"
        (input)="onSliderChange($event)"
        max="10"
        min="0"
        step="1"
        tickInterval="1"
        thumbLabel="true"
        value="0"
        [(ngModel)]="rpeScore"
        [disabled]="isHistory">
      </mat-slider>
    </div>
  </header>
  <main>
    <mat-accordion>
      <ng-container *ngFor="let tier of workoutSession.workout.tiers; let tierIndex = index">
        <div *ngIf="tier && tier.circuit">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div fxFlex fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
                  <mat-icon>loop</mat-icon>
                  <div>{{ tier.name }}</div>
                  <div fxFlex></div>
                  <mat-icon *ngIf="!tier.comments" class="small" (click)="openCommentsDialog(tierIndex, -1)">edit</mat-icon>
                  <mat-icon *ngIf="tier.comments" class="small" (click)="openCommentsDialog(tierIndex, -1)">comment</mat-icon>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
              <div fxLayout="column">
                <div *ngFor="let row of tier.rows; let rowIndex = index">
                  {{ row.exercise.name }}
                </div>
              </div>
              <div fxFlex></div>
              <div class="rounds"
                *ngIf="tier.circuit && tier.circuit.executions.length && tier.circuit.executions[0].num_rounds && tier.circuit.executions[0].num_rounds !== -1"
                fxLayoutAlign="center center" fxFlex>
                {{ tier.circuit.executions[0].num_rounds }} Rounds
              </div>
              <div><button *ngIf="!isHistory" mat-button (click)="openEditTier(tierIndex)">Edit</button></div>
            </div>
          </mat-expansion-panel>
        </div>
        <div *ngIf="tier && !tier.circuit">
          <mat-expansion-panel *ngFor="let row of tier.rows; let rowIndex = index">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                  <div>{{ row.exercise.name }}</div>
                  <div fxFlex></div>
                  <mat-icon *ngIf="!row.comments" class="small" (click)="openCommentsDialog(tierIndex, rowIndex)">edit</mat-icon>
                  <mat-icon *ngIf="row.comments" class="small" (click)="openCommentsDialog(tierIndex, rowIndex)">comment</mat-icon>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="exercise-notes" *ngIf="row.notes">
              {{ row.notes }}
              <hr>
            </div>
            <div *ngFor="let execution of row.executions; let setIndex = index" fxLayout="row" fxLayoutGap="20px">
              <div fxLayoutAlign="center center">
                Set {{ setIndex + 1 }}
              </div>
              <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
                <app-exercise-set-item
                  fxFlex="1 1"
                  [workoutSession]="workoutSession"
                  [isHistory]="true"
                  [execution]="execution"
                  [currentWorkoutIndex]="tierIndex"
                  [currentRowIndex]="rowIndex"
                  [setIndex]="setIndex"></app-exercise-set-item>
              </div>
              <div fxFlex></div>
              <div *ngIf="execution.complete_time" fxLayoutAlign="center center">
                <mat-icon>check_mark</mat-icon>
              </div>
              <div><button *ngIf="!isHistory" mat-button (click)="openEditSet(tierIndex, rowIndex, setIndex)">Edit</button></div>
            </div>
          </mat-expansion-panel>
        </div>
      </ng-container>
    </mat-accordion>
  </main>
  <footer>
    <button *ngIf="isHistory && workoutSession" (click)="startNewWorkout()" mat-raised-button color="secondary">Start New Workout</button>
    <span fxFlex></span>
    <button *ngIf="!isHistory" (click)="saveWorkout()" mat-raised-button color="primary">SAVE AND EXIT</button>
    <button *ngIf="isHistory" (click)="closeHistory()" mat-raised-button color="primary">EXIT</button>
  </footer>
</content>
