<h2 matDialogTitle>{{data.title}}</h2>
<mat-dialog-content style="white-space: pre-wrap;" fxLayout="column" fxLayoutGap="16px">
  <span>{{data.content}}</span>
  <div *ngIf = "data.list">
    <ul>
      <div *ngFor="let item of data.list">
        <li>{{item}}</li>
      </div>
    </ul>
  </div>
  <div *ngIf="data.showSecondaryAction" fxLayout="column" fxLayoutGap="16px">
    <div *ngIf="data.secondaryActionMessage">{{data.secondaryActionMessage}}</div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <button *ngIf="data.secondaryAction" mat-button (click)="data.secondaryAction.emit()">{{data.secondaryActionText}}</button>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button *ngIf="data.cancelButton" mat-button [matDialogClose]>{{data.cancelButton || 'Cancel'}}</button>
  <button *ngIf="!data.redirectUrl" mat-raised-button [matDialogClose] color="primary">{{data.button || 'Okay' }}</button>
  <button *ngIf="data.redirectUrl" mat-raised-button color="primary" (click)="redirect(data.redirectUrl)">{{data.button || 'Go'}}</button>
</mat-dialog-actions>
