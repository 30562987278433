/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "@angular/common";
import * as i9 from "./alerts.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "@ngxs/store";
import * as i12 from "@angular/router";
var styles_AlertsComponent = [i0.styles];
var RenderType_AlertsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertsComponent, data: {} });
export { RenderType_AlertsComponent as RenderType_AlertsComponent };
function View_AlertsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "has-alerts mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["notifications_active"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
function View_AlertsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["disabled", ""], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "no-alerts mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["notifications_none"]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 1, 0, currVal_2); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 3).inline; var currVal_4 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_3, currVal_4); }); }
export function View_AlertsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_2)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showNotification; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.showNotification; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AlertsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alerts", [], null, null, null, View_AlertsComponent_0, RenderType_AlertsComponent)), i1.ɵdid(1, 114688, null, 0, i9.AlertsComponent, [i10.MatDialog, i11.Store, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertsComponentNgFactory = i1.ɵccf("app-alerts", i9.AlertsComponent, View_AlertsComponent_Host_0, {}, {}, []);
export { AlertsComponentNgFactory as AlertsComponentNgFactory };
