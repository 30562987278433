import { Pipe, PipeTransform } from '@angular/core';
import { DocumentService } from 'app/services/document.service'
import * as _ from 'lodash'
import { ResourceType } from 'fitforce-document-sync'

@Pipe({
  name: 'getExerciseVideo'
})
export class GetExerciseVideoPipe implements PipeTransform {

  constructor(
    private documentService: DocumentService
  ) {}

  transform(tierRow: any): any {
    return this.getExerciseVideo(tierRow)
  }

  getExerciseVideo(tierRow: any) {
    const exerciseId = tierRow.exercise.exercise_program_id
    const exerciseDetails = _.find(this.documentService.store.exerciseProgram.document.exercises, { id: exerciseId })
    if (exerciseDetails && exerciseDetails.resources && exerciseDetails.resources.length > 0) {
      return _.find(exerciseDetails.resources, { type: ResourceType.Video })
    }
    return
  }

}
