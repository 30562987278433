<div fxLayout="column">
    <h3>PFT/CFT Event Performance</h3>
    <p class="pft-description">Enter your PFT or CFT event performance for as many exercises as you can.</p>
    <div fxFlex></div>
    <div class="totals-div" fxLayout="row" fxLayoutGap="20px">
        <app-pft-cft-exercise-score fxFlex [score]="totalPftScore" [category]="'pft'"></app-pft-cft-exercise-score>
        <app-pft-cft-exercise-score fxFlex [score]="totalCftScore" [category]="'cft'"></app-pft-cft-exercise-score>
        <div fxFlex fxLayout="column" fxLayoutAlign="center baseline">
            <div fxFlex></div>
            <button mat-raised-button class="log-button" (click)="saveScoreObservationDocs()" color="primary" fxFlex>Log</button>
            <div fxFlex></div>
        </div>
    </div>
    <div>
        <p *ngIf="pftMessage" class="failing">{{ pftMessage }}</p>
        <p *ngIf="cftMessage" class="failing">{{ cftMessage }}</p>        
    </div>    
    <div fxFlex></div>
    <cdk-virtual-scroll-viewport [itemSize]="50" class="exercises-viewport">
        <ng-container>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <p class="pft-list-header">PFT Events</p>
                <button mat-button color="warn" (click)="clearPft()">Clear All</button>
            </div>
            <div fxLayout="column" fxLayoutGap="10px" *ngFor="let item of pftFilteredExercises;">
                <mat-card fxLayout="row" fxLayoutAlign="center center" (click)="onExerciseSelected(item); false">
                    <div>{{ item.name }}</div>
                    <div fxFlex></div>
                    <button mat-button class="action-items primary" [disabled]="disablePftAdd(item)" (click)="openCalcPft(item)">
                        <div *ngIf="!item['pft'] ||item['pft']['score'] === undefined || item['pft']['score'] === null || item['pft']['score'] === 'NaN'">ADD</div>
                        <div *ngIf="item['pft'] && (item['pft']['score'] || item['pft']['score'] === 0) && item['pft']['score'] !== 'NaN'">{{ item['pft']['score'] | number: '1.0-0' }}</div>
                    </button>
                </mat-card>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <p class="pft-list-header">CFT Events</p>
                <button mat-button color="warn" (click)="clearCft()">Clear All</button>
            </div>
            <div fxLayout="column" fxLayoutGap="10px" *ngFor="let item of cftFilteredExercises;">
                <mat-card fxLayout="row" fxLayoutAlign="center center" (click)="onExerciseSelected(item); false">
                    <div>{{ item.name }}</div>
                    <div fxFlex></div>
                    <button mat-button class="action-items primary" [disabled]="disableCftAdd(item)" (click)="openCalcCft(item)">
                        <div *ngIf="!item['cft'] || item['cft']['score'] === undefined || item['cft']['score'] === null || item['cft']['score'] === 'NaN'">ADD</div>
                        <div *ngIf="(item['cft'] && (item['cft']['score'] || item['cft']['score'] === 0)) && item['cft']['score'] !== 'NaN'">{{ item['cft']['score'] | number: '1.0-0' }}</div>
                    </button>
                </mat-card>
            </div>
        </ng-container>
    </cdk-virtual-scroll-viewport>
</div>
