import * as tslib_1 from "tslib";
import { DocumentRecord } from 'fitforce-offline-first';
import { DatabaseRecord } from '../classes/database-record.class';
var WorkoutSessionDocument = /** @class */ (function (_super) {
    tslib_1.__extends(WorkoutSessionDocument, _super);
    function WorkoutSessionDocument(data) {
        return _super.call(this, data) || this;
    }
    WorkoutSessionDocument.recordName = 'WorkoutSessionDocument';
    WorkoutSessionDocument.objectStoreName = 'workoutSessionDocuments';
    return WorkoutSessionDocument;
}(DocumentRecord));
export { WorkoutSessionDocument };
// Used for v1 :/
var WorkoutSessionDBDocument = /** @class */ (function (_super) {
    tslib_1.__extends(WorkoutSessionDBDocument, _super);
    function WorkoutSessionDBDocument(data) {
        var _this = _super.call(this, data) || this;
        _this.exercise_program_id = data.exercise_program_id;
        _this.start_time = data.start_time;
        _this.end_time = data.end_time;
        _this.complete = data.complete;
        _this.workout_complete = data.workout_complete;
        _this.group = data.group;
        _this.plan = data.plan;
        _this.workout = data.workout;
        // computed on front-end
        _this.total_tiers = data.total_tiers;
        _this.completed_tiers = data.completed_tiers;
        _this.total_exercises = data.total_exercises;
        _this.completed_exercises = data.completed_exercises;
        // computed on back-end
        _this.attendance_count = data.attendance_count;
        return _this;
    }
    WorkoutSessionDBDocument.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, [
            'exercise_program_id',
            'start_time',
            'end_time',
            'complete',
            'group',
            'plan',
            'workout',
            // computed on front-end
            'total_tiers',
            'completed_tiers',
            'total_exercises',
            'completed_exercises',
            // computed on back-end
            'attendance_count',
        ]);
    };
    WorkoutSessionDBDocument.objectStoreName = 'workoutSessionDocuments';
    return WorkoutSessionDBDocument;
}(DatabaseRecord));
export { WorkoutSessionDBDocument };
