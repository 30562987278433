import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { WorkoutState } from 'app/state/workout/workout.state';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss']
})
export class NotesDialogComponent implements OnInit {
  workoutSession: any
  isHistory = false
  isTierNote = true
  isCircuit = false
  notes: string

  constructor (
    private store: Store,
    public dialogRef: MatDialogRef<NotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data) {
      this.isTierNote = !!data.isTierNote
      this.isHistory = !!data.isHistory
      this.workoutSession = data.workoutSession
      this.isCircuit = !!data.isCircuit
    }
  }

  ngOnInit() {
    let currentWorkoutSession
    if (this.workoutSession) {
      currentWorkoutSession = this.workoutSession
    } else {
      currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession)
    }
    let currentWorkoutIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex)
    let currentRowIndex = this.store.selectSnapshot(WorkoutState.currentRowIndex)

    if (this.data.tierIndex >= 0) {
      currentWorkoutIndex = this.data.tierIndex
    }
    if (this.data.rowIndex >= 0) {
      currentRowIndex = this.data.rowIndex
    }

    const tier = currentWorkoutSession.workout.tiers[currentWorkoutIndex]
    if (this.isTierNote) {
      this.notes = tier.rows[currentRowIndex].notes
    } else if (this.isCircuit) {
      this.notes = tier.comments
    } else {
      this.notes = tier.rows[currentRowIndex].comments
    }
  }

  cancelSelected() {
    this.dialogRef.close({
      success: false
    })
  }

  submitSelected() {
    if (!this.notes) {
      this.cancelSelected()
      return
    }

    this.dialogRef.close({
      success: true,
      notes: this.notes,
    })
  }
}
