import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { DatabaseRecord } from '../../classes/database-record.class';
import { SurveyQuestion } from './survey-question.model';
var SurveyTemplate = /** @class */ (function (_super) {
    tslib_1.__extends(SurveyTemplate, _super);
    function SurveyTemplate(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.id = data['id'] || '';
        _this.category = data['category'];
        if (_.has(data, 'questions')) {
            _this.questions = data['questions'];
        }
        return _this;
    }
    Object.defineProperty(SurveyTemplate.prototype, "questions", {
        get: function () {
            return this.getForeignKeys(SurveyQuestion);
        },
        set: function (questions) {
            this.setForiegnRecords(SurveyQuestion, questions);
        },
        enumerable: true,
        configurable: true
    });
    SurveyTemplate.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, [
            'id',
            'category',
            'questions'
        ]);
    };
    SurveyTemplate.prototype.getQuestions = function () {
        return this.getRecords(SurveyQuestion);
    };
    SurveyTemplate.objectStoreName = 'surveyTemplates';
    return SurveyTemplate;
}(DatabaseRecord));
export { SurveyTemplate };
