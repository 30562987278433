<div fxLayout="column">
  <div>Stats</div>
  <p class="stats-description">Enter your 1RM or Max Set for as many exercises as you can. If you haven’t measured your 1RM, type in the most recent Weight/Reps for that exercise to calculate it automatically.</p>
  <mat-form-field fxFlex>
    <input matInput placeholder="Search" [formControl]="search" />
  </mat-form-field>
  <cdk-virtual-scroll-viewport [itemSize]="50" class="exercises-viewport">
    <ng-container *cdkVirtualFor="let item of filteredExercises; ">
      <div class="stats-list-header" fxLayout="row" fxLayoutGap="10px" *ngIf="item.type === 'category' || item.type === 'bodyweightCategory'">
        <p>{{ item.name }}</p>
        <div fxFlex></div>
        <p *ngIf="item.type === 'category'">1RM</p>
        <p *ngIf="item.type === 'bodyweightCategory'">Max Set</p>
      </div>
      <div fxLayout="column" fxLayoutGap="10px" *ngIf="item.type === 'exercise' || item.type === 'bodyweightExercise'">
        <mat-card fxLayout="row" fxLayoutAlign="center center" (click)="onExerciseSelected(item); false">
          <div>{{ item.name }}</div>
          <div fxFlex></div>
          <div *ngIf="item.type === 'exercise'" class="action-items primary" (click)="openCalc1RM(item.categoryId, item)">
            <div *ngIf="!item['1rm'] || !item['1rm']['1rm']">ADD</div>
            <div *ngIf="item['1rm'] && item['1rm']['1rm']">{{ item['1rm']['1rm'] | number: '1.0-0' }}</div>
          </div>
          <div *ngIf="item.type === 'bodyweightExercise'" class="action-items primary" (click)="openMaxSet(item.categoryId, item)">
            <div *ngIf="!item['1rm'] || !item['1rm']['max_set']">ADD</div>
            <div *ngIf="item['1rm'] && item['1rm']['max_set']">{{ item['1rm']['max_set'] | number: '1.0-0' }}</div>
          </div>
        </mat-card>
      </div>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</div>
