import * as tslib_1 from "tslib";
import { DatabaseRecord } from '../classes/database-record.class';
import { Day } from './day.model';
var Plan = /** @class */ (function (_super) {
    tslib_1.__extends(Plan, _super);
    function Plan(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.name = data['name'] || '';
        _this.description = data['description'];
        _this.durationInDays = data['durationInDays'];
        _this.revision = data['revision'] || 1;
        _this.createdAt = DatabaseRecord.parseDate(data['createdAt']);
        _this.updatedAt = DatabaseRecord.parseDate(data['updatedAt']);
        _this.startDate = DatabaseRecord.parseDate(data['startDate']);
        _this.endDate = DatabaseRecord.parseDate(data['endDate']);
        _this.days = data['days'];
        _this.version = data['version'];
        return _this;
    }
    Object.defineProperty(Plan.prototype, "days", {
        get: function () {
            return this.getForeignKeys(Day);
        },
        set: function (records) {
            this.setForiegnRecords(Day, records);
        },
        enumerable: true,
        configurable: true
    });
    Plan.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, [
            'name',
            'description',
            'durationInDays',
            'revision',
            'createdAt',
            'updatedAt',
            'startDate',
            'endDate',
            'days',
            'version'
        ]);
    };
    Plan.objectStoreName = 'plans';
    return Plan;
}(DatabaseRecord));
export { Plan };
