import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, fromEvent, merge, throwError, of } from 'rxjs';
import { share, map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

const SERVER_DATE_FORMAT = 'MM/DD/YYYY';

@Injectable({
  providedIn: 'root'
})
export class ResourcesRestService {
  constructor(private http: HttpClient) { }

  public get (route: string, params?: any, root?: boolean): Observable<any> {
    return this.http.get(this.buildRoute(route, root), { params })
  }

  public post (route: string, data: any, root?: boolean): Observable<any>  {
    return this.http.post(this.buildRoute(route, root), data)
  }

  public delete (route: string, root?: boolean): Observable<any> {
    return this.http.delete(this.buildRoute(route, root))
  }

  private buildRoute (route: string, root = false) {
    function applySubstitutions(url) {
      return url.replace(/{{HOSTNAME}}/gi, location.hostname)
    }
    if (root) {
      return applySubstitutions(environment.api_url) + 'api/' + route
    }
    return applySubstitutions(environment.api_url) + 'api/Mobile/' + route
  }

  private handleError (e) {
    return throwError(e.message || 'Server error')
  }

  private isOnline () {
    const online = !!window.navigator.onLine
    // console.log('Currently marked as ' + (online ? 'online' : 'offline'))
    return online
  }

  public isOnlineSubscription() {
    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      Observable.create(sub => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }
}
