import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'app/services/data.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
var PftCftChartComponent = /** @class */ (function () {
    function PftCftChartComponent(dataService, cdr) {
        this.dataService = dataService;
        this.cdr = cdr;
        this.resetSub = new Subscription();
        /*
         * PLOTLY NOTES:
         *
         * Hover label of Trace (Legend) is white... https://community.plotly.com/t/trace-hover-label-color/12584
         * Merging dates into Months require a different data structure
         * No way to hide week numbers (the main axis used to separate out dates)
         * No way to merge a scatter+line plot WITH a multiaxis bar chart
         */
        this.graph = {
            layout: {
                showlegend: false,
                height: 140,
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
                xaxis: {
                    showticklabels: true,
                    tickmode: 'array',
                    tickangle: '-30',
                    tickfont: {
                        size: 10,
                        color: '#FFFFFF',
                    },
                    showgrid: false,
                },
                yaxis: {
                    showticklabels: true,
                    tickfont: {
                        size: 10,
                        color: '#FFFFFF',
                    },
                    showgrid: false,
                    range: [0, 350],
                },
                margin: {
                    l: 30,
                    r: 0,
                    b: 40,
                    t: 5,
                },
                hoverlabel: {
                    bgcolor: '#666666',
                    font: { color: '#FFFFFF' },
                },
            },
            config: {
                displayModeBar: false,
                responsive: true,
            },
        };
        this.targetPftScore = 235;
        this.targetPftScoreMid = 200;
        this.targetPftScoreLow = 150;
        this.targetCftScore = 235;
        this.targetCftScoreMid = 200;
        this.targetCftScoreLow = 150;
        // Stored by YYYY-MM
        this.pftScores = {
            actual: {},
            target: {},
            targetMid: {},
            targetLow: {},
        };
        this.cftScores = {
            actual: {},
            target: {},
            targetMid: {},
            targetLow: {},
        };
        this.loading = false;
    }
    PftCftChartComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.init();
        this.resetSub = this.dataService.pftCftChartReset$.subscribe(function () {
            _this.init();
        });
    };
    PftCftChartComponent.prototype.ngOnDestroy = function () {
        this.resetSub.unsubscribe();
    };
    PftCftChartComponent.prototype.init = function () {
        var _this = this;
        this.loading = true;
        this.initAsync().then(function () {
            _this.loading = false;
            _this.cdr.detectChanges();
        });
    };
    PftCftChartComponent.prototype.initAsync = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getPftScores()];
                    case 1:
                        _a.pftScores = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.getCftScores()];
                    case 2:
                        _b.cftScores = _c.sent();
                        this.setChart();
                        return [2 /*return*/];
                }
            });
        });
    };
    // currently uses mock data below; update for real data
    PftCftChartComponent.prototype.getPftScores = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pftScoreDocs, actualScores, targetScores, targetScoresMid, targetScoresLow, startDate, endDate, startDateKey, endDateKey;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getPftScoreDocuments()];
                    case 1:
                        pftScoreDocs = _a.sent();
                        actualScores = {};
                        targetScores = {};
                        targetScoresMid = {};
                        targetScoresLow = {};
                        startDate = moment(new Date()).subtract(30, 'days');
                        endDate = moment(new Date()).add(5, 'days');
                        startDateKey = startDate.format('YYYY-MM-DD');
                        endDateKey = endDate.format('YYYY-MM-DD');
                        targetScores[startDateKey] = this.targetPftScore;
                        targetScores[endDateKey] = this.targetPftScore;
                        targetScoresMid[startDateKey] = this.targetPftScoreMid;
                        targetScoresMid[endDateKey] = this.targetPftScoreMid;
                        targetScoresLow[startDateKey] = this.targetPftScoreLow;
                        targetScoresLow[endDateKey] = this.targetPftScoreLow;
                        _.each(pftScoreDocs, function (pft) {
                            if (pft && pft.document && pft.document.result && pft.document.result.score) {
                                var date = moment(pft.document.timestamp);
                                var result = pft.document.result;
                                var pftScore = result.score;
                                var dateKey = date.format('YYYY-MM-DD');
                                actualScores[dateKey] = pftScore;
                                targetScores[dateKey] = _this.targetPftScore; // update with whatever the target score is
                                targetScoresMid[dateKey] = _this.targetPftScoreMid;
                                targetScoresLow[dateKey] = _this.targetPftScoreLow;
                            }
                        });
                        // console.log('pft actual scores', actualScores);
                        return [2 /*return*/, {
                                actual: actualScores,
                                target: targetScores,
                                targetMid: targetScoresMid,
                                targetLow: targetScoresLow,
                            }];
                }
            });
        });
    };
    PftCftChartComponent.prototype.getCftScores = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cftScoreDocs, actualScores, targetScores, targetScoresMid, targetScoresLow, startDate, endDate, startDateKey, endDateKey;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getCftScoreDocuments()];
                    case 1:
                        cftScoreDocs = _a.sent();
                        actualScores = {};
                        targetScores = {};
                        targetScoresMid = {};
                        targetScoresLow = {};
                        startDate = moment(new Date()).subtract(30, 'days');
                        endDate = moment(new Date()).add(5, 'days');
                        startDateKey = startDate.format('YYYY-MM-DD');
                        endDateKey = endDate.format('YYYY-MM-DD');
                        targetScores[startDateKey] = this.targetCftScore;
                        targetScores[endDateKey] = this.targetCftScore;
                        targetScoresMid[startDateKey] = this.targetCftScoreMid;
                        targetScoresMid[endDateKey] = this.targetCftScoreMid;
                        targetScoresLow[startDateKey] = this.targetCftScoreLow;
                        targetScoresLow[endDateKey] = this.targetCftScoreLow;
                        _.each(cftScoreDocs, function (cft) {
                            if (cft && cft.document && cft.document.result && cft.document.result.score) {
                                var date = moment(cft.document.timestamp);
                                var result = cft.document.result;
                                var cftScore = result.score;
                                var dateKey = date.format('YYYY-MM-DD');
                                actualScores[dateKey] = cftScore;
                                targetScores[dateKey] = _this.targetCftScore; // update with whatever the target score is
                                targetScoresMid[dateKey] = _this.targetCftScoreMid;
                                targetScoresLow[dateKey] = _this.targetCftScoreLow;
                            }
                        });
                        // console.log('cft actual scores', actualScores);
                        return [2 /*return*/, {
                                actual: actualScores,
                                target: targetScores,
                                targetMid: targetScoresMid,
                                targetLow: targetScoresLow,
                            }];
                }
            });
        });
    };
    PftCftChartComponent.prototype.getMockCftScores = function () {
        var scores = [];
        var document1 = {
            id: '17736ee3-af51-4af6-affc-802e2e6f1baf',
            measure: {
                description: '',
                tags: ['Fitness', 'Evaluation'],
                parameters: [
                    {
                        property: 'value',
                        type: 'score',
                        required: true,
                        unit: 'N/A',
                    },
                ],
            },
            result: {
                type: 'CFT',
                score: 269,
            },
            timestamp: '2022-01-31T15:02:22.459Z',
        };
        var document2 = {
            id: 'a5940a51-7623-47b4-b4f9-54e01b5d1d0f',
            measure: {
                description: '',
                tags: ['Fitness', 'Evaluation'],
                parameters: [
                    {
                        property: 'value',
                        type: 'score',
                        required: true,
                        unit: 'N/A',
                    },
                ],
            },
            result: {
                type: 'CFT',
                score: 130,
            },
            timestamp: '2022-01-21T15:02:22.459Z',
        };
        var doc1 = {
            document: document1,
        };
        var doc2 = {
            document: document2,
        };
        scores.push(doc1);
        scores.push(doc2);
        return scores;
    };
    PftCftChartComponent.prototype.getMockPftScores = function () {
        var scores = [];
        var document1 = {
            id: 'dd06eb7d-c11b-45ed-9362-07af77533396',
            measure: {
                description: '',
                tags: ['Fitness', 'Evaluation'],
                parameters: [
                    {
                        property: 'value',
                        type: 'score',
                        required: true,
                        unit: 'N/A',
                    },
                ],
            },
            result: {
                type: 'PFT',
                score: 300,
            },
            timestamp: '2022-01-31T15:02:22.459Z',
        };
        var document2 = {
            id: '3c0fa605-fcf2-4dff-8835-888d4847a5e7',
            measure: {
                description: '',
                tags: ['Fitness', 'Evaluation'],
                parameters: [
                    {
                        property: 'value',
                        type: 'score',
                        required: true,
                        unit: 'N/A',
                    },
                ],
            },
            result: {
                type: 'PFT',
                score: 200,
            },
            timestamp: '2022-01-21T15:02:22.459Z',
        };
        var doc1 = {
            document: document1,
        };
        var doc2 = {
            document: document2,
        };
        scores.push(doc1);
        scores.push(doc2);
        return scores;
    };
    PftCftChartComponent.prototype.setChart = function () {
        var pftActualChartData = this.formatChartData(this.pftScores.actual, 'scatter', false);
        var pftTargetChartData = this.formatChartData(this.pftScores.target, 'bar', true);
        var pftTargetChartDataMid = this.formatChartData(this.pftScores.targetMid, 'bar', true);
        var pftTargetChartDataLow = this.formatChartData(this.pftScores.targetLow, 'bar', true);
        var cftActualChartData = this.formatChartData(this.cftScores.actual, 'scatter', false);
        var cftTargetChartData = this.formatChartData(this.cftScores.target, 'bar', true);
        var cftTargetChartDataMid = this.formatChartData(this.cftScores.targetMid, 'bar', true);
        var cftTargetChartDataLow = this.formatChartData(this.cftScores.targetLow, 'bar', true);
        this.setChartData(pftActualChartData, pftTargetChartData, pftTargetChartDataMid, pftTargetChartDataLow, cftActualChartData, cftTargetChartData, cftTargetChartDataMid, cftTargetChartDataLow);
    };
    PftCftChartComponent.prototype.formatChartData = function (scores, type, isTarget) {
        var graphData = {
            x: [],
            xKey: {},
            y: [],
            barWidth: [],
            type: type,
        };
        if (type === 'scatter') {
            graphData.mode = 'lines+markers';
            var graphColor = isTarget ? '#D68C38' : '#51CDD7';
            graphData.marker = {
                color: graphColor,
                size: 10,
            };
        }
        // TODO: update data with PFT/CFT-relative scores
        Object.keys(scores)
            .sort()
            .forEach(function (dateKey) {
            var score = scores[dateKey];
            if (score) {
                var xLabel = moment(dateKey).format('M/D/YY');
                graphData.xKey[moment(dateKey).format('YYYY-MM-DD')] = xLabel;
                graphData.x.push(xLabel);
                graphData.y.push(score);
                if (type === 'bar') {
                    graphData.barWidth.push(1);
                }
            }
        });
        return graphData;
    };
    PftCftChartComponent.prototype.setChartData = function (pftActualScatterChartData, pftTargetScatterChartData, pftTargetScatterChartDataMid, pftTargetScatterChartDataLow, cftActualScatterChartData, cftTargetScatterChartData, cftTargetScatterChartDataMid, cftTargetScatterChartDataLow) {
        var categoryKeys = tslib_1.__assign({}, pftActualScatterChartData.xKey, pftTargetScatterChartData.xKey, cftActualScatterChartData.xKey, cftTargetScatterChartData.xKey);
        this.graph.layout.xaxis.categoryarray = Object.keys(categoryKeys)
            .sort()
            .map(function (x) { return categoryKeys[x]; });
        this.graph.data = [
            {
                x: pftActualScatterChartData.x,
                y: pftActualScatterChartData.y,
                name: 'Actual',
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: '#0181FA', size: 10 },
            },
            {
                x: pftTargetScatterChartData.x,
                y: pftTargetScatterChartData.y,
                name: 'Target (1st)',
                type: 'scatter',
                mode: 'lines+points',
                marker: { color: 'green' },
                line: {
                    dash: 'dash',
                },
            },
            {
                x: pftTargetScatterChartDataMid.x,
                y: pftTargetScatterChartDataMid.y,
                name: 'Target (2nd)',
                type: 'scatter',
                mode: 'lines+points',
                marker: { color: '#D68C38' },
                line: {
                    dash: 'dash',
                },
            },
            {
                x: pftTargetScatterChartDataLow.x,
                y: pftTargetScatterChartDataLow.y,
                name: 'Target (3rd)',
                type: 'scatter',
                mode: 'lines+points',
                marker: { color: '#c1121f' },
                line: {
                    dash: 'dash',
                },
            },
            {
                x: cftActualScatterChartData.x,
                y: cftActualScatterChartData.y,
                name: 'Actual',
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: 'lightgreen', size: 10 },
            },
        ];
    };
    return PftCftChartComponent;
}());
export { PftCftChartComponent };
