export class StartWorkout {
  static readonly type = '[Workout] Start'
  constructor(public workoutDocument: any, public measuredResponse: any) {}
}

export class PauseWorkout {
  static readonly type = '[Workout] Pause'
  constructor(public totalTime?: number) {}
}

export class ResumeWorkout {
  static readonly type = '[Workout] Resume'
  constructor() {}
}

export class EndWorkout {
  static readonly type = '[Workout] End'
  constructor(public totalTime: number) {}
}

export class StartCircuit {
  static readonly type = '[Workout] Start Circuit'
  constructor() {}
}
export class StopCircuit {
  static readonly type = '[Workout] Execute Circuit'
  constructor() {}
}

export class SetCircuitRounds {
  static readonly type = '[Workout] Set Circuit Rounds'
  constructor(public rounds: number, public tierIndex: number) {}
}

export class ExecuteSet {
  static readonly type = '[Workout] Execute Set'
  constructor(public setIndex: number, public paramValues: any) {}
}

export class EditSet {
  static readonly type = '[Workout] Edit Set'
  constructor(
    public tierIndex: number,
    public rowIndex: number,
    public setIndex: number,
    public paramValues: any,
    public completeTime?: string,
    public manual?: boolean
  ) {}
}

export class AddNewSet {
  static readonly type = '[Workout] Add New Set'
  constructor(public paramValues: any) {}
}

export class DeleteSet {
  static readonly type = '[Workout] Delete Set'
  constructor(public paramValues: any) {}
}

export class ChangeExercise {
  static readonly type = '[Workout] ChangeExercise'
  constructor(public prev: boolean) {}
}

export class SaveComments {
  static readonly type = '[Workout] Note'
  constructor(public comments: string, public tierIndex?: number, public rowIndex?: number) {}
}

export class SetExecutionsRM {
  static readonly type = '[Workout] Set Executions RM'
  constructor(public oneRM: any, public manual?: boolean) {}
}

export class SaveWorkout {
  static readonly type = '[Workout] Save Workout'
  constructor(public rpeScore: number, public totalTime: number) {}
}

export class ResetWorkoutSession {
  static readonly type = '[Workout] Reset Session'
}
