<div matDialogTitle>Change Password</div>

<div mat-dialog-content style="overflow: visible">
  <form #updatePasswordForm="ngForm" fxLayout="column" id="update-password">
    <mat-form-field>
      <input type="password" matInput placeholder="Old password" name="currentPassword" #currentPassword="ngModel"
        [(ngModel)]="updatePassword.current" required>
      <mat-error *ngIf="currentPassword.errors && (currentPassword.dirty || currentPassword.touched) && currentPassword.errors.required">
        Current password is <strong>required</strong>.
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input type="password" matInput placeholder="New password" name="newPassword" #newPassword="ngModel"
      [(ngModel)]="updatePassword.newPW" required (keyup)="updatePassword.confirmPW = ''" [minlength]="minPasswordLength">
      <mat-error *ngIf="newPassword.errors && (newPassword.dirty || newPassword.touched) && newPassword.errors.required">
        New password is <strong>required</strong>.
      </mat-error>
      <mat-error *ngIf="newPassword.errors && (newPassword.dirty || newPassword.touched) && newPassword.errors.minlength">
        New password should contain at least {{minPasswordLength}} characters.
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input type="password" matInput placeholder="Confirm new" name="confirmPassword" #confirmPassword="ngModel"
      [(ngModel)]="updatePassword.confirmPW" required [pattern]="getRegex(newPassword)">
        <mat-error *ngIf="confirmPassword.errors && (confirmPassword.dirty || confirmPassword.touched) && confirmPassword.errors.required">
          Please confirm your new password.
        </mat-error>
        <mat-error *ngIf="confirmPassword.errors && (confirmPassword.dirty || confirmPassword.touched) && confirmPassword.errors.pattern">
        The passwords do not match.
        </mat-error>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button [matDialogClose]>Cancel</button>
  <button mat-button [matDialogClose]="updatePassword" color="primary" [disabled]="updatePasswordForm.invalid">Update and save</button>
</div>
