import * as _ from 'lodash';
import * as moment from 'moment';
import { DatabaseRecord } from '../../classes/database-record.class';
import { SurveyDocument } from './survey-document.model';
import { v4 as uuid } from 'uuid'

export class SurveyTemplateDocument extends DatabaseRecord {
  static objectStoreName = 'surveyTemplateDocument';

  uuid: string;
  document: SurveyDocument;
  version: number;

  constructor (data: {} = {}) {
    super(data);
    this.uuid = data['uuid'] || uuid();
    this.document = new SurveyDocument(data['document']);
    this.version = data['version'];
  }

  toJSON () {
    return super.toJSON([
      'uuid',
      'document',
      'version'
    ]);
  }

  getDocument() {
      return this.getRecords(SurveyDocument);
  }
}
