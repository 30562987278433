export class AuthStateModel {
  midasToken?: string;
  lastLoginDate?: string;
  activeUser?: ActiveUser;
}

export class ActiveUser {
  uuid: string;
  confirmed: boolean;
  email_domain_approved: boolean;
  email: string;
  firstName: string;
  hasProfilePic: boolean;
  image_name: string;
  image_content_type: string;
  profilePicture?: any;
  lastName: string;
  rank: string;
  profile: any;
}
