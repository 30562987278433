import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map, mergeMap, share, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { SetActiveUser } from 'app/state/auth/auth.actions';
import { Store } from '@ngxs/store';
import { environment } from '../../environments/environment';
import { of } from 'rxjs';
export function applySubstitutions(url) {
    return url.replace(/{{HOSTNAME}}/gi, location.hostname);
}
var AuthService = /** @class */ (function () {
    function AuthService(route, router, httpClient, store, sanitizer) {
        this.route = route;
        this.router = router;
        this.httpClient = httpClient;
        this.store = store;
        this.sanitizer = sanitizer;
        this.currentUser = {};
        this.isLoggedIn = false;
        this.approvedEmailDomains = [];
    }
    AuthService.prototype.isAuthenticated = function () {
        if (localStorage.getItem('midas-token')) {
            this.isLoggedIn = true;
            return true;
        }
        return false;
    };
    AuthService.prototype.getToken = function () {
        return localStorage.getItem('midas-token');
    };
    AuthService.prototype.getUsername = function () {
        return localStorage.getItem('midas-username');
    };
    AuthService.prototype.getUserUUID = function () {
        return localStorage.getItem('midas-userUUID');
    };
    AuthService.prototype.getCurrentUser = function () {
        var storedUser = JSON.parse(localStorage.getItem('midas-currentUser'));
        if (storedUser !== null) {
            return storedUser;
        }
        return this.currentUser;
    };
    AuthService.prototype.login = function (username, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var results;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.post(this.buildRoute('api/Auth/login'), {
                            email: username,
                            password: password
                        }).toPromise()];
                    case 1:
                        results = _a.sent();
                        if (!!results.error) return [3 /*break*/, 3];
                        this.setMidasTokens(username, results);
                        return [4 /*yield*/, this.fetchLoginStatus()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/, results];
                }
            });
        });
    };
    AuthService.prototype.setMidasTokens = function (username, results) {
        this.username = username;
        this.authToken = results.auth_token;
        this.userUUID = results.user_uuid;
        this.isLoggedIn = true;
        if (this.isDifferentUser(username)) {
            localStorage.removeItem('last-sync');
        }
        localStorage.setItem('midas-userUUID', this.userUUID);
        localStorage.setItem('midas-username', username);
        localStorage.setItem('midas-token', results.auth_token);
    };
    AuthService.prototype.isDifferentUser = function (username) {
        return this.getUsername() !== username;
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        var logoutObs = this.httpClient.post(this.buildRoute('/api/Auth/logout'), {
            responseType: 'json'
        }).pipe(share(), catchError(function (err) { return of({}); }));
        logoutObs.subscribe(function () {
            _this.setLoggedOut();
        });
        return logoutObs;
    };
    AuthService.prototype.setLoggedOut = function () {
        this.isLoggedIn = false;
        localStorage.removeItem('last-sync');
        localStorage.removeItem('last-sync-attempt');
        localStorage.removeItem('midas-username');
        localStorage.removeItem('midas-token');
        localStorage.removeItem('midas-userUUID');
        localStorage.removeItem('midas-currentUser');
        return of({});
    };
    AuthService.prototype.fetchLoginStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, _a, user, auth_token, e_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        token = this.getToken();
                        if (!token) return [3 /*break*/, 6];
                        if (!window.navigator.onLine) return [3 /*break*/, 5];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.httpClient.get(this.buildRoute('/api/Auth/status'), {
                                responseType: 'json'
                            }).toPromise()];
                    case 2:
                        _a = _b.sent(), user = _a.user, auth_token = _a.auth_token;
                        if (user['email'] && user['email'].toLowerCase() === this.getUsername().toLowerCase()) {
                            this.isLoggedIn = true;
                            localStorage.setItem('midas-token', auth_token);
                            this.setActiveUser(user);
                            return [2 /*return*/, true];
                        }
                        else {
                            return [2 /*return*/, false];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        return [2 /*return*/, false];
                    case 4: return [3 /*break*/, 6];
                    case 5: 
                    // Can't check status online, assume logged in
                    return [2 /*return*/, true];
                    case 6: return [2 /*return*/, false];
                }
            });
        });
    };
    AuthService.prototype.setActiveUser = function (status) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.currentUser = {
                            uuid: status.uuid || this.currentUser.uuid,
                            confirmed: status.confirmed || this.currentUser.confirmed,
                            email_domain_approved: status.email_domain_approved || this.currentUser.email_domain_approved,
                            email: status.email || this.currentUser.email,
                            firstName: status.firstname || this.currentUser.firstName,
                            hasProfilePic: !!status.image_name || this.currentUser.hasProfilePic,
                            profilePicture: status.profilePicture || this.currentUser.profilePicture,
                            image_name: status.image_name || this.currentUser.image_name,
                            image_content_type: status.image_content_type || this.currentUser.image_content_type,
                            lastName: status.lastname || this.currentUser.lastName,
                            rank: status.rank,
                            profile: (this.currentUser && this.currentUser.profile && this.currentUser.uuid === status.uuid) ? this.currentUser.profile : {},
                        };
                        localStorage.setItem('midas-currentUser', JSON.stringify(this.currentUser));
                        return [4 /*yield*/, this.store.dispatch(new SetActiveUser(this.currentUser)).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.sendVerificationEmail = function (email) {
        return this.httpClient.post(this.buildRoute('/api/Auth/sendVerificationEmail'), { email: email });
    };
    AuthService.prototype.saveActiveUser = function (route, userData) {
        var _this = this;
        return this.httpClient.put(this.buildRoute(route), userData).pipe(mergeMap(function () { return _this.saveUserImage(userData.profilePicture); }), tap(function () { return _this.setActiveUser(userData); }));
    };
    AuthService.prototype.saveUserImage = function (profilePicture) {
        if (profilePicture) {
            var formData = new FormData();
            formData.append('file', profilePicture.requestData);
            return this.httpClient.post(this.buildRoute('/api/User/' + this.currentUser.uuid + '/image'), formData);
        }
        else {
            return of([]);
        }
    };
    /**
     * Get the profile picture for the user from server.
     *
     * @param  {} userUUID - id of the user
     */
    AuthService.prototype.getUserImage = function (userUUID) {
        var _this = this;
        return this.httpClient.get(this.buildRoute('/api/User/' + userUUID + '/image'), {
            responseType: 'blob'
        }).pipe(map(function (data) {
            var status = {
                profilePicture: _this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(data)),
            };
            return status;
        }));
    };
    AuthService.prototype.getApprovedDomains = function () {
        var _this = this;
        var url = this.buildRoute('api/Auth/approveddomains');
        return this.httpClient.get(url)
            .pipe(map(function (res) {
            _this.approvedEmailDomains = res;
        }));
    };
    AuthService.prototype.buildRoute = function (route) {
        route = route.replace(/^\//, '');
        return applySubstitutions(environment.api_url) + route;
    };
    /**
   * Change the password of the account.
   *
   * @param  {string} old - old password
   * @param  {string} newPW - new password
   */
    AuthService.prototype.changePassword = function (old, newPW) {
        return this.httpClient.post(applySubstitutions(environment.api_url) + 'api/Auth/password', {
            oldPassword: old,
            password: newPW
        }).pipe(map(function (res) {
            localStorage.setItem('midas-token', res['auth_token']);
            return res;
        }), catchError(function (e) {
            return observableThrowError(e.message || 'Server error');
        }));
    };
    return AuthService;
}());
export { AuthService };
