import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { WorkoutState } from 'app/state/workout/workout.state';
import * as _ from 'lodash';
import { ExerciseProgramDocumentUtils, getDefaultParams } from 'fitforce-document-sync';
import { DocumentService } from 'app/services/document.service';
import { parseInt as _parseInt } from 'lodash';
var EditSetDialogComponent = /** @class */ (function () {
    function EditSetDialogComponent(store, documentService, dialogRef, data) {
        this.store = store;
        this.documentService = documentService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.tierIndex = data.tierIndex;
        this.rowIndex = data.rowIndex;
        this.setIndex = data.setIndex;
        // data.forComplete
    }
    EditSetDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        var currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession);
        this.setParamValues = _.cloneDeep(currentWorkoutSession.workout.tiers[this.tierIndex].rows[this.rowIndex].executions[this.setIndex].parameter_values);
        this.setExecution = currentWorkoutSession.workout.tiers[this.tierIndex].rows[this.rowIndex].executions[this.setIndex];
        var exerciseReference = currentWorkoutSession.workout.tiers[this.tierIndex].rows[this.rowIndex].exercise;
        var exercise = exerciseReference ? this.documentService.store.exerciseProgram.document.exercises.find(function (ex) { return ex.id === exerciseReference.exercise_program_id; }) : undefined;
        if (exercise) {
            this.setParams = ExerciseProgramDocumentUtils.getPossibleParamsForExercise(currentWorkoutSession.workout.tiers[this.tierIndex].rows[this.rowIndex].exercise.exercise_program_id, this.documentService.store.exerciseProgram.document);
        }
        else {
            this.setParams = getDefaultParams();
        }
        _.each(this.setParamValues, function (value, key) {
            if (!_this.hasParam(key) && key !== '1rm') {
                delete (_this.setParamValues[key]);
            }
        });
        if (this.hasParam('load') && !this.setParamValues['load']) {
            this.setParamValues['load'] = undefined;
        }
        if (this.data.forComplete && this.setExecution.complete_time) {
            this.isComplete = true;
        }
        else {
            this.isComplete = false;
        }
        delete (this.setParamValues['1rm']);
    };
    EditSetDialogComponent.prototype.cancelSelected = function () {
        this.dialogRef.close({
            success: false
        });
    };
    EditSetDialogComponent.prototype.submitSelected = function () {
        if (!this.setParamValues) {
            this.cancelSelected();
            return;
        }
        var _loop_1 = function (param) {
            if (param in this_1.setParamValues) {
                var config = this_1.setParams.find(function (p) { return p.param === param; });
                if (config && config.type === 'number') {
                    this_1.setParamValues[param] = Number.parseInt(this_1.setParamValues[param], 10);
                }
            }
        };
        var this_1 = this;
        for (var param in this.setParamValues) {
            _loop_1(param);
        }
        this.dialogRef.close({
            success: true,
            setParamValues: this.setParamValues,
            isComplete: this.isComplete,
        });
    };
    EditSetDialogComponent.prototype.trackByFn = function (index, item) {
        return index;
    };
    EditSetDialogComponent.prototype.hasParam = function (param) {
        var exists = false;
        if (param === '1rm') {
            return true;
        }
        _.each(this.setParams, function (v) {
            if (v.param === param) {
                exists = true;
                return true;
            }
        });
        return exists;
    };
    EditSetDialogComponent.prototype.getMinutes = function () {
        if (this.setParamValues['time']) {
            return Math.floor(this.setParamValues['time'] / 60);
        }
        return null;
    };
    EditSetDialogComponent.prototype.getSeconds = function () {
        if (this.setParamValues['time']) {
            return this.setParamValues['time'] - (Math.floor(this.setParamValues['time'] / 60) * 60);
        }
        return null;
    };
    EditSetDialogComponent.prototype.updateMinutes = function ($event) {
        var mins = $event.target.value;
        var secs = this.setParamValues['time']
            ? _parseInt(this.setParamValues['time']) - (Math.floor(_parseInt(this.setParamValues['time']) / 60) * 60)
            : 0;
        this.setParamValues['time'] = (mins * 60) + secs;
    };
    EditSetDialogComponent.prototype.updateSeconds = function ($event) {
        var mins = this.setParamValues['time']
            ? Math.floor(_parseInt(this.setParamValues['time']) / 60) * 60
            : 0;
        var secs = $event.target.value;
        this.setParamValues['time'] = mins + _parseInt(secs);
    };
    return EditSetDialogComponent;
}());
export { EditSetDialogComponent };
