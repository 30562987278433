import * as _ from 'lodash'
import { DatabaseRecord } from '../classes/database-record.class';

export class User extends DatabaseRecord {
  static objectStoreName = 'users'

  name: string
  email: string
  firstName: string
  lastName: string
  rank: string
  isAdmin: boolean
  midas_username: string
  midas_token: string

  constructor (props: {} = {}) {
    super(props)
    this.email = props['email']
    this.firstName = props['firstName']
    this.lastName = props['lastName']
    this.name = props['name'] || _.join([this.firstName, this.lastName], ' ')
    this.rank = props['rank'] || ''
    this.isAdmin = props['isAdmin'] || false
    this.midas_username = props['midas_username']
    this.midas_token = props['midas_token']
  }

  toJSON () {
    return super.toJSON(['name', 'email', 'firstName', 'lastName', 'rank', 'isAdmin', 'midas_username'])
  }
}
