import * as tslib_1 from "tslib";
import { DatabaseRecord } from '../classes/database-record.class';
var PlanExercise = /** @class */ (function (_super) {
    tslib_1.__extends(PlanExercise, _super);
    function PlanExercise(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.isComplete = false;
        _this.name = data['name'];
        _this.sets = data['sets'];
        _this.distance = data['distance'];
        _this.time = data['time'];
        _this.load = data['load'];
        _this.reps = data['reps'];
        _this.hold = data['hold'];
        _this.rowNum = data['rowNum'];
        _this.notes = data['notes'];
        _this.regression = data['regression'];
        _this.video = data['video'];
        return _this;
    }
    PlanExercise.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, [
            'name',
            'sets',
            'distance',
            'time',
            'load',
            'reps',
            'hold',
            'rowNum',
            'notes',
            'regression',
            'isComplete',
            'setsCompleted',
            'distanceCompleted',
            'timeCompleted',
            'loadCompleted',
            'repsCompleted',
            'video',
        ]);
    };
    PlanExercise.objectStoreName = 'plannedExercises';
    return PlanExercise;
}(DatabaseRecord));
export { PlanExercise };
