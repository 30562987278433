import * as tslib_1 from "tslib";
import { State, Action, Selector } from '@ngxs/store';
import { AuthStateModel } from './auth.model';
import { AuthService } from 'app/services/auth.service';
import { Signin, Signout, SetActiveUser } from './auth.actions';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
var AuthState = /** @class */ (function () {
    function AuthState(authService) {
        this.authService = authService;
    }
    AuthState.token = function (state) {
        return state.midasToken;
    };
    AuthState.isAuthenticated = function (state) {
        return !!state.midasToken;
    };
    AuthState.activeUser = function (state) {
        return state.activeUser;
    };
    AuthState.prototype.login = function (_a, _b) {
        var patchState = _a.patchState, dispatch = _a.dispatch;
        var email = _b.email, password = _b.password;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loginStatus;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: 
                    // Signout before attempting a login
                    return [4 /*yield*/, dispatch(new Signout()).toPromise()];
                    case 1:
                        // Signout before attempting a login
                        _c.sent();
                        return [4 /*yield*/, this.authService.login(email, password)];
                    case 2:
                        loginStatus = _c.sent();
                        if (loginStatus && loginStatus.auth_token) {
                            patchState({
                                midasToken: loginStatus.auth_token,
                                lastLoginDate: moment().format()
                            });
                        }
                        return [2 /*return*/, loginStatus];
                }
            });
        });
    };
    AuthState.prototype.logout = function (_a) {
        var setState = _a.setState;
        return this.authService.logout().pipe(tap(function (_) {
            setState({
                activeUser: undefined
            });
        }));
    };
    AuthState.prototype.setActiveUser = function (_a, _b) {
        var patchState = _a.patchState;
        var activeUser = _b.activeUser;
        patchState({
            activeUser: activeUser
        });
    };
    tslib_1.__decorate([
        Action(Signin),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Signin]),
        tslib_1.__metadata("design:returntype", Promise)
    ], AuthState.prototype, "login", null);
    tslib_1.__decorate([
        Action(Signout),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AuthState.prototype, "logout", null);
    tslib_1.__decorate([
        Action(SetActiveUser),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetActiveUser]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AuthState.prototype, "setActiveUser", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AuthStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AuthState, "token", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AuthStateModel]),
        tslib_1.__metadata("design:returntype", Boolean)
    ], AuthState, "isAuthenticated", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [AuthStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AuthState, "activeUser", null);
    AuthState = tslib_1.__decorate([
        State({
            name: 'auth',
            defaults: {
                activeUser: undefined
            }
        }),
        tslib_1.__metadata("design:paramtypes", [AuthService])
    ], AuthState);
    return AuthState;
}());
export { AuthState };
