import * as moment from 'moment'
import * as _ from 'lodash'
import { DatabaseRecord } from '../classes/database-record.class'
import { Plan } from './plan.model'
import { User } from './user.model'

export class Group extends DatabaseRecord {
  static objectStoreName = 'groups'

  name: string
  private: boolean
  owners: any[]
  created: moment.Moment

  constructor (data: {} = {}) {
    super(data)
    this.name = data['name'] || ''
    this.private = data['private'] || false
    this.plans = data['plans']
    this.users = data['users']
    this.owners = data['owners']
    this.created = DatabaseRecord.parseDate(data['created'])
  }

  toJSON () {
    return super.toJSON(['name', 'plans', 'users', 'private', 'owners', 'created'])
  }

  get plans () {
    return this.getForeignKeys(Plan)
  }

  set plans (records: any[]) {
    this.setForiegnRecords(Plan, records)
  }

  get users () {
    return this.getForeignKeys(User)
  }

  set users (users: any[]) {
    this.setForiegnRecords(User, users)
  }
}
