<content *ngIf="workoutDocument">
  <header fxLayout="column">
    <div fxLayout="row"  fxLayoutAlign="center">
      <h2>{{ workoutDocument.name }}</h2>
    </div>
    <div class="workout-estimated">
      <div fxLayout="row">
        <div fxFlex fxLayout="column" fxLayoutAlign="center center">
          <div class="card-footer-label">Duration</div>
          <div class="card-footer-value">{{ workoutDocument.est_completion_time_ms | msToTime }}</div>
        </div>
        <div fxFlex></div>
        <div fxFlex fxLayout="column" fxLayoutAlign="center center">
          <div class="card-footer-label">Difficulty</div>
          <div class="card-footer-value">{{ workoutDocument.est_rpe ? repStrings[workoutDocument.est_rpe]: 'N/A' }}</div>
        </div>
        <div fxFlex></div>
        <div fxFlex fxLayout="column" fxLayoutAlign="center center">
          <div class="card-footer-label">Load</div>
          <div class="card-footer-value">{{ load }}</div>
        </div>
      </div>
    </div>
  </header>
  <main>
    <mat-card class="card-spacer" *ngFor="let tier of workoutDocument.tiers" fxLayout="column" fxLayoutGap="10px">
      <h4 style="font-size: 1.2rem;">Tier {{ tier.order }}: {{ tier.name }}</h4>
      <div>{{ tier.notes }}</div>
      <div fxLayout="column" fxLayoutGap="10px" *ngIf="tier.rows">
        <div fxLayout="row" fxLayoutGap="10px" *ngFor="let row of tier.rows">
          <div fxLayoutAlign="center center">
            <span *ngIf="row.video; else no_video">
              <mat-icon class="video-icon" (click)="openVideo(row.video)" color="primary">play_circle_filled</mat-icon>
            </span>
            <ng-template #no_video>
              <mat-icon class="video-icon disabled-icon">play_circle_outline</mat-icon>
            </ng-template>
          </div>
          <div fxLayoutAlign="center center" fxLayout="column">
            <div class="exercise-name">{{ row.exercise_name }}</div>
          </div>
        </div>
      </div>
    </mat-card>
  </main>
  <footer>
    <button mat-button (click)="goBack()">BACK</button>
    <span fxFlex></span>
    <button mat-raised-button (click)="startExercise()" color="primary">START</button>
  </footer>
</content>
