import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-programs-search',
  templateUrl: './programs-search.component.html',
  styleUrls: ['./programs-search.component.css']
})
export class ProgramsSearchComponent implements OnInit {
  @Input() searchText: string
  @Output() searchTextChanged = new EventEmitter<string>()

  constructor() { }

  ngOnInit() {
  }

  onSearchTextChanged() {
    this.searchTextChanged.emit(this.searchText);
  }

}
