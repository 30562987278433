import { Component, OnInit, Input, Inject } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent implements OnInit {
  public title: string
  public trustedUrl: SafeUrl

  constructor(
    public dialogRef: MatDialogRef<VideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    this.title = this.data.title
  }

  ngOnInit() {
    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.dvidshub.net/video/embed/' + this.data.videoId)
  }
}
