/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exercise-set-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "./exercise-set-item.component";
import * as i6 from "@ngxs/store";
import * as i7 from "../../../services/document.service";
import * as i8 from "../../../pipes/sets-reps-load.pipe";
var styles_ExerciseSetItemComponent = [i0.styles];
var RenderType_ExerciseSetItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExerciseSetItemComponent, data: {} });
export { RenderType_ExerciseSetItemComponent as RenderType_ExerciseSetItemComponent };
export function View_ExerciseSetItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"], ["fxLayoutGap", "5px"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵted(4, null, [" ", "\n"]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "5px"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "center center"; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.setDetails; _ck(_v, 4, 0, currVal_3); }); }
export function View_ExerciseSetItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-exercise-set-item", [], null, null, null, View_ExerciseSetItemComponent_0, RenderType_ExerciseSetItemComponent)), i1.ɵdid(1, 114688, null, 0, i5.ExerciseSetItemComponent, [i6.Store, i7.DocumentService, i8.SetsRepsLoadPipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExerciseSetItemComponentNgFactory = i1.ɵccf("app-exercise-set-item", i5.ExerciseSetItemComponent, View_ExerciseSetItemComponent_Host_0, { isHistory: "isHistory", workoutSession: "workoutSession", hasRM: "hasRM", currentWorkoutIndex: "currentWorkoutIndex", currentRowIndex: "currentRowIndex", setIndex: "setIndex", execution: "execution" }, {}, []);
export { ExerciseSetItemComponentNgFactory as ExerciseSetItemComponentNgFactory };
