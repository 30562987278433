/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rmcalc-set-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/flex-layout/flex";
import * as i9 from "@angular/flex-layout/core";
import * as i10 from "./rmcalc-set-dialog.component";
var styles_RMCalcSetDialogComponent = [i0.styles];
var RenderType_RMCalcSetDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RMCalcSetDialogComponent, data: {} });
export { RenderType_RMCalcSetDialogComponent as RenderType_RMCalcSetDialogComponent };
export function View_RMCalcSetDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i3.MatDialogTitle, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["1RM Calculated"])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["Your 1RM for ", " is ", " lbs."])), i1.ɵppd(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The FitForce app will automatically adjust this based on your workouts and performance."])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["fxFlex", ""], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(15, 671744, null, 0, i8.DefaultFlexDirective, [i1.ElementRef, i9.StyleUtils, i9.LAYOUT_CONFIG, i8.FlexStyleBuilder, i9.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_5 = ""; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.exerciseName; var currVal_2 = i1.ɵunv(_v, 7, 1, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.data.results["1rm"], "1.0-0")); _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = (i1.ɵnov(_v, 14).disabled || null); var currVal_4 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_3, currVal_4); }); }
export function View_RMCalcSetDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rmcalc-set-dialog", [], null, null, null, View_RMCalcSetDialogComponent_0, RenderType_RMCalcSetDialogComponent)), i1.ɵdid(1, 49152, null, 0, i10.RMCalcSetDialogComponent, [i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], null, null); }
var RMCalcSetDialogComponentNgFactory = i1.ɵccf("app-rmcalc-set-dialog", i10.RMCalcSetDialogComponent, View_RMCalcSetDialogComponent_Host_0, {}, {}, []);
export { RMCalcSetDialogComponentNgFactory as RMCalcSetDialogComponentNgFactory };
