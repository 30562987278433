import { Component, OnInit } from '@angular/core'
import { DataService } from 'app/services/data.service'
import * as _ from 'lodash'
import * as moment from 'moment'

@Component({
  selector: 'app-training-load-chart',
  templateUrl: './training-load-chart.component.html',
  styleUrls: ['./training-load-chart.component.scss']
})
export class TrainingLoadChartComponent implements OnInit {
  /*
  * PLOTLY NOTES:
  *
  * Hover label of Trace (Legend) is white... https://community.plotly.com/t/trace-hover-label-color/12584
  * Merging dates into Months require a different data structure
  * No way to hide week numbers (the main axis used to separate out dates)
  * No way to merge a scatter+line plot WITH a multiaxis bar chart
  */
  graph: any = {
    layout: {
      showlegend: false,
      height: 140,
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      xaxis: {
        title: 'Week Number',
        titlefont: {
          size: 12,
          color: '#fff'
        },
        showticklabels: true,
        tickmode: 'array',
        tickangle: '-30',
        tickfont: {
          size: 10,
          color: '#FFFFFF'
        },
        showgrid: false,
      },
      yaxis: {
        showticklabels: true,
        tickfont: {
          size: 10,
          color: '#FFFFFF'
        },
        showgrid: false,
      },
      margin: {
        l: 30,
        r: 0,
        b: 40,
        t: 5,
      },
      hoverlabel: {
        bgcolor: '#666666',
        font: { color: '#FFFFFF' }
      }
    },
    config: {
      displayModeBar: false,
      responsive: true,
    },
  }

  // Stored by YYYY-MM
  averages: {
    actual: {},
    target: {},
  }

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.init()
  }

  async init() {
    // Stores averages separated by year-week
    this.averages = await this.getAverages()
    this.setChart()
  }

  async getAverages() {
    const allWorkouts = await this.getWorkoutSessions()
    const reportedAverages = {}
    const estimatedAverages = {}
    _.each(allWorkouts, session => {
      const endDate = moment(session.document.end_time)
      const trainingLoad = session.document.training_load;
      const estTrainingLoad = session.document.est_training_load;
      const dateKey = endDate.startOf('week').format('YYYY-MM-DD')
      reportedAverages[dateKey] = this.newAverage(endDate, reportedAverages[dateKey], trainingLoad)
      estimatedAverages[dateKey] = this.newAverage(endDate, estimatedAverages[dateKey], estTrainingLoad)
    })

    return {
      actual: reportedAverages,
      target: estimatedAverages,
    }
  }

  async getWorkoutSessions() {
    const documents = await this.dataService.getAllWorkoutSessionDocuments()
    // Grab only the last 3 months
    const sessions = _.filter(documents, (doc: any) => {
      const currentDate = moment().startOf('day').hour(12)
      if (doc.document.end_time) {
        const end_time = moment(doc.document.end_time)
        if (currentDate.diff(end_time, 'months') <= 3) {
          return doc
        }
      }
    })
    return sessions
  }

  newAverage(endDate, average, rpe) {
    // rpe = Math.random() * 100
    const month = endDate.format('MMM')
    const weekOfMonth = average ? average.weekOfMonth : this.weekOfMonth(endDate)
    if (!average) {
      return {
        month,
        weekOfMonth,
        totalScore: rpe,
        count: 1,
        avg: rpe
      }
    }
    const newScore = average.totalScore + rpe
    const newCount = average.count + 1

    return {
      month,
      weekOfMonth,
      totalScore: newScore,
      count: newCount,
      avg: Math.floor(newScore / newCount)
    }
  }

  weekOfMonth(m) {
    return Math.ceil(m.date() / 7 )
  }

  setChart() {
    const barChartData = this.formatChartData(this.averages.actual, 'bar')
    const scatterChartData = this.formatChartData(this.averages.target, 'scatter')
    this.setChartData(barChartData, scatterChartData)
  }

  formatChartData(averages, type): any {
    const graphData: any = {
      x: [], xKey: {}, y: [], barWidth: [], type: type
    }

    if (type === 'scatter') {
      graphData.mode = 'line+points',
      graphData.marker = {
        color: '#D68C38'
      }
    }
    Object.keys(averages).sort().forEach(dateKey => {
      const average = averages[dateKey].avg
      if (average) {
        const xLabel = moment(dateKey).format('M/D/YY')
        graphData.xKey[moment(dateKey).format('YYYY-MM-DD')] = xLabel
        graphData.x.push(xLabel)
        graphData.y.push(average)
        graphData.barWidth.push(.5)
      }
    })

    return graphData
  }

  setChartData(barChartData, scatterChartData) {
    const categoryKeys = { ...barChartData.xKey, ...scatterChartData.xKey }
    this.graph.layout.xaxis.categoryarray = Object.keys(categoryKeys).sort().map(x => categoryKeys[x])
    this.graph.data = [{
      x: scatterChartData.x,
      y: scatterChartData.y,
      name: 'Target',
      type: 'scatter',
      mode: 'lines+markers',
      marker: { color: '#D68C38', size: 12 },
    }, {
      x: barChartData.x,
      y: barChartData.y,
      width: barChartData.barWidth,
      type: 'bar',
      name: 'Actual',
      marker: {
        color: '#51CDD7', size: 12
      },
    }]
  }
}
