<content>
  <header>
    <mat-card fxLayout="column">
      <h2>Pre-workout Survey</h2>
    </mat-card>
  </header>
  <main>
    <div>
      <p>{{surveyConfiguration.description}}</p>
    </div>
    <div *ngIf="measures">
      <form [formGroup]="form" fxLayout="column">
        <div *ngFor="let measure of measures" class="">
          <ng-container *ngIf="measure.options; else defaultInput">
            <mat-form-field class="input-time" appearance="fill" fxFlex>
              <mat-label>{{ measure.description }}</mat-label>
                <mat-select formControlName="{{ measure.id }}" panelClass="wkt-survey-option-select">
                  <mat-option *ngFor="let option of measure.options" [value]="option.value">{{ option.description }}</mat-option>
                </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-template #defaultInput>
            <app-preworkout-survey-question [form]="form" [measure]="measure"></app-preworkout-survey-question>
          </ng-template>
        </div>
      </form>
    </div>
  </main>
  <footer fxLayout="row" fxLayoutGap="space-between">
    <button mat-button (click)="goBack()">BACK</button>
    <span fxFlex></span>
    <button mat-raised-button (click)="startExercise()" color="primary">CONTINUE</button>
  </footer>
</content>
