import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { Select, Store } from '@ngxs/store';
import { AppState } from 'app/state/app/app.state';
import { AuthState } from 'app/state/auth/auth.state';
import { DataService } from 'app/services/data.service';
import { LeaveDialogComponent } from 'app/modules/group-management/leave-dialog/leave-dialog.component';
import { LeaveGroup } from 'app/state';
import { Observable } from 'rxjs';
var ProgramsJoinedComponent = /** @class */ (function () {
    function ProgramsJoinedComponent(store, dataService, dialog) {
        this.store = store;
        this.dataService = dataService;
        this.dialog = dialog;
        this.pageSize = 10;
        this.isOnline = false;
    }
    ProgramsJoinedComponent.prototype.ngOnInit = function () {
        var _this = this;
        var activeUser = this.store.selectSnapshot(AuthState.activeUser);
        this.userUUID = activeUser.uuid;
        this.isOnline$.subscribe(function (isOnline) {
            _this.isOnline = isOnline;
        });
        this.joinedGroups$.subscribe(function (groups) {
            _this.listGroups();
        });
    };
    ProgramsJoinedComponent.prototype.listGroups = function () {
        this.groups = [];
        this.initIndices();
        this.fetchJoinedGroups();
    };
    ProgramsJoinedComponent.prototype.fetchJoinedGroups = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var groups, sortedGroups;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getGroups().toPromise()];
                    case 1:
                        groups = _a.sent();
                        sortedGroups = _.sortBy(groups, function (g) { return g.name.toLowerCase(); });
                        _.each(sortedGroups, function (group) {
                            _this.groups.push(group);
                        });
                        this.groups = _.uniqBy(this.groups, function (group) { return group.key; });
                        this.totalGroups = this.groups.length;
                        this.filteredGroups = this.groups;
                        return [2 /*return*/, this.groups];
                }
            });
        });
    };
    ProgramsJoinedComponent.prototype.initIndices = function () {
        if (this.paginator) {
            this.paginator.pageIndex = 0;
        }
        this.startIndex = 0;
        this.stopIndex = this.pageSize;
    };
    ProgramsJoinedComponent.prototype.onPageEvent = function ($event) {
        var pageIndex = $event.pageIndex;
        if (pageIndex) {
            this.startIndex = pageIndex * this.pageSize;
            this.stopIndex = this.startIndex + this.pageSize - 1;
        }
        else {
            this.initIndices();
        }
    };
    ProgramsJoinedComponent.prototype.onSearchEvent = function (searchText) {
        var _this = this;
        this.initIndices();
        this.searchText = searchText;
        this.filteredGroups = this.searchText.trim() === '' ? this.groups
            : this.groups.filter(function (g) { return g.name.toLowerCase().includes(_this.searchText.toLowerCase()); });
        this.totalGroups = this.filteredGroups.length;
    };
    ProgramsJoinedComponent.prototype.openLeaveDialog = function (group) {
        var _this = this;
        var dialogRef = this.dialog.open(LeaveDialogComponent, {
            panelClass: 'modal-leaveConfirm',
        });
        dialogRef.afterClosed().subscribe(function (isLeaving) {
            if (isLeaving) {
                _this.requestLeave(group);
            }
        });
    };
    ProgramsJoinedComponent.prototype.requestLeave = function (group) {
        var _this = this;
        this.store.dispatch(new LeaveGroup(group)).subscribe(function () {
            _this.searchText = '';
        });
    };
    tslib_1.__decorate([
        Select(AppState.isOnline),
        tslib_1.__metadata("design:type", Observable)
    ], ProgramsJoinedComponent.prototype, "isOnline$", void 0);
    tslib_1.__decorate([
        Select(AppState.joinedGroups),
        tslib_1.__metadata("design:type", Observable)
    ], ProgramsJoinedComponent.prototype, "joinedGroups$", void 0);
    return ProgramsJoinedComponent;
}());
export { ProgramsJoinedComponent };
