/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exercise.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./subexercise/subexercise.component.ngfactory";
import * as i10 from "./subexercise/subexercise.component";
import * as i11 from "@ngxs/store";
import * as i12 from "../../services/document.service";
import * as i13 from "@angular/material/dialog";
import * as i14 from "@angular/material/snack-bar";
import * as i15 from "../../pipes/sets-reps-load.pipe";
import * as i16 from "./exercise.component";
import * as i17 from "@angular/router";
import * as i18 from "../../services/page-transition.service";
var styles_ExerciseComponent = [i0.styles];
var RenderType_ExerciseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExerciseComponent, data: {} });
export { RenderType_ExerciseComponent as RenderType_ExerciseComponent };
export function View_ExerciseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "content", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "exercise-time"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["TIME ELAPSED"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "subtext"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "exercise-count"], ["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(8, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["STEP"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "subtext"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", " of ", ""])), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "exercise-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["color", "warn"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.endWorkout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["FINISH"])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-subexercise", [], null, null, null, i9.View_SubexerciseComponent_0, i9.RenderType_SubexerciseComponent)), i1.ɵdid(20, 114688, null, 0, i10.SubexerciseComponent, [i11.Store, i12.DocumentService, i13.MatDialog, i14.MatSnackBar, i15.SetsRepsLoadPipe], null, null)], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 8, 0, currVal_1); var currVal_6 = "warn"; _ck(_v, 16, 0, currVal_6); _ck(_v, 20, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timeElapsed; _ck(_v, 6, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(_co.currentExerciseCount$)); var currVal_3 = _co.currentWorkoutSession.total_exercises; _ck(_v, 12, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 16).disabled || null); var currVal_5 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_4, currVal_5); }); }
export function View_ExerciseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-exercise", [], null, [["window", "beforeunload"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).beforeUnloadHander($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ExerciseComponent_0, RenderType_ExerciseComponent)), i1.ɵdid(1, 245760, null, 0, i16.ExerciseComponent, [i11.Store, i12.DocumentService, i17.Router, i11.Actions, i18.PageTransitionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExerciseComponentNgFactory = i1.ɵccf("app-exercise", i16.ExerciseComponent, View_ExerciseComponent_Host_0, {}, {}, []);
export { ExerciseComponentNgFactory as ExerciseComponentNgFactory };
