import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, Select, ofActionSuccessful } from '@ngxs/store'
import { DocumentService } from 'app/services/document.service'
import { AppState } from 'app/state/app/app.state'
import { Observable, Subscription } from 'rxjs'
import * as _ from 'lodash'
import * as moment from 'moment'
import { Set1RM } from 'app/state';

@Component({
  selector: 'app-rm-chart',
  templateUrl: './rm-chart.component.html',
  styleUrls: ['./rm-chart.component.scss']
})

export class RmChartComponent implements OnInit, OnDestroy {

  @Select(AppState.selectedRM)
  selectedRM$: Observable<any>
  _subscription: Subscription
  selectedRM: any

  observations: any

  graph: any = {
    config: {
      displayModeBar: false,
      responsive: true,
    },
  }

  constructor(
    private documentService: DocumentService,
    private actions$: Actions
  ) {
    this.actions$
      .pipe(ofActionSuccessful(Set1RM))
      .subscribe(() => {
        this._subscription.unsubscribe();
        this.setEmptyLayout()
        this.init()
      });
  }

  ngOnInit() {
    this.setEmptyLayout()
    this.init()
  }

  async init() {
    await this.documentService.restoreData().toPromise()
    this.observations = this.documentService.store.observations
    this._subscription = this.selectedRM$.subscribe(exercise => {
      if (exercise) {
        this.selectedRM = exercise
        // Find all observations with this exercise
        const exerciseObservations = this.observations.filter((o: any) => {
          return o.document.result.exercise_id === exercise.id
        })

        if (exerciseObservations.length > 0) {
          this.setChartData(exerciseObservations)
          this.setLayout()
        } else {
          this.setEmptyLayout('No data for this exercise.')
        }
      }
    })
  }

  setChartData(exerciseObservations: any) {
   // Sort by date
   const sortedObservations = _.orderBy(exerciseObservations, (o) => {
     return o.document.timestamp
   }, 'asc')

    const data = {
      type: 'scatter',
      x: [], // date
      y: [], // result
      marker: {
        size: 12
      }
    }

    sortedObservations.forEach(o => {
      if (o.document.result.value) {
        data.x.push(moment(o.document.timestamp).format('MM/D/YY'))
        data.y.push(o.document.result.value)
      }
    })

    this.graph.data = [data]
  }

  setLayout() {
    if (!this.selectedRM) {
      this.setEmptyLayout()
    } else {
      this.graph.layout = {
        showlegend: false,
        height: 140,
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        xaxis: {
          showticklabels: true,
          tickmode: 'array',
          tickangle: 'auto',
          tickfont: {
            size: 10,
            color: '#FFFFFF'
          },
          showgrid: false,
        },
        yaxis: {
          automargin: true,
          title: {
            standoff: 10,
            text: '1RM',
          },
          showticklabels: true,
          tickfont: {
            size: 10,
            color: '#FFFFFF'
          },
          showgrid: true,
          gridcolor: '#666',
          gridwidth: 1,
        },
        margin: {
          l: 20,
          r: 20,
          b: 40,
          t: 5,
        },
        hoverlabel: {
          bgcolor: '#666666',
          font: { color: '#FFFFFF' }
        }
      }
    }
  }

  setEmptyLayout(text?: string) {
    if (!text) {
      text = 'No exercise selected.'
    }
    this.graph.data = []
    this.graph.layout = {
      height: 140,
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      xaxis: {
        visible: false
      },
      yaxis: {
        visible: false
      },
      annotations: [{
        text: text,
        xref: 'paper',
        yref: 'paper',
        showarrow: false,
        font: {
            size: 20
        }
      }],
      hoverlabel: {
        bgcolor: '#666666',
        font: { color: '#FFFFFF' }
      }
    }
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe()
    }
  }
}
