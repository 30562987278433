import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-preworkout-survey-question',
  templateUrl: './preworkout-survey-question.component.html',
  styleUrls: ['./preworkout-survey-question.component.scss']
})
export class PreworkoutSurveyQuestionComponent implements OnInit {
  @Input() form: FormGroup
  @Input() measure: any
  isNumberQuestion: boolean

  constructor() { }

  ngOnInit() {
    const paramResponse = this.measure.parameters.find(p => p.property === 'response')
    if (paramResponse) {
      if (paramResponse.type === 'number') {
        this.isNumberQuestion = true
      }
    }
  }
}
