import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
var MicroUiService = /** @class */ (function () {
    function MicroUiService() {
        this.eventSubject = new Subject();
    }
    /**
     * dispatches a custom event with provided type and detail
     * to the parent container of a micro-ui component
     * @param type custom event type
     * @param detail custom event detail
     */
    MicroUiService.prototype.dispatchEvent = function (type, parent, detail) {
        if (parent) {
            window.parent.dispatchEvent(new CustomEvent(type, { detail: detail }));
        }
        else {
            window.dispatchEvent(new CustomEvent(type, { detail: detail }));
        }
    };
    /**
     * listens for custom events of provided type dispatched from
     * a child micro-ui component
     * @param type custom event type
     */
    MicroUiService.prototype.listenForEvents = function (type, parent) {
        var _this = this;
        if (parent) {
            window.parent.addEventListener(type, function (e) {
                _this.eventSubject.next(e);
            });
        }
        else {
            window.addEventListener(type, function (e) {
                _this.eventSubject.next(e);
            });
        }
        return this.eventSubject.asObservable().pipe(filter(function (ev) { return ev.type === type; }));
    };
    MicroUiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MicroUiService_Factory() { return new MicroUiService(); }, token: MicroUiService, providedIn: "root" });
    return MicroUiService;
}());
export { MicroUiService };
