import { Component, OnDestroy, OnInit } from '@angular/core'
import { Day, Group, Plan, PlanBlock, PlanExercise, User, WorkoutSession } from './models'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { OnlineStatusChange, SyncWorkoutSessions } from './state'
import { Select, Store } from '@ngxs/store'
import { animate, group, query, style, transition, trigger } from '@angular/animations'

import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component'
import { AuthService } from './services/auth.service'
import { AuthState } from './state/auth/auth.state'
import { DatabaseService } from './services/database.service'
import { DeviceDetectorService } from 'ngx-device-detector'
import { DomSanitizer } from '@angular/platform-browser'
import { MatDialog } from '@angular/material/dialog'
import { MatIconRegistry } from '@angular/material/icon'
import { PageTransitionService } from './services/page-transition.service'
import { RestService } from './services/rest.service'
import { ServiceWorkerService } from 'fitforce-offline-first'
import { Subscription } from 'rxjs'
import { environment } from '../environments/environment'
import { slideAnimation } from './animations/slide.animation'

declare let gtag: Function;

const slideToRight = [
  query(':enter, :leave', style({ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0 }), { optional: true }),
  query(':leave', style({ transform: 'translateX(0%)' }), { optional: true }),
  query(':enter', style({ transform: 'translateX(-100%)' }), { optional: true }),
  group([
      query(':leave', [
          animate('500ms ease-in-out', style({ transform: 'translateX(100%)' })),
      ], { optional: true }),
      query(':enter', [
          animate('500ms ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true })
  ])
];

const slideToLeft = [
  query(':enter, :leave', style({ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0 }), { optional: true }),
  query(':leave', style({ transform: 'translateX(0%)' }), { optional: true }),
  query(':enter', style({ transform: 'translateX(100%)' }), { optional: true }),
  group([
      query(':leave', [
          animate('500ms ease-in-out', style({ transform: 'translateX(-100%)' })),
      ], { optional: true }),
      query(':enter', [
          animate('500ms ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true })
  ])
];

const slideToTop = [
  query(':enter, :leave', style({ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0 }), { optional: true }),
  query(':leave', style({ transform: 'translateY(0%)' }), { optional: true }),
  query(':enter', style({ transform: 'translateY(100%)' }), { optional: true }),
  group([
      query(':leave', [
          animate('500ms ease-in-out', style({ transform: 'translateY(-100%)' })),
      ], { optional: true }),
      query(':enter', [
          animate('500ms ease-in-out', style({ transform: 'translateY(0%)' })),
      ], { optional: true })
  ])
];

const slideToBottom = [
  query(':enter, :leave', style({ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0 }), { optional: true }),
  query(':leave', style({ transform: 'translateY(0%)' }), { optional: true }),
  query(':enter', style({ transform: 'translateY(-100%)' }), { optional: true }),
  group([
      query(':leave', [
          animate('500ms ease-in-out', style({ transform: 'translateY(100%)' })),
      ], { optional: true }),
      query(':enter', [
          animate('500ms ease-in-out', style({ transform: 'translateY(0%)' })),
      ], { optional: true })
  ])
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routeTransition', [
    transition('* => slideToLeft', slideToLeft),
    transition('* => slideToRight', slideToRight),
    transition('* => slideToTop', slideToTop),
    transition('* => slideToBottom', slideToBottom),
    transition('* => slideToLeftDuplicate', slideToLeft),
    transition('* => slideToRightDuplicate', slideToRight),
    transition('* => slideToTopDuplicate', slideToTop),
    transition('* => slideToBottomDuplicate', slideToBottom),
    ])
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  tabSelection = ''
  version: string = environment.version
  inProd: boolean = environment.production
  isOnline = true
  onlineSub: Subscription

  @Select(AuthState.activeUser) activeUser$;
  activeUser;

  constructor(
    private restService: RestService,
    private router: Router,
    private authService: AuthService,
    private databaseService: DatabaseService,
    private serviceWorkerService: ServiceWorkerService,
    private store: Store,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private pageTransitionService: PageTransitionService,
    private deviceService: DeviceDetectorService,
    private dialog: MatDialog,
  ) {
    this.initIcons()

    this.onlineSub = this.restService.isOnlineSubscription().subscribe(isOnline => {
      this.isOnline = isOnline
      this.store.dispatch(new OnlineStatusChange(isOnline))
      if (isOnline) {
        // We also sync observations
        this.store.dispatch(new SyncWorkoutSessions())
      }
    })
    // Support changing tab on load
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const url = val.url

        // Currently used to display nav footer
        // Maybe we can reverse it to hide from login and survey
        if (url.indexOf('dashboard') !== -1) {
          this.tabSelection = 'main'
        }
        if (url.indexOf('history') !== -1) {
          this.tabSelection = 'history'
        }
        if (url.indexOf('group') !== -1) {
          this.tabSelection = 'group'
        }
        if (url.indexOf('resources') !== -1) {
          this.tabSelection = 'resources'
        }
      }
    })
    this.serviceWorkerService.init(1000 * 60 * 60) // will poll for updates every 1 hour
    this.serviceWorkerService.checkForUpdate()
  }

  initIcons() {

    this.matIconRegistry.addSvgIcon(
      'icon_calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
        '/assets/images/iconCalendar.svg')
    )

    // For Tab
    this.matIconRegistry.addSvgIcon(
      'icon_workout',
      this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
        '/assets/images/iconWorkout.svg')
    )

    // For Tab
    this.matIconRegistry.addSvgIcon(
      'icon_stats',
      this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
        '/assets/images/iconStats.svg')
    )

    // For Tab
    this.matIconRegistry.addSvgIcon(
      'icon_programs',
      this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
        '/assets/images/iconPrograms.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'icon_workout_stats',
      this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
        '/assets/images/iconStatsInWorkout.svg')
    )


    this.matIconRegistry.addSvgIcon(
      'icon_right_arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
        '/assets/images/rightArrow.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'icon_left_arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
        '/assets/images/leftArrow.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'check_done',
      this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
        '/assets/images/checkDone.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'check_empty',
      this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
        '/assets/images/checkEmpty.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'check_incomplete',
      this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
        '/assets/images/checkIncomplete.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'marine_logo_white',
      this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
        '/assets/images/wGlobeAnchor.svg')
    )

    this.matIconRegistry.addSvgIcon(
      'icon_resources',
      this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base)
       + '/assets/images/iconSupport.svg')
    );
  }

  isActive(name: string) {
    return this.router.url.indexOf(name) > -1
  }

  changeTab(name: string) {
    this.tabSelection = name
    this.router.navigate([name])
  }

  loggedIn() {
    return this.authService.isLoggedIn && this.router.url.indexOf('login') < 0 && this.router.url !== '/'
  }

  logout() {
    if (this.loggedIn()) {
      this.authService.logout().subscribe(() => {
        // this.databaseService.connect().then(() => {
        //   this.databaseService.clear(Group)
        //   this.databaseService.clear(Plan)
        //   this.databaseService.clear(Day)
        //   this.databaseService.clear(PlanBlock)
        //   this.databaseService.clear(PlanExercise)
        //   this.databaseService.clear(User)
        //   this.databaseService.clear(WorkoutSession)
        // })
        localStorage.removeItem('midas-token')
        localStorage.removeItem('midas-username')
        localStorage.removeItem('midas-userUUID')
        localStorage.removeItem('midas-currentUser')
        this.router.navigate(['login'])
      })
    }
  }

  ngOnInit() {
    this.subs.push(
      this.activeUser$.subscribe(value => {
        this.activeUser = value;
      })
    )

    const devInfo = this.deviceService.getDeviceInfo();
    const browser = devInfo.browser;
    if (this.deviceService.isTablet() || this.deviceService.isDesktop()) {
      this.dialog.open(AlertDialogComponent, {
        data: {
          title: 'Tablet or Desktop Detected',
          content: 'Looking for fitness program planning tools? Head to FitForce Planner to get started!',
          redirectUrl: this.getPlannerLink(),
          button: 'Launch Planner',
          cancelButton: 'Close',
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.onlineSub.unsubscribe()
    this.subs.forEach(sub => sub.unsubscribe())
  }

  getPage(outlet) {
    return outlet.activatedRouteData['page'] || 'login'
  }

  getAnimation() {
    return this.pageTransitionService.getCurrentAnimation()
  }

  getPlannerLink() {
    return environment.plannerUrl === '{{PLANNER_URL}}' ?  `https://${window.location.host}/` : environment.plannerUrl;
  }
}
