import { Component, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-adaptations-status',
  templateUrl: './adaptations-status.component.html',
  styleUrls: ['./adaptations-status.component.scss'],
})
export class AdaptationsStatusComponent {
  applyingAdaptations$: Observable<boolean> = of(false);

  constructor(
    private dialogRef: MatDialogRef<AdaptationsStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  close() {
    this.dialogRef.close();
  }
}
