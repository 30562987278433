import * as _ from 'lodash';
import * as moment from 'moment';
import { DatabaseRecord } from '../../classes/database-record.class';
import { SurveyTemplate } from './survey-template.model';
import { SurveyChoice } from './survey-choice.model';

export class SurveyDocument extends DatabaseRecord {
  static objectStoreName = 'surveyDocument'

  id: string;
  name: string;
  type: string;
  version: number;

  get surveyChoices() {
    // const keys = this.getForeignKeys(SurveyChoice);
    // const surveyChoices = [];
    // for (let i = 0; i < keys.length; ++i) {
    //   const choice = new SurveyChoice(keys[i]);
    //   if (choice) {
    //     surveyChoices.push(choice);
    //   }
    // }
    // return surveyChoices;
    return this.getForeignKeys(SurveyChoice);
  }

  set surveyChoices(surveyChoices: any[]) {
    // const choices = [];
    // for (let i = 0; i < surveyChoices.length; ++i) {
    //   const choice = new SurveyChoice(choices[i]);
    //   choices.push(choice);
    // }
    // this.setForiegnRecords(SurveyChoice, choices);
    this.setForiegnRecords(SurveyChoice, surveyChoices);
  }

  get surveyTemplate() {
      return this.getForeignKeys(SurveyTemplate);
  }

  set surveyTemplate(surveyTemplate: any[]) {
      this.setForiegnRecords(SurveyTemplate, surveyTemplate);
  }

  constructor (data: {} = {}) {
    super(data);
    this.id = data['id'] || '';
    this.name = data['name'];
    this.type = data['type'];
    this.version = data['version'];
    if (_.has(data, 'survey_choices')) {
        this.surveyChoices = data['survey_choices'];
    }
    if (_.has(data, 'survey_template')) {
        this.surveyTemplate = data['survey_template'];
    }
  }

  toJSON () {
    return super.toJSON([
      'id',
      'name',
      'type',
      'version',
      'survey_choices',
      'survey_template'
    ]);
  }

  public getName(): string {
    return this.name;
  }

  public getSurveyChoices() {
      return this.getRecords(SurveyChoice);
  }

  public getSurveyTemplate() {
      return this.getRecords(SurveyTemplate);
  }
}
