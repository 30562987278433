import * as tslib_1 from "tslib";
import { DatabaseRecord } from '../../classes/database-record.class';
var SurveyQuestion = /** @class */ (function (_super) {
    tslib_1.__extends(SurveyQuestion, _super);
    function SurveyQuestion(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.id = data['id'] || '';
        _this.order = data['order'];
        _this.question = data['question'];
        return _this;
    }
    SurveyQuestion.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, [
            'id',
            'order',
            'question'
        ]);
    };
    SurveyQuestion.objectStoreName = 'surveyQuestions';
    return SurveyQuestion;
}(DatabaseRecord));
export { SurveyQuestion };
