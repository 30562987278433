<div class="resources-content" fxLayout="column">
    <h4>Resources</h4>
    <div class="resources-viewport">
        <div *ngFor="let item of resources">
            <div class="resources-list-header" fxLayout="column" fxLayoutGap="10px">
                <div fxFlex="33%" *ngIf="item.name !== 'Total Fitness Self Check'"></div>
                <p>{{ item.name }}</p>
                <div fxFlex="10%"></div>
            </div>
            <div fxLayout="column" fxLayoutGap="10px">
                <mat-card fxLayout="column" fxLayoutAlign="start start" (click)=navigateToResources(item)>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <div class="resources-description">{{ item.description }} <span *ngIf="item.phone !== ''">Call <a href="{{item.phone}}">{{item.phone.toString().substring(4)}}</a> or click LAUNCH to view online resources.</span></div>
                        <div fxFlex></div>
                        <div *ngIf="item.url" class="action-items primary">
                            <div>
                                <a *ngIf="item.name === 'Total Fitness Self Check'" [routerLink]="item.url" activeRoute="'active'">LAUNCH</a>
                                <a *ngIf="item.name !== 'Total Fitness Self Check'" [href]="item.url" target="_">LAUNCH</a>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <div>
            <div class="resources-list-header" fxLayout="column" fxLayoutGap="10px">
                <p></p>
                <div fxFlex></div>
            </div>
            <div fxLayout="column" fxLayoutGap="10px" class="placeholder-div"></div>
        </div>
    </div>
</div>
