import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DataService } from 'app/services/data.service';
import * as _ from 'lodash';
import * as moment from 'moment';
var TrainingLoadChartComponent = /** @class */ (function () {
    function TrainingLoadChartComponent(dataService) {
        this.dataService = dataService;
        /*
        * PLOTLY NOTES:
        *
        * Hover label of Trace (Legend) is white... https://community.plotly.com/t/trace-hover-label-color/12584
        * Merging dates into Months require a different data structure
        * No way to hide week numbers (the main axis used to separate out dates)
        * No way to merge a scatter+line plot WITH a multiaxis bar chart
        */
        this.graph = {
            layout: {
                showlegend: false,
                height: 140,
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
                xaxis: {
                    title: 'Week Number',
                    titlefont: {
                        size: 12,
                        color: '#fff'
                    },
                    showticklabels: true,
                    tickmode: 'array',
                    tickangle: '-30',
                    tickfont: {
                        size: 10,
                        color: '#FFFFFF'
                    },
                    showgrid: false,
                },
                yaxis: {
                    showticklabels: true,
                    tickfont: {
                        size: 10,
                        color: '#FFFFFF'
                    },
                    showgrid: false,
                },
                margin: {
                    l: 30,
                    r: 0,
                    b: 40,
                    t: 5,
                },
                hoverlabel: {
                    bgcolor: '#666666',
                    font: { color: '#FFFFFF' }
                }
            },
            config: {
                displayModeBar: false,
                responsive: true,
            },
        };
    }
    TrainingLoadChartComponent.prototype.ngOnInit = function () {
        this.init();
    };
    TrainingLoadChartComponent.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // Stores averages separated by year-week
                        _a = this;
                        return [4 /*yield*/, this.getAverages()];
                    case 1:
                        // Stores averages separated by year-week
                        _a.averages = _b.sent();
                        this.setChart();
                        return [2 /*return*/];
                }
            });
        });
    };
    TrainingLoadChartComponent.prototype.getAverages = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allWorkouts, reportedAverages, estimatedAverages;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getWorkoutSessions()];
                    case 1:
                        allWorkouts = _a.sent();
                        reportedAverages = {};
                        estimatedAverages = {};
                        _.each(allWorkouts, function (session) {
                            var endDate = moment(session.document.end_time);
                            var trainingLoad = session.document.training_load;
                            var estTrainingLoad = session.document.est_training_load;
                            var dateKey = endDate.startOf('week').format('YYYY-MM-DD');
                            reportedAverages[dateKey] = _this.newAverage(endDate, reportedAverages[dateKey], trainingLoad);
                            estimatedAverages[dateKey] = _this.newAverage(endDate, estimatedAverages[dateKey], estTrainingLoad);
                        });
                        return [2 /*return*/, {
                                actual: reportedAverages,
                                target: estimatedAverages,
                            }];
                }
            });
        });
    };
    TrainingLoadChartComponent.prototype.getWorkoutSessions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var documents, sessions;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getAllWorkoutSessionDocuments()
                        // Grab only the last 3 months
                    ];
                    case 1:
                        documents = _a.sent();
                        sessions = _.filter(documents, function (doc) {
                            var currentDate = moment().startOf('day').hour(12);
                            if (doc.document.end_time) {
                                var end_time = moment(doc.document.end_time);
                                if (currentDate.diff(end_time, 'months') <= 3) {
                                    return doc;
                                }
                            }
                        });
                        return [2 /*return*/, sessions];
                }
            });
        });
    };
    TrainingLoadChartComponent.prototype.newAverage = function (endDate, average, rpe) {
        // rpe = Math.random() * 100
        var month = endDate.format('MMM');
        var weekOfMonth = average ? average.weekOfMonth : this.weekOfMonth(endDate);
        if (!average) {
            return {
                month: month,
                weekOfMonth: weekOfMonth,
                totalScore: rpe,
                count: 1,
                avg: rpe
            };
        }
        var newScore = average.totalScore + rpe;
        var newCount = average.count + 1;
        return {
            month: month,
            weekOfMonth: weekOfMonth,
            totalScore: newScore,
            count: newCount,
            avg: Math.floor(newScore / newCount)
        };
    };
    TrainingLoadChartComponent.prototype.weekOfMonth = function (m) {
        return Math.ceil(m.date() / 7);
    };
    TrainingLoadChartComponent.prototype.setChart = function () {
        var barChartData = this.formatChartData(this.averages.actual, 'bar');
        var scatterChartData = this.formatChartData(this.averages.target, 'scatter');
        this.setChartData(barChartData, scatterChartData);
    };
    TrainingLoadChartComponent.prototype.formatChartData = function (averages, type) {
        var graphData = {
            x: [], xKey: {}, y: [], barWidth: [], type: type
        };
        if (type === 'scatter') {
            graphData.mode = 'line+points',
                graphData.marker = {
                    color: '#D68C38'
                };
        }
        Object.keys(averages).sort().forEach(function (dateKey) {
            var average = averages[dateKey].avg;
            if (average) {
                var xLabel = moment(dateKey).format('M/D/YY');
                graphData.xKey[moment(dateKey).format('YYYY-MM-DD')] = xLabel;
                graphData.x.push(xLabel);
                graphData.y.push(average);
                graphData.barWidth.push(.5);
            }
        });
        return graphData;
    };
    TrainingLoadChartComponent.prototype.setChartData = function (barChartData, scatterChartData) {
        var categoryKeys = tslib_1.__assign({}, barChartData.xKey, scatterChartData.xKey);
        this.graph.layout.xaxis.categoryarray = Object.keys(categoryKeys).sort().map(function (x) { return categoryKeys[x]; });
        this.graph.data = [{
                x: scatterChartData.x,
                y: scatterChartData.y,
                name: 'Target',
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: '#D68C38', size: 12 },
            }, {
                x: barChartData.x,
                y: barChartData.y,
                width: barChartData.barWidth,
                type: 'bar',
                name: 'Actual',
                marker: {
                    color: '#51CDD7', size: 12
                },
            }];
    };
    return TrainingLoadChartComponent;
}());
export { TrainingLoadChartComponent };
