import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'

@Pipe({
  name: 'msToTime'
})
export class MStoTimePipe implements PipeTransform {
  transform(totalTime: number): string {
    // correct negative values
    if (totalTime < 0) {
      totalTime = 0;
    }

    const duration = moment.duration(totalTime, 'milliseconds')
    const fromMinutes = Math.floor(duration.asMinutes())
    const fromSeconds = Math.floor(duration.asSeconds() - fromMinutes * 60)

    if ( Math.floor(duration.asSeconds()) >= 60) {
      return (fromMinutes <= 9 ? '0' + fromMinutes : fromMinutes) + ':' + (fromSeconds <= 9 ? '0' + fromSeconds : fromSeconds)
    } else {
      return '00:' + (fromSeconds <= 9 ? '0' + fromSeconds : fromSeconds)
    }
  }
}
