import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { DatabaseRecord } from '../classes/database-record.class';
import { Observable } from 'rxjs/internal/Observable';
var DatabaseService = /** @class */ (function () {
    function DatabaseService() {
        this.objectStores = {
            'users': { keyPath: 'key' },
            'plans': { keyPath: 'key' },
            'planDays': {
                keyPath: ['planUUID', 'key'],
                indices: [
                    { key: 'date', unique: false },
                    { key: 'planUUID', unique: false },
                ],
            },
            'planBlocks': {
                keyPath: ['planDayUUID', 'key']
            },
            'plannedExercises': { keyPath: 'key' },
            'groups': { keyPath: 'key' },
            'workoutSessions': { keyPath: 'key' },
            'workoutPreviews': { keyPath: 'key' },
            'workoutSessionDocuments': { keyPath: 'key' },
            'offlineErrors': { keyPath: 'key' },
            'surveyTemplateDocument': { keyPath: 'key' },
            'surveyTemplates': { keyPath: 'key' },
            'surveyDocument': { keyPath: 'key' },
            'surveyChoices': { keyPath: 'key' },
            'surveyQuestions': { keyPath: 'key' },
        };
        this.seed = {
        // 'users': seedUsers,
        // 'planDays': seedDays.concat(seedDays2),
        // 'groups': seedGroups,
        // 'plans': seedPlans,
        // 'workoutSessions': seedWorkoutSessions,
        // 'workoutPreviews': {},
        };
        this.planDayMigrations = [];
    }
    DatabaseService.prototype.connect = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.db) {
                // if we're already connected, just resolve
                return resolve();
            }
            var request = window.indexedDB.open(DatabaseService.dbName, DatabaseService.version);
            request.onsuccess = function (event) {
                // console.log('db.connect complete', event)
                _this.db = event.target.result;
                _this.db.onerror = _this.onDatabaseError.bind(_this);
                _this.seedDatabase().then(resolve, reject);
                // resolve()
            };
            request.onerror = function (event) {
                // console.log('db.connect fail', event)
                reject(event);
            };
            request.onupgradeneeded = function (event) {
                _this.onUpgradeNeeded(event);
            };
        });
    };
    DatabaseService.prototype.getVersion = function () {
        return this.db.version;
    };
    DatabaseService.prototype.get = function (recordType, where) {
        if (recordType === void 0) { recordType = DatabaseRecord; }
        if (typeof where === 'string' || Array.isArray(where)) {
            return this.getByKey(recordType, where);
        }
        else {
            return this.queryOne(recordType, where);
        }
    };
    DatabaseService.prototype.getByKey = function (recordType, key) {
        var _this = this;
        var objectStoreName;
        if (typeof recordType === 'string') {
            objectStoreName = recordType;
        }
        else {
            objectStoreName = recordType.objectStoreName;
        }
        return new Promise(function (resolve, reject) {
            // console.log('db.get', objectStoreName, 'by key', key)
            var transaction = _this.transaction(objectStoreName, resolve, reject);
            var objectStore = transaction.objectStore(objectStoreName);
            var request = objectStore.get(key);
            request.onerror = function (event) {
                // console.log('db.get error', event)
                reject(event);
            };
            request.onsuccess = function (event) {
                // console.log('db.get success', event.target.result)
                if (typeof recordType !== 'string') {
                    var record_1 = new recordType(event.target.result);
                    record_1.loadAssociations(_this).then(function () {
                        resolve(record_1);
                    }).catch(reject);
                }
                else {
                    resolve(event.target.result);
                }
            };
        });
    };
    DatabaseService.prototype.queryOne = function (recordType, where) {
        // console.log('db.queryOne', recordType.objectStoreName, 'by', where)
        return this.query(recordType, where).then(function (records) {
            var record = _.first(records);
            // console.log('db.queryOne', recordType.objectStoreName, 'found', record)
            return record;
        });
    };
    DatabaseService.prototype.query = function (recordType, where) {
        // console.log('db.query', recordType.objectStoreName, 'by', where)
        return this.getAll(recordType).then(function (records) {
            var whereFilter = {};
            _.each(where, function (value, key) {
                if (value instanceof DatabaseRecord) {
                    whereFilter[key] = value.key;
                }
                else {
                    whereFilter[key] = value;
                }
            });
            records = _.filter(records, whereFilter);
            // console.log('db.query', recordType.objectStoreName, 'found', records, 'using filter', whereFilter)
            return records;
        });
    };
    DatabaseService.prototype.getRecordsAsync = function (recordType, indexName, where) {
        var objectStoreName = recordType.objectStoreName;
        // console.log('db.getAll', objectStoreName)
        var transaction = this.db.transaction([objectStoreName], 'readonly');
        var objectStore = transaction.objectStore(objectStoreName);
        return new Observable(function (observer) {
            if (!objectStore) {
                observer.error(new Error('Object store does not exist: ' + objectStoreName));
                return;
            }
            // Default index name to key
            if (!indexName) {
                indexName = 'key';
            }
            var index = objectStore.index(indexName);
            var request = index.openCursor();
            // Success
            request.onsuccess = function (event) {
                // Steps through all the values in the object store
                var cursor = event.target.result;
                if (cursor) {
                    if (!_.isUndefined(where)) {
                        _.each(where, function (whereParam, key) {
                            var filterString = whereParam;
                            if (whereParam instanceof DatabaseRecord) {
                                filterString = whereParam.key;
                            }
                            // If the index matches with the filter key
                            if (cursor.value[key] === filterString) {
                                observer.next(cursor.value);
                            }
                        });
                    }
                    else {
                        observer.next(cursor.value);
                    }
                    cursor.continue();
                }
                else {
                    observer.complete();
                }
            };
            // Error
            request.onerror = function (event) {
                // console.log('IndexedDB service: ' + (<IDBRequest>event.target).error.name);
                observer.error(event.target.error.name);
            };
        });
    };
    DatabaseService.prototype.getRecordsByKeys = function (objectStoreName, keys) {
        var _this = this;
        var getAll = _.map(keys, function (key) {
            return _this.get(objectStoreName, key);
        });
        return Promise.all(getAll);
    };
    DatabaseService.prototype.getRecords = function (recordType, keys) {
        var _this = this;
        var objectStoreName = recordType.objectStoreName;
        var getAll = _.map(keys, function (key) {
            return _this.get(recordType, key);
        });
        // console.log('db.query', recordType.objectStoreName, 'by', keys)
        return Promise.all(getAll);
    };
    DatabaseService.prototype.getAll = function (recordType) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var objectStoreName;
            if (typeof recordType === 'string') {
                objectStoreName = recordType;
            }
            else {
                objectStoreName = recordType.objectStoreName;
            }
            // console.log('db.getAll', objectStoreName)
            var transaction = _this.transaction(objectStoreName, resolve, reject);
            var objectStore = transaction.objectStore(objectStoreName);
            if (!objectStore) {
                reject(new Error('Object store does not exist: ' + objectStoreName));
                return;
            }
            var request;
            try {
                request = objectStore.getAll();
            }
            catch (ex) {
                reject(ex);
                return;
            }
            request.onerror = function (event) {
                // console.log('db.getAll', objectStoreName, 'error', event)
                reject(event);
            };
            request.onsuccess = function (event) {
                // console.log('db.getAll', objectStoreName, 'success', event.target.result)
                if (typeof recordType !== 'string') {
                    var records = _.map(event.target.result, function (record) {
                        return new recordType(record);
                    });
                    resolve(records);
                }
                else {
                    resolve(event.target.result);
                }
            };
        });
    };
    /**
     * Insert records into the datastore.
     *
     * @param {string} objectStoreName Object store name (table name).
     * @param {DatabaseRecord[]} records Database records to insert.
     * @param {boolean} [forceUpdate=false] Set to true to force an update if the record already exists.
     */
    DatabaseService.prototype.insert = function (records, forceUpdate, filter) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // console.log('INSERT:', records)
            records = _.toArray(records);
            if (records.length === 0) {
                resolve();
                return;
            }
            // We do the reverse storage like our API
            if (filter && filter['options'] && filter['options']['append']) {
                // console.log(filter.options)
                _.forEach(records, function (record, i) {
                    _.extend(record, filter.options.append);
                });
            }
            // console.log('INSERT:', records)
            var objectStoreName = _.first(records).constructor.objectStoreName;
            // console.log('db.insert', objectStoreName)
            var transaction = _this.transaction(objectStoreName, resolve, reject);
            var objectStore = transaction.objectStore(objectStoreName);
            var promises = _.map(records, function (record) {
                var obj = record.toJSON();
                var request = forceUpdate ? objectStore.put(obj) : objectStore.add(obj);
                request.onerror = function (event) {
                    // console.log('db.insert', objectStoreName, 'error', event)
                    reject(event);
                };
                request.onsuccess = function (event) {
                    var key = event.target.result;
                    // console.log('db.insert', objectStoreName, 'success', key, obj)
                    resolve(event);
                };
            });
            Promise.all(promises).then(resolve, reject);
        });
    };
    DatabaseService.prototype.put = function (objectStoreName, record, key) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var transaction = _this.transaction(objectStoreName, resolve, reject);
                        var objectStore = transaction.objectStore(objectStoreName);
                        objectStore.put(record);
                    })];
            });
        });
    };
    DatabaseService.prototype.update = function (record) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var objectStoreName = record.constructor.objectStoreName;
            // console.log('db.update', objectStoreName)
            var transaction = _this.transaction(objectStoreName, resolve, reject);
            var objectStore = transaction.objectStore(objectStoreName);
            var request = objectStore.put(record.toJSON());
            request.onerror = function (event) {
                //  console.log('db.update error', event)
                reject(event);
            };
            request.onsuccess = function (event) {
                //  console.log('db.update success', event)
                resolve();
            };
        });
    };
    DatabaseService.prototype.delete = function (objectStoreName, key) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // console.log('db.delete', objectStoreName)
            var transaction = _this.transaction(objectStoreName, resolve, reject);
            var objectStore = transaction.objectStore(objectStoreName);
            var request = objectStore.delete(key);
            request.onerror = function (event) {
                // console.log('db.delete error', event)
                reject(event);
            };
            request.onsuccess = function (event) {
                // console.log('db.delete success', event)
                resolve();
            };
        });
    };
    DatabaseService.prototype.clear = function (recordType) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var objectStoreName = recordType.objectStoreName;
            // console.log('db.clear', objectStoreName)
            var transaction = _this.transaction(objectStoreName, resolve, reject);
            var objectStore = transaction.objectStore(objectStoreName);
            var request = objectStore.clear();
            request.onerror = function (event) {
                // console.log('db.clear error', event)
                reject(event);
            };
            request.onsuccess = function (event) {
                // console.log('db.clear success', event)
                resolve();
            };
        });
    };
    DatabaseService.prototype.transaction = function (objectStoreName, resolve, reject) {
        var transaction;
        try {
            transaction = this.db.transaction([objectStoreName], 'readwrite');
        }
        catch (ex) {
            if (ex.name === 'NotFoundError') {
                // console.log('Need to bump DatabaseService.version')
                reject(ex);
            }
            else {
                // console.log('db.transaction error', ex.name)
                reject(ex);
            }
        }
        transaction.oncomplete = function (event) {
            // console.log('db.transaction complete', event)
            // resolve()
        };
        transaction.onerror = function (event) {
            // console.log('db.transaction fail', event.target.error)
            reject(event);
        };
        return transaction;
    };
    DatabaseService.prototype.onUpgradeNeeded = function (event) {
        var _this = this;
        // console.log('db.onUpgradeNeeded')
        this.db = event.target.result;
        var transaction = event.target.transaction;
        _.each(_.keys(this.objectStores), function (objectStoreName) {
            var objectStoreParams = _this.objectStores[objectStoreName];
            // console.log('creating datastore:', objectStoreName)
            var objectStore = _this.createObjectStore(objectStoreName);
            if (objectStore) {
                // Add additional indices
                if (!_.isUndefined(objectStoreParams.indices)) {
                    _.each(objectStoreParams.indices, function (index) {
                        // console.log('creating index on:', objectStoreName, index.key);
                        objectStore.createIndex(index.key, index.key, { unique: index.unique });
                    });
                }
            }
            else {
                if (event.oldVersion > 1 &&
                    event.oldVersion < 24 &&
                    objectStoreName === 'planDays') {
                    var migraterequest = transaction.objectStore(objectStoreName, 'readonly').getAll();
                    migraterequest.onsuccess = function (e) {
                        _this.planDayMigrations = e.target.result;
                        if (_this.planDayMigrations.length > 0) {
                            _this.connect().then(function () {
                                var store = transaction.objectStore('planDays');
                                for (var i = 0; i < _this.planDayMigrations.length; ++i) {
                                    store.add(_this.planDayMigrations[i]);
                                }
                                transaction.oncomplete = function () {
                                    // console.log('Migration of planDays complete')
                                };
                            });
                        }
                    };
                    _this.db.deleteObjectStore(objectStoreName);
                    _this.createObjectStore(objectStoreName);
                }
            }
        });
    };
    DatabaseService.prototype.seedDatabase = function () {
        var _this = this;
        var stores = _.keys(this.objectStores);
        var promises = _.filter(_.map(stores, function (objectStoreName) {
            var records = _this.seed[objectStoreName];
            if (records && records.length > 0) {
                return _this.insert(records, true);
            }
        }));
        return Promise.all(promises);
    };
    DatabaseService.prototype.createObjectStore = function (objectStoreName) {
        if (!_.includes(this.db.objectStoreNames, objectStoreName)) {
            return this.db.createObjectStore(objectStoreName, this.objectStores[objectStoreName]);
        }
        else {
            return null;
        }
    };
    DatabaseService.prototype.onDatabaseError = function (event) {
        // console.log('Database error:', event.target.error)
    };
    DatabaseService.version = 25;
    DatabaseService.dbName = 'fitforce';
    return DatabaseService;
}());
export { DatabaseService };
