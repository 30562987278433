import * as _ from 'lodash';
import * as moment from 'moment';
import { DatabaseRecord } from '../../classes/database-record.class';

export class SurveyQuestion extends DatabaseRecord {
  static objectStoreName = 'surveyQuestions'

  id: string;
  order: number;
  question: string;

  constructor (data: {} = {}) {
    super(data)
    this.id = data['id'] || ''
    this.order = data['order']
    this.question = data['question']
  }

  toJSON () {
    return super.toJSON([
      'id',
      'order',
      'question'
    ]);
  }
}
