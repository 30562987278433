import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { WorkoutState } from 'app/state/workout/workout.state';
import * as _ from 'lodash'
import { ExerciseProgramDocumentUtils, getDefaultParams } from 'fitforce-document-sync'
import { DocumentService } from 'app/services/document.service';
import { parseInt as _parseInt } from 'lodash';

@Component({
  selector: 'app-edit-set-dialog',
  templateUrl: './edit-set-dialog.component.html',
  styleUrls: ['./edit-set-dialog.component.scss']
})
export class EditSetDialogComponent implements OnInit {
  tierIndex: number
  rowIndex: number
  setIndex: number
  setExecution: any
  isComplete: boolean
  setParams: any
  setParamValues: any

  constructor (
    private store: Store,
    private documentService: DocumentService,
    public dialogRef: MatDialogRef<EditSetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.tierIndex = data.tierIndex
    this.rowIndex = data.rowIndex
    this.setIndex = data.setIndex
    // data.forComplete
  }

  ngOnInit() {
    const currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession)
    this.setParamValues = _.cloneDeep(
      currentWorkoutSession.workout.tiers[this.tierIndex].rows[this.rowIndex].executions[this.setIndex].parameter_values
    )
    this.setExecution = currentWorkoutSession.workout.tiers[this.tierIndex].rows[this.rowIndex].executions[this.setIndex]

    const exerciseReference = currentWorkoutSession.workout.tiers[this.tierIndex].rows[this.rowIndex].exercise;
    const exercise = exerciseReference ? this.documentService.store.exerciseProgram.document.exercises.find(
      ex => ex.id === exerciseReference.exercise_program_id
    ) : undefined;
    if (exercise) {
      this.setParams = ExerciseProgramDocumentUtils.getPossibleParamsForExercise(
        currentWorkoutSession.workout.tiers[this.tierIndex].rows[this.rowIndex].exercise.exercise_program_id,
        this.documentService.store.exerciseProgram.document
      )
    } else {
      this.setParams = getDefaultParams();
    }
    _.each(this.setParamValues, (value, key) => {
      if (!this.hasParam(key) && key !== '1rm') {
        delete(this.setParamValues[key])
      }
    })
    if (this.hasParam('load') && !this.setParamValues['load']) {
      this.setParamValues['load'] = undefined
    }
    if (this.data.forComplete && this.setExecution.complete_time) {
      this.isComplete = true
    } else {
      this.isComplete = false
    }
    delete(this.setParamValues['1rm'])
  }

  cancelSelected() {
    this.dialogRef.close({
      success: false
    })
  }

  submitSelected() {
    if (!this.setParamValues) {
      this.cancelSelected()
      return
    }

    for (const param in this.setParamValues) {
      if (param in this.setParamValues) {
        const config = this.setParams.find(p => p.param === param)
        if (config && config.type === 'number') {
          this.setParamValues[param] = Number.parseInt(this.setParamValues[param], 10)
        }
      }
    }

    this.dialogRef.close({
      success: true,
      setParamValues: this.setParamValues,
      isComplete: this.isComplete,
    })
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  hasParam(param: string) {
    let exists = false
    if (param === '1rm') {
      return true
    }
    _.each(this.setParams, (v) => {
      if (v.param === param) {
        exists = true
        return true
      }
    })
    return exists
  }

  getMinutes() {
    if (this.setParamValues['time']) {
      return Math.floor(this.setParamValues['time'] / 60);
    }

    return null;
  }

  getSeconds() {
    if (this.setParamValues['time']) {
      return this.setParamValues['time'] - (Math.floor(this.setParamValues['time'] / 60) * 60);
    }

    return null;
  }

  updateMinutes($event) {
    const mins = $event.target.value;
    const secs = this.setParamValues['time']
      ? _parseInt(this.setParamValues['time']) - (Math.floor(_parseInt(this.setParamValues['time']) / 60) * 60)
      : 0;
    this.setParamValues['time'] = (mins * 60) + secs;
  }

  updateSeconds($event) {
    const mins = this.setParamValues['time']
      ? Math.floor(_parseInt(this.setParamValues['time']) / 60) * 60
      : 0;
    const secs = $event.target.value;
    this.setParamValues['time'] = mins + _parseInt(secs);
  }
}
