import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { WorkoutState } from 'app/state/workout/workout.state';

@Component({
  selector: 'app-rmcalc-set-dialog',
  templateUrl: './rmcalc-set-dialog.component.html',
  styleUrls: ['./rmcalc-set-dialog.component.scss']
})
export class RMCalcSetDialogComponent {
  constructor (
    public dialogRef: MatDialogRef<RMCalcSetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  closeDialog() {
    this.dialogRef.close()
  }
}
