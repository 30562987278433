import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { DatabaseRecord } from '../classes/database-record.class';
import { PlanBlock } from './plan-block.model';
var Day = /** @class */ (function (_super) {
    tslib_1.__extends(Day, _super);
    function Day(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.name = data['name'] || _.get(data, 'workoutType.name') || _.get(data, 'restrictLabel.name') || 'Other';
        _this.planUUID = data['planUUID'];
        _this.date = moment(data['date']);
        _this.action = data['action'] || 'Go';
        _this.isComplete = data['isComplete'] || false;
        _this.isRestricted = data['isRestricted'] || false;
        _this.restrictLabel = data['restrictLabel'];
        _this.workoutType = data['workoutType'];
        _this.blocks = data['blocks'];
        _this.load = data['load'] || 0;
        _this.est_completion_time_ms = data['est_completion_time_ms'];
        _this.version = data['version'];
        return _this;
    }
    Object.defineProperty(Day.prototype, "blocks", {
        get: function () {
            return this.getForeignKeys(PlanBlock);
        },
        set: function (records) {
            this.setForiegnRecords(PlanBlock, records);
        },
        enumerable: true,
        configurable: true
    });
    Day.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, ['name', 'planUUID', 'date', 'action', 'isComplete', 'isRestricted', 'restrictLabel', 'workoutType', 'blocks', 'load', 'est_completion_time_ms', 'version']);
    };
    Day.objectStoreName = 'planDays';
    return Day;
}(DatabaseRecord));
export { Day };
