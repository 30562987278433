<div class="tier" *ngIf="currentWorkoutSession">
  <ng-container *ngIf="tier.circuit; else notCircuit">
    <app-subexercise-circuit
      (changeTier)="changeTier($event)">
    </app-subexercise-circuit>
  </ng-container>
  <ng-template #notCircuit>
    <ng-container *ngIf="tierRow; else noExercise">
      <mat-expansion-panel class="collapsible-note" [disabled]="!!!tier.notes">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b>Tier {{ tier.order }}: {{ tier.name }}</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p *ngIf="tier.notes">{{ tier.notes }}</p>
      </mat-expansion-panel>
      <div class="exercise-info" fxLayout="column" fxLayoutGap="10px">
        <div class="exercise-details" fxLayout="row">
          <div fxFlex="100" fxLayout="column">
            <div fxLayout="row" fxLayoutGap="20px" fxFlex="100">
              <div class="arrow arrow-left">
                <mat-icon *ngIf="hasPrev; else noPrev" (click)="changeExercise(true)" svgIcon="icon_left_arrow"></mat-icon>
                <ng-template #noPrev>
                  <mat-icon class="disabled" svgIcon="icon_left_arrow"></mat-icon>
                </ng-template>
              </div>
              <div class="main-details" fxFlex="70" (click)="onToggleRowNote()">
                <div class="exercise-title" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                  <div>{{ tierRow.exercise.name }}</div>
                  <div *ngIf="tier.rows[currentRowIndex].notes" class="notes-dropdown" alt="Has Notes" title="Has Notes">
                    <mat-icon *ngIf="rowNoteVisible">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="!rowNoteVisible">keyboard_arrow_down</mat-icon>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                  <div class="sets-reps-loads">{{ setDetails | titlecase}}</div>
                  <div
                    *ngIf="tierRow.parameters.hasOwnProperty('load') && isValidLoad(tierRow.parameters.load)"
                    class="small-icon-button" fxLayoutAlign="center center">
                      <mat-icon
                        (click)="openCalc1RM()"
                        svgIcon="icon_workout_stats">
                    </mat-icon>
                    </div>
                  </div>
              </div>
              <div class="arrow arrow-right">
                <mat-icon *ngIf="hasNext; else noNext"  (click)="changeExercise()" svgIcon="icon_right_arrow"></mat-icon>
                <ng-template #noNext>
                  <mat-icon class="disabled" svgIcon="icon_right_arrow"></mat-icon>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="rowNoteVisible">{{tier.rows[currentRowIndex].notes}}</div>
        <div class="action-buttons" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <button fxFlex mat-raised-button (click)="openCommentsDialog()">NOTES</button>
          <button fxFlex [disabled]="!exerciseVideo" mat-raised-button color="primary" (click)="openVideo()">VIDEO</button>
          <div class="check-toggle" fxLayoutAlign="center center">
            <mat-icon *ngIf="toggleStatus === 2" class="accent" svgIcon="check_done" (click)="toggleAllSets(true)"></mat-icon>
            <mat-icon *ngIf="toggleStatus === 1" class="accent" svgIcon="check_incomplete" (click)="toggleAllSets(true)"></mat-icon>
            <mat-icon *ngIf="toggleStatus === 0" class="accent" svgIcon="check_empty" (click)="toggleAllSets(false)"></mat-icon>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="execution-card" *ngFor="let execution of tierRow.executions; let setIndex = index">
          <mat-card fxLayout="row" fxLayoutGap="20px" (click)="openEditSet(setIndex)">
            <div>Set {{ setIndex + 1 }}</div>
            <div fxLayout="row" fxLayoutGap="5px">
              <app-exercise-set-item
                fxFlex="1 1"
                [execution]="execution"
                [hasRM]="hasRM"
                [currentWorkoutIndex]="currentWorkoutIndex"
                [currentRowIndex]="currentRowIndex"
                [setIndex]="setIndex"></app-exercise-set-item>
            </div>
            <div fxFlex></div>
            <div (click)="onExecuteSet(setIndex)">
              <ng-template
                *ngIf="isExecuted(setIndex);then isExecutedBlock; else notExecutedBlock">
              </ng-template>
              <ng-template #isExecutedBlock>
                <mat-icon class="accent" svgIcon="check_done"></mat-icon>
              </ng-template>
              <ng-template #notExecutedBlock>
                <mat-icon svgIcon="check_empty"></mat-icon>
              </ng-template>
            </div>
          </mat-card>
        </div>
        <div class="button-wrapper">
          <div class="add-more">
            <button mat-raised-button color="primary" (click)="onAddNewSet($event)">
              <mat-icon>add</mat-icon>
              ADD SET
            </button>
          </div>
          <div class="delete-set">
            <button mat-raised-button 
              color="warn" 
              [disabled]="!this.tierRow.executions[this.tierRow.executions.length - 1]['user_added']" 
              (click)="onDeleteSet($event)"
            >
              <mat-icon>remove</mat-icon>
              REMOVE SET
            </button>
          </div>
        </div>
        <div *ngIf="hasTimer" class="timer">
          <mat-icon *ngIf="timerState === 'off' && !timerFinished" color="primary" (click)="startTimer()">play_arrow</mat-icon>
          <mat-icon *ngIf="timerState === 'on' && !timerFinished" color="primary" (click)="pauseTimer()">pause</mat-icon>
          <mat-card class="timer-display">
            {{timer.mins}}:{{timer.secs}}
          </mat-card>
        </div>
        <div *ngIf="hasTimer && timerOrientation === 'up' && timerState === 'on'" class="timer-log-time">
          <button mat-raised-button (click)="logTime()">Log Time</button>
        </div>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #noExercise>
    <h3>{{ tier.name }}</h3>
    <p>There are no exercises for this tier.</p>
    <div fxLayout="row">
      <button *ngIf="hasPrev" mat-raised-button color="primary" (click)="changeExercise(true)">Previous Tier</button>
      <div fxFlex></div>
      <button *ngIf="hasNext" mat-raised-button color="primary" (click)="changeExercise()">Next Tier</button>
    </div>
  </ng-template>
</div>
