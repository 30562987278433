import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AtpDataService = /** @class */ (function () {
    function AtpDataService(http) {
        this.http = http;
    }
    AtpDataService.prototype.getPlanId = function () {
        var _this = this;
        return this.http.get(environment.atp_gateway_url + "/plan").pipe(map(function (response) {
            _this.planId = response.plan_id;
            localStorage.setItem('atpPlanId', _this.planId);
            return response.plan_id;
        }));
    };
    AtpDataService.prototype.postPlan = function (plan) {
        return this.http
            .post(environment.atp_gateway_url + "/plan", {
            plan: plan,
        })
            .pipe(map(function (response) { return null; }));
    };
    AtpDataService.prototype.postWorkoutSessions = function (plan, wkts, userId) {
        var lastWorkout = plan.workouts.find(function (wkt) { return wkt.id === wkts[wkts.length - 1].workout.id; });
        return this.http
            .post(environment.atp_gateway_url + "/workout", {
            workouts: wkts,
            user_id: userId,
            plan_id: this.planId,
            // week & day number of assigned date of most recent (last) workout session
            week: lastWorkout.schedule.week[0],
            day: lastWorkout.schedule.day[0],
        })
            .pipe(map(function (response) { return response['plan']; }));
    };
    AtpDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AtpDataService_Factory() { return new AtpDataService(i0.ɵɵinject(i1.HttpClient)); }, token: AtpDataService, providedIn: "root" });
    return AtpDataService;
}());
export { AtpDataService };
