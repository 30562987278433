import * as _ from 'lodash';
import * as moment from 'moment';
import { DatabaseRecord } from '../../classes/database-record.class';
import { SurveyQuestion } from './survey-question.model';

export class SurveyTemplate extends DatabaseRecord {
  static objectStoreName = 'surveyTemplates'

  id: string;
  category: string;

  get questions() {
    return this.getForeignKeys(SurveyQuestion);
  }

  set questions(questions: any[]) {
    this.setForiegnRecords(SurveyQuestion, questions);
  }

  constructor (data: {} = {}) {
    super(data);
    this.id = data['id'] || '';
    this.category = data['category'];
    if (_.has(data, 'questions')) {
        this.questions = data['questions'];
    }
  }

  toJSON () {
    return super.toJSON([
      'id',
      'category',
      'questions'
    ]);
  }

  getQuestions() {
      return this.getRecords(SurveyQuestion);
  }
}
