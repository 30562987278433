import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-pft-cft-exercise-score',
  templateUrl: './pft-cft-exercise-score.component.html',
  styleUrls: ['./pft-cft-exercise-score.component.scss']
})
export class PftCftExerciseScoreComponent implements OnInit {
  @Input() category: string;
  @Input() score: number;
  public notANumber = Number.NaN;

  constructor() { }

  ngOnInit() {
  }

  isNaN(score: any): boolean {
    return Number.isNaN(score) || score === 'NaN';
  }
}
