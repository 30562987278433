import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '../custom-material/shared.module'
import { VideoDialogComponent } from '../video-dialog/video-dialog.component'

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    VideoDialogComponent
  ],
  entryComponents: [VideoDialogComponent]
})
export class VideoDialogModule { }
