import { Component, OnInit, Input } from '@angular/core'
import { Store, Select } from '@ngxs/store'
import { DocumentService } from 'app/services/document.service'
import { WorkoutState } from 'app/state/workout/workout.state'
import * as _ from 'lodash'
import { ExecuteSet } from 'app/state'
import { WorkoutSession } from 'app/models/workout-session-document.model'
import { ExerciseProgramDocumentUtils, getDefaultParams } from 'fitforce-document-sync'
import { SetsRepsLoadPipe } from 'app/pipes/sets-reps-load.pipe'

@Component({
  selector: 'app-exercise-set-item',
  templateUrl: './exercise-set-item.component.html',
  styleUrls: ['./exercise-set-item.component.scss']
})
export class ExerciseSetItemComponent implements OnInit {
  @Input() isHistory: boolean
  @Input() workoutSession: WorkoutSession
  currentWorkoutSession: WorkoutSession
  @Input() hasRM: any
  @Input() currentWorkoutIndex: number
  @Input() currentRowIndex: number
  @Input() setIndex: number
  @Input() execution: any
  setParams: any
  setDetails: any
  loadPercentage: number

  constructor(
    private store: Store,
    private documentService: DocumentService,
    private setRepsLoadPipe: SetsRepsLoadPipe,
  ) {}

  ngOnInit(): void {
    this.loadData()
  }

  async loadData() {
    this.currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession)
    if (this.workoutSession) {
      this.currentWorkoutSession = this.workoutSession
    }
    if (!this.documentService.store.exerciseProgram) {
      await this.documentService.restoreData().toPromise()
    }
    const parameterSelections =
      this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex].parameters
    const loadPercentage = parameterSelections['load']
    if (loadPercentage) {
      this.loadPercentage = loadPercentage / 100
    }
    const exerciseReference = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex].exercise;
    const exercise = exerciseReference ? this.documentService.store.exerciseProgram.document.exercises.find(
      ex => ex.id === exerciseReference.exercise_program_id
    ) : undefined;
    if (exercise) {
      this.setParams = ExerciseProgramDocumentUtils.getPossibleParamsForExercise(
        this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex].exercise.exercise_program_id,
        this.documentService.store.exerciseProgram.document
      )
    } else {
      this.setParams = getDefaultParams();
    }
    this.getSetsRepsLoad()
  }

  isExecuted(setIndex: number) {
    const tierRow = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex]
    if (tierRow.executions[setIndex] && tierRow.executions[setIndex].complete_time) {
      return true
    }
    return false
  }

  inSetParams(param: string) {
    if (this.setParams) {
      return this.setParams.some(p => p.param === param)
    }
    return true
  }

  onExecuteSet(setIndex) {
    const tierRow = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex]
    this.store.dispatch(new ExecuteSet(setIndex, tierRow.executions[setIndex].parameter_values)).subscribe(() => {
      this.loadData()
    })
  }

  getSetsRepsLoad() {
    this.setDetails = this.setRepsLoadPipe.transform({
      setParams: this.setParams,
      currentWorkoutSession: this.currentWorkoutSession,
      currentWorkoutIndex: this.currentWorkoutIndex,
      currentRowIndex: this.currentRowIndex,
      execution: this.execution,
      loadPercentage: this.loadPercentage,
      hasSet: false
    })
  }
}
