import { Injectable } from '@angular/core'
import {trigger, query, transition, style, animate, state, group} from '@angular/animations'

export enum PAGETRANSITIONS {
  LEFT,
  RIGHT,
  TOP,
  BOTTOM
}
@Injectable()
export class PageTransitionService {

  currentAnimation: any = null
  currentAnimationId: PAGETRANSITIONS = null
  public animations: any
  constructor() {

  }
  setCurrentAnimation(animationId: PAGETRANSITIONS) {
    let nextAnimation = ''
    let isDuplicate = false

    switch (animationId) {
        case PAGETRANSITIONS.LEFT:
            nextAnimation = 'slideToLeft'
            break
        case PAGETRANSITIONS.RIGHT:
            nextAnimation = 'slideToRight'
            break
        case PAGETRANSITIONS.TOP:
            nextAnimation = 'slideToTop'
            break
        case PAGETRANSITIONS.BOTTOM:
            nextAnimation = 'slideToBottom'
            break
    }
    if (this.currentAnimation && (this.currentAnimation.indexOf('Duplicate') > -1)) {
        isDuplicate = true
    }

    /* add duplicate if previous animation otherwise animation will not work */
    if ((animationId === this.currentAnimationId) && !isDuplicate) {
        nextAnimation = nextAnimation + 'Duplicate'
    }

    this.currentAnimation = nextAnimation
    this.currentAnimationId = animationId
  }
  getCurrentAnimation() {
    return this.currentAnimation
  }
}
