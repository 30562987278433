import * as tslib_1 from "tslib";
import { DatabaseRecord } from '../../classes/database-record.class';
var SurveyChoice = /** @class */ (function (_super) {
    tslib_1.__extends(SurveyChoice, _super);
    function SurveyChoice(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.id = data['id'] || '';
        _this.label = data['label'];
        _this.scoreWeight = data['score_weight'];
        return _this;
    }
    SurveyChoice.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, [
            'id',
            'label',
            'score_weight'
        ]);
    };
    SurveyChoice.objectStoreName = 'surveyChoices';
    return SurveyChoice;
}(DatabaseRecord));
export { SurveyChoice };
