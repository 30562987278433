import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ScoreResult } from 'app/models/score-document.model';
import { DataService } from 'app/services/data.service';
import { Observation, ObservationDocument } from 'fitforce-document-sync';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pft-cft-chart',
  templateUrl: './pft-cft-chart.component.html',
  styleUrls: ['./pft-cft-chart.component.scss'],
})
export class PftCftChartComponent implements OnInit, OnDestroy {
  private resetSub: Subscription = new Subscription();

  /*
   * PLOTLY NOTES:
   *
   * Hover label of Trace (Legend) is white... https://community.plotly.com/t/trace-hover-label-color/12584
   * Merging dates into Months require a different data structure
   * No way to hide week numbers (the main axis used to separate out dates)
   * No way to merge a scatter+line plot WITH a multiaxis bar chart
   */
  graph: any = {
    layout: {
      showlegend: false,
      height: 140,
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      xaxis: {
        showticklabels: true,
        tickmode: 'array',
        tickangle: '-30',
        tickfont: {
          size: 10,
          color: '#FFFFFF',
        },
        showgrid: false,
      },
      yaxis: {
        showticklabels: true,
        tickfont: {
          size: 10,
          color: '#FFFFFF',
        },
        showgrid: false,
        range: [0, 350],
      },
      margin: {
        l: 30,
        r: 0,
        b: 40,
        t: 5,
      },
      hoverlabel: {
        bgcolor: '#666666',
        font: { color: '#FFFFFF' },
      },
    },
    config: {
      displayModeBar: false,
      responsive: true,
    },
  };

  targetPftScore = 235;
  targetPftScoreMid = 200;
  targetPftScoreLow = 150;
  targetCftScore = 235;
  targetCftScoreMid = 200;
  targetCftScoreLow = 150;

  // Stored by YYYY-MM
  pftScores = {
    actual: {},
    target: {},
    targetMid: {},
    targetLow: {},
  };

  cftScores = {
    actual: {},
    target: {},
    targetMid: {},
    targetLow: {},
  };

  loading = false;

  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.init();

    this.resetSub = this.dataService.pftCftChartReset$.subscribe(() => {
      this.init();
    });
  }

  ngOnDestroy(): void {
    this.resetSub.unsubscribe();
  }

  init() {
    this.loading = true;
    this.initAsync().then(() => {
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

  async initAsync() {
    this.pftScores = await this.getPftScores();
    this.cftScores = await this.getCftScores();
    this.setChart();
  }

  // currently uses mock data below; update for real data
  async getPftScores() {
    const pftScoreDocs = await this.dataService.getPftScoreDocuments();
    const actualScores = {};
    const targetScores = {};
    const targetScoresMid = {};
    const targetScoresLow = {};

    const startDate = moment(new Date()).subtract(30, 'days');
    const endDate = moment(new Date()).add(5, 'days');
    const startDateKey = startDate.format('YYYY-MM-DD');
    const endDateKey = endDate.format('YYYY-MM-DD');
    targetScores[startDateKey] = this.targetPftScore;
    targetScores[endDateKey] = this.targetPftScore;
    targetScoresMid[startDateKey] = this.targetPftScoreMid;
    targetScoresMid[endDateKey] = this.targetPftScoreMid;
    targetScoresLow[startDateKey] = this.targetPftScoreLow;
    targetScoresLow[endDateKey] = this.targetPftScoreLow;

    _.each(pftScoreDocs, (pft: any) => {
      if (pft && pft.document && pft.document.result && pft.document.result.score) {
        const date = moment(pft.document.timestamp);
        const result = <ScoreResult>pft.document.result;
        const pftScore = result.score;
        const dateKey = date.format('YYYY-MM-DD');
        actualScores[dateKey] = pftScore;
        targetScores[dateKey] = this.targetPftScore; // update with whatever the target score is
        targetScoresMid[dateKey] = this.targetPftScoreMid;
        targetScoresLow[dateKey] = this.targetPftScoreLow;
      }
    });

    // console.log('pft actual scores', actualScores);

    return {
      actual: actualScores,
      target: targetScores,
      targetMid: targetScoresMid,
      targetLow: targetScoresLow,
    };
  }

  async getCftScores() {
    const cftScoreDocs = await this.dataService.getCftScoreDocuments();
    const actualScores = {};
    const targetScores = {};
    const targetScoresMid = {};
    const targetScoresLow = {};

    const startDate = moment(new Date()).subtract(30, 'days');
    const endDate = moment(new Date()).add(5, 'days');
    const startDateKey = startDate.format('YYYY-MM-DD');
    const endDateKey = endDate.format('YYYY-MM-DD');
    targetScores[startDateKey] = this.targetCftScore;
    targetScores[endDateKey] = this.targetCftScore;
    targetScoresMid[startDateKey] = this.targetCftScoreMid;
    targetScoresMid[endDateKey] = this.targetCftScoreMid;
    targetScoresLow[startDateKey] = this.targetCftScoreLow;
    targetScoresLow[endDateKey] = this.targetCftScoreLow;

    _.each(cftScoreDocs, (cft: any) => {
      if (cft && cft.document && cft.document.result && cft.document.result.score) {
        const date = moment(cft.document.timestamp);
        const result = <ScoreResult>cft.document.result;
        const cftScore = result.score;
        const dateKey = date.format('YYYY-MM-DD');
        actualScores[dateKey] = cftScore;
        targetScores[dateKey] = this.targetCftScore; // update with whatever the target score is
        targetScoresMid[dateKey] = this.targetCftScoreMid;
        targetScoresLow[dateKey] = this.targetCftScoreLow;
      }
    });

    // console.log('cft actual scores', actualScores);

    return {
      actual: actualScores,
      target: targetScores,
      targetMid: targetScoresMid,
      targetLow: targetScoresLow,
    };
  }

  getMockCftScores(): any[] {
    const scores = [];
    const document1 = {
      id: '17736ee3-af51-4af6-affc-802e2e6f1baf',
      measure: {
        description: '',
        tags: ['Fitness', 'Evaluation'],
        parameters: [
          {
            property: 'value',
            type: 'score',
            required: true,
            unit: 'N/A',
          },
        ],
      },
      result: {
        type: 'CFT',
        score: 269,
      },
      timestamp: '2022-01-31T15:02:22.459Z',
    };
    const document2 = {
      id: 'a5940a51-7623-47b4-b4f9-54e01b5d1d0f',
      measure: {
        description: '',
        tags: ['Fitness', 'Evaluation'],
        parameters: [
          {
            property: 'value',
            type: 'score',
            required: true,
            unit: 'N/A',
          },
        ],
      },
      result: {
        type: 'CFT',
        score: 130,
      },
      timestamp: '2022-01-21T15:02:22.459Z',
    };
    const doc1 = {
      document: document1,
    };
    const doc2 = {
      document: document2,
    };
    scores.push(doc1);
    scores.push(doc2);
    return scores;
  }

  getMockPftScores() {
    const scores = [];
    const document1 = {
      id: 'dd06eb7d-c11b-45ed-9362-07af77533396',
      measure: {
        description: '',
        tags: ['Fitness', 'Evaluation'],
        parameters: [
          {
            property: 'value',
            type: 'score',
            required: true,
            unit: 'N/A',
          },
        ],
      },
      result: {
        type: 'PFT',
        score: 300,
      },
      timestamp: '2022-01-31T15:02:22.459Z',
    };
    const document2 = {
      id: '3c0fa605-fcf2-4dff-8835-888d4847a5e7',
      measure: {
        description: '',
        tags: ['Fitness', 'Evaluation'],
        parameters: [
          {
            property: 'value',
            type: 'score',
            required: true,
            unit: 'N/A',
          },
        ],
      },
      result: {
        type: 'PFT',
        score: 200,
      },
      timestamp: '2022-01-21T15:02:22.459Z',
    };
    const doc1 = {
      document: document1,
    };
    const doc2 = {
      document: document2,
    };
    scores.push(doc1);
    scores.push(doc2);
    return scores;
  }

  setChart(): void {
    const pftActualChartData = this.formatChartData(
      this.pftScores.actual,
      'scatter',
      false
    );
    const pftTargetChartData = this.formatChartData(
      this.pftScores.target,
      'bar',
      true
    );
    const pftTargetChartDataMid = this.formatChartData(
      this.pftScores.targetMid,
      'bar',
      true
    );
    const pftTargetChartDataLow = this.formatChartData(
      this.pftScores.targetLow,
      'bar',
      true
    );
    const cftActualChartData = this.formatChartData(
      this.cftScores.actual,
      'scatter',
      false
    );
    const cftTargetChartData = this.formatChartData(
      this.cftScores.target,
      'bar',
      true
    );
    const cftTargetChartDataMid = this.formatChartData(
      this.cftScores.targetMid,
      'bar',
      true
    );
    const cftTargetChartDataLow = this.formatChartData(
      this.cftScores.targetLow,
      'bar',
      true
    );
    this.setChartData(
      pftActualChartData,
      pftTargetChartData,
      pftTargetChartDataMid,
      pftTargetChartDataLow,
      cftActualChartData,
      cftTargetChartData,
      cftTargetChartDataMid,
      cftTargetChartDataLow,
    );
  }

  formatChartData(scores: Object, type: string, isTarget: boolean): any {
    const graphData: any = {
      x: [],
      xKey: {},
      y: [],
      barWidth: [],
      type: type,
    };

    if (type === 'scatter') {
      graphData.mode = 'lines+markers';
      const graphColor = isTarget ? '#D68C38' : '#51CDD7';
      graphData.marker = {
        color: graphColor,
        size: 10,
      };
    }
    // TODO: update data with PFT/CFT-relative scores

    Object.keys(scores)
      .sort()
      .forEach((dateKey) => {
        const score = scores[dateKey];
        if (score) {
          const xLabel = moment(dateKey).format('M/D/YY');
          graphData.xKey[moment(dateKey).format('YYYY-MM-DD')] = xLabel;
          graphData.x.push(xLabel);
          graphData.y.push(score);
          if (type === 'bar') {
            graphData.barWidth.push(1);
          }
        }
      });

    return graphData;
  }

  setChartData(
    pftActualScatterChartData,
    pftTargetScatterChartData,
    pftTargetScatterChartDataMid,
    pftTargetScatterChartDataLow,
    cftActualScatterChartData,
    cftTargetScatterChartData,
    cftTargetScatterChartDataMid,
    cftTargetScatterChartDataLow
  ) {
    const categoryKeys = {
      ...pftActualScatterChartData.xKey,
      ...pftTargetScatterChartData.xKey,
      ...cftActualScatterChartData.xKey,
      ...cftTargetScatterChartData.xKey,
    };
    this.graph.layout.xaxis.categoryarray = Object.keys(categoryKeys)
      .sort()
      .map((x) => categoryKeys[x]);
    this.graph.data = [
      {
        x: pftActualScatterChartData.x,
        y: pftActualScatterChartData.y,
        name: 'Actual',
        type: 'scatter',
        mode: 'lines+markers',
        marker: { color: '#0181FA', size: 10 }, // #D68C38 is original orange
      },
      {
        x: pftTargetScatterChartData.x,
        y: pftTargetScatterChartData.y,
        name: 'Target (1st)',
        type: 'scatter',
        mode: 'lines+points',
        marker: { color: 'green' }, // #51CDD7 is original cyan blue
        line: {
          dash: 'dash',
        },
      },
      {
        x: pftTargetScatterChartDataMid.x,
        y: pftTargetScatterChartDataMid.y,
        name: 'Target (2nd)',
        type: 'scatter',
        mode: 'lines+points',
        marker: { color: '#D68C38' },
        line: {
          dash: 'dash',
        },
      },
      {
        x: pftTargetScatterChartDataLow.x,
        y: pftTargetScatterChartDataLow.y,
        name: 'Target (3rd)',
        type: 'scatter',
        mode: 'lines+points',
        marker: { color: '#c1121f' },
        line: {
          dash: 'dash',
        },
      },
      {
        x: cftActualScatterChartData.x,
        y: cftActualScatterChartData.y,
        name: 'Actual',
        type: 'scatter',
        mode: 'lines+markers',
        marker: { color: 'lightgreen', size: 10 },
      },
      // {
      //   x: cftTargetScatterChartData.x,
      //   y: cftTargetScatterChartData.y,
      //   name: "CFT Target (1st)",
      //   type: "scatter",
      //   mode: "lines+points",
      //   marker: { color: "green" },
      //   line: {
      //     dash: "dash",
      //   },
      // },
      // {
      //   x: cftTargetScatterChartDataMid.x,
      //   y: cftTargetScatterChartDataMid.y,
      //   name: "CFT Target (2nd)",
      //   type: "scatter",
      //   mode: "lines+points",
      //   marker: { color: "#D68C38" },
      //   line: {
      //     dash: "dash",
      //   },
      // },
      // {
      //   x: cftTargetScatterChartDataLow.x,
      //   y: cftTargetScatterChartDataLow.y,
      //   name: "CFT Target (3rd)",
      //   type: "scatter",
      //   mode: "lines+points",
      //   marker: { color: "#c1121f" },
      //   line: {
      //     dash: "dash",
      //   },
      // },
    ];
  }
}
