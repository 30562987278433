import * as _ from 'lodash';
import * as moment from 'moment';
import { DatabaseRecord } from '../../classes/database-record.class';

export class SurveyChoice extends DatabaseRecord {
  static objectStoreName = 'surveyChoices'

  id: string;
  label: string;
  scoreWeight: number;

  constructor (data: {} = {}) {
    super(data)
    this.id = data['id'] || ''
    this.label = data['label']
    this.scoreWeight = data['score_weight']
  }

  toJSON () {
    return super.toJSON([
      'id',
      'label',
      'score_weight'
    ]);
  }
}
