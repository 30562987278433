import { AboutDialogComponent } from '../dashboard/components/about-dialog/about-dialog.component';
import { AdaptationsStatusComponent } from './adaptations-status/adaptations-status.component';
import { AlertDialogComponent } from './alerts/alert-dialog/alert-dialog.component'
import { AlertsComponent } from './alerts/alerts.component';
import { CommonModule } from '@angular/common'
import { FeedbackDialogComponent } from '../dashboard/components/feedback-dialog/feedback-dialog.component'
import { HeaderComponent } from './header.component'
import { NgModule } from '@angular/core'
import { ProfileComponent } from './profile/profile.component';
import { SharedModule } from '../custom-material/shared.module';
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';
import { NotificationDialogComponent } from './dialogs/notification-dialog/notification-dialog.component';
import { ProfileDialogComponent } from './dialogs/profile-dialog/profile-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    HeaderComponent,
    AboutDialogComponent,
    FeedbackDialogComponent,
    AlertsComponent,
    AlertDialogComponent,
    ProfileComponent,
    AdaptationsStatusComponent,
    ChangePasswordDialogComponent,
    NotificationDialogComponent,
    ProfileDialogComponent,
  ],
  entryComponents: [
    AboutDialogComponent,
    FeedbackDialogComponent,
    AlertDialogComponent,
    ProfileComponent,
    ChangePasswordDialogComponent,
    NotificationDialogComponent,
    ProfileDialogComponent,
  ],
  exports: [
    HeaderComponent
  ],
})
export class HeaderModule { }
