import { Injectable } from '@angular/core';
import { Resource, ResourceType } from './models/resource';
import { environment } from 'environments/environment';
import { MockResourceService } from './mock-resource.service';
import { ResourcesRestService } from './resources-rest.service';
import { SurveyTemplateDocument, SurveyDocument, SurveyTemplate, SurveyChoice } from './models/survey-template-document';
import { SurveyResponseDocument, SurveyResponse, SurveyAnswer } from './models/survey-response-document';
import { SurveyResultColor, SurveyResult, SurveyAdditionalResource } from './models/survey';
import { Observable, from } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { FormGroup } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  private surveyResults: SurveyResult[] = [
    {
      id: SurveyResultColor.GOOD,
      label: 'Good',
      color: '#00ff00' // green
    },
    {
      id: SurveyResultColor.OKAY,
      label: 'Okay',
      color: 'yellow'
    },
    {
      id: SurveyResultColor.LOW,
      label: 'Low',
      color: 'orange'
    },
    {
      id: SurveyResultColor.VERY_LOW,
      label: 'Very Low',
      color: 'red'
    }
  ];

  private surveyAdditionalResources: SurveyAdditionalResource[] = [
    {
      id: '892955d2-5b45-11ec-bf63-0242ac130002',
      label: 'Physical Fitness',
      text: [
        `Physically resilient
        Marines embrace
        discomfort, knowing it
        leads to greater
        endurance, performance,
        awareness of one's
        current limits, and ability
        to train and challenge
        those current limits.
        Includes balanced physical
        training planning, as well
        as nutrition and injury
        prevention.`,
        `Having the strength, endurance, coordination,
        and reserve capacity necessary for success
        in any environment. Possessing the
        knowledge, skills, attitudes, and resources
        necessary to obtain, sustain, and regain
        optimum personal physical performance.`
      ],
      box1Title: 'Embrace Discomfort',
      photo1: 'assets/images/total-fitness-survey/medium-res/physical.jpg',
      box2Links: [
        {
          text: 'Force Fitness Facebook Page',
          href: 'https://www.facebook.com/USMCFFD'
        },
        {
          text: 'Force Fitness Instagram Page',
          href: 'https://www.instagram/marinecorpsforcefitness'
        },
        {
          text: 'PFT/CFT Preparation',
          href: 'https://www.fitness.marines.mil/PFT-CFT_Standards17/'
        },
        {
          text: 'Marine Corps Fitness Website',
          href: 'https://www.fitness.marines.mil/'
        },
        {
          text: 'FitForce mobile app',
          href: 'https://usmcfitforce.com/'
        },
        {
          text: 'USMC HIT Workout of the day',
          href: 'https://www.facebook.com/pg/HighIntensityTacticalTraininghitt/posts/'
        },
        {
          text: 'Performance Nutrition',
          href: 'https://www.fitness.marines.mil/PerformanceNutrition/'
        },
        {
          text: 'Physical Fitness Centers',
          href: 'https://usmc-mccs.org/services/fitness/fitness-centers/'
        },
        {
          text: 'Semper Fit Health Promotion',
          href: 'https://usmc-mccs.org/services/fitness/health-promotion/'
        },
        {
          text: 'High Intensity Tactical Training',
          href: 'https://usmc-mccs.org/MCCS/index.cfm/locations/'
        },
        {
          text: 'CHAMP Physical Fitness resources',
          href: 'https://www.hprc-online.org/physical-fitness/training-performance'
        }
      ],
      photo2: 'assets/images/total-fitness-survey/medium-res/physical_2.jpg',
      box3Quote: `"Marines develop strong mental, moral, spiritual, and ethical understanding
      because they are as important as physical skills when operating in the violence
      of combat." – MCDP-7`
    },
    {
      id: '89295834-5b45-11ec-bf63-0242ac130002',
      label: 'Mental Fitness',
      text: [
        `Mentally resilient Marines
        embrace life-long learning
        knowing it leads to faster
        decision-making under
        stress, a strong mindset,
        and the ability to solve
        complex problems.
        Includes professional and
        personal development.`,
        `Remaining engaged in psychologically healthy
        thinking and behaviors. Includes one’s mindset,
        attitudes, and practices that help deal with
        various stressors that impede overall
        performance. Able to acknowledge stress, seek
        help early, eliminate stigma around seeking
        help, help other Marines/Sailors, have strong
        relationships and friendships, have healthy
        family relationships, balance family and work
        requirements, and understand the principles of
        Combat Operational Stress Control (COSC).`
      ],
      box1Title: 'Embrace Learning',
      photo1: 'assets/images/total-fitness-survey/medium-res/mental.jpg',
      box2Links: [
        {
          text: 'Mental Health Care',
          href: 'https://www.tricare.mil/CoveredServices/Mental/GettingMHCare'
        },
        {
          text: 'Community Counseling',
          href: 'https://usmc-mccs.org/services/support/community-counseling/'
        },
        {
          text: 'Military and Family Life Counselors',
          href: 'https://usmc-mccs.org/services/support/military-family-life-counselors/'
        },
        {
          text: 'National Suicide Prevention Hotline',
          href: 'https://suicidepreventionlifeline.org/'
        },
        {
          text: 'Sexual Assault Prevention',
          href: 'https://usmc-mccs.org/services/support/sexual-assault-prevention/'
        },
        {
          text: 'SAFE Helpline',
          href: 'https://safehelpline.org/'
        },
        {
          text: 'Substance Abuse',
          href: 'https://usmc-mccs.org/services/support/substance-abuse/'
        },
        {
          text: 'Military One Source',
          href: 'https://www.militaryonesource.mil/'
        },
        {
          text: 'CHAMP Mental Fitness Resources',
          href: 'https://www.hprc-online.org/mental-fitness/mental-health'
        },
        {
          text: 'Marine Corps Mental Fitness Website',
          href: 'https://www.fitness.marines.mil/Resilience/Mental-Fitness/'
        },
        {
          text: 'Family Advocacy',
          href: 'https://www.usmc-mccs.org/services/support/family-advocacy/'
        }
      ],
      photo2: 'assets/images/total-fitness-survey/medium-res/mental_2.jpg',
      box3Quote: `Development of the moral compass is required on and off today’s complex battlefield. Marine Corps
      Core Values/Leadership Traits and Principles and tie-ins assist in developing the Ethical Warrior. –
      MCO 1500.59A
      `
    },
    {
      id: '8929594c-5b45-11ec-bf63-0242ac130002',
      label: 'Spiritual Fitness',
      box1Title: 'Embrace Values',
      text: [
      `1) Spiritual fitness is being able to adhere to beliefs, principles, or values needed to persevere and
      prevail in accomplishing missions (Chairman of the Joint Chiefs of Staff Instruction 3405.01).`,
      `2) Spiritual
      Fitness is the identification of personal faith, foundational values, and moral living from a variety of sources
      and traditions that help Marines live out Core Values of Honor, Courage, and Commitment, live the warrior ethos,
      and exemplify the character expected of a United States Marine (Marine Corps Human Performance/Resilience website)`,
      `Spiritually
      resilient Marines embrace
      doing what’s right, not
      what’s easy. Doing what’s
      right increases strength of
      character and spirit.
      “Embracing right” starts with
      embracing both personal
      values and Marine Corps
      Core Values of Honor,
      Courage, and Commitment.`
      ],
      photo1: 'assets/images/total-fitness-survey/medium-res/spiritual.jpg',
      box2Links: [
        {
          text: 'Marine Corps Spiritual Fitness website',
          href: 'https://www.fitness.marines.mil/Resilience/Spiritual-Fitness/'
        },
        {
          text: 'Spiritual Fitness Leaders Guide',
          href: 'https://www.fitness.marines.mil/Portals/211/Resiliency%20Videos/SF%20Leaders%20Guide%20Notebook%20FEB2022.pdf?ver=Dh8gEr_1SuTaqzEtZ1IDlw%3d%3d'
        },
        {
          text: 'Spiritual Fitness PowerPoint',
          href: 'https://www.hqmc.marines.mil/Portals/61/Docs/Chaplain%20MC/SF/Spiritual%20Fitness%20FFI.pptx'
        },
        {
          text: 'CHAMP Spiritual Fitness Resources',
          href: 'https://www.hqmc.marines.mil/Portals/61/Docs/Chaplain%20MC/SF/Spiritual%20Fitness%20FFI.pptx'
        },
        {
          text: 'MCCS Spiritual Fitness Website',
          href: 'https://usmc-mccs.org/articles/strengthening-your-spiritual-fitness/'
        }
      ],
      photo2: 'assets/images/total-fitness-survey/medium-res/spiritual_2.jpg',
      box3Quote: `Integrity. Uprightness of character and soundness of
      moral principles. Integrity includes the qualities of
      truthfulness and honesty. – MCRP 611D`
    },
    {
      id: '89295a50-5b45-11ec-bf63-0242ac130002',
      label: 'Social Fitness',
      text: [
        `Socially resilient
        Marines embrace trust
        in others, knowing
        there is strength in
        numbers. Marines also
        embrace the challenge
        of proving themselves
        trustworthy to their
        fellow Marines by
        practicing
        accountability, follow-through, and integrity.`,
        `Giving and receiving support in positive relationships
        with peers, families, and communities. Maintaining
        strong relationships and friendships, having healthy
        family relationships, being kind and respectful to
        others, and seeking and enjoying companionship.
        `
      ],
      box1Title: 'Embrace Trust',
      photo1: 'assets/images/total-fitness-survey/medium-res/social.jpg',
      box2Links: [
        {
          text: 'Cross Cultural Competence',
          href: 'https://www.fitness.marines.mil/Portals/211/Resiliency%20Videos/Cross%20Cultural%20competency%20Resources_1.pdf?ver=lu5GDsamjfbkPBRtQCDjDg%3d%3d'
        },
        {
          text: 'Leading Respectful Conversation',
          href: 'https://www.fitness.marines.mil/Portals/211/Resiliency%20Videos/Leading%20Conversation%20Guidance_1.pdf?ver=5K9_02HtyeD4cs5W3FBQ-A%3d%3d'
        },
        {
          text: 'Single Marine Program',
          href: 'https://usmc-mccs.org/services/activities/single-marine-program/'
        },
        {
          text: 'CREDO Programs',
          href: 'https://www.usmc-mccs.org/services/relationships/chaplains-religious-enrichment-development-operation/#:~:text=Chaplain%20Religious%20Enrichment%20Development%20Operation%20%28CREDO%29%20provides%20regionally,Marines%20and%20their%20families%2C%20and%20enhance%20mission%20readiness.'
        },
        {
          text: 'Family Resources',
          href: 'https://usmc-mccs.org/topics/family/'
        },
        {
          text: 'USO',
          href: 'https://www.uso.org/locations/'
        },
        {
          text: 'Volunteering',
          href: 'https://usmc-mccs.org/index.cfm/services/activities/volunteering/'
        },
        {
          text: 'CHAMP Social Fitness resources',
          href: 'https://www.hprc-online.org/social-fitness/teams-leadership'
        },
        {
          text: 'Marine Corps Social Fitness Website',
          href: 'https://www.fitness.marines.mil/Resilience/Social-Fitness/'
        }
      ],
      photo2: 'assets/images/total-fitness-survey/medium-res/social_2.jpg',
      box3Quote: ''
    },
    {
      id: 'd8af2c35-44d6-407b-8f4f-aa2b3d2ec86b',
      label: 'Resiliency',
      text: [
        'Resiliency is an important aspect of a Marine\'s personal and professional life. It ensures that when faced with the challenges in and out of combat, Marines will be able to meet those challenges, drawing from internal and external sources of strength and support. Marines with a high degree of physical and cognitive performance build and maintain resiliency in the four areas of fitness: Physical, Mental, Social, and Spiritual'
      ],
      box1Title: '',
      photo1: '',
      box2Links: [
        {
          text: 'Marine Corps Resilience Website',
          href: 'https://www.fitness.marines.mil/Resilience/'
        },
        {
          text: 'Recommended Reading list 1',
          href: 'https://www.fitness.marines.mil/Portals/211/Resiliency%20Reading%20List_updated%2014AUG2020_1.pdf'
        },
        {
          text: 'Recommended Reading list 2',
          href: 'https://www.fitness.marines.mil/Portals/211/Fuller%20recommended%20resiliency%20resources.pdf'
        },
        {
          text: 'Recommended Reading list 3',
          href: 'https://www.fitness.marines.mil/Portals/211/Resiliency%20Resource%20Review-Slyman.pdf'
        },
        {
          text: 'CMC Professional Reading Program 2020',
          href: 'https://grc-usmcu.libguides.com/usmc-reading-list-2020'
        }
      ],
      photo2: 'assets/images/total-fitness-survey/medium-res/resiliency.jpg',
      box3Quote: `"Fitness" is a holistic
      approach to physical,
      mental, spiritual, and
      social fitness…Ensuring
      holistic wellbeing boosts
      morale, cohesiveness, and
      resiliency - enabling
      Marines to execute the
      toughest challenges and
      recuperate in shorter time.
      – Marine Leader
      Development`
    }
  ];

  constructor(private mockResourceService: MockResourceService,
    private resourcesRestService: ResourcesRestService,
    private authService: AuthService) { }

  public getSurveyTemplateDocumentIds(): Observable<any> {
    const jwt = 'Bearer ' + this.authService.getToken();
    return this.resourcesRestService.get(`SurveyTemplateDocument/`, { 'Authorization': jwt }, true);
  }

  public getSurveyTemplateDocument(templateUuid: string): Observable<SurveyTemplateDocument> {
    const jwt = 'Bearer ' + this.authService.getToken();
    return this.resourcesRestService.get(`SurveyTemplateDocument/` + templateUuid, { 'Authorization': jwt }, true);
  }

  public getSurveyResponseDocumentIds(): Observable<any> {
    const jwt = 'Bearer ' + this.authService.getToken();
    return this.resourcesRestService.get(`SurveyResponseDocument/`, { 'Authorization': jwt }, true);
  }

  public getSurveyResponseDocument(documentUuid: string): Observable<SurveyResponseDocument> {
    const jwt = 'Bearer ' + this.authService.getToken();
    return this.resourcesRestService.get(`SurveyResponseDocument/` + documentUuid, { 'Authorization': jwt }, true);
  }

  public getLegendSurveyResultArray(): SurveyResult[] {
    return this.surveyResults;
  }

  public findSurveyResultbyEnum(color: SurveyResultColor): SurveyResult {
    return this.surveyResults.find(result => result.id === color);
  }

  public findSurveyAdditionalResourcesByLabel(label: string): SurveyAdditionalResource {
    return this.surveyAdditionalResources.find(resource => resource.label === label);
  }

  public convertToSurveyResponse(form: FormGroup, surveyTemplate: SurveyTemplate, surveyChoices: SurveyChoice[]): SurveyResponse {
    const surveyQuestions = surveyTemplate.questions.sort((a, b) => a.order < b.order ? -1 : 1);
    const answers = [];
    for (let i = 0; i < surveyQuestions.length; ++i) {
      const questionId = surveyQuestions[i].id.toString();
      const choiceId = form.value[questionId];
      let choiceScore = 0;
      if (choiceId) {
        const choice = surveyChoices.find(surveyChoice => surveyChoice.id === choiceId);
        if (choice) {
          choiceScore = choice.score_weight;
        }
        const surveyAnswer = <SurveyAnswer> {
          question_id: questionId,
          choice_id: choiceId,
          choice_score: choiceScore
        };
        answers.push(surveyAnswer);
      }
    }
    const totalScore = this.sumSectionAnswerScores(answers);
    const surveyResponse = <SurveyResponse> {
      category_id: surveyTemplate.id,
      category_name: surveyTemplate.category,
      answers: answers,
      category_score: totalScore
    };
    return surveyResponse;
  }

  private escapeQuotes(str: string): string {
    return str.replace(new RegExp('["]'), '\"');
  }

  public submitSurveyResponseDocument(surveyTemplateDocumentUuid: string, surveyResponses: SurveyResponse[]): Observable<any> {
    const jwt = 'Bearer ' + this.authService.getToken();
    const userUuid: string = this.authService.getUserUUID();
    const surveyResponseDocument = this.createSurveyResponseDocument(surveyTemplateDocumentUuid, userUuid, surveyResponses);
    const documentUuid = surveyResponseDocument.id;
    const surveyResponseDocumentStr: string = this.convertSurveyResponseDocument(surveyResponseDocument);
    const stringVersion: string = this.escapeQuotes(surveyResponseDocumentStr);
    const payload = {
      'document': stringVersion
    };
    const headers = new HttpHeaders({'Authorization': jwt});
    const data = {
      'headers': headers,
      'payload': payload
    };
    return this.resourcesRestService.post(`SurveyResponseDocument/${documentUuid}`,
      { 'headers': { 'Authorization': jwt }, 'document': stringVersion }, true);
  }

  public convertSurveyResponseDocument(surveyResponseDocument: SurveyResponseDocument): string {
    return JSON.stringify(surveyResponseDocument);
  }

  public createSurveyResponseDocument(
    surveyTemplateDocumentUuid: string,
    userUuid: string,
    surveyResponses: SurveyResponse[]
  ): SurveyResponseDocument {
    const surveyResponseUuid = uuid();
    const surveyTotalScore = this.sumSurveySectionScores(surveyResponses);
    const surveyResponseDocument = <SurveyResponseDocument> {
      id: surveyResponseUuid,
      type: 'SurveyResponseDocument',
      user_uuid: userUuid,
      survey_template_id: surveyTemplateDocumentUuid,
      survey_response: surveyResponses,
      total_score: surveyTotalScore,
      timestamp: moment(Date.now())
    };
    return surveyResponseDocument;
  }

  private sumSurveySectionScores(surveyResponses: SurveyResponse[]) {
    let total = 0;
    if (!surveyResponses) {
      return total;
    }
    for (let i = 0; i < surveyResponses.length; ++i) {
      total += surveyResponses[i].category_score;
    }
    return total;
  }

  private sumSectionAnswerScores(answers: SurveyAnswer[]): number {
    let total = 0;

    if (!answers) {
      return total;
    }

    for (let i = 0; i < answers.length; ++i) {
      total += answers[i].choice_score;
    }

    return total;
  }

  public isOnlineSubscription(): Observable<boolean> {
    return this.resourcesRestService.isOnlineSubscription();
  }

}
