import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

export interface UpdatePasswordInterface {
  current: string;
  newPW: string;
  confirmPW: string;
}

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  public updatePassword: UpdatePasswordInterface = <UpdatePasswordInterface> {
    current: '',
    newPW: '',
    confirmPW: ''
  };

  minPasswordLength: number;


  constructor() { }

  ngOnInit() {
    this.minPasswordLength = environment.minPasswordLength === '{{MIN_PASSWORD_LENGTH}}'
    ? 15
    : Number.parseInt(environment.minPasswordLength, 10);
  }

  getRegex(str: string): RegExp {
    return new RegExp(str);
  }

}
