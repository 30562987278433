<h2 matDialogTitle>
  Account not Verified
</h2>
<mat-dialog-content fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
  <div>
    Please check your email for an account verification message and follow the instructions.
  </div>
  <div *ngIf="data.email">
    Didn't get the email? Click the button below to re-send the verification email.
  </div>
  <div  *ngIf="data.email" fxLayout="row" fxLayoutAlign="center center">
    <button mat-button (click)="sendVerificationEmail()">Re-send Email</button>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button mat-button mat-raised-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>
