import { NgModule } from '@angular/core'
import { CustomMaterialModule } from './custom-material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [
    // CommonModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MomentModule,
    ScrollingModule,
  ],
  exports: [
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MomentModule,
    ScrollingModule,
  ]
})
export class SharedModule { }
