
<div mat-dialog-title>
  <span *ngIf="isTierNote">Note</span>
  <span *ngIf="!isTierNote">Comment</span>
</div>
<mat-dialog-content>
  <mat-form-field class="full-width">
    <textarea matInput matTextareaAutosize
      [placeholder]="(isTierNote ? 'Notes' : 'Comments')"
      matFocusStart
      matAutosizeMinRows="3"
      matAutosizeMaxRows="5"
      [(ngModel)]="notes"
      [disabled]="isTierNote">
    </textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="cancelSelected()" fxFlex>Close</button>
  <button *ngIf="!isTierNote && !this.isHistory" class="yes-button" mat-button (click)="submitSelected()" cdkFocusInitial fxFlex>Save</button>
</mat-dialog-actions>
