<div *ngIf="tier && tier.circuit">
  <mat-expansion-panel class="collapsible-note" [disabled]="!!!tier.notes">
    <mat-expansion-panel-header>
      <mat-panel-title>
       <b>Tier {{ tier.order }}: {{ tier.name }}</b>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p *ngIf="tier.notes">{{ tier.notes }}</p>
  </mat-expansion-panel>
  <div class="exercise-info" fxLayout="column" fxLayoutGap="10px">
    <div class="exercise-details" fxLayout="row">
      <div fxFlex="100"  fxLayout="column">
        <div fxLayout="row" fxLayoutGap="20px" fxFlex="100">
          <div class="arrow arrow-left">
            <mat-icon *ngIf="hasPrevTier; else noPrev" (click)="onPrevTier()" svgIcon="icon_left_arrow"></mat-icon>
            <ng-template #noPrev>
              <mat-icon class="disabled" svgIcon="icon_left_arrow"></mat-icon>
            </ng-template>
          </div>
          <div class="main-details" fxFlex="70">
            <div class="exercise-title" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <div>CIRCUIT</div>
            </div>
            <div class="sets-reps-loads">
              {{ tierRunStats() }}
            </div>
          </div>
          <div class="arrow arrow-right">
            <mat-icon *ngIf="hasNextTier; else noNext"  (click)="onNextTier()" svgIcon="icon_right_arrow"></mat-icon>
            <ng-template #noNext>
              <mat-icon class="disabled" svgIcon="icon_right_arrow"></mat-icon>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="action-buttons" fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <button fxFlex mat-raised-button (click)="openCommentsDialog()">NOTES</button>
        <div *ngIf="this.tier" fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <div class="rounds-text">ROUNDS</div>
          <button mat-raised-button class="rounds-count"
          [ngClass]="{'rounds-at-zero': numRoundsComplete === 0, 'rounds-not-max': numRoundsComplete < numRoundsMax , 'rounds-at-max': numRoundsComplete === numRoundsMax }"
          (click)="increaseNumberRoundsComplete()" color="secondary">{{ numRoundsComplete || 0 }}</button>
        </div>
      </div>
  </div>
  <div class="execution-card" *ngFor="let row of tier.rows; let rowIndex = index">
    <mat-card fxLayout="row" fxLayoutGap="10px">
        <div fxLayoutAlign="center center">
          <div *ngIf="row | getExerciseVideo as video; else no_video">
            <mat-icon class="video-icon" (click)="openVideo(video)" color="primary">play_circle_filled</mat-icon>
          </div>
          <ng-template #no_video>
            <mat-icon class="video-icon disabled-icon">play_circle_outline</mat-icon>
          </ng-template>
        </div>
        <div fxLayout="column" fxLayoutGap="2px">
          <div>{{ row.exercise.name }}</div>
          <div class="exercise-params" (click)="openEditSet(rowIndex)">{{ getSetsRepsLoad(row, rowIndex) }}</div>
          <div *ngIf="row.notes" class="exercise-note">{{ row.notes }}</div>
        </div>
    </mat-card>
  </div>
</div>
