import { LogLevel } from 'fitforce-logging';

export const environment = {
  production: true,
  api_hostname: '{{HOSTNAME}}',
  api_url: 'https://api.usmcfitforce.com/',
  atp_gateway_url: '{{ATP_GATEWAY_URL}}',
  version: '1.1.0 (1c98ae7)',
  base: '/',
  logLevel: LogLevel.DEBUG,
  externalSupportUrl: 'https://usmcfitforce.com/',
  externalSupportHelpUrl: 'mailto:fitforce@aptima.com',
  preWktSurveyConfig: 'e211b946-d493-4b2b-a06b-d7c75ca355d4',
  registerUrl: 'https://account.usmcfitforce.com/',
  forgotUrl: 'https://account.usmcfitforce.com/forgot',
  plannerUrl: 'https://planner.usmcfitforce.com/',
  requestUrl: 'https://account.usmcfitforce.com/request',
  hideConsent: 'false',
  atpUrl: '/atp-graph/',
  minPasswordLength: '15',
};
