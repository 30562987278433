import * as tslib_1 from "tslib";
import { State, Action, Selector, Store } from '@ngxs/store';
import * as moment from 'moment';
import * as _ from 'lodash';
import { WorkoutStateModel } from './workout.model';
import { StartWorkout, PauseWorkout, ResumeWorkout, EndWorkout, ExecuteSet, AddNewSet, DeleteSet, ChangeExercise, SaveComments, EditSet, SetExecutionsRM, SaveWorkout, StartCircuit, StopCircuit, SetCircuitRounds, ResetWorkoutSession, } from './workout.actions';
import { DocumentService } from 'app/services/document.service';
import { AppState } from '../app/app.state';
import { DataService } from 'app/services/data.service';
import { WorkoutSessionDocument } from 'app/models/workout-session-document.model';
import { from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { produce } from 'immer';
import { WorkoutStateUtils } from './workout.state.utils';
import { PlanDocumentUtils } from 'fitforce-document-sync';
import { AuthState } from '../auth/auth.state';
var WorkoutState = /** @class */ (function () {
    function WorkoutState(store, dataService, documentService) {
        this.store = store;
        this.dataService = dataService;
        this.documentService = documentService;
    }
    WorkoutState.currentExerciseCount = function (state) {
        return state.currentExerciseCount;
    };
    WorkoutState.currentWorkoutSession = function (state) {
        return state.currentWorkoutSession;
    };
    WorkoutState.currentWorkoutIndex = function (state) {
        return state.currentWorkoutIndex;
    };
    WorkoutState.currentRowIndex = function (state) {
        return state.currentRowIndex;
    };
    WorkoutState.totalTime = function (state) {
        return state.totalTime;
    };
    WorkoutState.prototype.startWorkout = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var workoutDocument = _b.workoutDocument, measuredResponse = _b.measuredResponse;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var group, groupData, state, temp;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        group = this.store.selectSnapshot(AppState.activeGroupSelected);
                        return [4 /*yield*/, this.dataService.getGroup(group.uuid || group.key)];
                    case 1:
                        groupData = _c.sent();
                        state = produce(getState(), function (draft) {
                            var plan = _this.store.selectSnapshot(AppState.activePlanSelected);
                            var planDocument = _this.documentService.store.planDocuments.find(function (planDoc) {
                                return planDoc.document.id === plan.key;
                            });
                            var planMetaData = _this.documentService.store.planMetadata.find(function (planMetaDataDoc) {
                                return planMetaDataDoc.key === plan.key;
                            });
                            var activeUser = _this.store.selectSnapshot(AuthState.activeUser);
                            var workoutSession = _this.dataService.createWorkoutSessionDocument(groupData, [], planDocument, planMetaData, workoutDocument, activeUser);
                            draft.startTime = moment();
                            draft.pauseTime = moment();
                            draft.currentExerciseCount = 1;
                            draft.totalTime = 0;
                            draft.currentWorkoutIndex = 0;
                            draft.currentRowIndex = 0;
                            draft.currentWorkoutSession = workoutSession;
                        });
                        setState(state);
                        temp = getState();
                        // Save observations
                        this.saveMeasuredResponses(getState(), measuredResponse);
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkoutState.prototype.saveMeasuredResponses = function (state, measuredResponse) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var measures, responses, hasMeasure, measures_1, measures_1_1, measure, responseParameter, typedResponse, doc, e_1_1, isOnline;
            var e_1, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!measuredResponse) {
                            return [2 /*return*/, true];
                        }
                        measures = measuredResponse.measures;
                        responses = measuredResponse.response;
                        hasMeasure = false;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, 7, 8]);
                        measures_1 = tslib_1.__values(measures), measures_1_1 = measures_1.next();
                        _b.label = 2;
                    case 2:
                        if (!!measures_1_1.done) return [3 /*break*/, 5];
                        measure = measures_1_1.value;
                        if (!responses[measure.id]) return [3 /*break*/, 4];
                        hasMeasure = true;
                        responseParameter = measure.parameters.find(function (p) { return p.property === 'response'; });
                        typedResponse = responses[measure.id];
                        if (responseParameter) {
                            if (responseParameter.type === 'number') {
                                typedResponse = +typedResponse;
                            }
                            else {
                                typedResponse = typedResponse.toString();
                            }
                        }
                        doc = this.dataService.generateSurveyObservation(state.currentWorkoutSession.id, measure, typedResponse);
                        return [4 /*yield*/, this.dataService.storeObservationDocument(doc)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        measures_1_1 = measures_1.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _b.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (measures_1_1 && !measures_1_1.done && (_a = measures_1.return)) _a.call(measures_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8:
                        isOnline = this.store.selectSnapshot(AppState.isOnline);
                        if (isOnline && hasMeasure) {
                            this.dataService.pushDocuments();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkoutState.prototype.pauseWorkout = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var totalTime = _b.totalTime;
        var state = produce(getState(), function (draft) {
            draft.totalTime = moment().diff(draft.pauseTime, 'milliseconds', true) + totalTime;
            draft.pauseTime = moment();
        });
        setState(state);
    };
    WorkoutState.prototype.resumeWorkout = function (_a, _b) {
        var getState = _a.getState, patchState = _a.patchState;
        // const state = getState()
        // patchState(state)
    };
    WorkoutState.prototype.endWorkout = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var totalTime = _b.totalTime;
        var state = produce(getState(), function (draft) {
            draft.currentWorkoutSession.end_time = moment().format();
            draft.currentWorkoutSession.completion_time_ms = totalTime;
            draft.currentWorkoutSession.workout_complete = true;
        });
        setState(state);
    };
    WorkoutState.prototype.startCircuit = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var state = produce(getState(), function (draft) {
            var cwx = draft.currentWorkoutIndex;
            if (draft.currentWorkoutSession.workout.tiers[cwx].circuit
                && draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions.length) {
                draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions[0].start_time = moment().format();
            }
            else {
                draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions[0] = {
                    start_time: moment().format()
                };
            }
        });
        setState(state);
    };
    WorkoutState.prototype.stopCircuit = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var state = produce(getState(), function (draft) {
            var cwx = draft.currentWorkoutIndex;
            draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions[0].end_time = moment().format();
        });
        setState(state);
    };
    WorkoutState.prototype.setCircuitRounds = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var rounds = _b.rounds, tierIndex = _b.tierIndex;
        var state = getState();
        var draftState = produce(state, function (draft) {
            var cwx = state.currentWorkoutIndex;
            if (tierIndex || tierIndex === 0) {
                cwx = tierIndex;
            }
            if (!draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions.length) {
                draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions.push({
                    num_rounds: rounds
                });
            }
            draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions[0].num_rounds = rounds;
        });
        setState(draftState);
    };
    WorkoutState.prototype.executeSet = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var setIndex = _b.setIndex, paramValues = _b.paramValues;
        var state = produce(getState(), function (draft) {
            var cwx = draft.currentWorkoutIndex;
            var crx = draft.currentRowIndex;
            var execution = draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions[setIndex];
            // This was complete, uncomplete it
            if (execution.complete_time) {
                execution.complete_time = undefined;
            }
            else {
                execution.complete_time = moment().format();
            }
            // tslint:disable-next-line:max-line-length
            draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions[setIndex] = execution;
        });
        setState(state);
    };
    WorkoutState.prototype.editSet = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var tierIndex = _b.tierIndex, rowIndex = _b.rowIndex, setIndex = _b.setIndex, paramValues = _b.paramValues, completeTime = _b.completeTime, manual = _b.manual;
        if (manual) {
            paramValues['manual'] = true;
        }
        var state = produce(getState(), function (draft) {
            draft.currentWorkoutSession.workout.tiers[tierIndex].rows[rowIndex].executions[setIndex].complete_time = completeTime;
            draft.currentWorkoutSession.workout.tiers[tierIndex].rows[rowIndex].executions[setIndex].parameter_values = paramValues;
            if (!completeTime) {
                draft.currentWorkoutSession.workout.tiers[tierIndex].rows[rowIndex].executions[setIndex].editted = true;
            }
        });
        setState(state);
    };
    WorkoutState.prototype.addNewSet = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var paramValues = _b.paramValues;
        var state = produce(getState(), function (draft) {
            var cwx = draft.currentWorkoutIndex;
            var crx = draft.currentRowIndex;
            var executions = draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions;
            executions.push({
                parameter_values: paramValues,
                user_added: true
            });
            draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions = executions;
        });
        setState(state);
    };
    WorkoutState.prototype.deleteSet = function (_a) {
        var getState = _a.getState, setState = _a.setState;
        var state = produce(getState(), function (draft) {
            var cwx = draft.currentWorkoutIndex;
            var crx = draft.currentRowIndex;
            var executions = draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions;
            if (executions[executions.length - 1].user_added) {
                executions.pop();
            }
            draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions = executions;
        });
        setState(state);
    };
    WorkoutState.prototype.changeExcercise = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var prev = _b.prev;
        var state = produce(getState(), function (draft) {
            var cwx = draft.currentWorkoutIndex;
            var tier = draft.currentWorkoutSession.workout.tiers[cwx];
            var tiersLength = draft.currentWorkoutSession.workout.tiers.length;
            var rowsLength = tier.rows.length;
            if (prev) {
                // First row? Previous tier
                if (tier.circuit || draft.currentRowIndex === 0) {
                    // First tier? Do nothing
                    if (cwx === 0) {
                        // Do nothing
                    }
                    else {
                        var prevTier = draft.currentWorkoutSession.workout.tiers[cwx - 1];
                        draft.currentWorkoutIndex = cwx - 1;
                        draft.currentRowIndex = 0;
                        if (prevTier.circuit) {
                            draft.currentRowIndex = 0;
                            draft.currentExerciseCount = draft.currentExerciseCount - 1;
                        }
                        else if (prevTier.rows.length) {
                            draft.currentRowIndex = prevTier.rows.length - 1;
                            draft.currentExerciseCount = draft.currentExerciseCount - 1;
                        }
                    }
                }
                else {
                    draft.currentRowIndex = draft.currentRowIndex - 1;
                    if (draft.currentRowIndex < 0) {
                        draft.currentRowIndex = 0;
                    }
                    draft.currentExerciseCount = draft.currentExerciseCount - 1;
                }
            }
            else {
                // Last row? Next tier
                if (tier.circuit || draft.currentRowIndex >= rowsLength - 1) {
                    // Last tier? End workout
                    if (cwx >= tiersLength) {
                        // Do nothing
                    }
                    else {
                        var nextTier = draft.currentWorkoutSession.workout.tiers[cwx + 1];
                        draft.currentWorkoutIndex = cwx + 1;
                        draft.currentRowIndex = 0;
                        if ((tier.circuit || rowsLength) && (nextTier.circuit || nextTier.rows.length)) {
                            draft.currentExerciseCount = draft.currentExerciseCount + 1;
                        }
                    }
                }
                else {
                    draft.currentRowIndex = draft.currentRowIndex + 1;
                    draft.currentExerciseCount = draft.currentExerciseCount + 1;
                    // Catchall, never go above - this should never happen, but edge case
                    if (draft.currentExerciseCount > draft.currentWorkoutSession.total_exercises) {
                        draft.currentExerciseCount = draft.currentWorkoutSession.total_exercises;
                    }
                }
            }
            if (draft.currentExerciseCount < 1) {
                draft.currentExerciseCount = 1;
            }
            return draft;
        });
        setState(state);
    };
    WorkoutState.prototype.saveComments = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var comments = _b.comments, tierIndex = _b.tierIndex, rowIndex = _b.rowIndex;
        var state = produce(getState(), function (draft) {
            var cwx = draft.currentWorkoutIndex;
            if (tierIndex >= 0) {
                cwx = tierIndex;
            }
            var crx = draft.currentRowIndex;
            if (rowIndex >= 0) {
                crx = rowIndex;
            }
            // Represents a Tier comment if rowIndex is -1
            if (rowIndex === -1) {
                draft.currentWorkoutSession.workout.tiers[cwx].comments = comments;
            }
            else {
                draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].comments = comments;
            }
        });
        setState(state);
    };
    WorkoutState.prototype.setExecutionsRM = function (_a, _b) {
        var getState = _a.getState, setState = _a.setState;
        var oneRM = _b.oneRM, manual = _b.manual;
        var state = produce(getState(), function (draft) {
            var cwx = draft.currentWorkoutIndex;
            var crx = draft.currentRowIndex;
            var executions = draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions;
            for (var i = 0; i < executions.length; i++) {
                // Recalculate 1RM if execution is modified
                if (!manual && executions[i].parameter_values['1rm']) {
                    continue;
                }
                executions[i].parameter_values['1rm'] = oneRM['1rm'];
                var load = executions[i].parameter_values['load'];
                // Is a percentage load?
                var loadParam = _.parseInt(load, 10);
                // Do not update an editted set item
                if (!executions[i].editted) {
                    if (manual || !executions[i].init) {
                        executions[i].init = true;
                        // Round to nearest whole number (this should be the calculated value with loadPercentage)
                        var parameterSelections = draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].parameters;
                        if (parameterSelections['load']) {
                            var loadPercentage = parameterSelections['load'] / 100;
                            executions[i].parameter_values['load'] = Math.ceil(oneRM['1rm'] * loadPercentage);
                        }
                    }
                    else if ((!isNaN(loadParam) && load !== -1) || /^\d+(\.\d+)?%$/.test(load)) {
                        // Reset load to nearest 5
                        executions[i].parameter_values['load'] = Math.ceil(oneRM['1rm'] * (_.parseInt(load, 10) / 100) / 5) * 5;
                    }
                }
            }
            draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions = executions;
        });
        setState(state);
    };
    WorkoutState.prototype.saveWorkout = function (_a, _b) {
        var _this = this;
        var getState = _a.getState, setState = _a.setState, dispatch = _a.dispatch;
        var rpeScore = _b.rpeScore, totalTime = _b.totalTime;
        var state = getState();
        var draftState = produce(state, function (draft) {
            var e_2, _a;
            draft.currentWorkoutSession.rpe = rpeScore;
            draft.currentWorkoutSession.completion_time_ms = totalTime;
            var stats = WorkoutStateUtils.completionStats(draft.currentWorkoutSession);
            draft.currentWorkoutSession.complete = stats.complete;
            draft.currentWorkoutSession.attendance_count = stats.attendance_count;
            draft.currentWorkoutSession.total_tiers = stats.total_tiers;
            draft.currentWorkoutSession.completed_tiers = stats.completed_tiers;
            draft.currentWorkoutSession.total_exercises = stats.total_exercises;
            draft.currentWorkoutSession.completed_exercises = stats.completed_exercises;
            draft.currentWorkoutSession.training_load = stats.training_load;
            draft.currentWorkoutSession.est_training_load = stats.est_training_load;
            // get plan metadata
            var plan = _this.documentService.store.planDocuments.find(function (doc) { return doc.uuid === state.currentWorkoutSession.plan.id; });
            if (plan) {
                var plannedWorkout = plan.document.workouts.find(function (w) { return w.id === state.currentWorkoutSession.workout.id; });
                if (plannedWorkout) {
                    var phase_1 = PlanDocumentUtils.findPhase(plan.document.phases, { day: plannedWorkout.schedule.day[0], week: plannedWorkout.schedule.week[0] });
                    if (phase_1) {
                        if (!phase_1.color) {
                            var phaseLabel = _this.documentService.store.exerciseProgram.document.phases.find(function (ph) { return ph.id === phase_1.exercise_program_phase_id; });
                            if (phaseLabel) {
                                phase_1.color = phaseLabel.color;
                            }
                        }
                        draft.currentWorkoutSession.phase = {
                            uuid: phase_1.id,
                            name: phase_1.name,
                            color: phase_1.color,
                        };
                    }
                }
            }
            // Mark tiers complete
            if (stats.completedTierIndces.length > 0) {
                try {
                    for (var _b = tslib_1.__values(stats.completedTierIndces), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var i = _c.value;
                        draft.currentWorkoutSession.workout.tiers[i].complete = true;
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        });
        setState(draftState);
        var newState = getState();
        var workoutSessionDatabaseRecord = new WorkoutSessionDocument({
            key: newState.currentWorkoutSession.id,
            version: 1,
            document: newState.currentWorkoutSession
        });
        return from(this.dataService.saveWorkoutSessionDocument(workoutSessionDatabaseRecord)).pipe(tap(function () { return dispatch(new ResetWorkoutSession()); }), map(function () {
            return workoutSessionDatabaseRecord;
        }));
    };
    WorkoutState.prototype.resetWorkoutSession = function (_a) {
        var getState = _a.getState, setState = _a.setState;
        var draftState = produce(getState(), function (draft) {
            draft.currentWorkoutSession = undefined;
        });
        return setState(draftState);
    };
    tslib_1.__decorate([
        Action(StartWorkout),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, StartWorkout]),
        tslib_1.__metadata("design:returntype", Promise)
    ], WorkoutState.prototype, "startWorkout", null);
    tslib_1.__decorate([
        Action(PauseWorkout),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, PauseWorkout]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "pauseWorkout", null);
    tslib_1.__decorate([
        Action(ResumeWorkout),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ResumeWorkout]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "resumeWorkout", null);
    tslib_1.__decorate([
        Action(EndWorkout),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, EndWorkout]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "endWorkout", null);
    tslib_1.__decorate([
        Action(StartCircuit),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, StartCircuit]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "startCircuit", null);
    tslib_1.__decorate([
        Action(StopCircuit),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, StopCircuit]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "stopCircuit", null);
    tslib_1.__decorate([
        Action(SetCircuitRounds),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetCircuitRounds]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "setCircuitRounds", null);
    tslib_1.__decorate([
        Action(ExecuteSet),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ExecuteSet]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "executeSet", null);
    tslib_1.__decorate([
        Action(EditSet),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, EditSet]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "editSet", null);
    tslib_1.__decorate([
        Action(AddNewSet),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddNewSet]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "addNewSet", null);
    tslib_1.__decorate([
        Action(DeleteSet),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "deleteSet", null);
    tslib_1.__decorate([
        Action(ChangeExercise),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ChangeExercise]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "changeExcercise", null);
    tslib_1.__decorate([
        Action(SaveComments),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SaveComments]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "saveComments", null);
    tslib_1.__decorate([
        Action(SetExecutionsRM),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetExecutionsRM]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "setExecutionsRM", null);
    tslib_1.__decorate([
        Action(SaveWorkout),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SaveWorkout]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "saveWorkout", null);
    tslib_1.__decorate([
        Action(ResetWorkoutSession),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState.prototype, "resetWorkoutSession", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [WorkoutStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState, "currentExerciseCount", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [WorkoutStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState, "currentWorkoutSession", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [WorkoutStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState, "currentWorkoutIndex", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [WorkoutStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState, "currentRowIndex", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [WorkoutStateModel]),
        tslib_1.__metadata("design:returntype", void 0)
    ], WorkoutState, "totalTime", null);
    WorkoutState = tslib_1.__decorate([
        State({
            name: 'workout',
            defaults: {
                currentWorkoutIndex: 0,
                currentRowIndex: 0,
            }
        }),
        tslib_1.__metadata("design:paramtypes", [Store,
            DataService,
            DocumentService])
    ], WorkoutState);
    return WorkoutState;
}());
export { WorkoutState };
