import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MicroUiService {
  private eventSubject: Subject<Event> = new Subject<Event>();

  constructor() { }

  /**
   * dispatches a custom event with provided type and detail
   * to the parent container of a micro-ui component
   * @param type custom event type
   * @param detail custom event detail
   */
  dispatchEvent(type: string, parent: boolean, detail?: any) {
    if (parent) {
      window.parent.dispatchEvent(new CustomEvent(type, { detail }));
    } else {
      window.dispatchEvent(new CustomEvent(type, { detail }));
    }
  }

  /**
   * listens for custom events of provided type dispatched from
   * a child micro-ui component
   * @param type custom event type
   */
  listenForEvents(type: string, parent: boolean): Observable<Event> {
    if (parent) {
      window.parent.addEventListener(type, e => {
        this.eventSubject.next(e);
      });
    } else {
      window.addEventListener(type, e => {
        this.eventSubject.next(e);
      });
    }

    return this.eventSubject.asObservable().pipe(filter(ev => ev.type === type));
  }
}
