import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from 'app/services/auth.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  private _profile: { sex: string, dob: string, height: { feet: number, inches: number }, weight: number };

  profile: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ProfileComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) { }

  close(submit: boolean = false): void {
    if (submit) {
      this.dialogRef.close({
        sex: this.profile.controls['sex'].value,
        dob: new Date(this.profile.controls['dob'].value).toISOString(),
        height: {
          feet: this.profile.controls['heightFt'].value,
          inches: this.profile.controls['heightIn'].value,
        },
        weight: this.profile.controls['weight'].value,
      });
    } else {
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {
    if (this.authService.currentUser.profile) {
      this._profile = this.authService.currentUser.profile;

      this.profile = this.formBuilder.group({
        sex: this.formBuilder.control(this._profile.sex || undefined, [Validators.required]),
        dob: this.formBuilder.control(new Date(this._profile.dob) || undefined, [Validators.required]),
        heightFt: this.formBuilder.control(
          this._profile.height ? this._profile.height.feet || undefined : undefined, [Validators.required]),
        heightIn: this.formBuilder.control(
          this._profile.height ? this._profile.height.inches || undefined : undefined, [Validators.required]),
        weight: this.formBuilder.control(this._profile.weight || undefined, [Validators.required]),
      });
    }
  }

}
