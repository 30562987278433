import { Component, OnInit, Inject, OnChanges } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { UserProfilePftCftData, UserProfilePftCftDataStat } from 'app/models/user-profile.model';
import { MatSnackBar } from '@angular/material';
import { PftCftRubricService, AgeRange, ScoringCategory, Gender, Exercise } from 'app/services/pft-cft-rubric.service';

export interface PftCftData {
  exercise: any,
  category: string,
  gender: string,
  age_group: AgeRange
}

@Component({
  selector: 'app-pft-cft-calc-dialog',
  templateUrl: './pft-cft-calc-dialog.component.html',
  styleUrls: ['./pft-cft-calc-dialog.component.scss']
})
export class PftCftCalcDialogComponent implements OnInit, OnChanges {
  originalExercise: any;
  exercise: any;
  exerciseName: string;
  category: string;
  gender: string;
  age_group: string;
  ageGroups = [
    AgeRange.AGE_17_20,
    AgeRange.AGE_21_25,
    AgeRange.AGE_26_30,
    AgeRange.AGE_31_35,
    AgeRange.AGE_36_40,
    AgeRange.AGE_41_45,
    AgeRange.AGE_46_50,
    AgeRange.AGE_51
  ];

  isKnownScore: string;
  isKnownGender: string;
  isKnownAgeGroup: string;
  score = '';
  unitLabel: string;

  constructor(private store: Store,
    public dialogRef: MatDialogRef<PftCftCalcDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PftCftData,
    private snackBar: MatSnackBar,
    private rubricService: PftCftRubricService) {
      if (this.data) {
        this.originalExercise = Object.assign({}, {}, this.data.exercise);
        this.exercise = this.data.exercise;
        this.category = this.data.category;
        const propertyName = this.data.category.toLowerCase();
        const exerciseEnum = this.getExerciseEnum(this.exercise.name);
        this.unitLabel = this.rubricService.getExerciseUnitLabel(exerciseEnum);
        if (this.exercise[propertyName]) {
          if (!this.exercise[propertyName] || this.exercise[propertyName] === 'NaN') {
            this.exercise[propertyName] = {
              score: undefined,
              raw: undefined
            };
            this.score = undefined;
            this.isKnownScore = undefined;
          }
          if (this.exercise[propertyName]['score'] && this.exercise[propertyName]['raw']) {
            this.score = this.exercise[propertyName]['score'];
            this.isKnownScore = this.exercise[propertyName]['raw'];
          }
        }
        if (this.data.gender) {
          this.gender = this.data.gender;
          this.isKnownGender = this.gender;
        }
        if (this.data.age_group) {
          this.age_group = this.data.age_group;
        }
      }
      this.updateScore(this.isKnownScore);
    }

  ngOnInit() {

  }

  ngOnChanges(): void {

  }

  updateScore(record: string): void {
    let category = ScoringCategory.PFT;
    switch (this.category.toLowerCase()) {
      case 'pft':
        category = ScoringCategory.PFT;
        break;
      case 'cft':
        category = ScoringCategory.CFT;
        break;
      default:
        return;
    }
    let gender = Gender.MALE;
    if (this.isKnownGender) {
      if (this.isKnownGender.toLowerCase().indexOf('f') > -1) {
        gender = Gender.FEMALE;
      }
    } else if (this.data && this.data.gender) {
      if (this.data.gender.toLowerCase().indexOf('f') > -1) {
        gender = Gender.FEMALE;
      }
    }
    const ageGroup = this.rubricService.getAgeRange(this.age_group);
    const numericRecord = this.rubricService.getNumericRecord(record, this.unitLabel === 'Time');
    const score = this.rubricService.getScore(numericRecord, category,
        this.getExerciseEnum(this.exercise.name), gender, ageGroup, this.unitLabel === 'Time');
    this.score = '' + score;
    this.exercise[this.category.toLowerCase()] = {
      score: this.score,
      raw: this.isKnownScore
    };
  }

  getExerciseEnum(exerciseName: string): Exercise {
    const name = exerciseName.toLowerCase();
    if (this.category === 'PFT') {
      if (name.indexOf('pull') > -1) {
        return Exercise.PULLUPS;
      } else if (name.indexOf('push') > -1) {
        return Exercise.PUSHUPS;
      } else if (name.indexOf('crunch') > -1) {
        return Exercise.CRUNCHES;
      } else if (name.indexOf('run') > -1) {
        if (name.indexOf('alt') > -1) {
          return Exercise.THREE_MILE_RUN_ALTITUDE;
        } else {
          return Exercise.THREE_MILE_RUN;
        }
      } else if (name.indexOf('row') > -1) {
        if (name.indexOf('alt') > -1) {
          return Exercise.FIVE_K_ROW_ALTITUDE;
        }
        return Exercise.FIVE_K_ROW;
      } else if (name.indexOf('plank') > -1) {
        return Exercise.PLANK;
      } else {
        console.log('unknown PFT exercise!');
      }
    } else {
      if (name.indexOf('movement') > -1) {
          if (name.indexOf('alt') > -1) {
            return Exercise.MOVEMENT_TO_CONTACT_ALTITUDE;
          }
          return Exercise.MOVEMENT_TO_CONTACT;
      } else if (name.indexOf('fire') > -1) {
        if (name.indexOf('alt') > -1) {
          return Exercise.MANEUVER_UNDER_FIRE_ALTITUDE;
        }
        return Exercise.MANEUVER_UNDER_FIRE;
      } else if (name.indexOf('ammunition') > -1) {
        return Exercise.AMMUNITION_LIFT;
      } else {
        console.log('unknown CFT exercise!')
      }
    }
  }

  cancelSelected(): void {
    this.dialogRef.close({
      success: false,
      exercise: this.originalExercise
    });
  }

  submitSelected() {
    const response = {
      success: true,
      exercise: this.exercise
    };
    if (this.isKnownScore !== null && this.isKnownScore !== undefined && this.isKnownScore !== '') {
      const propertyName = this.category.toLowerCase();
      this.exercise[propertyName]['score'] = parseInt(this.score, 10);
      this.exercise[propertyName]['raw'] = this.isKnownScore;
      response['exercise'] = this.exercise;
    } else {
      this.snackBar.open(this.category + ' event performance is not provided.', undefined, {
        duration: 2500
      });
      return;
    }
    if (this.isKnownGender) {
      if (this.isKnownGender.toLowerCase().indexOf('f') > -1) {
        response['gender'] = 'FEMALE';
      } else if (this.isKnownGender.toLowerCase().indexOf('m') > -1) {
        response['gender'] = 'MALE';
      } else {
        this.snackBar.open('Gender was not provided.', undefined, {
          duration: 2500
        });
        return;
      }
    }
    if (this.age_group) {
      response['age_group'] = this.age_group;
    } else {
      this.snackBar.open('Age group was not provided.', undefined, {
        duration: 2500
      });
      return;
    }
    this.dialogRef.close(response);
  }

  display5KRowMessage() {
    if (this.exercise.name.includes('5K')) {
      return true;
    }

    return false;
  }
}
