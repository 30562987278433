import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../custom-material/shared.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms'
import { FlexLayoutModule } from '@angular/flex-layout'
import { NgxSpinnerModule } from 'ngx-spinner';
import { ResourcesComponent } from './resources/resources.component';
import { TotalFitnessSurveyComponent } from './total-fitness-survey/total-fitness-survey.component';
import { ResourcesRoutes } from './resources.routing';
import { SurveySectionComponent } from './total-fitness-survey/survey-section/survey-section.component';
import { RatingSurveyQuestionComponent } from './total-fitness-survey/survey-section/rating-survey-question/rating-survey-question.component';
import { TotalFitnessSurveyResultComponent } from './total-fitness-survey-result/total-fitness-survey-result.component';
import { ScoringLegendComponent } from './total-fitness-survey-result/scoring-legend/scoring-legend.component';
import { TotalScoreComponent } from './total-fitness-survey-result/total-score/total-score.component';
import { SectionScoreComponent } from './total-fitness-survey-result/section-score/section-score.component';
import { SurveyAdditionalResourcesComponent } from './total-fitness-survey-result/survey-additional-resources/survey-additional-resources.component';

@NgModule({
  declarations: [
    ResourcesComponent,
    TotalFitnessSurveyComponent,
    SurveySectionComponent,
    RatingSurveyQuestionComponent,
    TotalFitnessSurveyResultComponent,
    ScoringLegendComponent,
    TotalScoreComponent,
    SectionScoreComponent,
    SurveyAdditionalResourcesComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    NgxSpinnerModule,
    RouterModule.forChild(ResourcesRoutes),
    ReactiveFormsModule,
    FlexLayoutModule
  ],
  exports: [
    ResourcesComponent
  ]
})
export class ResourcesModule { }
