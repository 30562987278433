import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { DocumentService } from 'app/services/document.service';
import { WorkoutState } from 'app/state/workout/workout.state';
import { ExecuteSet } from 'app/state';
import { ExerciseProgramDocumentUtils, getDefaultParams } from 'fitforce-document-sync';
import { SetsRepsLoadPipe } from 'app/pipes/sets-reps-load.pipe';
var ExerciseSetItemComponent = /** @class */ (function () {
    function ExerciseSetItemComponent(store, documentService, setRepsLoadPipe) {
        this.store = store;
        this.documentService = documentService;
        this.setRepsLoadPipe = setRepsLoadPipe;
    }
    ExerciseSetItemComponent.prototype.ngOnInit = function () {
        this.loadData();
    };
    ExerciseSetItemComponent.prototype.loadData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var parameterSelections, loadPercentage, exerciseReference, exercise;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession);
                        if (this.workoutSession) {
                            this.currentWorkoutSession = this.workoutSession;
                        }
                        if (!!this.documentService.store.exerciseProgram) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.documentService.restoreData().toPromise()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        parameterSelections = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex].parameters;
                        loadPercentage = parameterSelections['load'];
                        if (loadPercentage) {
                            this.loadPercentage = loadPercentage / 100;
                        }
                        exerciseReference = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex].exercise;
                        exercise = exerciseReference ? this.documentService.store.exerciseProgram.document.exercises.find(function (ex) { return ex.id === exerciseReference.exercise_program_id; }) : undefined;
                        if (exercise) {
                            this.setParams = ExerciseProgramDocumentUtils.getPossibleParamsForExercise(this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex].exercise.exercise_program_id, this.documentService.store.exerciseProgram.document);
                        }
                        else {
                            this.setParams = getDefaultParams();
                        }
                        this.getSetsRepsLoad();
                        return [2 /*return*/];
                }
            });
        });
    };
    ExerciseSetItemComponent.prototype.isExecuted = function (setIndex) {
        var tierRow = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex];
        if (tierRow.executions[setIndex] && tierRow.executions[setIndex].complete_time) {
            return true;
        }
        return false;
    };
    ExerciseSetItemComponent.prototype.inSetParams = function (param) {
        if (this.setParams) {
            return this.setParams.some(function (p) { return p.param === param; });
        }
        return true;
    };
    ExerciseSetItemComponent.prototype.onExecuteSet = function (setIndex) {
        var _this = this;
        var tierRow = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex];
        this.store.dispatch(new ExecuteSet(setIndex, tierRow.executions[setIndex].parameter_values)).subscribe(function () {
            _this.loadData();
        });
    };
    ExerciseSetItemComponent.prototype.getSetsRepsLoad = function () {
        this.setDetails = this.setRepsLoadPipe.transform({
            setParams: this.setParams,
            currentWorkoutSession: this.currentWorkoutSession,
            currentWorkoutIndex: this.currentWorkoutIndex,
            currentRowIndex: this.currentRowIndex,
            execution: this.execution,
            loadPercentage: this.loadPercentage,
            hasSet: false
        });
    };
    return ExerciseSetItemComponent;
}());
export { ExerciseSetItemComponent };
