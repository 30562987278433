<div *ngIf="calendarDays && calendarDays.length > 0">
  <cdk-virtual-scroll-viewport
    itemSize="1"
    orientation="horizontal"
    class="calendar-header"
    fxLayout="row"
    fxLayoutAling="start center"
    fxLayoutGap="20px">
    <div *cdkVirtualFor ="let visibleDay of calendarDays"
      fxFlex fxLayout="column" fxLayoutAlign="center center"
      class="calendar-day"
      (click)="onClickDay(visibleDay.date)">
      <div class="calendar-day-name">{{ visibleDay.day }}</div>
      <ng-container *ngIf="visibleDay.active; else notActive">
        <div class="circle-container active-circle">
          <div id="activeDate" [ngClass]="{'calendar-day-number is-active': true, 'has-workout': visibleDay.hasWorkout}">
            {{ visibleDay.number }}
          </div>
        </div>
      </ng-container>
      <ng-template #notActive>
        <div class="circle-container">
          <div [ngClass]="{'calendar-day-number': true, 'has-workout': visibleDay.hasWorkout}">
            {{ visibleDay.number }}
          </div>
        </div>
      </ng-template>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
