import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { WorkoutState } from 'app/state/workout/workout.state';
import * as moment from 'moment';
import * as _ from 'lodash';
import { EditSetDialogComponent } from '../edit-set-dialog/edit-set-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { EditSet, SaveWorkout, AddUnsyncedWorkoutSession, SaveComments, SetActiveDay, SetCircuitRounds } from 'app/state';
import { Router, ActivatedRoute } from '@angular/router';
import { DocumentService } from 'app/services/document.service';
import { DataService } from 'app/services/data.service';
import { PageTransitionService, PAGETRANSITIONS } from 'app/services/page-transition.service';
import { EditCircuitDialogComponent } from '../edit-circuit-dialog/edit-circuit-dialog.component';
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component';
var CompleteComponent = /** @class */ (function () {
    function CompleteComponent(store, router, dataService, dialog, snackBar, activatedRoute, pageTransitionService, documentService) {
        this.store = store;
        this.router = router;
        this.dataService = dataService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.activatedRoute = activatedRoute;
        this.pageTransitionService = pageTransitionService;
        this.documentService = documentService;
        this.rpeScore = 0;
        this.hasRM = {
            oneRM: {},
            index: 0
        };
        this.repStrings = [
            'Rest',
            'Very, Very Easy',
            'Easy',
            'Moderate',
            'Somewhat Hard',
            'Hard',
            'Hard',
            'Very Hard',
            'Very Hard',
            'Very Hard',
            'Maximal'
        ];
    }
    CompleteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            var workoutSessionId = params.workoutSessionId;
            if (!workoutSessionId) {
                _this.isHistory = false;
                _this.workoutSession = _.cloneDeep(_this.store.selectSnapshot(WorkoutState.currentWorkoutSession));
                _this.setTimeElapsed();
            }
            else {
                _this.isHistory = true;
                _this.dataService.getWorkoutSessionDocument(workoutSessionId).then(function (workoutSession) {
                    _this.workoutSession = workoutSession;
                    _this.rpeScore = _this.workoutSession.rpe;
                    _this.setTimeElapsed();
                });
            }
        });
    };
    CompleteComponent.prototype.setTimeElapsed = function () {
        var seconds = moment.duration(this.workoutSession.completion_time_ms).seconds().toString().padStart(2, '0');
        var minutes = _.floor(this.workoutSession.completion_time_ms / 1000 / 60);
        // console.log('got moment', seconds, minutes, this.workoutSession.completion_time_ms)
        this.timeElapsed = minutes + ":" + seconds;
        // Convert to numbers
        this.timeMinutes = +minutes;
        this.timeSeconds = +seconds;
    };
    CompleteComponent.prototype.openEditTier = function (tierIndex) {
        var _this = this;
        this.dialog.open(EditCircuitDialogComponent, {
            data: {
                tierIndex: tierIndex
            }
        }).afterClosed().subscribe(function (result) {
            if (result && result.success) {
                var rounds = result.num_rounds;
                if (_this.workoutSession.workout.tiers[tierIndex].circuit) {
                    _this.workoutSession.workout.tiers[tierIndex].circuit.executions[0].num_rounds = rounds;
                }
                else {
                    _this.workoutSession.workout.tiers[tierIndex].executions[0].num_rounds = rounds;
                }
                _this.store.dispatch(new SetCircuitRounds(rounds, tierIndex));
            }
        });
    };
    CompleteComponent.prototype.openCommentsDialog = function (tierIndex, rowIndex) {
        var _this = this;
        var dialogRef = this.dialog.open(NotesDialogComponent, {
            maxWidth: '100vw',
            width: '80%',
            data: {
                isTierNote: false,
                tierIndex: tierIndex,
                rowIndex: rowIndex,
                workoutSession: this.workoutSession,
                isHistory: this.isHistory,
                isCircuit: rowIndex === -1
            }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res && res.success) {
                _this.store.dispatch(new SaveComments(res.notes, tierIndex, rowIndex)).subscribe(function () {
                    _this.workoutSession = _.cloneDeep(_this.store.selectSnapshot(WorkoutState.currentWorkoutSession));
                    _this.snackBar.open('Saved notes.', undefined, {
                        duration: 2500
                    });
                });
            }
        });
    };
    CompleteComponent.prototype.openEditSet = function (tierIndex, rowIndex, setIndex) {
        var _this = this;
        var dialogRef = this.dialog.open(EditSetDialogComponent, {
            maxWidth: '100vw',
            width: '80%',
            data: {
                tierIndex: tierIndex,
                rowIndex: rowIndex,
                setIndex: setIndex,
                forComplete: true
            }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res && res.success) {
                var execution = _.cloneDeep(_this.workoutSession.workout.tiers[tierIndex].rows[rowIndex].executions[setIndex]);
                var complete_time = execution.complete_time;
                if (res.isComplete && !complete_time) {
                    execution.complete_time = moment().format();
                }
                else if (!res.isComplete && complete_time) {
                    execution.complete_time = undefined;
                }
                execution.parameter_values = res.setParamValues;
                _this.workoutSession.workout.tiers[tierIndex].rows[rowIndex].executions[setIndex] = execution;
                _this.store.dispatch(new EditSet(tierIndex, rowIndex, setIndex, res.setParamValues, execution.complete_time));
            }
        });
    };
    CompleteComponent.prototype.saveWorkout = function () {
        var _this = this;
        var totalTime = (this.timeMinutes * 60000) + (this.timeSeconds * 1000);
        this.store.dispatch([
            new SaveWorkout(this.rpeScore, totalTime),
            new AddUnsyncedWorkoutSession(this.workoutSession.id)
        ]).subscribe(function () {
            _this.snackBar.open('Workout saved.', undefined, {
                duration: 2500
            });
            _this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.RIGHT);
            _this.router.navigate(['/main']);
        });
    };
    CompleteComponent.prototype.closeHistory = function () {
        this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.RIGHT);
        this.router.navigate(['/main']);
    };
    CompleteComponent.prototype.onSliderChange = function ($event) {
        this.rpeScore = $event.value;
    };
    CompleteComponent.prototype.onTimeChange = function ($event, type) {
        var value = $event.target.value;
        if (!value) {
            return;
        }
        switch (type) {
            case 'minutes':
                this.timeMinutes = value;
                break;
            case 'seconds':
                this.timeSeconds = value;
                break;
        }
        this.timeElapsed = this.timeMinutes + ":" + this.timeSeconds;
    };
    CompleteComponent.prototype.startNewWorkout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var activeGroup, activePlan, activeDay;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                activeGroup = this.workoutSession.group;
                activeGroup.key = this.workoutSession.group.id;
                activePlan = this.workoutSession.plan;
                activePlan.key = this.workoutSession.plan.id;
                activeDay = this.workoutSession.workout;
                activeDay.key = this.workoutSession.workout.id;
                activeDay.planUUID = this.workoutSession.plan.id;
                this.store.dispatch(new SetActiveDay(activeGroup, activePlan, activeDay)).subscribe(function () {
                    _this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT);
                    _this.router.navigate(['playcard-summary']);
                });
                return [2 /*return*/];
            });
        });
    };
    return CompleteComponent;
}());
export { CompleteComponent };
