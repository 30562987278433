import { ExerciseComponent } from './exercise.component';
import { CompleteComponent } from './complete/complete.component';
import { PreworkoutSurveyComponent } from './preworkout-survey/preworkout-survey.component';
var ɵ0 = { page: 'exercise' }, ɵ1 = { page: 'survey' }, ɵ2 = { page: 'complete' }, ɵ3 = { page: 'history' };
export var ExerciseRoutes = [{
        path: '',
        component: ExerciseComponent,
        data: ɵ0,
    }, {
        path: 'survey',
        component: PreworkoutSurveyComponent,
        data: ɵ1,
    }, {
        path: 'complete',
        component: CompleteComponent,
        data: ɵ2,
    }, {
        path: 'complete/:workoutSessionId',
        component: CompleteComponent,
        data: ɵ3,
    }];
export { ɵ0, ɵ1, ɵ2, ɵ3 };
