import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { StartWorkout } from 'app/state';
import { PAGETRANSITIONS, PageTransitionService } from 'app/services/page-transition.service';
import { Router } from '@angular/router';
import { DocumentService } from 'app/services/document.service';
import { AppState } from 'app/state/app/app.state';
import * as _ from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from 'environments/environment';
// TODO: move to fitforce-shared
function addMeasures(measureConfiguration, configsRef, measuresRef) {
    if (measureConfiguration.measure_configuration_ids) {
        return _.flatten(measureConfiguration.measure_configuration_ids
            .map(function (id) { return configsRef.find(function (mConfig) { return mConfig.id === id; }); })
            .map(function (mConfig) { return addMeasures(mConfig, configsRef, measuresRef); }));
    }
    if (measureConfiguration.measure_ids) {
        return measureConfiguration.measure_ids
            .map(function (id) { return measuresRef.find(function (m) { return m.id === id; }); })
            .filter(function (m) { return m !== undefined; });
    }
}
var PreworkoutSurveyComponent = /** @class */ (function () {
    function PreworkoutSurveyComponent(store, router, documentService, pageTransitionService) {
        this.store = store;
        this.router = router;
        this.documentService = documentService;
        this.pageTransitionService = pageTransitionService;
    }
    PreworkoutSurveyComponent.prototype.ngOnInit = function () {
        this.init();
    };
    PreworkoutSurveyComponent.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var surveyConfiguration, measureConfigurations, measures, _measures_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.documentService.restoreData().toPromise()];
                    case 1:
                        _a.sent();
                        this.setActiveWorkoutDocument();
                        this.measures = [];
                        surveyConfiguration = this.documentService.store.exerciseProgram.document.measure_configurations
                            .find(function (mc) { return mc.id === environment.preWktSurveyConfig; });
                        measureConfigurations = this.documentService.store.exerciseProgram.document.measure_configurations;
                        measures = this.documentService.store.exerciseProgram.document.measures;
                        if (surveyConfiguration) {
                            this.surveyConfiguration = surveyConfiguration;
                            _measures_1 = [];
                            measures.forEach(function (measure) {
                                if (surveyConfiguration.measure_ids.includes(measure.id)) {
                                    _measures_1.push(measure);
                                }
                            });
                            this.measures = _measures_1;
                        }
                        this.form = this.buildForm();
                        return [2 /*return*/];
                }
            });
        });
    };
    PreworkoutSurveyComponent.prototype.buildForm = function () {
        var formGroup = new FormGroup({});
        _.each(this.measures, function (measure, i) {
            var formId = measure.id;
            var formControl = new FormControl();
            formGroup.addControl(formId, formControl);
        });
        return formGroup;
    };
    PreworkoutSurveyComponent.prototype.setActiveWorkoutDocument = function () {
        var snapshotDay = this.store.selectSnapshot(AppState.activeDaySelected);
        var planDocument = _.find(this.documentService.store.planDocuments, { key: snapshotDay.planUUID });
        this.activeWorkoutDocument = _.find(planDocument.document.workouts, { id: snapshotDay.key });
    };
    PreworkoutSurveyComponent.prototype.goBack = function () {
        this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.RIGHT);
        this.router.navigate(['/playcard-summary']);
    };
    PreworkoutSurveyComponent.prototype.startExercise = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var measuredResponse;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.measures) {
                            measuredResponse = {
                                measures: this.measures,
                                response: this.form.value
                            };
                        }
                        return [4 /*yield*/, this.store.dispatch(new StartWorkout(this.activeWorkoutDocument, measuredResponse)).toPromise()];
                    case 1:
                        _a.sent();
                        this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT);
                        this.router.navigate(['/exercise']);
                        return [2 /*return*/];
                }
            });
        });
    };
    return PreworkoutSurveyComponent;
}());
export { PreworkoutSurveyComponent };
