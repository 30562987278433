import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-verify-account-dialog',
  templateUrl: './verify-account-dialog.component.html',
  styleUrls: ['./verify-account-dialog.component.scss']
})
export class VerifyAccountDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, private authService: AuthService) { }

  ngOnInit() {
  }

  sendVerificationEmail() {
    if (this.data.email) {
      this.authService.sendVerificationEmail(this.data.email).subscribe(() => {
        this.snackBar.open('Verification email sent', 'Dismiss', { duration: 5000 });
      });
    }
  }
}
