import { Component, OnInit } from '@angular/core'
import { Store } from '@ngxs/store'
import { WorkoutState } from 'app/state/workout/workout.state'
import * as moment from 'moment'
import * as _ from 'lodash'
import { EditSetDialogComponent } from '../edit-set-dialog/edit-set-dialog.component'
import { MatDialog, MatSnackBar } from '@angular/material'
import { EditSet, SaveWorkout, AddUnsyncedWorkoutSession, SaveComments, SetActiveDay, SetCircuitRounds } from 'app/state'
import { Router, ActivatedRoute } from '@angular/router'
import { DocumentService } from 'app/services/document.service'
import { DataService } from 'app/services/data.service'
import { PageTransitionService, PAGETRANSITIONS } from 'app/services/page-transition.service'
import { EditCircuitDialogComponent } from '../edit-circuit-dialog/edit-circuit-dialog.component'
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component'

@Component({
  selector: 'app-exercise-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})
export class CompleteComponent implements OnInit {
  workoutSession: any
  timeElapsed: string
  timeMinutes: number
  timeSeconds: number
  isHistory: boolean
  rpeScore = 0
  hasRM = {
    oneRM: {},
    index: 0
  }
  repStrings = [
    'Rest',
    'Very, Very Easy',
    'Easy',
    'Moderate',
    'Somewhat Hard',
    'Hard',
    'Hard',
    'Very Hard',
    'Very Hard',
    'Very Hard',
    'Maximal'
  ]

  constructor(
    private store: Store,
    private router: Router,
    private dataService: DataService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private pageTransitionService: PageTransitionService,
    private documentService: DocumentService,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      const workoutSessionId = params.workoutSessionId
      if (!workoutSessionId) {
        this.isHistory = false
        this.workoutSession = _.cloneDeep(this.store.selectSnapshot(WorkoutState.currentWorkoutSession))
        this.setTimeElapsed()
      } else {
        this.isHistory = true
        this.dataService.getWorkoutSessionDocument(workoutSessionId).then((workoutSession) => {
          this.workoutSession = workoutSession
          this.rpeScore = this.workoutSession.rpe
          this.setTimeElapsed()
        })
      }
    })
  }

  setTimeElapsed() {
    const seconds = moment.duration(this.workoutSession.completion_time_ms).seconds().toString().padStart(2, '0')
    const minutes = _.floor(this.workoutSession.completion_time_ms / 1000 / 60)
    // console.log('got moment', seconds, minutes, this.workoutSession.completion_time_ms)
    this.timeElapsed = `${minutes}:${seconds}`
    // Convert to numbers
    this.timeMinutes = +minutes
    this.timeSeconds = +seconds
  }

  openEditTier(tierIndex) {
    this.dialog.open(EditCircuitDialogComponent, {
      data: {
        tierIndex
      }
    }).afterClosed().subscribe(result => {
      if (result && result.success) {
        const rounds = result.num_rounds
        if (this.workoutSession.workout.tiers[tierIndex].circuit) {
          this.workoutSession.workout.tiers[tierIndex].circuit.executions[0].num_rounds = rounds
        } else {
          this.workoutSession.workout.tiers[tierIndex].executions[0].num_rounds = rounds
        }
        this.store.dispatch(new SetCircuitRounds(rounds, tierIndex))
      }
    })
  }

  openCommentsDialog(tierIndex: number, rowIndex: number) {
    const dialogRef = this.dialog.open(NotesDialogComponent, {
      maxWidth: '100vw',
      width: '80%',
      data: {
        isTierNote: false,
        tierIndex,
        rowIndex,
        workoutSession: this.workoutSession,
        isHistory: this.isHistory,
        isCircuit: rowIndex === -1
      }
    })

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.success) {
        this.store.dispatch(new SaveComments(res.notes, tierIndex, rowIndex)).subscribe(() => {
          this.workoutSession = _.cloneDeep(this.store.selectSnapshot(WorkoutState.currentWorkoutSession))
          this.snackBar.open('Saved notes.', undefined, {
            duration: 2500
          })
        })
      }
    })
  }

  openEditSet(tierIndex: number, rowIndex: number, setIndex: number) {
    const dialogRef = this.dialog.open(EditSetDialogComponent, {
      maxWidth: '100vw',
      width: '80%',
      data: {
        tierIndex,
        rowIndex,
        setIndex,
        forComplete: true
      }
    })

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.success) {
        const execution = _.cloneDeep(this.workoutSession.workout.tiers[tierIndex].rows[rowIndex].executions[setIndex])
        const complete_time = execution.complete_time
        if (res.isComplete && !complete_time) {
          execution.complete_time = moment().format()
        } else if (!res.isComplete && complete_time) {
          execution.complete_time = undefined
        }
        execution.parameter_values = res.setParamValues
        this.workoutSession.workout.tiers[tierIndex].rows[rowIndex].executions[setIndex] = execution
        this.store.dispatch(new EditSet(
          tierIndex,
          rowIndex,
          setIndex,
          res.setParamValues,
          execution.complete_time
        ))
      }
    })
  }

  saveWorkout() {
    const totalTime = (this.timeMinutes * 60000) + (this.timeSeconds * 1000)
    this.store.dispatch([
      new SaveWorkout(this.rpeScore, totalTime),
      new AddUnsyncedWorkoutSession(this.workoutSession.id)]
    ).subscribe(() => {
      this.snackBar.open('Workout saved.', undefined, {
        duration: 2500
      })
      this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.RIGHT)
      this.router.navigate(['/main'])
    })
  }

  closeHistory() {
    this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.RIGHT)
    this.router.navigate(['/main'])
  }

  onSliderChange($event) {
    this.rpeScore = $event.value
  }

  onTimeChange($event, type: string) {
    const value = $event.target.value
    if (!value) {
      return
    }
    switch (type) {
      case 'minutes':
        this.timeMinutes = value
        break;
      case 'seconds':
        this.timeSeconds = value
        break;
    }
    this.timeElapsed = `${this.timeMinutes}:${this.timeSeconds}`
  }

  async startNewWorkout() {
    const activeGroup = this.workoutSession.group
    activeGroup.key = this.workoutSession.group.id
    const activePlan = this.workoutSession.plan
    activePlan.key = this.workoutSession.plan.id
    const activeDay = this.workoutSession.workout
    activeDay.key = this.workoutSession.workout.id
    activeDay.planUUID = this.workoutSession.plan.id
    this.store.dispatch(new SetActiveDay(
      activeGroup,
      activePlan,
      activeDay)
    ).subscribe(() => {
      this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT)
      this.router.navigate(['playcard-summary'])
    })
  }
}
