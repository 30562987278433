import * as _ from 'lodash'

import { ActivatedRoute, Router } from '@angular/router'
import { ClearActiveGroup, InitMain } from 'app/state'
import { Component, OnDestroy, OnInit } from '@angular/core'

import { AuthService } from 'app/services/auth.service'
import { DataService } from 'app/services/data.service'
import { Group } from 'app/models'
import { LeaveDialogComponent } from '../leave-dialog/leave-dialog.component'
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner'
import { RestService } from 'app/services/rest.service'
import { Store } from '@ngxs/store'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-group-view',
  templateUrl: './group-view.component.html',
  styleUrls: ['./group-view.component.scss']
})
export class GroupViewComponent implements OnInit, OnDestroy {
  isOnline = false
  group: Group
  owners: any
  isRequested = false
  onlineSub: Subscription

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private dataService: DataService,
    private authService: AuthService,
    private restService: RestService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
    this.onlineSub = this.restService.isOnlineSubscription().subscribe(isOnline => this.isOnline = isOnline)
  }

  ngOnInit() {
    this.loadData()
  }

  loadData() {
    this.route.params.subscribe(async (params) => {
      const groupKey = params.groupId
      await this.loadGroup(groupKey)
    })
  }

  async loadGroup(groupKey: string) {
    this.group = await this.dataService.getGroup(groupKey)
    this.owners = await this.dataService.getOwnersInfo(this.group.owners).toPromise()
  }

  isJoined(): boolean {
    return _.includes(this.group.users, this.authService.getUserUUID())
  }

  requestJoin() {
    this.spinner.show();
    this.dataService.groupRequestInvite(this.group.key, this.authService.getUsername()).subscribe(res => {
      let alreadyInvited = false
      this.group.users = _.map(res.members, (v, i) => {
        if (v.uuid === this.authService.getUserUUID()) {
          alreadyInvited = true
        }

        return v.uuid
      })
      this.dataService.update(this.group)

      // Resync data for the plans
      this.store.dispatch(new InitMain(true)).subscribe(() => {
        this.spinner.hide();
        this.isRequested = true && !alreadyInvited
      })
    })
  }

  requestLeave() {
    this.spinner.show();
    this.store.dispatch(new ClearActiveGroup())
    this.dataService.groupRequestLeave(this.group.key, this.authService.getUserUUID()).subscribe(res => {
      this.group.users = _.map(res.members, (v, i) => {
        return v.uuid
      })
      this.deleteFromGroup(this.authService.getUserUUID())
    })
  }

  deleteFromGroup(userId: string) {
    _.remove(this.group.users, user => {
      return user === userId
    })
    this.dataService.update(this.group).then(() => {
      this.spinner.hide()
    })
  }

  openLeaveDialog() {
    const dialogRef = this.dialog.open(LeaveDialogComponent, {
      panelClass: 'modal-leaveConfirm',
    })

    dialogRef.afterClosed().subscribe(isLeaving => {
      if (isLeaving) {
        this.requestLeave()
      }
    })
  }

  ngOnDestroy(): void {
    this.onlineSub.unsubscribe()
  }
}
