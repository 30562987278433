import { DocumentRecord, DocumentRecordData } from 'fitforce-offline-first'
import { DatabaseRecord } from '../classes/database-record.class'
import * as _ from 'lodash'

export class WorkoutSessionDocument extends DocumentRecord<WorkoutSession> {
  static recordName = 'WorkoutSessionDocument';
  static objectStoreName = 'workoutSessionDocuments';

  constructor(data?: DocumentRecordData<WorkoutSession>) {
    super(data);
  }
}
// Used for v1 :/
export class WorkoutSessionDBDocument extends DatabaseRecord {
  static objectStoreName = 'workoutSessionDocuments'

  exercise_program_id: string

  start_time: string
  end_time: string
  complete?: boolean
  workout_complete?: boolean

  group?: Group
  plan: Plan
  workout: Workout

  // computed on front-end
  total_tiers: number
  completed_tiers: number
  total_exercises: number
  completed_exercises: number

  // computed on back-end
  attendance_count: number

  constructor(data: WorkoutSession) {
    super(data)

    this.exercise_program_id = data.exercise_program_id

    this.start_time = data.start_time
    this.end_time = data.end_time
    this.complete = data.complete
    this.workout_complete = data.workout_complete

    this.group = data.group
    this.plan = data.plan
    this.workout = data.workout

    // computed on front-end
    this.total_tiers = data.total_tiers
    this.completed_tiers = data.completed_tiers
    this.total_exercises = data.total_exercises
    this.completed_exercises = data.completed_exercises

    // computed on back-end
    this.attendance_count = data.attendance_count

  }

  toJSON () {
    return super.toJSON([
      'exercise_program_id',
      'start_time',
      'end_time',
      'complete',
      'group',
      'plan',
      'workout',

      // computed on front-end
      'total_tiers',
      'completed_tiers',
      'total_exercises',
      'completed_exercises',

      // computed on back-end
      'attendance_count',
    ])
  }
}


export interface WorkoutSession {
  id: string
  type: string
  exercise_program_id: string

  start_time: string
  end_time?: string
  completion_time_ms?: number // in milliseconds
  workout_complete?: boolean // keeps track of user completed action
  complete?: boolean
  rpe?: number

  group?: Group
  leaders: User[]
  attendees: User[]

  plan: Plan
  workout: Workout

  // computed on front-end
  total_tiers: number
  completed_tiers: number
  total_exercises: number
  completed_exercises: number

  // computed on front-end
  attendance_count?: number
  group_size?: number
  training_load?: number

  // measures for survey
  measures?: any

  // Data for analysis
  est_rpe?: number
  est_completion_time_ms?: number
  est_training_load?: number

  phase?: { uuid: string, name: string, color: string }
}

interface Plan {
  version?: number;
  id: string;
  name: string;
  num_weeks: number;
  start_date: string;
  creator: User;
  notes?: string;
}

interface Workout {
  id: string,
  name: string;
  workout_type_id?: string;
  tiers: Tier[];
  // Keep track of the plan day version to compare against
  version: number;
}

interface Tier {
  id: string;
  name: string;
  notes?: string;
  comments?: string;
  order: number;
  rows: Row[];
  circuit?: any;
  executions: CircuitExecution[]
  complete?: boolean;
}

interface CircuitExecution {
  start_time?: string;
  end_time?: string;
  num_rounds?: number
}

interface Row {
  comments?: string;
  exercise: Exercise;
  order: number;
  parameters: any;
  notes?: string;
  executions: Execution[];
  start_time: string;
  end_time: string;
  complete?: boolean;
}

interface Exercise {
  exercise_program_id: string;
  name: string;
  exercise_categories: string[];
}

interface Execution {
  parameter_values: object;
  rest_time?: string;
  complete_time?: string;
  editted?: boolean;
  init?: boolean;
  user_added?: boolean;
}

interface Group  {
  uuid: string
  name: string
}

interface User {
  uuid: string;
  email: string;
  first_name?: string;
  last_name?: string;
  confirmed?: boolean;
  firstName?: string;
  hasProfilePic?: boolean;
  lastName?: string;
  rank?: string;
}
