import { Component, OnInit, Inject} from '@angular/core';
import { Alert, AlertDialogData } from '../classes/alert';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetMainTabIndex } from 'app/state';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  public alert: Alert;

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>,
    private store: Store,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData) {
  }

  ngOnInit() {
    this.alert = this.data.alert;
  }

  open(): void {
    if (this.alert.url) {
      window.open(this.alert.url, '_blank')
    } else if (this.alert.route) {
      this.router.navigateByUrl(this.alert.route)
    } else if (typeof this.alert.tabIndex !== 'undefined') {
      this.store.dispatch(new SetMainTabIndex(this.alert.tabIndex))
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
