import * as PlotlyJS from 'plotly.js/dist/plotly.js'

import { LoggingModule, LoggingModuleOptions } from 'fitforce-logging'

import { CalendarHeaderComponent } from './calendar-header/calendar-header.component'
import { ChangePlanDialogComponent } from './change-plan-dialog/change-plan-dialog.component'
import { CommonModule } from '@angular/common'
import { FlexLayoutModule } from '@angular/flex-layout'
import { HeaderModule } from '../header/header.module'
import { MStoTimePipe } from './pipes/ms-to-time.pipe'
import { MainComponent } from './main.component'
import { MainRoutes } from './main.routing'
import { MaxsetDialogComponent } from '../maxset-dialog/maxset-dialog.component'
import { MyStatsComponent } from '../my-stats/my-stats.component'
import { NgModule } from '@angular/core'
import { NgxSpinnerModule } from 'ngx-spinner'
import { PftCftCalcDialogComponent } from '../pft-cft-calc-dialog/pft-cft-calc-dialog.component'
import { PftCftChartComponent } from '../pft-cft-chart/pft-cft-chart.component';
import { PftCftExerciseScoreComponent } from '../pft-cft-exercise-score/pft-cft-exercise-score.component'
import { PftCftScoresComponent } from '../pft-cft-scores/pft-cft-scores.component';
import { PlotlyModule } from 'angular-plotly.js'
import { ProgramsModule } from '../programs/programs.module'
import { RMCalcDialogModule } from '../rmcalc-dialog/rmcalc-dialog.module'
import { RMCalcSetDialogModule } from '../rmcalc-set-dialog/rmcalc-set-dialog.module'
import { ReactiveFormsModule } from '@angular/forms'
import { ResourcesComponent } from '../resources/resources/resources.component'
import { ResourcesModule } from '../resources/resources.module'
import { RmChartComponent } from '../rm-chart/rm-chart.component'
import { RouterModule } from '@angular/router'
import { SharedModule } from '../custom-material/shared.module'
import { StatsComponent } from './stats/stats.component'
import { TrainingLoadChartComponent } from '../training-load-chart/training-load-chart.component'
import { WorkoutHistoryComponent } from '../workout-history/workout-history.component'
import { environment } from 'environments/environment'

const LOGGING_OPTIONS: LoggingModuleOptions = {
  logLevel: environment.logLevel
}

PlotlyModule.plotlyjs = PlotlyJS

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(MainRoutes),
    NgxSpinnerModule,
    RMCalcDialogModule,
    RMCalcSetDialogModule,
    HeaderModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ProgramsModule,
    LoggingModule.forRoot(LOGGING_OPTIONS),
    PlotlyModule,
    ResourcesModule
  ],
  declarations: [
    MainComponent,
    StatsComponent,
    ChangePlanDialogComponent,
    MStoTimePipe,
    CalendarHeaderComponent,
    TrainingLoadChartComponent,
    MyStatsComponent,
    WorkoutHistoryComponent,
    RmChartComponent,
    PftCftScoresComponent,
    PftCftChartComponent,
    PftCftCalcDialogComponent,
    PftCftExerciseScoreComponent
  ],
  entryComponents: [
    ChangePlanDialogComponent,
    MaxsetDialogComponent,
    PftCftScoresComponent,
    PftCftCalcDialogComponent
  ],
  exports: [
    MStoTimePipe
  ]
})
export class MainModule { }
