<div class="login-form"fxLayout="column">
  <h1 class="brand-primary">FitForce</h1>

  <form #loginForm="ngForm" fxLayout="column" autocomplete="off">
    <div *ngIf="errorMsg" class="error-msg">{{errorMsg}}</div>

    <mat-form-field class="input-container">
      <input matInput
      autofill="off"
        type="text"
        placeholder="Email"
        name="username"
        #usernameField="ngModel"
        [(ngModel)]="username"
      >
      <mat-error
        *ngIf="usernameField.errors && (usernameField.dirty || usernameField.touched) && usernameField.errors.required">
        Username is <strong>required</strong>.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="input-container">
      <input matInput
        type="password"
        placeholder="Password"
        name="password"
        #passwordField="ngModel"
        [(ngModel)]="password"
      >
      <mat-error
        *ngIf="passwordField.errors && (passwordField.dirty || passwordField.touched) && passwordField.errors.required">
        Password is <strong>required</strong>.
      </mat-error>
    </mat-form-field>

    <div class="login-footer" fxLayout="column">
      <button mat-raised-button color="primary" type="submit" (click)="login(username, password)" [disabled]="!loginForm.form.valid">
        Log In
      </button>
      <div class="login-options">
        <a href="{{registerPath}}">Create Account</a> |  <a href="{{forgotPath}}">Forgot Password</a>
      </div>
      <div class="login-options">
        <a [href]="tou_url">Terms of Use</a>
         |
         <a [href]="pp_url">Privacy Policy</a>
      </div>
      <div class="login-version">{{version}}</div>
    </div>
  </form>
</div>
