/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/flex-layout/flex";
import * as i9 from "@angular/flex-layout/core";
import * as i10 from "./video-dialog.component";
import * as i11 from "@angular/platform-browser";
var styles_VideoDialogComponent = [i0.styles];
var RenderType_VideoDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoDialogComponent, data: {} });
export { RenderType_VideoDialogComponent as RenderType_VideoDialogComponent };
function View_VideoDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["allowfullscreen", ""], ["allowtransparency", ""], ["frameborder", "0"], ["height", "360"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trustedUrl; _ck(_v, 1, 0, currVal_0); }); }
export function View_VideoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"], ["style", "padding: 0"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideoDialogComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "button", [["fxFlex", ""], ["mat-button", ""], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dialogRef.close(i1.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(10, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(11, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(12, 671744, null, 0, i8.DefaultFlexDirective, [i1.ElementRef, i9.StyleUtils, i9.LAYOUT_CONFIG, i8.FlexStyleBuilder, i9.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = _co.trustedUrl; _ck(_v, 6, 0, currVal_2); var currVal_7 = ""; _ck(_v, 10, 0, currVal_7); var currVal_8 = ""; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 2, 0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 10).ariaLabel || null); var currVal_4 = i1.ɵnov(_v, 10).type; var currVal_5 = (i1.ɵnov(_v, 11).disabled || null); var currVal_6 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_VideoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-video-dialog", [], null, null, null, View_VideoDialogComponent_0, RenderType_VideoDialogComponent)), i1.ɵdid(1, 114688, null, 0, i10.VideoDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA, i11.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoDialogComponentNgFactory = i1.ɵccf("app-video-dialog", i10.VideoDialogComponent, View_VideoDialogComponent_Host_0, {}, {}, []);
export { VideoDialogComponentNgFactory as VideoDialogComponentNgFactory };
