export class Signin {
  static readonly type = '[Auth] Signin'
  constructor(public email: string, public password: string) {}
}

export class Signout {
  static readonly type = '[Auth] Signout'
}

export class SetActiveUser {
  static readonly type = '[Auth] SetActiveUser'
  constructor(public activeUser: any) {}
}
