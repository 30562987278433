import * as tslib_1 from "tslib";
import { DatabaseRecord } from '../classes/database-record.class';
import { Plan } from './plan.model';
import { User } from './user.model';
var Group = /** @class */ (function (_super) {
    tslib_1.__extends(Group, _super);
    function Group(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.name = data['name'] || '';
        _this.private = data['private'] || false;
        _this.plans = data['plans'];
        _this.users = data['users'];
        _this.owners = data['owners'];
        _this.created = DatabaseRecord.parseDate(data['created']);
        return _this;
    }
    Group.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, ['name', 'plans', 'users', 'private', 'owners', 'created']);
    };
    Object.defineProperty(Group.prototype, "plans", {
        get: function () {
            return this.getForeignKeys(Plan);
        },
        set: function (records) {
            this.setForiegnRecords(Plan, records);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Group.prototype, "users", {
        get: function () {
            return this.getForeignKeys(User);
        },
        set: function (users) {
            this.setForiegnRecords(User, users);
        },
        enumerable: true,
        configurable: true
    });
    Group.objectStoreName = 'groups';
    return Group;
}(DatabaseRecord));
export { Group };
