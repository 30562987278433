import 'hammerjs'

import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { Injectable, NgModule } from '@angular/core'
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin'

import { APPSTATES } from './state';
import { APP_BASE_HREF } from '@angular/common'
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { AuthGuardService } from './services/auth-guard.service'
import { AuthInterceptor } from './services/auth-interceptor.service'
import { AuthService } from './services/auth.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CustomMaterialModule } from './modules/custom-material/custom-material.module'
import { DataService } from './services/data.service'
import { DatabaseService } from './services/database.service'
import { DeviceDetectorService } from 'ngx-device-detector';
import { DocumentOfflineService } from './services/document-offline.service'
import { ErrorHandler } from '@angular/core'
import { ExerciseModule } from './modules/exercise/exercise.module'
import { FlexLayoutModule } from '@angular/flex-layout'
import { GestureConfig } from '@angular/material'
import { GroupDataService } from './services/group.data.service'
import { GroupManagementModule } from './modules/group-management/group-management.module'
import { HeaderComponent } from './modules/header/header.component'
import { InformedConsentDialogComponent } from './components/informed-consent-dialog/informed-consent-dialog.component'
import { LoggingModule } from 'fitforce-logging'
import { LoginComponent } from './components/login/login.component'
import { MainModule } from './modules/main/main.module'
import { MaxsetDialogComponent } from './modules/maxset-dialog/maxset-dialog.component'
import { MicroUiContainerComponent } from './components/micro-ui-container/micro-ui-container.component'
import { MomentModule } from 'ngx-moment'
import { NgxsModule } from '@ngxs/store'
import { NgxsResetPluginModule } from 'ngxs-reset-plugin'
import { NgxsRouterPluginModule } from '@ngxs/router-plugin'
import { OfflineFirstModule } from 'fitforce-offline-first'
import { PageTransitionService } from './services/page-transition.service'
import { PlaycardSummaryModule } from './modules/playcard-summary/playcard-summary.module'
import { ResourcesModule } from './modules/resources/resources.module';
import { RestOptions } from 'fitforce-document-sync'
import { RestService } from './services/rest.service'
import { ServiceWorkerModule } from '@angular/service-worker'
import { VerifyAccountDialogComponent } from './components/verify-account-dialog/verify-account-dialog.component'
import { environment } from 'environments/environment';
import { DodNoticeAndConsentBannerDialogComponent } from './components/dod-notice-and-consent-banner-dialog/dod-notice-and-consent-banner-dialog.component'

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InformedConsentDialogComponent,
    VerifyAccountDialogComponent,
    MaxsetDialogComponent,
    AlertDialogComponent,
    MicroUiContainerComponent,
    DodNoticeAndConsentBannerDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    CustomMaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    OfflineFirstModule,
    LoggingModule.forRoot({
      logLevel: environment.logLevel
    }),
    NgxsModule.forRoot(APPSTATES, { developmentMode: !!!environment.production }),
    // https://stackoverflow.com/questions/50838547/ngxs-router-plugin-breaks-routing
    // NgxsStoragePluginModule.forRoot({ key: ['workout', 'auth', 'app'] }),
    NgxsStoragePluginModule.forRoot(),
    // NgxsRouterPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    FlexLayoutModule,
    MainModule,
    PlaycardSummaryModule,
    GroupManagementModule,
    ExerciseModule,
    ResourcesModule
  ],
  providers: [
    AuthService,
    DataService,
    RestService,
    AuthGuardService,
    DatabaseService,
    DocumentOfflineService,
    {
      provide: APP_BASE_HREF,
      useValue: environment.base
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    GroupDataService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig
    },
    {
      provide: RestOptions,
      useValue: environment.api_url
    },
    PageTransitionService,
    DeviceDetectorService
  ],
  entryComponents: [
    DodNoticeAndConsentBannerDialogComponent,
    InformedConsentDialogComponent,
    VerifyAccountDialogComponent,
    AlertDialogComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
