import { Routes } from '@angular/router'
import { ResourcesComponent } from './resources/resources.component'
import { TotalFitnessSurveyResultComponent } from './total-fitness-survey-result/total-fitness-survey-result.component';
import { SurveyAdditionalResourcesComponent } from './total-fitness-survey-result/survey-additional-resources/survey-additional-resources.component';
import { AuthGuardService as AuthGuard } from '../../services/auth-guard.service'

export const ResourcesRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ResourcesComponent,
        data: { page: 'resources' },
      }
    ]
  },
  {
    path: 'resources',
    children: [
      {
        path: '',
        component: ResourcesComponent,
        data: { page: 'resources' },
      }
    ]
  }
];
