import * as _ from 'lodash';

import { ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import {
  SetUserAgeGroup,
  SetUserGender,
} from 'app/state/app/app.actions';

import { AgeRange } from '../../services/pft-cft-rubric.service';
import { AppState } from 'app/state/app/app.state';
import { DataService } from '../../services/data.service';
import { DocumentService } from 'app/services/document.service';
import { MatDialog } from '@angular/material';
import { PftCftCalcDialogComponent } from '../pft-cft-calc-dialog/pft-cft-calc-dialog.component';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import {
  UserProfilePftCftData,
} from '../../models/user-profile.model';

const noCategoryId = '00000000-0000-0000-0000-000000000000';
const pftCategory = 'PFT Scores';
const cftCategory = 'CFT Scores';

@Component({
  selector: 'app-pft-cft-scores',
  templateUrl: './pft-cft-scores.component.html',
  styleUrls: ['./pft-cft-scores.component.scss'],
})
export class PftCftScoresComponent implements OnInit, OnChanges {
  private subs: Subscription[] = [];
  // Default no category
  pftExercises = {
    id: 'pft',
    name: pftCategory,
    exercises: [],
  };
  cftExercises = {
    id: 'cft',
    name: cftCategory,
    exercises: [],
  };
  exercises = [];
  allExercises = [];
  pftFilteredExercises = [];
  cftFilteredExercises = [];
  selectedTab: string;
  userProfileScoreData: UserProfilePftCftData;
  totalPftScore: number | undefined = undefined;
  totalCftScore: number | undefined = undefined;
  categories = [];
  pftCategoryId: string;
  cftCategoryId: string;
  pftMessage: string;
  cftMessage: string;
  minPftCount = 2;
  maxPftCount = 3;
  minCftCount = 1;
  maxCftCount = 3;

  constructor(
    private documentService: DocumentService,
    private dataService: DataService,
    private store: Store,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const userProfile = this.store.selectSnapshot(AppState.userProfile);
    if (userProfile && userProfile.scores) {
      this.userProfileScoreData = _.cloneDeep(userProfile.scores);
    } else {
      this.userProfileScoreData = {
        exercises: [],
        gender: '',
        age_group: undefined,
      };
    }

    this.documentService.restoreData().subscribe(() => {
      const observations = this.documentService.store.observations;
      this.categories =
        this.documentService.store.exerciseProgram.document.exercise_categories;
      this.pftCategoryId = this.categories.find(cat => cat.name === 'PFT').id;
      this.cftCategoryId = this.categories.find(cat => cat.name === 'CFT').id;
      this.allExercises =
        this.documentService.store.exerciseProgram.document.exercises;
      const totalPftScore = 0;
      let totalPftUndefined = true;
      const totalCftScore = 0;
      let totalCftUndefined = true;
      this.allExercises.forEach((exercise, ei) => {
        // if the exercise is a pft exercise
        if (
          (exercise.exercise_categories &&
            exercise.exercise_categories.indexOf(this.pftCategoryId) > -1) ||
          (exercise.categories &&
            exercise.categories.indexOf(this.pftCategoryId) > -1)
        ) {
          // Does the user have pft scores?
          if (userProfile.scores) {
            const userExerciseIndex = userProfile.scores.exercises.findIndex(
              e => {
                return e.exercise_id === exercise.id;
              },
            );
            if (userExerciseIndex !== -1) {
              const pftScore =
                userProfile.scores.exercises[userExerciseIndex]['pft']['score'];
              if (pftScore !== NaN && pftScore !== 'NaN') {
                totalPftUndefined = false;
                exercise['pft']['score'] = pftScore;
              }
              const rawScore =
                userProfile.scores.exercises[userExerciseIndex]['pft']['raw'];
              if (rawScore !== NaN && rawScore !== 'NaN') {
                exercise['pft']['raw'] = rawScore;
              }
            }
            this.pftExercises.exercises.push(exercise);
          }
        }
        // if the exercise is a cft exercise
        if (
          (exercise.exercise_categories &&
            exercise.exercise_categories.indexOf(this.cftCategoryId) > -1) ||
          (exercise.categories &&
            exercise.categories.indexOf(this.cftCategoryId) > -1)
        ) {
          // Does the user have cft scores?
          if (userProfile.scores) {
            const userExerciseIndex = userProfile.scores.exercises.findIndex(
              e => {
                return e.exercise_id === exercise.id;
              },
            );
            if (userExerciseIndex !== -1) {
              const cftScore =
                userProfile.scores.exercises[userExerciseIndex]['cft']['score'];
              if (cftScore !== NaN && cftScore !== 'NaN') {
                totalCftUndefined = false;
                exercise['cft']['score'] = cftScore;
              }
              const rawScore =
                userProfile.scores.exercises[userExerciseIndex]['cft']['raw'];
              if (rawScore !== NaN && rawScore !== 'NaN') {
                exercise['cft']['raw'] = rawScore;
              }
            }
            this.cftExercises.exercises.push(exercise);
          }
        }
      });
      this.pftFilteredExercises = this.pftExercises.exercises;
      this.cftFilteredExercises = this.cftExercises.exercises;
    });
    this.calcTotalPftScore();
    this.calcTotalCftScore();
  }

  ngOnChanges(): void {}

  refreshExercises(value?: string): void {
    const pftExercises = _.cloneDeep(this.pftExercises.exercises);
    const cftExercises = _.cloneDeep(this.cftExercises.exercises);
    if (value) {
      this.pftFilteredExercises = pftExercises.filter(
        ex => ex.name.toLowerCase().indexOf(value.toLowerCase()) > -1,
      );
      this.cftFilteredExercises = cftExercises.filter(
        ex => ex.name.toLowerCase().indexOf(value.toLowerCase()) > -1,
      );
    }
  }

  trackByFn(itemKey: any): any {
    return itemKey;
  }

  onExerciseSelected(exercise): void {
    // console.log('exercise selected: ', exercise);
    // TODO: Add SetSelectedPft or SetSelectedCft to store.actions
    // this.store.dispatch(new SetSelectedRM(exercise))
  }

  openCalcCft(exercise: any): void {
    const categoryKey = 'CFT';
    if (!exercise) {
      return;
    }
    const dialogRef = this.dialog.open(PftCftCalcDialogComponent, {
      maxWidth: '100vw',
      width: '80%',
      height: '55vh',
      data: {
        exercise: exercise,
        category: categoryKey,
        gender: this.userProfileScoreData.gender,
        age_group: this.getAgeGroupEnum(),
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.success === true) {
        if (res['age_group']) {
          if (this.userProfileScoreData) {
            this.userProfileScoreData.age_group = res['age_group'];
            this.store.dispatch(
              new SetUserAgeGroup(this.userProfileScoreData.age_group),
            );
          }
        }
        if (res['gender']) {
          if (this.userProfileScoreData) {
            this.userProfileScoreData.gender = res['gender'];
            this.store.dispatch(
              new SetUserGender(this.userProfileScoreData.gender),
            );
          }
        }
        // TODO: Update userProfileScoreData on user profile by adding an action
        const index = this.cftFilteredExercises.indexOf(exercise);
        if (
          res['exercise'] &&
          res['exercise']['cft'] !== undefined &&
          res['exercise']['cft'] !== null
        ) {
          exercise['cft']['score'] = res['exercise']['cft']['score'];
          exercise['cft']['raw'] = res['exercise']['cft']['raw'];
          if (index > -1) {
            this.cftFilteredExercises[index] = exercise;
          }
          this.calcTotalCftScore();
          const newCft = {
            exercise_id: res['exercise']['id'],
            type: 'cft',
            score: res['exercise']['cft']['score'],
            raw: res['exercise']['cft']['raw'],
          };
        }

        //   const userProfile = this.store.selectSnapshot(AppState.userProfile)
        //   const statIndex = userProfile.stats.exercises.findIndex(ex => ex.exercise_id === exercise.id);
        //   let action;
        //   if (statIndex > -1) {
        //     action = new Set1RM(new1RM, statIndex);
        //   } else {
        //     action = new Set1RM(new1RM);
        //   }

        //   this.store.dispatch([
        //     action
        //   ]).subscribe(() => {
        //     const categoryIndex = this.exercises.findIndex(cat => cat.id === categoryKey);
        //     const exerciseIndex = this.exercises[categoryIndex].exercises.findIndex(ex => ex.id === exercise.id);
        //     this.exercises[categoryIndex].exercises[exerciseIndex]['1rm'] = new1RM
        //     this.refreshExercises(this.search.value);
        //   })
        // }
      } else if (res && res.success === false) {
        // TODO: Update userProfileScoreData on user profile by adding an action
        const index = this.cftFilteredExercises.indexOf(exercise);
        if (
          res['exercise'] &&
          res['exercise']['cft'] !== undefined &&
          res['exercise']['cft'] !== null
        ) {
          exercise['cft']['score'] = res['exercise']['cft']['score'];
          exercise['cft']['raw'] = res['exercise']['cft']['raw'];
          if (index > -1) {
            this.cftFilteredExercises[index] = exercise;
          }
          this.calcTotalCftScore();
          const newCft = {
            exercise_id: res['exercise']['id'],
            type: 'cft',
            score: res['exercise']['cft']['score'],
            raw: res['exercise']['cft']['raw'],
          };
        }
      }
    });
  }

  getAgeGroupEnum(): AgeRange {
    if (!this.userProfileScoreData && !this.userProfileScoreData.age_group) {
      return AgeRange.AGE_17_20;
    }
    switch (this.userProfileScoreData.age_group) {
      case AgeRange.AGE_17_20:
        return AgeRange.AGE_17_20;
      case AgeRange.AGE_21_25:
        return AgeRange.AGE_21_25;
      case AgeRange.AGE_26_30:
        return AgeRange.AGE_26_30;
      case AgeRange.AGE_31_35:
        return AgeRange.AGE_31_35;
      case AgeRange.AGE_36_40:
        return AgeRange.AGE_36_40;
      case AgeRange.AGE_41_45:
        return AgeRange.AGE_41_45;
      case AgeRange.AGE_46_50:
        return AgeRange.AGE_46_50;
      default:
        return AgeRange.AGE_51;
    }
  }

  openCalcPft(exercise: any): void {
    const categoryKey = 'PFT';
    if (!exercise) {
      return;
    }
    const dialogRef = this.dialog.open(PftCftCalcDialogComponent, {
      maxWidth: '100vw',
      width: '85%',
      height: '70vh',
      data: {
        exercise: exercise,
        category: categoryKey,
        gender: this.userProfileScoreData.gender,
        age_group: this.getAgeGroupEnum(),
      },
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.success === true) {
        // console.log('dialog result', res);
        if (res['age_group']) {
          if (this.userProfileScoreData) {
            this.userProfileScoreData.age_group = res['age_group'];
            this.store.dispatch(
              new SetUserAgeGroup(this.userProfileScoreData.age_group),
            );
          }
        }
        if (res['gender']) {
          if (this.userProfileScoreData) {
            this.userProfileScoreData.gender = res['gender'];
            this.store.dispatch(
              new SetUserGender(this.userProfileScoreData.gender),
            );
          }
        }
        // TODO: Update userProfileScoreData on user profile by adding an action
        const index = this.pftFilteredExercises.indexOf(exercise);
        if (
          res['exercise'] &&
          res['exercise']['pft'] !== undefined &&
          res['exercise']['pft'] !== null
        ) {
          exercise['pft'] = res['exercise']['pft'];
          exercise['pft']['score'] = res['exercise']['pft']['score'];
          exercise['pft']['raw'] = res['exercise']['pft']['raw'];
          if (index > -1) {
            this.pftFilteredExercises[index] = exercise;
          }
          this.calcTotalPftScore();
          const newPft = {
            exercise_id: res['exercise']['id'],
            type: 'pft',
            score: res['exercise']['pft']['score'],
            raw: res['exercise']['pft']['raw'],
          };
        }
        // this.saveScoreObservationDocs();

        //   const userProfile = this.store.selectSnapshot(AppState.userProfile)
        //   const statIndex = userProfile.stats.exercises.findIndex(ex => ex.exercise_id === exercise.id);
        //   let action;
        //   if (statIndex > -1) {
        //     action = new Set1RM(new1RM, statIndex);
        //   } else {
        //     action = new Set1RM(new1RM);
        //   }

        //   this.store.dispatch([
        //     action
        //   ]).subscribe(() => {
        //     const categoryIndex = this.exercises.findIndex(cat => cat.id === categoryKey);
        //     const exerciseIndex = this.exercises[categoryIndex].exercises.findIndex(ex => ex.id === exercise.id);
        //     this.exercises[categoryIndex].exercises[exerciseIndex]['1rm'] = new1RM
        //     this.refreshExercises(this.search.value);
        //   })
        // }
      } else if (res && res.success === false) {
        console.log('PFT dialog result', res);
        // TODO: Update userProfileScoreData on user profile by adding an action
        const index = this.pftFilteredExercises.indexOf(exercise);
        if (
          res['exercise'] &&
          res['exercise']['pft'] !== undefined &&
          res['exercise']['pft'] !== null
        ) {
          exercise['pft'] = res['exercise']['pft'];
          exercise['pft']['score'] = res['exercise']['pft']['score'];
          exercise['pft']['raw'] = res['exercise']['pft']['raw'];
          if (index > -1) {
            this.pftFilteredExercises[index] = exercise;
          }
          this.calcTotalPftScore();
          const newPft = {
            exercise_id: res['exercise']['id'],
            type: 'pft',
            score: res['exercise']['pft']['score'],
            raw: res['exercise']['pft']['raw'],
          };
        }
      }
    });
  }

  calcTotalPftScore(): void {
    let totalScore = 0;
    let isUndefined = true;
    let pftCount = 0;
    for (let i = 0; i < this.pftFilteredExercises.length; ++i) {
      const exercise = this.pftFilteredExercises[i];
      if (exercise['pft'] === undefined || exercise['pft'] === null) {
        continue;
      }
      if (
        exercise['pft']['score'] === undefined ||
        exercise['pft']['score'] === null ||
        exercise['pft']['score'] === 'NaN'
      ) {
        continue;
      }
      isUndefined = false;
      if (exercise['pft']['score'] === 0 || exercise['pft']['score'] === '0') {
        totalScore = 0;
        ++pftCount;
        break;
      } else {
        totalScore += exercise['pft']['score'];
        ++pftCount;
      }
    }

    if (pftCount < this.minPftCount || pftCount > this.maxPftCount || isUndefined) {
      this.totalPftScore = undefined;
    } else {
      this.totalPftScore = totalScore;
    }
    if (this.totalPftScore === 0) {
      this.pftMessage = this.getFailingMessage('PFT');
    } else {
      this.pftMessage = '';
    }
  }

  private getFailingMessage(category: string): string {
    return `Your total ${category} event performance is 0 because you did not pass the minimum requirements of at least one exercise.`;
  }

  calcTotalCftScore(): void {
    let totalScore = 0;
    let isUndefined = true;
    let cftCount = 0;
    for (let i = 0; i < this.cftFilteredExercises.length; ++i) {
      const exercise = this.cftFilteredExercises[i];
      if (exercise['cft'] === undefined || exercise['cft'] === null) {
        continue;
      }
      if (
        exercise['cft']['score'] === undefined ||
        exercise['cft']['score'] === null ||
        exercise['cft']['score'] === 'NaN'
      ) {
        continue;
      }
      isUndefined = false;
      if (exercise['cft']['score'] === 0 || exercise['cft']['score'] === '0') {
        totalScore = 0;
        ++cftCount;
        break;
      } else {
        totalScore += exercise['cft']['score'];
        ++cftCount;
      }
    }
    if (cftCount < this.minCftCount || cftCount > this.maxCftCount || isUndefined) {
      this.totalCftScore = undefined;
    } else {
      this.totalCftScore = totalScore;
    }
    if (this.totalCftScore === 0) {
      this.cftMessage = this.getFailingMessage('CFT');
    } else {
      this.cftMessage = '';
    }
  }

  async saveScoreObservationDocs() {
    this.calcTotalPftScore();
    this.calcTotalCftScore();

    const pftObservationDocument =
      this.dataService.generatePftCftScoreObservation(
        this.totalPftScore,
        'PFT',
      );
    const cftObservationDocument =
      this.dataService.generatePftCftScoreObservation(
        this.totalCftScore,
        'CFT',
      );

    await this.dataService.storeObservationDocument(pftObservationDocument);
    await this.dataService.postScoreDocument(pftObservationDocument);

    await this.dataService.storeObservationDocument(cftObservationDocument);
    await this.dataService.postScoreDocument(cftObservationDocument);

    this.dataService.pftCftChartReset();
  }

  disableCftAdd(item: any) {
    const hasScore = (
      exercises: any[],
      name: string,
      filter: string,
      id: string,
    ) =>
      name.includes(filter) &&
      exercises.filter(
        (ex: any) =>
          ex.name.includes(filter) && ex.cft && ex.cft.raw && ex.id !== id,
      ).length > 0;

    const hasBeenScored = this.cftFilteredExercises.filter(
      ex => ex.name === item.name && ex.cft && ex.cft.raw
    ).length > 0;

    const notMaxExercises = this.cftFilteredExercises.filter(
      ex => (ex.cft && ex.cft.raw)
    ).length < this.maxCftCount;

    if (!notMaxExercises && !hasBeenScored) {
      return true;
    }

    if (item.exercise_categories.includes(this.cftCategoryId)) {
      if (
        hasScore(this.cftFilteredExercises, item.name, 'MTC', item.id) ||
        hasScore(this.cftFilteredExercises, item.name, 'MANUF', item.id)
      ) {
        return true;
      }
    }

    // if item has an altitude alternate version, disable other non-altitude or altitude options
    // (whichever is opposite of one with the score)
    if (!item.name.includes('AL')) {
      if (item.name.includes('altitude')) {
        if (
          this.cftFilteredExercises.filter((ex: any) =>
            (!ex.name.includes('AL') && !ex.name.includes('altitude') && ex.cft && ex.cft.raw && ex.id !== item.id)
          ).length === 0
        ) {
          return false;
        }
        return true;
      } else {
        if (
          this.cftFilteredExercises.filter((ex: any) =>
            (!ex.name.includes('AL') && ex.name.includes('altitude') && ex.cft && ex.cft.raw && ex.id !== item.id)
          ).length === 0
        ) {
          return false;
        }
        return true;
      }
    }

    return false;
  }

  disablePftAdd(item: any) {
    const hasScore = (name: string, filter: string) =>
      item.name === name &&
      this.pftFilteredExercises.filter(
        ex => ex.name === filter && ex.pft && ex.pft.raw,
      ).length > 0;


    const hasBeenScored = this.pftFilteredExercises.filter(
      ex => ex.name === item.name && ex.pft && ex.pft.raw
    ).length > 0;

    const notMaxExercises = this.pftFilteredExercises.filter(
      ex => (ex.pft && ex.pft.raw)
    ).length < this.maxPftCount;

    if (!notMaxExercises && !hasBeenScored) {
      return true;
    }

    if (item.exercise_categories.includes(this.pftCategoryId)) {
      if (
        hasScore('Pull-ups', 'Push-ups') ||
        hasScore('Push-ups', 'Pull-ups')
      ) {
        return true;
      }
      if (hasScore('Crunches', 'Plank') || hasScore('Plank', 'Crunches')) {
        return true;
      }
      if (
        hasScore('3-mile run', '3-mile run (altitude)') ||
        hasScore('3-mile run (altitude)', '3-mile run') ||
        hasScore('3-mile run', '5K Row (altitude)') ||
        hasScore('3-mile run (altitude)', '5K Row')
      ) {
        return true;
      }
      if (
        hasScore('5K Row', '5K Row (altitude)') ||
        hasScore('5K Row (altitude)', '5K Row') ||
        hasScore('5K Row', '3-mile run (altitude)') ||
        hasScore('5K Row (altitude)', '3-mile run')
      ) {
        return true;
      }
    }

    return false;
  }

  clearPft() {
    this.pftFilteredExercises.forEach(ex => {
      ex.pft = undefined
    });
    this.calcTotalPftScore();
  }

  clearCft() {
    this.cftFilteredExercises.forEach(ex => {
      ex.cft = undefined
    });
    this.calcTotalCftScore();
  }
}
