<app-header
  [hasGoBack] = true
  [hasLogout] = true
></app-header>
<ngx-spinner
  bdColor = "rgba(51, 51, 51, 0.8)"
  size = "large"
  color = "#fff"
  type = "ball-atom">
  <p>Syncing group plans...</p>
</ngx-spinner>
<div *ngIf="group; else loadingData" class="group-view">
  <h2>{{ group.name }}</h2>
  <div *ngIf="!isJoined() && !isRequested && isOnline" class="group-button join-group" fxLayout="row" fxLayoutAlign="center">
    <button mat-raised-button color="primary" (click)="requestJoin()">Request to Join</button>
  </div>
  <div *ngIf="isRequested" fxLayout="row" fxLayoutAlign="center">
    <h2>Request Pending</h2>
  </div>
  <h4>Leader</h4>
  <p *ngFor="let owner of owners">
    {{ owner.rank }} {{ owner.firstName}} {{ owner.lastName }} <span *ngIf="owner.email">&lt;{{ owner.email }}&gt;</span>
  </p>
  <h4>Marines</h4>
  <p>{{ group.users ? group.users.length : "0" }}</p>
  <div *ngIf="isJoined() && isOnline" class="group-button leave-group" fxLayout="row" fxLayoutAlign="center">
    <button mat-raised-button color="warn" (click)="openLeaveDialog()">Leave Group</button>
  </div>
</div>
<ng-template #loadingData>
  <p>Fetching...</p>
</ng-template>
