import { Routes } from '@angular/router'
import { ExerciseComponent } from './exercise.component'
import { CompleteComponent } from './complete/complete.component'
import { PreworkoutSurveyComponent } from './preworkout-survey/preworkout-survey.component'

export const ExerciseRoutes: Routes = [{
  path: '',
  component: ExerciseComponent,
  data: { page: 'exercise' },
}, {
  path: 'survey',
  component: PreworkoutSurveyComponent,
  data: { page: 'survey' },
}, {
  path: 'complete',
  component: CompleteComponent,
  data: { page: 'complete' },
}, {
  path: 'complete/:workoutSessionId',
  component: CompleteComponent,
  data: { page: 'history' },
}]
