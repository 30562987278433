import * as _ from 'lodash';
import * as moment from 'moment';
import { DatabaseRecord } from '../classes/database-record.class'
import { PlanBlock } from './plan-block.model';

export class Day extends DatabaseRecord {
  static objectStoreName = 'planDays'
  planUUID: string
  name: string
  date: moment.Moment
  interval: number
  action: string
  isComplete: boolean
  isRestricted: boolean
  restrictLabel: Object
  workoutType: Object
  load: number
  est_completion_time_ms?: number
  version: number

  constructor (data: {} = {}) {
    super(data)

    this.name = data['name'] || _.get(data, 'workoutType.name') || _.get(data, 'restrictLabel.name') || 'Other'
    this.planUUID = data['planUUID']
    this.date = moment(data['date'])
    this.action = data['action'] || 'Go'
    this.isComplete = data['isComplete'] || false
    this.isRestricted = data['isRestricted'] || false
    this.restrictLabel = data['restrictLabel']
    this.workoutType = data['workoutType']
    this.blocks = data['blocks']
    this.load = data['load'] || 0
    this.est_completion_time_ms = data['est_completion_time_ms']
    this.version = data['version']
  }

  get blocks () {
    return this.getForeignKeys(PlanBlock)
  }

  set blocks (records: any[]) {
    this.setForiegnRecords(PlanBlock, records)
  }

  toJSON () {
    return super.toJSON(
      ['name', 'planUUID', 'date', 'action', 'isComplete', 'isRestricted', 'restrictLabel', 'workoutType', 'blocks', 'load', 'est_completion_time_ms', 'version']
    )
  }
}
