import { Component, OnInit } from '@angular/core'
import { Store, Select } from '@ngxs/store'
import { Navigate } from '@ngxs/router-plugin'
import { AppState } from 'app/state/app/app.state'
import { Observable } from 'rxjs'
import { DocumentService } from 'app/services/document.service'
import * as _ from 'lodash'
import { Workout, Exercise, PlanDocument, Program, ResourceType } from 'fitforce-document-sync'
import { environment } from 'environments/environment'
import { VideoDialogComponent } from '../video-dialog/video-dialog.component'
import { MatDialog } from '@angular/material'
import { StartWorkout } from 'app/state/workout/workout.actions'
import { Router } from '@angular/router'
import { PageTransitionService, PAGETRANSITIONS } from 'app/services/page-transition.service'
import { DataService } from 'app/services/data.service'
import { Day } from 'app/models'

@Component({
  selector: 'app-playcard-summary',
  templateUrl: './playcard-summary.component.html',
  styleUrls: ['./playcard-summary.component.scss']
})

export class PlaycardSummaryComponent implements OnInit {
  @Select(AppState.activeDaySelected)
  activeDaySelected$: Observable<any>
  planDocument: PlanDocument
  activeWorkout: Workout
  workoutDocument: any
  load: any
  repStrings = [
    'Rest',
    'Very, Very Easy',
    'Easy',
    'Moderate',
    'Somewhat Hard',
    'Hard',
    'Hard',
    'Very Hard',
    'Very Hard',
    'Very Hard',
    'Maximal'
  ]

  constructor(
    private store: Store,
    private router: Router,
    public dialog: MatDialog,
    private documentService: DocumentService,
    private pageTransitionService: PageTransitionService,
    private dataService: DataService,
  ) { }

  ngOnInit() {
    this.load = 'N/A'
    const snapshotDay = this.store.selectSnapshot(AppState.activeDaySelected)
    this.documentService.restoreData().subscribe(() => {
      this.planDocument = _.find(this.documentService.store.planDocuments, { key: snapshotDay.planUUID })
      this.workoutDocument = this.activeWorkout = _.find(this.planDocument.document.workouts, { id: snapshotDay.key })

      if (this.workoutDocument.est_rpe > -1 && this.workoutDocument.est_completion_time_ms > -1) {
        this.load = (this.workoutDocument.est_rpe * (this.workoutDocument.est_completion_time_ms / 1000 / 60)).toString()
      }

      // Go through workout document and get exercise details
      this.workoutDocument.tiers.forEach((tier, i) => {
        tier.rows.forEach((row, j) => {
          const exerciseDetails = this.exerciseDetails(row.exercise_id)
          if (exerciseDetails && exerciseDetails.resources ) {
            if (exerciseDetails.resources.length > 0) {
              const videoResource = _.find(exerciseDetails.resources, { type: ResourceType.Video })
              if (videoResource) {
                this.workoutDocument.tiers[i].rows[j].video = videoResource
              }
            }
          }
        })
      })
    })
  }

  openVideo(videoData) {
    if (!_.isEmpty(videoData)) {
      this.dialog.open(VideoDialogComponent, {
        panelClass: 'modal-workout-video',
        maxWidth: '100vw',
        width: '100%',
        data: {
          title: videoData.title,
          videoId: videoData.asset_id.replace('video:', ''),
        },
      })
    }
  }

  exerciseDetails(exerciseId: string) {
    const exercise = _.find(this.documentService.store.exerciseProgram.document.exercises, { id: exerciseId })
    return exercise
  }

  goBack() {
    this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.RIGHT)
    this.router.navigate(['/main'])
  }

  startExercise() {
    this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT)
    this.router.navigate(['/exercise/survey'])
  }
}
