import { Component, OnInit } from '@angular/core'
import { DataService } from 'app/services/data.service'
import { PAGETRANSITIONS, PageTransitionService } from 'app/services/page-transition.service'
import { Router } from '@angular/router'
import { trigger, transition, style, animate } from '@angular/animations'
import * as _ from 'lodash'
import * as moment from 'moment'
import { Phase, PlanDocumentUtils } from 'fitforce-document-sync'
import { DocumentService } from 'app/services/document.service'

@Component({
  selector: 'app-workout-history',
  templateUrl: './workout-history.component.html',
  styleUrls: ['./workout-history.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('300ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]
})
export class WorkoutHistoryComponent implements OnInit {
  sessions: any
  constructor(
    private router: Router,
    private dataService: DataService,
    private documentService: DocumentService,
    private pageTransitionService: PageTransitionService,
  ) { }

  ngOnInit() {
    this.loadSessions()
  }

  async loadSessions() {
    const documents = await this.dataService.getAllWorkoutSessionDocuments()
    const filteredSessions = _.filter(documents, (doc: any) => {
      const currentDate = moment().startOf('day').hour(12)
      if (doc.document.end_time) {
        const end_time = moment(doc.document.end_time)
        if (currentDate.diff(end_time, 'months') <= 3) {
          return true
        }
      }
    })
    await this.documentService.restoreData().toPromise()
    this.sessions = _.orderBy(filteredSessions, s => s.document.end_time, 'desc').map(m => {
      const doc = m.document
      doc.total_load = Math.floor(doc.rpe * (doc.completion_time_ms / 1000 / 60)) || 0
      doc.end_time_pretty = moment(doc.end_time).format('MMMM DD, YYYY')

      // Used for historic sessions too
      if (!doc.phase) {
        const planDocument = this.documentService.store.planDocuments.find((planDoc) => {
          return planDoc.document.id === doc.plan.id
        })
        // Unusual issue where a planDocument won't exist for a key/id
        if (planDocument) {
          const workout = planDocument.document.workouts.find((workoutDoc) => {
            return workoutDoc.id === doc.workout.id
          })
          const phase: Phase = PlanDocumentUtils.findPhase(planDocument.document.phases, {
            day: workout.schedule.day[0], week: workout.schedule.week[0]
          })
          if (phase) {
            doc.phase = {
              uuid: phase.id,
              name: phase.name,
              color: phase.color
            }
          }
        }
      }
      return doc
    })
  }

  onClickCard(session) {
    this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT)
    this.router.navigate(['exercise', 'complete', session.id])
  }
}
