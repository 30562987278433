import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { WorkoutState } from 'app/state/workout/workout.state';
import * as _ from 'lodash'
import { ExerciseProgramDocumentUtils } from 'fitforce-document-sync'
import { DocumentService } from 'app/services/document.service';
import { SetCircuitRounds } from 'app/state';

@Component({
  selector: 'app-edit-circuit-dialog',
  templateUrl: './edit-circuit-dialog.component.html',
  styleUrls: ['./edit-circuit-dialog.component.scss']
})
export class EditCircuitDialogComponent implements OnInit {
  tier: any
  tierIndex: number
  isCircuit: boolean
  totalRoundsCompleted: number

  constructor (
    private store: Store,
    private documentService: DocumentService,
    public dialogRef: MatDialogRef<EditCircuitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
    this.loadData()
  }

  async loadData() {
    this.isCircuit = false
    const currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession)
    let currentWorkoutIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex)
    if (this.data && this.data.tierIndex >= 0) {
      currentWorkoutIndex = this.data.tierIndex
      this.tierIndex = this.data.tierIndex
    }
    this.tier = currentWorkoutSession.workout.tiers[currentWorkoutIndex]
    if (this.tier.circuit.executions.length > 0 && this.tier.circuit.executions[0].num_rounds >= 0) {
      this.totalRoundsCompleted = this.tier.circuit.executions[0].num_rounds
    } else if (this.tier.circuit.num_rounds === -1) {
      this.totalRoundsCompleted = 0
    } else {
      this.totalRoundsCompleted = this.tier.circuit.num_rounds
    }

    if (this.tier.circuit && this.tier.circuit.num_rounds && this.tier.circuit.num_rounds > 0) {
      this.isCircuit = true
    }
  }

  onSave() {
    this.dialogRef.close({
      success: true,
      num_rounds: this.totalRoundsCompleted,
    })
  }

  onClose() {
    this.dialogRef.close({
      success: false
    })
  }
}
