<div *ngIf="graph">
  <div class="legend" fxLayout="row" fxLayoutGap="10px">
    <div *ngIf="selectedRM">{{ selectedRM.name }}</div>
  </div>
  <plotly-plot
    [data]="graph.data"
    [layout]="graph.layout"
    [config]="graph.config">
  </plotly-plot>
</div>
