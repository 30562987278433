import 'hammerjs';
import { environment } from 'environments/environment';
var ɵ0 = environment.base, ɵ1 = environment.api_url;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
