export enum ResourceType {
    INTERNAL,
    EXTERNAL
}

export class Resource {
    name: string;
    description: string;
    type: ResourceType;
    url: string;
    phone: string;
}
