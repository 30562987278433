
<div mat-dialog-title>{{ tierName }}</div>
<mat-dialog-content>
  <mat-form-field class="full-width">
    <textarea matInput matTextareaAutosize
      placeholder="Notes"
      matFocusStart
      matAutosizeMinRows="3"
      matAutosizeMaxRows="5"
      [(ngModel)]="notes"
      [disabled]="true">
    </textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="cancelSelected()" fxFlex>Close</button>
</mat-dialog-actions>
