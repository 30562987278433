
<div mat-dialog-title>Edit Set {{ setIndex + 1 }}</div>
<mat-dialog-content>
  <div *ngFor="let setParam of setParamValues | keyvalue; trackBy:trackByFn">
    <mat-form-field class="half-width" *ngIf="setParam.key === 'time'">
      <input matInput placeholder="Minutes" [value]="getMinutes()" (change)="updateMinutes($event)">
    </mat-form-field>
    <mat-form-field class="half-width" *ngIf="setParam.key === 'time'">
      <input matInput placeholder="Seconds" [value]="getSeconds()"  (change)="updateSeconds($event)">
    </mat-form-field>
    <mat-form-field class="full-width" *ngIf="setParam.key !== 'time'">
      <input matInput
        placeholder="{{ setParam.key }}"
        [ngModel]="setParamValues[setParam.key] | noNegative" (ngModelChange)="setParamValues[setParam.key]=$event">
    </mat-form-field>
  </div>
  <ng-container *ngIf="data.forComplete">
    <mat-checkbox [(ngModel)]="isComplete">Complete</mat-checkbox>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="cancelSelected()" fxFlex>Close</button>
  <button class="yes-button" mat-button (click)="submitSelected()" cdkFocusInitial fxFlex>Save</button>
</mat-dialog-actions>
