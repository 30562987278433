import * as _ from 'lodash'
import * as moment from 'moment'
import { Injectable, Inject } from '@angular/core'
import { Observable, from, ReplaySubject, BehaviorSubject, of, forkJoin, Subject } from 'rxjs'
import { delayWhen, map, tap } from 'rxjs/operators'
import { RestService } from './rest.service'
import { DatabaseRecord, DatabaseRecordType } from '../classes/database-record.class'
import { DatabaseService } from './database.service'
import { Day, Group, Plan, User, WorkoutSession, PlanBlock, PlanExercise } from '../models'
import { AuthService } from './auth.service'
import {
  PlanDocument,
  PlanDocumentUtils,
  Workout,
  DocumentModelFactoryService,
  DocumentModelType,
  Observation,
  ObservationDocument,
  MaxSetResult,
  Result,
  SurveyResult,
  ResourceType,
  Phase,
  Measure,
} from 'fitforce-document-sync'
import { DocumentService } from './document.service'
import { WorkoutSessionDBDocument, WorkoutSessionDocument, WorkoutSession as IWorkoutSession } from 'app/models/workout-session-document.model'
import { ScoreResult } from 'app/models/score-document.model';
import { v4 as uuid } from 'uuid'
import { RMResult } from 'fitforce-document-sync'
import { SurveyTemplateDocument } from 'app/models/survey-template-document/survey-template-document.model'
import { ResourceService } from '../modules/resources/resource.service';


/**
 * DataService
 *
 * Handles retrieiving data. This is offline-first, so we first attempt to
 * load data from a local database. If data is available, we return that,
 * but if there is a web connection we'll also attempt to sync to see if there
 * are new values.
 */

interface SessionExercises {
  plannedExerciseUUID: string,
  isComplete: boolean,
  setsCompleted: string,
  distanceCompleted: string,
  timeCompleted: string,
  loadCompleted: string,
  repsCompleted: string,
}

@Injectable({
  providedIn: 'root',
})
@Injectable()
@Inject(DocumentModelFactoryService)
export class DataService {
  fetchingMetaData$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  planDaysSyncPct$: ReplaySubject<number> = new ReplaySubject(1)
  planDaysPagesTotal = 0
  planDaysPagesLeft = 0
  selectedGroup: Group
  selectedPlan: Plan

  private pftCftChartResetSubject: Subject<void> = new Subject();
  pftCftChartReset$ = this.pftCftChartResetSubject.asObservable();

  constructor(
    private database: DatabaseService,
    private restService: RestService,
    private authService: AuthService,
    private documentService: DocumentService,
    private factory: DocumentModelFactoryService,
    private resourceService: ResourceService
  ) { }

  syncWorkoutSessionsLocal(): Observable<any> {
    return this.documentService.restoreData().pipe(map(() => {
      const objectStoreName = WorkoutSessionDocument.objectStoreName
      const workoutSessionDocuments = this.documentService.store.workoutSessionDocuments
      if (workoutSessionDocuments.length > 0) {
        const documentObs = workoutSessionDocuments.map((session) => {
          return this.database.put(objectStoreName, session, session.key)
        })
        return forkJoin(documentObs)
      } else {
        return of([])
      }
    }))
  }

  setDefaultGroup(groups: Group[]) {
    if (!this.selectedGroup) {
      this.selectedGroup = _.first(_.sortBy(groups, 'name'))
      if (this.selectedGroup.plans) {
        this.selectedPlan = _.first(this.selectedGroup.plans)
      }
    }
  }

  getUserGroups() {
    return this.getGroups().pipe(map((groups: Group[]) => {
      /* Groups are joined by default */
      // groups = _.filter(groups, group => this.isJoined(group))
      return groups
    }))
  }

  isJoined(group: Group): boolean {
    return _.includes(group.users, this.authService.getUserUUID())
  }

  async fetchData(): Promise<any> {
    // only fetch joined groups
    const groups = await this.fetchJoinedGroups()
    const data = await this.insertData(groups)
    return data
  }

  async fetchJoinedGroups(): Promise<any> {
    const groups = await this.fetchPagedGroups('Group/search/joined')
    return groups
  }

  async fetchPagedGroups(route: string, all = true) {
    const groups = []
    let fetchNextPage = true
    const params = {
      query: '',
      sort: 'ASC',
      limitPerPage: 50,
      pageNumber: 1
    }
    while (fetchNextPage) {
      const res = await this.restService.get(route, params, true).toPromise()
      groups.push(...res.groups)
      if (res.hasNextPage) {
        params.pageNumber += 1
      } else {
        fetchNextPage = false
      }
      if (!all) {
        fetchNextPage = false
      }
    }
    return groups
  }

  async fetchPublicGroups(pageNumber = 1, limitPerPage = 10, searchText = ''): Promise<any> {
    const route = 'Group/search/available'
    const params = {
      query: searchText,
      sort: 'ASC',
      limitPerPage: limitPerPage,
      pageNumber: pageNumber
    }
    return await this.restService.get(route, params, true).toPromise()
  }

  async fetchMetaData() {
    const metaData = await this.restService.get('fetchMetadata').toPromise()
    const data = await this.insertData(metaData.groups, false)
    return data
  }

  async insertData(groups: any, clearDB = true) {
    await this.database.connect()
    if (clearDB) {
      await this.clearDatabase()
    }
    // joined groups
    const mapped = await this.fetchGroupDocuments(groups)
    await this.database.insert(mapped.groups)
    await this.database.insert(mapped.plans, true)
    await this.database.insert(mapped.users)
    return mapped
  }

  async clearDatabase() {
    await this.database.clear(Day)
    await this.database.clear(Plan)
    await this.database.clear(Group)
    return
  }

  async fetchGroupDocuments(rawGroups: any) {
    let metaData = {
      groups: [],
      plans: [],
      users: [],
    }
    const joinedGroups = _.filter(rawGroups, group => {
      if (!group.hasOwnProperty('members')) {
        group.members = group.users
      }
      // return _.some(group.members, members => members.uuid === this.authService.getUserUUID())
      // rawGroups should only contain joined groups
      return true
    })
    const groupUUIDs = joinedGroups.map(group => group.uuid)
    await this.documentService.fetchDocuments(groupUUIDs).toPromise()
    const groups = await this.mapv2Plans(rawGroups)
    metaData = this.parseGroups(groups)
    return metaData
  }

  async syncSingleGroup(group: any) {
    let metaData = {
      groups: [],
      plans: [],
      users: [],
    }
    await this.database.connect()
    if (!group.key) {
      group.key = group.uuid
    }
    await this.documentService.fetchDocuments([group.key]).toPromise()
    const groups = await this.mapv2Plans([group])
    metaData = this.parseGroups(groups)
    await this.database.insert(metaData.groups)
    await this.database.insert(metaData.plans, true)
    await this.database.insert(metaData.users)
    return metaData
  }

  async saveWorkoutSessionDocument(workoutSession: WorkoutSessionDocument) {
    await this.database.connect()
    await this.database.insert([workoutSession as any], true)

    await this.documentService.insertWorkoutSessionDocument(workoutSession).toPromise();

    return
  }

  async syncWorkoutSessionDocuments(workoutSessionKeys: Array<any>) {
    const syncedSessions = []
    await this.database.connect()
    const workoutSessions: Array<any> = await this.database.getRecordsByKeys(WorkoutSessionDocument.objectStoreName, workoutSessionKeys)
    for (const workoutSession of workoutSessions) {
      try {
        // Modify document to be string...
        const doc = _.cloneDeep(workoutSession.document)
        await this.restService.post(`WorkoutSessionDocument/${workoutSession.key}`, {
          document: JSON.stringify(doc)
        }, true).toPromise()
        syncedSessions.push(workoutSession)
      } catch (e) {
        if (e.status === 409) {
          syncedSessions.push(workoutSession)
        } else {
          // console.log('did not sync', workoutSession)
        }
      }
    }

    return syncedSessions
  }

  async getAllWorkoutSessionDocuments() {
    await this.database.connect()
    return this.database.getAll(WorkoutSessionDocument.objectStoreName)
  }

  removeProp(obj, propToDelete) {
    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (typeof obj[property] === 'object') {
          this.removeProp(obj[property], propToDelete);
        } else {
          if (property === propToDelete && obj[property] === true) {
            delete obj[property];
          }
        }
      }
    }
    return obj
  }

  async getWorkoutSessionDocumentsForDay(groupKey: string, planKey: string, dayKey: string, dayVersion: number) {
    await this.database.connect()
    const records = await this.database.getAll(WorkoutSessionDocument.objectStoreName)
    const filtered = _.filter(records, {
      document: {
        group: {
          uuid: groupKey
        },
        plan: {
          id: planKey
        },
        workout: {
          id: dayKey,
          version: dayVersion,
        },
        workout_complete: true
      }
    }) as Array<any>
    return filtered
  }

  async getWorkoutSessionDocumentsForPlan(groupKey: string, planKey: string) {
    await this.database.connect()
    const records = await this.database.getAll(WorkoutSessionDocument.objectStoreName)
    const filtered = _.filter(records, {
      document: {
        group: {
          uuid: groupKey
        },
        plan: {
          id: planKey
        },
        workout_complete: true
      }
    }) as Array<any>
    return filtered
  }

  async getWorkoutSessionDocument(key: string) {
    await this.database.connect()
    const record = await this.database.getByKey(WorkoutSessionDocument.objectStoreName, key) as any
    return record.document
  }

  parseGroups(groups: any) {
    const allGroups: Group[] = []
    let allPlans: Plan[] = []
    let allUsers: User[] = []
    _.each(groups, groupJSON => {
      let groupPlans: Plan[] = []
      if (groupJSON.plans) {
        groupPlans = this.createGroupPlans(groupJSON.plans)
      }

      // TODO: is this needed? the response doesn't contain members or owners now
      const groupUsers = this.createGroupUsers(groupJSON.members)
      const groupOwners = this.createGroupUsers(groupJSON.owners)
      const group = this.createGroup(groupJSON, groupOwners, groupUsers, groupPlans)

      allGroups.push(group)

      allPlans = _.unionBy(allPlans, groupPlans, 'key')
      allUsers = _.unionBy(allUsers, [...groupUsers, ...groupOwners], 'key')
    })

    const response = {
      groups: allGroups,
      plans: allPlans,
      users: allUsers
    }
    return response
  }

  async getAllWorkouts() {
    const allDays = await this.getRecords(Day).toPromise()
    const filteredDays = _.filter(allDays, (day: Day) => {
      if ((day.isRestricted || day.isComplete)) {
        return true
      }
      return false
    })
    return filteredDays
  }

  async getWorkouts(date: moment.Moment) {
    const allDays = await this.getRecords(Day).toPromise()
    const filteredDays = _.filter(allDays, (day: Day) => {
      if (day.date.isSame(date, 'day') && (day.isRestricted || day.isComplete)) {
        return true
      }
      return false
    })
    return filteredDays
  }

  async getWorkout(planKey, dayKey: string) {
    const day = await this.getRecord(Day, { key: dayKey, planUUID: planKey })
    return day
  }

  createGroup(groupJSON: any, owners, users, plans?): Group {
    const group = new Group({
      id: groupJSON.uuid,
      name: groupJSON.name,
      private: groupJSON.private,
      owners: _.map(owners, 'key'),
      plans: plans,
      users: users,
      created: groupJSON.created_on_timestamp,
    })
    return group
  }

  createGroupUsers(data: any): User[] {
    const users: User[] = []
    _.each(data, user => {
      users.push(new User({
        id: user.uuid,
        email: user.email,
        firstName: user.firstName || user.firstname,
        lastName: user.lastName || user.lastname,
        rank: user.rank,
        isAdmin: user.isAdmin || false,
      }))
    })
    return users
  }

  createGroupPlans(data: any): Plan[] {
    const plans: Plan[] = []
    _.each(data, plan => {
      let planToAdd = plan
      if (!(plan instanceof Plan)) {
        planToAdd = new Plan({
          id: plan.id,
          name: plan.name,
          startDate: plan.startDate,
          endDate: plan.endDate,
          version: plan.version
        })
      }
      plans.push(planToAdd)
    })
    return plans
  }

  fetchInitialDays(plans: Plan[]): Observable<any> {
    const fromDate = moment().format('MM/DD/YYYY')

    const toDates = plans.map(plan => moment(plan.endDate))
    const toDate = moment.max(toDates).format('MM/DD/YYYY')

    const planKeys = _.map(plans, plan => plan.key)
    return this.fetchPlanDays(planKeys, fromDate, toDate, 1, 50)
  }

  mapv2Plans(metaGroups) {
    return new Promise(async (resolve) => {
      const plans = []
      const mappableData = {
        planDays: []
      }

      _.each(this.documentService.store.planDocuments, (planDocument, i) => {
        const allExercises = this.documentService.store.exerciseProgram.document.exercises
        const document = planDocument.document
        const startDate = moment(new Date(document.start_date))
        plans.push({
          id: planDocument.key,
          name: document.name,
          startDate: startDate,
          endDate: startDate.clone().add(document.num_weeks, 'w').subtract(1, 'd')
        })
        document.workouts.forEach((workout: Workout) => {
          const day = PlanDocumentUtils.getDate(startDate, workout.schedule.day[0], workout.schedule.week[0])
          const planBlocks = []
          workout.tiers.forEach(tier => {
            const plannedExercises = []
            tier.rows.forEach((row) => {

              const blockExercise = {
                uuid: row.exercise_id,
                name: row.exercise_name,
                sets: row.parameter_selections['sets'],
                distance: row.parameter_selections['distance'],
                reps: row.parameter_selections['reps'],
                time: row.parameter_selections['time'],
                load: row.parameter_selections['load'],
                hold: row.parameter_selections['hold'],
                regression: row.parameter_selections['regression'],
                rowNum: row.order,
                notes: row.notes,
              }

              // Find the exercise metadata
              const exerciseMetaData = _.find(allExercises, {id: row.exercise_id})
              if (exerciseMetaData && exerciseMetaData.resources) {
                // Which should always exist
                if (exerciseMetaData.resources.length > 0) {
                  const video: any = _.clone(_.find(exerciseMetaData.resources, { type: ResourceType.Video }))
                  // Format to meet our needs
                  if (video) {
                    video.assetId = video.asset_id
                    blockExercise['video'] = video
                  }
                }
              }

              plannedExercises.push(blockExercise)
            })
            plannedExercises.sort((a, b) => {
              return a.rowNum - b.rowNum
            })
            planBlocks.push({
              uuid: tier.id,
              name: tier.name,
              isCustom: !!workout.workout_type_id,
              // columns: tier.columns,
              notes: tier.notes,
              tier: tier.order,
              plannedExercises: plannedExercises,
              planDayUUID: workout.id,
            })
          })

          const newDay = {
            uuid: workout.id,
            planUUID: planDocument.uuid,
            date: day,
            isComplete: workout.complete,
            blocks: planBlocks,
            version: workout.version,
            // load: Math.trunc(dayLoad / loadCount),
          }

          if (workout.est_completion_time_ms) {
            newDay['est_completion_time_ms'] = workout.est_completion_time_ms
          }

          if (workout.restrict_label_id) {
            newDay['isRestricted'] = !!workout.restrict_label_id
            newDay['restrictLabel'] = {
              id: workout.restrict_label_id,
              name: workout.name
            }
          } else {
            newDay['workoutType'] = {
            id: workout.workout_type_id,
            name: workout.name,
            }
          }
          mappableData.planDays.push(newDay)
        })
      })

      // Go through each group and update with new set of plans
      const planDays = this.mapPlanDayData(mappableData)
      // Map Plan to Group
      _.each(this.documentService.store.planMetadata, (planMetaData) => {
        planMetaData.publishedToGroups.forEach((group) => {
          if (metaGroups) {
            if (metaGroups[0]) {
              if (metaGroups[0].key && !metaGroups[0].uuid) {
                metaGroups[0].uuid = metaGroups[0].key
              }
              if (metaGroups[0].uuid && !metaGroups[0].key) {
                metaGroups[0].key = metaGroups[0].uuid
              }

            }
            const gp = _.findIndex(metaGroups, { uuid: group.uuid })
            if (gp !== -1) {
              const planIndex = _.findIndex(plans, { id: planMetaData.key })
              if (planIndex !== -1) {
                plans[planIndex].version = 2
                if (!metaGroups[gp].plans) {
                  metaGroups[gp].plans = []
                }
                metaGroups[gp].plans = [...metaGroups[gp].plans, new Plan(plans[planIndex])]
              }
            }
          }
        })
      })
      resolve(metaGroups)

      this.insertAllPlanDays(
        planDays
      ).then(() => {
        resolve(metaGroups)
      })
    })
  }

  updateSyncPct() {
    let percent = 100
    if (this.planDaysPagesTotal !== 0) {
      percent = Math.floor((this.planDaysPagesLeft / this.planDaysPagesTotal) * 100)
    }
    this.planDaysSyncPct$.next(percent)
  }

  fetchPlanDays(
    planKeys: string[],
    fromDate: string,
    toDate: string,
    pageNumber: number,
    limitPerPage = 50
    ): Observable<any> {
    return this.restService.post('fetchPlanDays', {
      plans: planKeys,
      from_date: fromDate,
      to_date: toDate,
      limitPerPage: limitPerPage,
      pageNumber: pageNumber
    })
  }

  mapPlanDayData(data: any): any {
    const allDays: Day[] = []
    // const start = performance.now();

    _.each(data.planDays, planDay => {
      let dayLoad = 0
      let loadCount = 0
      const planBlocks: PlanBlock[] = []
      _.each(_.sortBy(planDay.blocks, 'tier'), planBlock => {
        const blockExercises: PlanExercise[] = []
        _.each(planBlock.plannedExercises, planExercise => {
          blockExercises.push(new PlanExercise({
            id: planExercise.uuid,
            name: planExercise.name,
            sets: planExercise.sets,
            distance: planExercise.distance,
            time: planExercise.time,
            load: planExercise.load,
            reps: planExercise.reps,
            hold: planExercise.hold,
            rowNum: planExercise.rowNum,
            notes: planExercise.notes,
            regression: planExercise.regression,
            video: planExercise.video,
          }))
          if (planBlock.tier === 3) {
            loadCount++
            dayLoad += parseInt(planExercise.load, 10)
          }
        })
        planBlocks.push(new PlanBlock({
          id: planBlock.uuid,
          name: planBlock.name,
          isCustom: planBlock.isCustom,
          columns: planBlock.columns,
          notes: planBlock.notes,
          tier: planBlock.tier,
          plannedExercises: blockExercises,
          planDayUUID: planDay.uuid,
        }))
      })

      const newDay = new Day({
        id: planDay.uuid,
        planUUID: planDay.planUUID,
        date: planDay.date,
        isComplete: planDay.isComplete,
        isRestricted: planDay.isRestricted,
        restrictLabel: planDay.restrictLabel,
        workoutType: planDay.workoutType,
        blocks: planBlocks,
        est_completion_time_ms: planDay.est_completion_time_ms,
        load: Math.trunc(dayLoad / loadCount),
        version: planDay.version,
      })
      allDays.push(newDay)
    })

    // console.log((performance.now() - start) / 1000);
    return allDays
  }

  async insertAllPlanDays(allDays: Day[]): Promise<any> {
    await this.database.connect()
    return this.database.insert(allDays, true)
  }

  async getAllSurveyTemplateDocuments(allSurveys: string[]): Promise<any> {
    await this.database.connect();
    return this.database.getRecords(SurveyTemplateDocument, allSurveys);
  }

  async insertAllSurveyTemplateDocuments(allSurveys: SurveyTemplateDocument[]): Promise<any> {
    await this.database.connect();
    return this.database.insert(allSurveys, true);
  }

  insertSurveyTemplateDocumentLocal(surveyTemplateDocument: SurveyTemplateDocument): Observable<any> {
    this.documentService.insertSurveyTemplateDocument(surveyTemplateDocument);
    return this.syncSurveyTemplateDocumentsLocal();
  }

  syncSurveyTemplateDocumentsLocal(): Observable<any> {
    return this.documentService.restoreData().pipe(map(() => {
      const objectStoreName = SurveyTemplateDocument.objectStoreName;
      const surveyTemplateDocuments = this.documentService.store.surveyTemplateDocuments;
      if (surveyTemplateDocuments.length > 0) {
        const surveyDocObs = surveyTemplateDocuments.map((surveyTemplateDoc) => {
          return this.database.put(objectStoreName, surveyTemplateDoc, surveyTemplateDoc.key);
        });
        return forkJoin(surveyDocObs);
      } else {
        return of([]);
      }
    }));
  }

  setLastSyncAttempt() {
    const time = moment().unix()
    localStorage.setItem('last-sync-attempt', time as any)
    return time
  }

  getLastSyncAttempt() {
    return localStorage.getItem('last-sync-attempt') as any
  }

  getLastSyncTime() {
    return localStorage.getItem('last-sync') as any
  }

  setLastSyncTime() {
    localStorage.setItem('last-sync', moment().unix() as any)
  }

  syncWorkoutSessions() {
    return new Promise((resWorkout, rejWorkout) => {
      this.postWorkoutSessionDocuments().then(() => {
        return this.database.query(WorkoutSession, { isSynced: false, isPreview: false })
      }).then((workoutSessions: WorkoutSession[]) => {
        return new Promise((res, reject) => {
          if (_.size(workoutSessions) > 0) {
            _.each(workoutSessions, (session, i) => {
              workoutSessions[i].requestSurvey = false
              workoutSessions[i].isSynced = true
            })
            // Sync and remove version 1 workouts
            const v1Sessions = workoutSessions.filter(session => session.version !== 2 )
            if (v1Sessions) {
              this.restService.post('syncWorkoutSession', { workouts: v1Sessions }).subscribe(data => {
                _.each(workoutSessions, (session, i) => {
                  // Delete v1 workout sessions that have successfully synced
                  if (!session.version || session.version !== 2) {
                    delete workoutSessions[i]
                    this.database.delete(WorkoutSession.objectStoreName, session.key)
                  }
                })
                // Remove deleted elements (undefined)
                _.compact(workoutSessions)
                resWorkout(workoutSessions)
              }, error => {
                resWorkout(workoutSessions)
              })
            } else {
              resWorkout(workoutSessions)
            }
          } else {
            // There are no workouts
            resWorkout([])
          }
        })
      }).catch((err) => {
        resWorkout([])
      })
    })
  }


  groupRequestInvite(groupId: string, email: string) {
    // emails expect to be an array
    return this.restService.post(`Group/${groupId}/invite`, { emails: [email] }, true)
  }

  groupRequestLeave(groupId: string, userId: string) {
    return this.restService.delete(`Group/${groupId}/members/${userId}`, true)
  }

  /**
   * Gets the groups available to the current user
   *
   * For workout leaders, this will be a list.
   * For trainees, this will likely be a single group.
   */
  getGroups(): Observable<Group[]> {
    return this.getRecords(Group) as Observable<Group[]>
  }

  getGroup(key: string): Promise<Group> {
    return this.getRecord(Group, key) as Promise<Group>;
  }

  getPlanExercise(key: string): Promise<PlanExercise> {
    return this.getRecord(PlanExercise, key) as Promise<PlanExercise>;
  }

  getPlans(plans: Plan[]): Observable<Plan[]> {
    if (!plans || plans.length === 0) {
      return of([])
    }
    return this.getRecordsByKeys(Plan, plans) as Observable<Plan[]>;
  }

  getDays(plan: Plan): Observable<Day[]> {
    if (!plan || !plan.key) {
      return of([])
    }
    return this.getRecords(Day, { planUUID: plan.key }) as Observable<Day[]>;
  }

  getDaysAsync(plan: Plan): Observable<any> {
    return this.getRecordsAsync(Day, 'date', { planUUID: plan.key });
  }

  getOwnersInfo(owners: any[]): Observable<User[]> {
    return this.getRecordsByKeys(User, owners) as Observable<User[]>
  }

  getWorkoutPreview(plan: Plan, day: Day): Observable<PlanBlock> {
    const options = {
      append: {
        planId: plan.key,
        planDayId: day.key,
      }
    }
    return this.getRecords(PlanBlock, { planDay: day, options: options }) as Observable<PlanBlock>;
  }

  getPlanBlocks(planDay: Day, planBlocks: PlanBlock[]): Observable<PlanBlock[]> {
    return this.getRecordsByKeys(PlanBlock, _.map(planBlocks, planBlock => [planDay.key, planBlock])) as Observable<PlanBlock[]>;
  }

  getPlannedExercisesInBlock(planExercises: PlanExercise[]): Observable<any> {
    return this.getRecordsByKeys(PlanExercise, planExercises)
  }

  getAllWorkoutSessions(): Observable<WorkoutSession[]> {
    // Version 1 is no longer displayed
    return this.getRecords(WorkoutSession, { isPreview: false, version: 2 }) as Observable<WorkoutSession[]>
  }

  getWorkoutSession(key: string): Promise<WorkoutSession> {
    if (_.isEmpty(key)) {
      throw new Error('data.getWorkoutSession requires a key')
    }
    return this.getRecord(WorkoutSession, key) as Promise<WorkoutSession>;
  }

  getCompletedWorkoutSessionsByDay(planUUID, planDayUUID): Observable<WorkoutSession[]>  {
    return this.getRecords(WorkoutSession, {
      planUUID: planUUID, planDayUUID: planDayUUID, isComplete: true, isDeprecated: false
    }) as Observable<WorkoutSession[]>
  }

  deprecateWorkoutSessions(workoutSessions) {
    workoutSessions.forEach((workoutSession, i) => {
      workoutSessions[i].isDeprecated = true
    })
    return this.database.insert(workoutSessions, true)
  }

  getCompletedWorkoutSessions(group: Group, plan: Plan): Observable<WorkoutSession[]> {
    return this.getRecords(WorkoutSession, {
      groupUUID: group.key, planUUID: plan.key, isComplete: true, isDeprecated: false
    }) as Observable<WorkoutSession[]>
  }

  createWorkoutSessionDocument(
    group, leaders: Array<User>, planDocument: PlanDocument, planMetaDataDoc: any, workout: Workout, activeUser?: any
  ): any {
    let currentUser
    if (activeUser) {
      currentUser = activeUser
    } else {
      currentUser = this.authService.getCurrentUser()
    }

    const planDoc = planDocument.document
    const groupLeaders = []
    leaders.forEach(user => {
      groupLeaders.push({
        uuid: user.key ,
        first_name: user.firstName,
        last_name: user.lastName,
        rank: user.rank,
        email: user.email
      })
    })
    let exerciseCount = 0
    const tiers = []
    workout.tiers.forEach(workoutTier => {
      const tier = {
        id: workoutTier.id,
        name: workoutTier.name,
        notes: workoutTier.notes,
        order: workoutTier.order,
        complete: false,
        circuit: workoutTier.circuit ? {
          num_rounds: workoutTier.circuit.num_rounds,
          rest_time_ms: workoutTier.circuit.rest_time_ms,
          executions: [],
        } : undefined,
        rows: []
      }

      if (tier.circuit) {
        tier.circuit.executions = []
      }

      const rows = []
      let isTierCounted = false
      workoutTier.rows.forEach(tierRow => {
        if (!tier.circuit || !isTierCounted) {
          isTierCounted = true
          exerciseCount++
        }
        // Normalize sets to be a number
        let defaultSets = parseInt(tierRow.parameter_selections['sets'], 10)
        const executions = []
        const paramValues = _.cloneDeep(tierRow.parameter_selections)
        delete paramValues['sets']
        if (!defaultSets || defaultSets === -1) {
          defaultSets = 1
        }
        // Check if load % is provided, should not be included in executions as the load is irrelevant
        if (paramValues['load']) {
          paramValues['load'] = undefined
        }
        for (let i = 0; i < defaultSets; i++) {
          executions.push({
            parameter_values: paramValues
          })
        }
        const row = {
          exercise: {
            exercise_program_id: tierRow.exercise_id,
            name: tierRow.exercise_name,
            exercise_categories: [],
          },
          notes: tierRow.notes,
          order: tierRow.order,
          executions: executions,
          start_time: null,
          end_time: null,
          parameters: tierRow.parameter_selections
        }
        rows.push(row)
      })
      rows.sort((a, b) => {
        return a.order - b.order
      })
      tier.rows = rows
      tiers.push(tier)
    })
    const workoutSession: IWorkoutSession = {
      id: uuid(),
      type: 'WorkoutSession',
      exercise_program_id: planDoc.exercise_program_id,
      start_time: moment().format(),
      workout_complete: false,
      leaders: groupLeaders,
      attendees: [{
        uuid: currentUser.uuid,
        email: currentUser.email,
      }],
      group: {
        uuid: group.key || group.uuid,
        name: group.name,
      },
      plan: {
        id: planDoc.id,
        name: planDoc.name,
        num_weeks: planDoc.num_weeks,
        start_date: planDoc.start_date,
        creator: {
          uuid: planMetaDataDoc.createdByUser.uuid,
          email: planMetaDataDoc.createdByUser.email,
        },
        notes: planMetaDataDoc.notes,
        version: planDoc.version,
      },
      workout: {
        id: workout.id,
        name: workout.name,
        workout_type_id: workout.workout_type_id,
        version: workout.version || -1,
        tiers: tiers,
      },
      group_size: group.users.length,
      attendance_count: 1,
      total_tiers: tiers.length,
      completed_tiers: 1,
      total_exercises: exerciseCount,
      completed_exercises: 1,
      est_completion_time_ms: workout.est_completion_time_ms || -1,
      est_rpe: workout.est_rpe || -1,
    }
    // If there is a phase, add it to the session
    const phase: Phase = PlanDocumentUtils.findPhase(planDocument.document.phases, {
      day: workout.schedule.day[0], week: workout.schedule.week[0]
    })
    if (phase) {
      workoutSession.phase = {
        uuid: phase.id,
        name: phase.name,
        color: phase.color
      }
    }
    return workoutSession
  }

  endWorkoutSession(workoutSession: WorkoutSessionDBDocument) {
    workoutSession.workout_complete = true
    workoutSession.end_time = moment().format()
    return this.database.update(workoutSession)
  }

  async postWorkoutSessionDocuments() {
    await this.database.connect()
    const workoutSessions = await this.database.query(
      WorkoutSession, { isSynced: false, isPreview: false, version: 2 }
    ) as WorkoutSession[]
    workoutSessions.forEach(async (workoutSession) => {
      try {
        const workoutSessionDocumentDB = await this.database.get(WorkoutSessionDBDocument, workoutSession.key)
        await this.restService.post(`WorkoutSessionDocument/${workoutSession.key}`, {
          // set space to 0 force no formatting
          document: JSON.stringify(workoutSessionDocumentDB, null, 0)
        }, true).toPromise()
        workoutSession.requestSurvey = false;
        workoutSession.isSynced = true;
        await this.database.update(workoutSession)
        return
      } catch {
        return
      }
    })
    return
  }

  private isScoreDocument(doc: ObservationDocument): boolean {
    if (doc && doc.document && doc.document.measure && doc.document.measure.parameters) {
      if (doc.document.measure.parameters.length > 0) {
        const parameters = doc.document.measure.parameters[0];
        if (parameters.type === 'score') {
          return true;
        }
      }
    }
    return false;
  }

  private getScoreType(doc: ObservationDocument): string {
    if (doc && doc.document && doc.document.result) {
      const result = doc.document.result as ScoreResult;
      if (result) {
        return result.type;
      }
    }
    return '';
  }

  async postPftCftScoreObservationDocuments() {
    await this.database.connect();
    const observationDocuments = Array.from(await this.documentService.store.observations.values());
    const scoreObservationDocuments = [];
    await observationDocuments.forEach(doc => {
      if (this.isScoreDocument(doc)) {
        scoreObservationDocuments.push(doc);
        this.postScoreDocument(doc);
      }
    });
    // TODO: find out whether we typically delete the observation documents in the local storaage or leave them there after posting.
  }

  async postScoreDocument(doc: ObservationDocument) {
    if (!this.isScoreDocument(doc)) {
      return;
    }
    if (doc && doc.document && doc.document.id) {
      const scoreType: string = this.getScoreType(doc);
      let url = 'ObservationDocument/'
      switch (scoreType) {
        case 'PFT':
          url += 'pft/' + doc.document.id
          break;
        case 'CFT':
          url += 'cft/' + doc.document.id
          break;
        default:
          return;
      }

      await this.restService.post(url, {
        document: JSON.stringify(doc.document, null, 0)
      }, true).toPromise();
    }
  }

  getOrCreateWorkoutSession(
    group: Group, plan: Plan, day: Day,
    isGroupWorkout: boolean, isPreview: boolean, sessionDateTime: string,
    planBlocks: PlanBlock[], plannedExercises: any, workoutSessionDocumentKey?: string
  ): Observable<WorkoutSession> {
    return new Observable(observer => {
      // Find a session that was created but not started
      this.getRecords(WorkoutSession,  {
        key: workoutSessionDocumentKey
      }).subscribe(session => {
        if (_.size(session) === 0) {
          this.createWorkoutSession(
            group, plan, day, isGroupWorkout, isPreview,
            sessionDateTime, planBlocks, plannedExercises, workoutSessionDocumentKey
          ).then(newSession => {
            // console.log('New session created', newSession)
            observer.next(newSession)
            observer.complete()
          })
        } else {
          // console.log('Found session', session)
          const workoutSession = _.first(session as WorkoutSession[])
          // update session date time if modified by date picker
          workoutSession.sessionDateTime = sessionDateTime
          this.update(workoutSession).then(() => {
            observer.next(workoutSession)
            observer.complete()
          })
        }
      })
    })
  }

  createWorkoutSession(
    group: Group, plan: Plan, day: Day, isGroupWorkout: boolean, isPreview: boolean,
    sessionDateTime: string, planBlocks: PlanBlock[], plannedExercises: any, workoutSessionDocumentKey?: string
  ): Promise<WorkoutSession> {
    return new Promise((resolve, reject) => {
      const sessionBlocks = []
      _.each(planBlocks, (block, i) => {
        const sessionExercises = <SessionExercises[]>[]
        _.each(plannedExercises[block.key], (exercise, j) => {
          sessionExercises.push({
            plannedExerciseUUID: exercise.key,
            isComplete: exercise.isComplete,
            setsCompleted: exercise.setsCompleted || '',
            distanceCompleted: exercise.distanceCompleted || '',
            timeCompleted: exercise.timeCompleted || '',
            loadCompleted: exercise.loadCompleted || '',
            repsCompleted: exercise.repsCompleted || '',
          })
        })
        sessionBlocks.push({
          name: block.name,
          blockUUID: block.key,
          isComplete: block.isComplete,
          plannedExercises: sessionExercises,
        })
      })

      const workoutSession = {
        groupUUID: group.key,
        planUUID: plan.key,
        planDayUUID: day.key,
        isGroupWorkout: isGroupWorkout,
        blocks: sessionBlocks,
        sessionDateTime: sessionDateTime,
        name: day.name,
      } as WorkoutSession
      if (workoutSessionDocumentKey) {
        workoutSession.key = workoutSessionDocumentKey
        workoutSession.version = 2
      }
      if (isPreview) {
        workoutSession.isPreview = isPreview
      }
      const newSession = new WorkoutSession(workoutSession)
      this.database.insert([newSession])
      .then(() => {
        resolve(workoutSession)
      }, err => {
        reject(err)
      })
    })
  }

  async markSessionComplete(workoutSession: WorkoutSession, result: boolean): Promise<any> {
    // We remove the session attempt so we can retry at least once after a workoutSession is marked complete
    localStorage.removeItem('last-sync-attempt')
    workoutSession.isComplete = true;
    workoutSession.requestSurvey = result;
    await this.resetSessionSurveyRequests(result);
    return this.database.update(workoutSession);
  }

  async resetSessionSurveyRequests(requestSurvey: boolean): Promise<any> {
    if (!requestSurvey) {
      return Promise.resolve(true)
    }
    await this.database.connect();
    const workoutSessions = await this.database.getAll(WorkoutSession) as WorkoutSession[];
    _.each(workoutSessions, (session, i) => {
      workoutSessions[i].requestSurvey = false;
    });
    const workoutSessions_1 = workoutSessions;
    return this.database.insert(workoutSessions_1, true);
  }

  getPlan(key: string): Promise<Plan> {
    if (_.isEmpty(key)) {
      throw new Error('data.getPlan requires a key')
    }

    return this.getRecord(Plan, key) as Promise<Plan>;
  }

  getUsers(users: User[]): Observable<User[]> {
    return this.getRecordsByKeys(User, users) as Observable<User[]>;
  }

  getDay(key: string): Promise<Day> {
    if (_.isEmpty(key)) {
      throw new Error('data.getDay requires a key')
    }

    return this.getRecord(Day, key) as Promise<Day>;
  }

  deleteGroup(group: Group) {
    return this.delete(group)
  }


  update(record: DatabaseRecord): Promise<DatabaseRecord> {
    return new Promise((resolve, reject) => {
      this.database.connect().then(() => {
        this.database.update(record).then(() => {
          resolve(record)
          // sync
        }).catch(reject)
      }).catch(reject)
    })
  }

  async delete(record: DatabaseRecord): Promise<any> {
    try {
      await this.database.connect()
      const objectStoreName = (record.constructor as DatabaseRecordType).objectStoreName
      await this.database.delete(objectStoreName, record.key)
      return true
    } catch (e) {
      return false
    }
  }

  private getRecord(typeClass: DatabaseRecordType, key: string | object): Promise<DatabaseRecord> {
    const table = typeClass.objectStoreName

    return new Promise((resolve, reject) => {
      this.database.connect().then(() => {
        // console.log('data.getRecord', table, key)
        this.database.get(typeClass, key).then((record) => {
          resolve(record)

          // if you're also online, re-async with the web service too
          // if (this.restService.isOnline()) {
          //   this.restService.getRecords(table)
          //     .subscribe((records) => {
          //       // convert raw objects to DatabaseRecord objects
          //       records = records.map(convertTo(typeClass))
          //
          //       // save these records to the offline database
          //       this.database.insert(table, records).then(() => {
          //         resolve(records)
          //       })
          //     })
          // }
        }).catch(reject)
      }).catch(reject)
    })
  }

  private getRecordsAsync(typeClass: DatabaseRecordType, indexName: string, where?: object): Observable<{}> {
    return this.database.getRecordsAsync(typeClass, indexName, where);
  }

  private getRecords(typeClass: DatabaseRecordType, filters?: any, clearOnSync?: boolean): Observable<{}> {
    return new Observable((observer) => {
      this.database.connect()
        .then(() => {
          if (filters) {
            return this.database.query(typeClass, filters)
          } else {
            return this.database.getAll(typeClass)
          }
        })
        .then((records) => {
          observer.next(records)
          observer.complete()
        })
        .catch((err) => {
          observer.error(err)
          observer.complete()
        })
    })
  }

  private getRecordsByKeys(typeClass: DatabaseRecordType, filters?: any): Observable<{}> {
    return new Observable((observer) => {
      this.database.connect()
        .then(() => {
          return this.database.getRecords(typeClass, filters)
        })
        .then((records) => {
          observer.next(records)
          observer.complete()
        })
        .catch((err) => {
          observer.error(err)
          observer.complete()
        })
    })
  }

  generatePftCftScoreObservation(score: number, type: string): ObservationDocument {
    const observation = this.factory.build<Observation>(DocumentModelType.Observation);
    const observationDocument: ObservationDocument = new ObservationDocument({
      id: observation.id,
      document: observation,
      version: 1
    });

    observationDocument.userUUID = this.authService.getUserUUID();

    observationDocument.document.result = {
      type: type,
      score: score
    } as ScoreResult;

    let measure = this.documentService.store.exerciseProgram.document.measures.find(m => m.name === type);
    if (!measure) {
      measure = <Measure> {
        name: type.toUpperCase(),
        description: '',
        tags: [
          'Fitness',
          'Evaluation'
        ],
        parameters: [{
          property: 'value',
          type: 'score',
          required: true,
          unit: 'N/A'
        }]
      };
    }
    observationDocument.document.measure = measure;
    observationDocument.markCreated();
    return observationDocument;
  }

  generate1RMObservation(oneRM: any) {
    const observation = this.factory.build<Observation>(DocumentModelType.Observation)
    const observationDocument: ObservationDocument = new ObservationDocument({
      id: observation.id,
      document: observation,
      version: 1
    })

    observationDocument.userUUID = this.authService.getUserUUID()

    observationDocument.document.result = {
      exercise_id: oneRM.exercise_id,
      value: +oneRM['1rm'],
      reps: 1,
    } as RMResult

    const measure = this.documentService.store.exerciseProgram.document.measures.find(m => m.name === '1RM')
    observationDocument.document.measure = measure
    observationDocument.markCreated()
    return observationDocument
  }

  generateMaxSetObservation(oneRM: any) {
    const observation = this.factory.build<Observation>(DocumentModelType.Observation)
    const observationDocument: ObservationDocument = new ObservationDocument({
      id: observation.id,
      document: observation,
      version: 1
    })

    observationDocument.userUUID = this.authService.getUserUUID()

    observationDocument.document.result = {
      exercise_id: oneRM.exercise_id,
      value: +oneRM['max_set'],
      time_limit: 120000,
    } as MaxSetResult

    const measure = this.documentService.store.exerciseProgram.document.measures.find(m => m.name === 'Max Set')
    observationDocument.document.measure = measure

    observationDocument.markCreated()
    return observationDocument
  }

  generateSurveyObservation(workoutSessionId: string, measure: any, response: string | number) {
    const observation = this.factory.build<Observation>(DocumentModelType.Observation)
    const observationDocument: ObservationDocument = new ObservationDocument({
      id: observation.id,
      document: observation,
      version: 1
    })

    observationDocument.document.measure = measure

    observationDocument.document.result = {
      response: response,
      workout_session: workoutSessionId
    } as SurveyResult

    observationDocument.markCreated()
    return observationDocument
  }

  async pushDocuments() {
    await this.documentService.pushDocuments().toPromise()
  }

  async storeObservationDocument(observationDocument: ObservationDocument) {
    await this.documentService.insertObservationDocument(observationDocument).toPromise()
    return observationDocument
  }

  async getPftScoreDocuments() {
    const userUuid = this.authService.getUserUUID();
    const token = 'Bearer ' + this.authService.getToken();

    const result = await this.restService.get(`ObservationDocument/pft/user/${userUuid}`, { 'Authorization': token }, true).toPromise();
    if (result.documents) {
      const reqs = result.documents.map(async docUuid => {
        const response = await this.restService.get(`ObservationDocument/pft/${docUuid}`, { 'Authorization': token }, true).toPromise();
        return { document: JSON.parse(response.document) };
      });
      return await Promise.all(reqs);
    }

    return [];
  }

  async getCftScoreDocuments() {
    const userUuid = this.authService.getUserUUID();
    const token = 'Bearer ' + this.authService.getToken();

    const result = await this.restService.get(`ObservationDocument/cft/user/${userUuid}`, { 'Authorization': token }, true).toPromise();
    if (result.documents) {
      const reqs = result.documents.map(async docUuid => {
        const response = await this.restService.get(`ObservationDocument/cft/${docUuid}`, { 'Authorization': token }, true).toPromise();
        return { document: JSON.parse(response.document) };
      });
      return await Promise.all(reqs);
    }

    return [];
  }

  getPftScoreDocument(docUuid: string) {
    const token = 'Bearer ' + this.authService.getToken();
    let pftScoreDocument = null;
    this.restService.get(`ObservationDocument/pft/${docUuid}`, { 'Authorization': token }, true).subscribe(doc => {
      pftScoreDocument = doc.document;
    });
    return pftScoreDocument;
  }
  pftCftChartReset() {
    this.pftCftChartResetSubject.next();
  }

  async loadObservations() {
    await this.documentService.restoreData().toPromise()
    const observations = this.documentService.store.observations
    const sortedObservations = _.orderBy(observations, o => o.createdAt, ['desc'])
    const exercises = []
    for (const observation of sortedObservations) {
      const result: RMResult|MaxSetResult = observation.document.result as (RMResult|MaxSetResult);
      if (result) {
        const statIndex = exercises.findIndex(ex => ex.exercise_id === result.exercise_id);
        if (statIndex === -1) {
          const exercise = {
            exercise_id: result.exercise_id,
          }
          // Bugfix 2020-08-04 (Measure can be undefined)
          if (observation.document.measure) {
            if (observation.document.measure.name === '1RM') {
              exercise['1rm'] = result.value
              exercise['units'] = 'lbs'
            }
            if (observation.document.measure.name === 'Max Set') {
              exercise['max_set'] = result.value
              exercise['units'] = 'lbs'
            }
          }
          exercises.push(exercise)
        }
      }
    }
    return exercises
  }

  updateUserProfile(userId: string,
    profile: {
      sex: string, dob: string, height: { feet: number, inches: number },
      weight: number
    }): Observable<void> {
    return this.restService.post(`User/${userId}`, profile, true)
  }
}

function convertTo(typeClass: DatabaseRecordType, assumedProperties?: {[key: string]: any}) {
  return function (data) {
    const record = new typeClass(data)
    return record
  }
}
