import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { DatabaseRecord } from '../../classes/database-record.class';
import { SurveyTemplate } from './survey-template.model';
import { SurveyChoice } from './survey-choice.model';
var SurveyDocument = /** @class */ (function (_super) {
    tslib_1.__extends(SurveyDocument, _super);
    function SurveyDocument(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.id = data['id'] || '';
        _this.name = data['name'];
        _this.type = data['type'];
        _this.version = data['version'];
        if (_.has(data, 'survey_choices')) {
            _this.surveyChoices = data['survey_choices'];
        }
        if (_.has(data, 'survey_template')) {
            _this.surveyTemplate = data['survey_template'];
        }
        return _this;
    }
    Object.defineProperty(SurveyDocument.prototype, "surveyChoices", {
        get: function () {
            // const keys = this.getForeignKeys(SurveyChoice);
            // const surveyChoices = [];
            // for (let i = 0; i < keys.length; ++i) {
            //   const choice = new SurveyChoice(keys[i]);
            //   if (choice) {
            //     surveyChoices.push(choice);
            //   }
            // }
            // return surveyChoices;
            return this.getForeignKeys(SurveyChoice);
        },
        set: function (surveyChoices) {
            // const choices = [];
            // for (let i = 0; i < surveyChoices.length; ++i) {
            //   const choice = new SurveyChoice(choices[i]);
            //   choices.push(choice);
            // }
            // this.setForiegnRecords(SurveyChoice, choices);
            this.setForiegnRecords(SurveyChoice, surveyChoices);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyDocument.prototype, "surveyTemplate", {
        get: function () {
            return this.getForeignKeys(SurveyTemplate);
        },
        set: function (surveyTemplate) {
            this.setForiegnRecords(SurveyTemplate, surveyTemplate);
        },
        enumerable: true,
        configurable: true
    });
    SurveyDocument.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, [
            'id',
            'name',
            'type',
            'version',
            'survey_choices',
            'survey_template'
        ]);
    };
    SurveyDocument.prototype.getName = function () {
        return this.name;
    };
    SurveyDocument.prototype.getSurveyChoices = function () {
        return this.getRecords(SurveyChoice);
    };
    SurveyDocument.prototype.getSurveyTemplate = function () {
        return this.getRecords(SurveyTemplate);
    };
    SurveyDocument.objectStoreName = 'surveyDocument';
    return SurveyDocument;
}(DatabaseRecord));
export { SurveyDocument };
