import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { AppState } from 'app/state/app/app.state';
import { Observable } from 'rxjs';
import { DocumentService } from 'app/services/document.service';
import * as _ from 'lodash';
import { Workout, PlanDocument, ResourceType } from 'fitforce-document-sync';
import { VideoDialogComponent } from '../video-dialog/video-dialog.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { PageTransitionService, PAGETRANSITIONS } from 'app/services/page-transition.service';
import { DataService } from 'app/services/data.service';
var PlaycardSummaryComponent = /** @class */ (function () {
    function PlaycardSummaryComponent(store, router, dialog, documentService, pageTransitionService, dataService) {
        this.store = store;
        this.router = router;
        this.dialog = dialog;
        this.documentService = documentService;
        this.pageTransitionService = pageTransitionService;
        this.dataService = dataService;
        this.repStrings = [
            'Rest',
            'Very, Very Easy',
            'Easy',
            'Moderate',
            'Somewhat Hard',
            'Hard',
            'Hard',
            'Very Hard',
            'Very Hard',
            'Very Hard',
            'Maximal'
        ];
    }
    PlaycardSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.load = 'N/A';
        var snapshotDay = this.store.selectSnapshot(AppState.activeDaySelected);
        this.documentService.restoreData().subscribe(function () {
            _this.planDocument = _.find(_this.documentService.store.planDocuments, { key: snapshotDay.planUUID });
            _this.workoutDocument = _this.activeWorkout = _.find(_this.planDocument.document.workouts, { id: snapshotDay.key });
            if (_this.workoutDocument.est_rpe > -1 && _this.workoutDocument.est_completion_time_ms > -1) {
                _this.load = (_this.workoutDocument.est_rpe * (_this.workoutDocument.est_completion_time_ms / 1000 / 60)).toString();
            }
            // Go through workout document and get exercise details
            _this.workoutDocument.tiers.forEach(function (tier, i) {
                tier.rows.forEach(function (row, j) {
                    var exerciseDetails = _this.exerciseDetails(row.exercise_id);
                    if (exerciseDetails && exerciseDetails.resources) {
                        if (exerciseDetails.resources.length > 0) {
                            var videoResource = _.find(exerciseDetails.resources, { type: ResourceType.Video });
                            if (videoResource) {
                                _this.workoutDocument.tiers[i].rows[j].video = videoResource;
                            }
                        }
                    }
                });
            });
        });
    };
    PlaycardSummaryComponent.prototype.openVideo = function (videoData) {
        if (!_.isEmpty(videoData)) {
            this.dialog.open(VideoDialogComponent, {
                panelClass: 'modal-workout-video',
                maxWidth: '100vw',
                width: '100%',
                data: {
                    title: videoData.title,
                    videoId: videoData.asset_id.replace('video:', ''),
                },
            });
        }
    };
    PlaycardSummaryComponent.prototype.exerciseDetails = function (exerciseId) {
        var exercise = _.find(this.documentService.store.exerciseProgram.document.exercises, { id: exerciseId });
        return exercise;
    };
    PlaycardSummaryComponent.prototype.goBack = function () {
        this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.RIGHT);
        this.router.navigate(['/main']);
    };
    PlaycardSummaryComponent.prototype.startExercise = function () {
        this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT);
        this.router.navigate(['/exercise/survey']);
    };
    tslib_1.__decorate([
        Select(AppState.activeDaySelected),
        tslib_1.__metadata("design:type", Observable)
    ], PlaycardSummaryComponent.prototype, "activeDaySelected$", void 0);
    return PlaycardSummaryComponent;
}());
export { PlaycardSummaryComponent };
