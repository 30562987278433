import { State, Action, StateContext, Selector, Store } from '@ngxs/store'
import * as moment from 'moment'
import * as _ from 'lodash'
import { WorkoutStateModel } from './workout.model'
import {
  StartWorkout,
  PauseWorkout,
  ResumeWorkout,
  EndWorkout,
  ExecuteSet,
  AddNewSet,
  DeleteSet,
  ChangeExercise,
  SaveComments,
  EditSet,
  SetExecutionsRM,
  SaveWorkout,
  StartCircuit,
  StopCircuit,
  SetCircuitRounds,
  ResetWorkoutSession,
} from './workout.actions'
import { DocumentService } from 'app/services/document.service'
import { AppState } from '../app/app.state'
import { DataService } from 'app/services/data.service'
import { WorkoutSessionDocument, WorkoutSession } from 'app/models/workout-session-document.model'
import { from } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { produce } from 'immer'
import { WorkoutStateUtils } from './workout.state.utils'
import { PlanDocumentUtils } from 'fitforce-document-sync';
import { AuthState } from '../auth/auth.state'

@State<WorkoutStateModel>({
  name: 'workout',
  defaults: {
    currentWorkoutIndex: 0,
    currentRowIndex: 0,
  }
})
export class WorkoutState {

  @Selector()
  static currentExerciseCount(state: WorkoutStateModel) {
    return state.currentExerciseCount
  }

  @Selector()
  static currentWorkoutSession(state: WorkoutStateModel) {
    return state.currentWorkoutSession
  }

  @Selector()
  static currentWorkoutIndex(state: WorkoutStateModel) {
    return state.currentWorkoutIndex
  }

  @Selector()
  static currentRowIndex(state: WorkoutStateModel) {
    return state.currentRowIndex
  }

  @Selector()
  static totalTime(state: WorkoutStateModel) {
    return state.totalTime
  }

  constructor(
    private store: Store,
    private dataService: DataService,
    private documentService: DocumentService,
  ) {}

  @Action(StartWorkout)
  async startWorkout(
    { getState, setState }: StateContext<WorkoutStateModel>,
    { workoutDocument, measuredResponse }: StartWorkout
  ) {
    const group: any = this.store.selectSnapshot(AppState.activeGroupSelected)
    const groupData = await this.dataService.getGroup(group.uuid || group.key)
    const state = produce(getState(), draft => {
      const plan = this.store.selectSnapshot(AppState.activePlanSelected)
      const planDocument = this.documentService.store.planDocuments.find((planDoc) => {
        return planDoc.document.id === plan.key
      })
      const planMetaData = this.documentService.store.planMetadata.find((planMetaDataDoc) => {
        return planMetaDataDoc.key === plan.key
      })
      const activeUser = this.store.selectSnapshot(AuthState.activeUser)
      const workoutSession = this.dataService.createWorkoutSessionDocument(
        groupData, [], planDocument, planMetaData, workoutDocument, activeUser
      )
      draft.startTime = moment()
      draft.pauseTime = moment()
      draft.currentExerciseCount = 1
      draft.totalTime = 0
      draft.currentWorkoutIndex = 0
      draft.currentRowIndex = 0
      draft.currentWorkoutSession = workoutSession
    })
    setState(state)

    const temp = getState()
    // Save observations
    this.saveMeasuredResponses(getState(), measuredResponse)
  }

  async saveMeasuredResponses(state: WorkoutStateModel, measuredResponse: any) {
    if (!measuredResponse) {
      return true
    }
    const measures = measuredResponse.measures
    const responses = measuredResponse.response

    // They are in order
    let hasMeasure = false
    for (const measure of measures) {
      if (responses[measure.id]) {
        hasMeasure = true
        const responseParameter = measure.parameters.find(p => p.property === 'response');
        let typedResponse = responses[measure.id];
        if (responseParameter) {
          if (responseParameter.type === 'number') {
            typedResponse = +typedResponse
          } else {
            typedResponse = typedResponse.toString();
          }
        }
        const doc = this.dataService.generateSurveyObservation(state.currentWorkoutSession.id, measure, typedResponse)
        await this.dataService.storeObservationDocument(doc)
      }
    }
    const isOnline = this.store.selectSnapshot(AppState.isOnline)
    if (isOnline && hasMeasure) {
      this.dataService.pushDocuments()
    }
  }

  @Action(PauseWorkout)
  pauseWorkout(
    { getState, setState }: StateContext<WorkoutStateModel>,
    { totalTime }: PauseWorkout
  ) {
    const state = produce(getState(), draft => {
      draft.totalTime = moment().diff(draft.pauseTime, 'milliseconds', true) + totalTime
      draft.pauseTime = moment()
    })
    setState(state)
  }

  @Action(ResumeWorkout)
  resumeWorkout(
    { getState, patchState }: StateContext<WorkoutStateModel>,
    { }: ResumeWorkout
  ) {
    // const state = getState()
    // patchState(state)
  }

  @Action(EndWorkout)
  endWorkout(
    { getState, setState }: StateContext<WorkoutStateModel>,
    { totalTime }: EndWorkout
  ) {
    const state = produce(getState(), draft => {
      draft.currentWorkoutSession.end_time = moment().format()
      draft.currentWorkoutSession.completion_time_ms = totalTime
      draft.currentWorkoutSession.workout_complete = true
    })

    setState(state)
  }

  @Action(StartCircuit)
  startCircuit(
    { getState, setState }: StateContext<WorkoutStateModel>,
    { }: StartCircuit
  ) {
    const state = produce(getState(), draft => {
      const cwx = draft.currentWorkoutIndex
      if (
        draft.currentWorkoutSession.workout.tiers[cwx].circuit
        && draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions.length
      ) {
        draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions[0].start_time = moment().format()
      } else {
        draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions[0] = {
          start_time: moment().format()
        }
      }
    })
    setState(state)
  }

  @Action(StopCircuit)
  stopCircuit(
    { getState, setState }: StateContext<WorkoutStateModel>,
    {}: StopCircuit
  ) {
    const state = produce(getState(), draft => {
      const cwx = draft.currentWorkoutIndex
      draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions[0].end_time = moment().format()
    })
    setState(state)
  }

  @Action(SetCircuitRounds)
  setCircuitRounds(
    { getState, setState }: StateContext<WorkoutStateModel>,
    { rounds, tierIndex }: SetCircuitRounds
  ) {
    const state = getState()
    const draftState = produce(state, draft => {
      let cwx = state.currentWorkoutIndex
      if (tierIndex || tierIndex === 0) {
        cwx = tierIndex
      }
      if (!draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions.length) {
        draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions.push({
          num_rounds: rounds
        })
      }
      draft.currentWorkoutSession.workout.tiers[cwx].circuit.executions[0].num_rounds = rounds
    })
    setState(draftState)
  }

  @Action(ExecuteSet)
  executeSet(
    { getState, setState }: StateContext<WorkoutStateModel>,
    { setIndex, paramValues }: ExecuteSet
  ) {
    const state = produce(getState(), draft => {
      const cwx = draft.currentWorkoutIndex
      const crx = draft.currentRowIndex
      const execution = draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions[setIndex]
      // This was complete, uncomplete it
      if (execution.complete_time) {
        execution.complete_time = undefined
      } else {
        execution.complete_time = moment().format()
      }
      // tslint:disable-next-line:max-line-length
      draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions[setIndex] = execution
    })
    setState(state)
  }

  @Action(EditSet)
  editSet(
    { getState, setState }: StateContext<WorkoutStateModel>,
    { tierIndex, rowIndex, setIndex, paramValues, completeTime, manual }: EditSet
  ) {
    if (manual) {
      paramValues['manual'] = true
    }
    const state = produce(getState(), draft => {
      draft.currentWorkoutSession.workout.tiers[tierIndex].rows[rowIndex].executions[setIndex].complete_time = completeTime
      draft.currentWorkoutSession.workout.tiers[tierIndex].rows[rowIndex].executions[setIndex].parameter_values = paramValues
      if (!completeTime) {
        draft.currentWorkoutSession.workout.tiers[tierIndex].rows[rowIndex].executions[setIndex].editted = true
      }
    })
    setState(state)
  }

  @Action(AddNewSet)
  addNewSet(
    { getState, setState }: StateContext<WorkoutStateModel>,
    { paramValues }: AddNewSet
  ) {
    const state = produce(getState(), draft => {
      const cwx = draft.currentWorkoutIndex
      const crx = draft.currentRowIndex
      const executions = draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions
      executions.push({
        parameter_values: paramValues,
        user_added: true
      })
      draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions = executions
    })
    setState(state)
  }

  @Action(DeleteSet)
  deleteSet(
    { getState, setState }: StateContext<WorkoutStateModel>,
  ) {
    const state = produce(getState(), draft => {
      const cwx = draft.currentWorkoutIndex
      const crx = draft.currentRowIndex
      const executions = draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions
      if (executions[executions.length - 1].user_added) {
        executions.pop()
      }
      draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions = executions
    })
    setState(state)
  }

  @Action(ChangeExercise)
  changeExcercise(
    { getState, setState }: StateContext<WorkoutStateModel>,
    { prev }: ChangeExercise
  ) {
    const state = produce(getState(), draft => {
      const cwx = draft.currentWorkoutIndex
      const tier = draft.currentWorkoutSession.workout.tiers[cwx]
      const tiersLength = draft.currentWorkoutSession.workout.tiers.length
      const rowsLength = tier.rows.length
      if (prev) {
        // First row? Previous tier
        if (tier.circuit || draft.currentRowIndex === 0) {
          // First tier? Do nothing
          if (cwx === 0) {
            // Do nothing
          } else {
            const prevTier = draft.currentWorkoutSession.workout.tiers[cwx - 1]
            draft.currentWorkoutIndex = cwx - 1
            draft.currentRowIndex = 0
            if (prevTier.circuit) {
              draft.currentRowIndex = 0
              draft.currentExerciseCount = draft.currentExerciseCount - 1
            } else if (prevTier.rows.length) {
              draft.currentRowIndex = prevTier.rows.length - 1
              draft.currentExerciseCount = draft.currentExerciseCount - 1
            }
          }
        } else {
          draft.currentRowIndex = draft.currentRowIndex - 1
          if (draft.currentRowIndex < 0) {
            draft.currentRowIndex = 0
          }
          draft.currentExerciseCount = draft.currentExerciseCount - 1
        }
      } else {
        // Last row? Next tier
        if (tier.circuit || draft.currentRowIndex >= rowsLength - 1) {
          // Last tier? End workout
          if (cwx >= tiersLength) {
            // Do nothing
          } else {
            const nextTier = draft.currentWorkoutSession.workout.tiers[cwx + 1]
            draft.currentWorkoutIndex = cwx + 1
            draft.currentRowIndex = 0
            if ((tier.circuit || rowsLength) && (nextTier.circuit || nextTier.rows.length))  {
              draft.currentExerciseCount = draft.currentExerciseCount + 1
            }
          }
        } else {
          draft.currentRowIndex = draft.currentRowIndex + 1
          draft.currentExerciseCount = draft.currentExerciseCount + 1
          // Catchall, never go above - this should never happen, but edge case
          if (draft.currentExerciseCount > draft.currentWorkoutSession.total_exercises) {
            draft.currentExerciseCount = draft.currentWorkoutSession.total_exercises
          }
        }
      }
      if (draft.currentExerciseCount < 1) {
        draft.currentExerciseCount = 1
      }

      return draft
    })
    setState(state)
  }

  @Action(SaveComments)
  saveComments(
    { getState, setState }: StateContext<WorkoutStateModel>,
    { comments, tierIndex, rowIndex }: SaveComments
  ) {
    const state = produce(getState(), draft => {
      let cwx = draft.currentWorkoutIndex
      if (tierIndex >= 0) {
        cwx = tierIndex
      }
      let crx = draft.currentRowIndex
      if (rowIndex >= 0) {
        crx = rowIndex
      }

      // Represents a Tier comment if rowIndex is -1
      if (rowIndex === -1) {
        draft.currentWorkoutSession.workout.tiers[cwx].comments = comments
      } else {
        draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].comments = comments
      }
    })
    setState(state)
  }

  @Action(SetExecutionsRM)
  setExecutionsRM(
    { getState, setState }: StateContext<WorkoutStateModel>,
    { oneRM, manual }: SetExecutionsRM
  ) {
    const state = produce(getState(), draft => {
      const cwx = draft.currentWorkoutIndex
      const crx = draft.currentRowIndex
      const executions = draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions
      for (let i = 0; i < executions.length; i++) {
        // Recalculate 1RM if execution is modified
        if (!manual && executions[i].parameter_values['1rm']) {
          continue
        }
        executions[i].parameter_values['1rm'] = oneRM['1rm']
        const load = executions[i].parameter_values['load']

        // Is a percentage load?
        const loadParam = _.parseInt(load, 10)
        // Do not update an editted set item
        if (!executions[i].editted) {
          if (manual || !executions[i].init) {
            executions[i].init = true
            // Round to nearest whole number (this should be the calculated value with loadPercentage)
            const parameterSelections = draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].parameters
            if (parameterSelections['load']) {
              const loadPercentage = parameterSelections['load'] / 100
              executions[i].parameter_values['load'] = Math.ceil(oneRM['1rm'] * loadPercentage)
            }
          } else if ((!isNaN(loadParam) && load !== -1) || /^\d+(\.\d+)?%$/.test(load)) {
            // Reset load to nearest 5
            executions[i].parameter_values['load'] = Math.ceil(oneRM['1rm'] * (_.parseInt(load, 10) / 100) / 5) * 5
          }
        }
      }
      draft.currentWorkoutSession.workout.tiers[cwx].rows[crx].executions = executions
    })
    setState(state)
  }

  @Action(SaveWorkout)
  saveWorkout(
    { getState, setState, dispatch }: StateContext<WorkoutStateModel>,
    { rpeScore, totalTime }: SaveWorkout
  ) {
    const state = getState()
    const draftState = produce(state, draft => {
      draft.currentWorkoutSession.rpe = rpeScore
      draft.currentWorkoutSession.completion_time_ms = totalTime

      const stats = WorkoutStateUtils.completionStats(draft.currentWorkoutSession)
      draft.currentWorkoutSession.complete = stats.complete
      draft.currentWorkoutSession.attendance_count = stats.attendance_count
      draft.currentWorkoutSession.total_tiers =  stats.total_tiers
      draft.currentWorkoutSession.completed_tiers = stats.completed_tiers
      draft.currentWorkoutSession.total_exercises = stats.total_exercises
      draft.currentWorkoutSession.completed_exercises = stats.completed_exercises
      draft.currentWorkoutSession.training_load = stats.training_load
      draft.currentWorkoutSession.est_training_load = stats.est_training_load

      // get plan metadata
      const plan = this.documentService.store.planDocuments.find(doc => doc.uuid === state.currentWorkoutSession.plan.id);
      if (plan) {
        const plannedWorkout = plan.document.workouts.find(w => w.id === state.currentWorkoutSession.workout.id);
        if (plannedWorkout) {
          const phase = PlanDocumentUtils.findPhase(
            plan.document.phases,
            { day: plannedWorkout.schedule.day[0], week: plannedWorkout.schedule.week[0] },
          );

          if (phase) {
            if (!phase.color) {
              const phaseLabel =
                this.documentService.store.exerciseProgram.document.phases.find(ph => ph.id === phase.exercise_program_phase_id);
              if (phaseLabel) {
                phase.color = phaseLabel.color;
              }
            }

            draft.currentWorkoutSession.phase = {
              uuid: phase.id,
              name: phase.name,
              color: phase.color,
            }
          }
        }
      }

      // Mark tiers complete
      if (stats.completedTierIndces.length > 0) {
        for (const i of stats.completedTierIndces) {
          draft.currentWorkoutSession.workout.tiers[i].complete = true
        }
      }
    })
    setState(draftState)
    const newState = getState()
    const workoutSessionDatabaseRecord = new WorkoutSessionDocument({
      key: newState.currentWorkoutSession.id,
      version: 1,
      document: newState.currentWorkoutSession
    })
    return from(this.dataService.saveWorkoutSessionDocument(workoutSessionDatabaseRecord)).pipe(
      tap(() => dispatch(new ResetWorkoutSession())),
      map(() => {
        return workoutSessionDatabaseRecord
      })
    )
  }

  @Action(ResetWorkoutSession)
  resetWorkoutSession(
    { getState, setState }: StateContext<WorkoutStateModel>,
  ) {
    const draftState = produce(getState(), draft => {
      draft.currentWorkoutSession = undefined
    })
    return setState(draftState)
  }
}
