import * as tslib_1 from "tslib";
import { AppState } from 'app/state/app/app.state';
import { OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SetActiveDate } from 'app/state';
var CalendarHeaderComponent = /** @class */ (function () {
    function CalendarHeaderComponent(store) {
        this.store = store;
        this.subs = [];
        // Not sure if needed
        moment.locale('en');
    }
    CalendarHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subs.push(this.activeDate$.subscribe(function (date) {
            if (!date) {
                return;
            }
            _this.activeDate = date;
            if (!_this.calendarBuilt || !_this.dateInCalendar(date)) {
                _this.calendarDays = [];
                _this.buildCalendar(date);
                _this.calendarBuilt = true;
            }
            _this.updatecalendarDays(date);
            setTimeout(function () {
                _this.scrollToActiveDay();
            }, 500);
        }), this.isSyncing$.subscribe(function (isSyncing) {
            if (isSyncing) {
                _this.calendarDays = [];
            }
            if (_this.isSyncing && !isSyncing) {
                // Sync is complete, rebuild
                _this.buildCalendar(_this.activeDate);
                _this.calendarBuilt = true;
                _this.updatecalendarDays(_this.activeDate);
                setTimeout(function () {
                    _this.scrollToActiveDay();
                }, 500);
            }
            _this.isSyncing = isSyncing;
        }));
    };
    CalendarHeaderComponent.prototype.ngOnDestroy = function () {
        this.subs.forEach(function (sub) { return sub.unsubscribe(); });
    };
    CalendarHeaderComponent.prototype.dateInCalendar = function (date) {
        var index = _.findIndex(this.calendarDays, function (day) {
            return moment(day.date).isSame(date, 'd');
        });
        return index !== -1;
    };
    CalendarHeaderComponent.prototype.ngAfterViewChecked = function () {
        this.scrollToActiveDay();
    };
    CalendarHeaderComponent.prototype.scrollToActiveDay = function () {
        var activeItem = document.getElementById('activeDate');
        if (activeItem) {
            activeItem.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
        }
    };
    CalendarHeaderComponent.prototype.prependMoreDates = function () {
        var earliestDate = this.calendarDays[0].date;
        var preDays = moment(earliestDate).subtract(1, 'd');
        for (var i = 0; i < 24; i++) {
            this.calendarDays.unshift({
                day: preDays.format('ddd'),
                number: preDays.format('D'),
                active: false,
                date: preDays.format()
            });
            preDays = preDays.clone().subtract(1, 'd');
        }
    };
    CalendarHeaderComponent.prototype.appendMoreDates = function () {
        var latestDate = this.calendarDays[this.calendarDays.length - 1].date;
        var nextDays = moment(latestDate).add(1, 'd');
        for (var i = 0; i < 24; i++) {
            this.calendarDays.push({
                day: nextDays.format('ddd'),
                number: nextDays.format('D'),
                active: false,
                date: nextDays.format()
            });
            nextDays = nextDays.clone().add(1, 'd');
        }
    };
    CalendarHeaderComponent.prototype.buildCalendar = function (date) {
        var activeDay = moment(date);
        var preDays = activeDay.clone().subtract(1, 'day');
        for (var i = 0; i < 14; i++) {
            this.calendarDays.unshift({
                day: preDays.format('ddd'),
                number: preDays.format('D'),
                active: activeDay.isSame(preDays, 'd'),
                date: preDays.format(),
                hasWorkout: this.availableDates.findIndex(function (d) { return d.isSame(preDays, 'd'); }) > -1
            });
            preDays = preDays.clone().subtract(1, 'd');
        }
        var nextDays = activeDay.clone();
        for (var i = 0; i < 14; i++) {
            this.calendarDays.push({
                day: nextDays.format('ddd'),
                number: nextDays.format('D'),
                active: activeDay.isSame(nextDays, 'd'),
                date: nextDays.format(),
                hasWorkout: this.availableDates.findIndex(function (d) { return d.isSame(nextDays, 'd'); }) > -1
            });
            nextDays = nextDays.clone().add(1, 'd');
        }
    };
    CalendarHeaderComponent.prototype.updatecalendarDays = function (date) {
        this.calendarDays = _.map(this.calendarDays, function (day) {
            if (moment(day.date).isSame(date, 'd')) {
                day.active = true;
            }
            else {
                day.active = false;
            }
            return day;
        });
    };
    CalendarHeaderComponent.prototype.onClickDay = function (dateString) {
        var date = moment(dateString);
        this.store.dispatch(new SetActiveDate(date.format()));
    };
    tslib_1.__decorate([
        Select(AppState.isSyncing),
        tslib_1.__metadata("design:type", Observable)
    ], CalendarHeaderComponent.prototype, "isSyncing$", void 0);
    tslib_1.__decorate([
        Select(AppState.activeDate),
        tslib_1.__metadata("design:type", Observable)
    ], CalendarHeaderComponent.prototype, "activeDate$", void 0);
    return CalendarHeaderComponent;
}());
export { CalendarHeaderComponent };
