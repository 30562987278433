import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { JoinGroup, LeaveGroup } from 'app/state';
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { PageTransitionService } from 'app/services/page-transition.service';
import { AuthService } from 'app/services/auth.service';
import { DataService } from 'app/services/data.service';
import { LeaveDialogComponent } from './leave-dialog/leave-dialog.component';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestService } from 'app/services/rest.service';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
var GroupManagementComponent = /** @class */ (function () {
    function GroupManagementComponent(router, store, dataService, authService, restService, dialog, spinner, pageTransitionService) {
        this.router = router;
        this.store = store;
        this.dataService = dataService;
        this.authService = authService;
        this.restService = restService;
        this.dialog = dialog;
        this.spinner = spinner;
        this.pageTransitionService = pageTransitionService;
        this.isOnline = false;
        this.noAvailableGroups = false;
        this.availableGroups = [];
        this.joinedGroups = [];
    }
    GroupManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userUUID = this.authService.getUserUUID();
        this.onlineSub = this.restService.isOnlineSubscription().subscribe(function (isOnline) { return _this.isOnline = isOnline; });
        this.fetchPublicGroups();
    };
    GroupManagementComponent.prototype.onWindowScroll = function ($event) {
        var position = $event.target['scrollingElement'].scrollTop;
        if (position !== 0) {
            this.lastPositionY = position;
        }
    };
    GroupManagementComponent.prototype.resetGroupList = function () {
        this.noAvailableGroups = false;
        this.availableGroups = [];
        this.joinedGroups = [];
    };
    GroupManagementComponent.prototype.scrollToPosition = function () {
        var _this = this;
        if (!this.panel || this.lastPositionY < 20) {
            return;
        }
        var scrollToTop = window.setInterval(function () {
            if (window.pageYOffset === 0) {
                window.scrollTo(0, _this.lastPositionY - 20);
            }
            else {
                window.clearInterval(scrollToTop);
            }
        }, 16);
    };
    GroupManagementComponent.prototype.fetchPublicGroups = function () {
        var _this = this;
        this.resetGroupList();
        this.dataService.getGroups().subscribe(function (groups) {
            groups = _.sortBy(groups, 'name');
            _.each(groups, function (group) {
                if (_this.isJoined(group)) {
                    _this.joinedGroups.push(group);
                }
                else {
                    if (!group.private) {
                        _this.availableGroups.push(group);
                    }
                }
            });
            // If there are no available groups, give an error
            if (!_this.joinedGroups.length && !_this.availableGroups.length) {
                _this.noAvailableGroups = true;
            }
            else {
                // Load from last position we have
                if (_this.lastPositionY) {
                    _this.scrollToPosition();
                }
            }
        });
    };
    GroupManagementComponent.prototype.isJoined = function (group) {
        return _.includes(group.users, this.userUUID);
    };
    GroupManagementComponent.prototype.requestJoin = function (group) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.spinner.show()];
                    case 1:
                        _a.sent();
                        this.dataService.groupRequestInvite(group.key, this.authService.getUsername()).subscribe(function (res) {
                            var alreadyInvitedIndex;
                            group.users = _.map(res.members, function (v, i) {
                                if (v.uuid === _this.authService.getUserUUID()) {
                                    alreadyInvitedIndex = i;
                                }
                                return v.uuid;
                            });
                            if (alreadyInvitedIndex !== -1) {
                                group.users.push(res.members[alreadyInvitedIndex]);
                            }
                            _this.dataService.update(group).then(function () {
                                // Resync data for the plans
                                _this.store.dispatch(new JoinGroup(group)).subscribe(function () {
                                    _this.fetchPublicGroups();
                                    _this.spinner.hide();
                                });
                            });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    GroupManagementComponent.prototype.requestLeave = function (group) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.spinner.show()];
                    case 1:
                        _a.sent();
                        this.dataService.groupRequestLeave(group.key, this.authService.getUserUUID()).subscribe(function (res) {
                            group.users = _.map(res.members, function (v, i) {
                                return v.uuid;
                            });
                            _this.deleteFromGroup(group, _this.authService.getUserUUID());
                            _this.store.dispatch(new LeaveGroup(group));
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    GroupManagementComponent.prototype.deleteFromGroup = function (group, userId) {
        var _this = this;
        _.remove(group.users, function (user) {
            return user === userId;
        });
        this.dataService.update(group).then(function () {
            _this.fetchPublicGroups();
            _this.spinner.hide();
        });
    };
    GroupManagementComponent.prototype.openLeaveDialog = function (group) {
        var _this = this;
        var dialogRef = this.dialog.open(LeaveDialogComponent, {
            panelClass: 'modal-leaveConfirm',
        });
        dialogRef.afterClosed().subscribe(function (isLeaving) {
            if (isLeaving) {
                _this.requestLeave(group);
            }
        });
    };
    GroupManagementComponent.prototype.ngOnDestroy = function () {
        if (this.onlineSub) {
            this.onlineSub.unsubscribe();
        }
    };
    return GroupManagementComponent;
}());
export { GroupManagementComponent };
