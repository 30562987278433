<h2 mat-dialog-title>Feedback</h2>
<mat-dialog-content fxLayout="column">
  <p>Have a new idea? Something not working? Let the FitForce Planner team know!</p>
  <mat-form-field fxFlex>
  <textarea matInput matTextareaAutosize
    placeholder="Questions or Comments"
    matFocusStart
    matAutosizeMinRows="3"
    matAutosizeMaxRows="5"
    [(ngModel)]="message">
  </textarea>
</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="cancelSelected()" fxFlex>Cancel</button>
  <button class="yes-button" mat-button (click)="submitSelected()" cdkFocusInitial fxFlex>Submit</button>
</mat-dialog-actions>
