import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { AddNewSet, ChangeExercise, DeleteSet, EditSet, ExecuteSet, SaveComments, Set1RM, SetExecutionsRM } from 'app/state';
import { OnInit } from '@angular/core';
import { ExerciseProgramDocumentUtils, ResourceType, getDefaultParams } from 'fitforce-document-sync';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AppState } from 'app/state/app/app.state';
import { DocumentService } from 'app/services/document.service';
import { EditSetDialogComponent } from '../edit-set-dialog/edit-set-dialog.component';
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component';
import { RMCalcDialogComponent } from '../../rmcalc-dialog/rmcalc-dialog.component';
import { RMCalcSetDialogComponent } from '../../rmcalc-set-dialog/rmcalc-set-dialog.component';
import { SetsRepsLoadPipe } from 'app/pipes/sets-reps-load.pipe';
import { Store } from '@ngxs/store';
import { VideoDialogComponent } from 'app/modules/video-dialog/video-dialog.component';
import { WorkoutState } from 'app/state/workout/workout.state';
var SubexerciseComponent = /** @class */ (function () {
    function SubexerciseComponent(store, documentService, dialog, snackBar, setRepsLoadPipe) {
        this.store = store;
        this.documentService = documentService;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.setRepsLoadPipe = setRepsLoadPipe;
        this.rowNoteVisible = false;
        this.hasRM = {
            oneRM: {},
            index: undefined
        };
        this.hasTimer = false;
        this.timerInterval = undefined;
        this.timerState = 'off';
        this.timerOrientation = 'down';
        this.timerStart = 0;
        this.timerMillis = 0;
        this.timerFinished = false;
    }
    Object.defineProperty(SubexerciseComponent.prototype, "timer", {
        get: function () {
            var mins = 0;
            var secs = 0;
            if (this.timerOrientation === 'up') {
                mins = Math.floor(this.timerMillis / 1000 / 60);
                secs = Math.floor(this.timerMillis / 1000) - (mins * 60);
            }
            else {
                var adjusted = this.timerOrientation === 'down' ? this.timerStart - this.timerMillis : this.timerMillis;
                mins = Math.floor(adjusted / 1000 / 60);
                secs = Math.floor((adjusted - (mins * 1000 * 60)) / 1000);
            }
            return { mins: mins.toString().padStart(2, '0'), secs: secs.toString().padStart(2, '0') };
        },
        enumerable: true,
        configurable: true
    });
    SubexerciseComponent.prototype.ngOnInit = function () {
        this.loadExercise();
    };
    SubexerciseComponent.prototype.loadExercise = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var exerciseReference_1, exercise, _param;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.process1RM();
                        if (!(this.hasRM.hasOwnProperty('index') && this.hasRM.index >= 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.store.dispatch(new SetExecutionsRM(this.hasRM.oneRM)).toPromise()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession);
                        this.currentWorkoutIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex);
                        this.currentRowIndex = this.store.selectSnapshot(WorkoutState.currentRowIndex);
                        this.tier = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex];
                        this.tierRow = _.cloneDeep(this.tier.rows[this.currentRowIndex]);
                        return [4 /*yield*/, this.documentService.restoreData().toPromise()];
                    case 3:
                        _a.sent();
                        this.updateArrows(this.currentWorkoutIndex, this.currentRowIndex, this.currentWorkoutSession.workout.tiers.length, this.tier.rows.length);
                        if (this.tierRow) {
                            exerciseReference_1 = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex].exercise;
                            exercise = exerciseReference_1 ? this.documentService.store.exerciseProgram.document.exercises.find(function (ex) { return ex.id === exerciseReference_1.exercise_program_id; }) : undefined;
                            if (exercise) {
                                this.setParams = ExerciseProgramDocumentUtils.getPossibleParamsForExercise(this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex].rows[this.currentRowIndex].exercise.exercise_program_id, this.documentService.store.exerciseProgram.document);
                            }
                            else {
                                this.setParams = getDefaultParams();
                            }
                            // if (this.tierRow.notes) {
                            //   this.rowNoteVisible = true
                            // } else {
                            //   this.rowNoteVisible = false
                            // }
                            this.checkToggleStatus();
                            this.documentService.restoreData().subscribe(function () {
                                _this.exerciseVideo = _this.getExerciseVideo();
                            });
                            this.getSetsRepsLoad();
                            if (this.setParams.find(function (param) { return param.param === 'time'; })) {
                                this.hasTimer = true;
                                this.timerOrientation = 'up';
                                this.timerStart = 0;
                            }
                            else if (this.setParams.find(function (param) { return param.param === 'timer'; }) && this.tierRow.parameters['timer']) {
                                this.timerOrientation = 'down';
                                this.hasTimer = true;
                                _param = this.setParams.find(function (param) { return param.param === 'timer'; });
                                this.timerStart = _param.unit === 'minutes'
                                    ? Math.floor(Number(this.tierRow.parameters[_param.param]) * 1000 * 60)
                                    : Math.floor(Number(this.tierRow.parameters[_param.param]) * 1000);
                            }
                            else {
                                this.hasTimer = false;
                            }
                        }
                        // tslint:disable
                        this.timerInterval && clearInterval(this.timerInterval);
                        // tslint:enable
                        this.timerMillis = 0;
                        this.timerState = 'off';
                        this.timerFinished = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SubexerciseComponent.prototype.process1RM = function () {
        var currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession);
        var currentWorkoutIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex);
        var currentRowIndex = this.store.selectSnapshot(WorkoutState.currentRowIndex);
        var tier = currentWorkoutSession.workout.tiers[currentWorkoutIndex];
        var tierRow = _.cloneDeep(tier.rows[currentRowIndex]);
        var userProfile = this.store.selectSnapshot(AppState.userProfile);
        var index = -1;
        // If tierRow is undefined..
        if (tierRow) {
            index = userProfile.stats.exercises.findIndex(function (e) {
                return e.exercise_id === tierRow.exercise.exercise_program_id;
            });
        }
        if (index !== -1) {
            this.hasRM = {
                oneRM: userProfile.stats.exercises[index],
                index: index
            };
        }
        else {
            this.hasRM = {
                oneRM: {},
                index: undefined
            };
        }
    };
    SubexerciseComponent.prototype.updateArrows = function (currentWorkoutIndex, currentRowIndex, tiersLength, rowsLength) {
        this.hasPrev = true;
        this.hasNext = true;
        if (currentRowIndex === 0 && currentWorkoutIndex === 0) {
            this.hasPrev = false;
        }
        if (currentRowIndex >= rowsLength - 1 && currentWorkoutIndex >= tiersLength - 1) {
            this.hasNext = false;
        }
    };
    SubexerciseComponent.prototype.inSetParams = function (param) {
        if (this.setParams) {
            return this.setParams.some(function (p) { return p.param === param; });
        }
        return true;
    };
    SubexerciseComponent.prototype.getSetsRepsLoad = function () {
        this.setDetails = this.setRepsLoadPipe.transform({
            setParams: this.setParams,
            currentWorkoutSession: this.currentWorkoutSession,
            currentWorkoutIndex: this.currentWorkoutIndex,
            currentRowIndex: this.currentRowIndex,
            hasSet: true,
            joinCharacter: ' | '
        });
    };
    SubexerciseComponent.prototype.checkToggleStatus = function () {
        var e_1, _a;
        var totalComplete = 0;
        var toggleStatus = 0;
        try {
            for (var _b = tslib_1.__values(this.tierRow.executions), _c = _b.next(); !_c.done; _c = _b.next()) {
                var execution = _c.value;
                if (execution.complete_time) {
                    totalComplete += 1;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (totalComplete >= 1) {
            toggleStatus = 1;
        }
        if (totalComplete === this.tierRow.executions.length) {
            toggleStatus = 2;
        }
        this.toggleStatus = toggleStatus;
    };
    SubexerciseComponent.prototype.isExecuted = function (setIndex) {
        if (this.tierRow.executions[setIndex] && this.tierRow.executions[setIndex].complete_time) {
            return true;
        }
        return false;
    };
    SubexerciseComponent.prototype.onExecuteSet = function (setIndex) {
        var _this = this;
        this.store.dispatch(new ExecuteSet(setIndex, this.tierRow.executions[setIndex].parameter_values)).subscribe(function () {
            _this.loadExercise();
        });
    };
    SubexerciseComponent.prototype.onAddNewSet = function ($event) {
        var _this = this;
        // Use param values from last set
        var paramValues = _.last(this.tierRow.executions)['parameter_values'];
        this.store.dispatch(new AddNewSet(paramValues)).subscribe(function () {
            _this.loadExercise();
        });
    };
    SubexerciseComponent.prototype.onDeleteSet = function ($event) {
        var _this = this;
        // Use param values from last set
        var paramValues = _.last(this.tierRow.executions)['parameter_values'];
        this.store.dispatch(new DeleteSet(paramValues)).subscribe(function () {
            _this.loadExercise();
        });
    };
    SubexerciseComponent.prototype.getExerciseVideo = function () {
        var exerciseId = this.tierRow.exercise.exercise_program_id;
        var exerciseDetails = _.find(this.documentService.store.exerciseProgram.document.exercises, { id: exerciseId });
        if (exerciseDetails && exerciseDetails.resources && exerciseDetails.resources.length > 0) {
            return _.find(exerciseDetails.resources, { type: ResourceType.Video });
        }
        return;
    };
    SubexerciseComponent.prototype.openVideo = function () {
        this.dialog.open(VideoDialogComponent, {
            panelClass: 'modal-workout-video',
            maxWidth: '100vw',
            width: '100%',
            data: {
                title: this.exerciseVideo.title,
                videoId: this.exerciseVideo.asset_id.replace('video:', ''),
            },
        });
    };
    SubexerciseComponent.prototype.openNotesDialog = function () {
        var dialogRef = this.dialog.open(NotesDialogComponent, {
            maxWidth: '100vw',
            width: '80%',
            data: {
                isTierNote: true,
            }
        });
    };
    SubexerciseComponent.prototype.openCommentsDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(NotesDialogComponent, {
            maxWidth: '100vw',
            width: '80%',
            data: {
                isTierNote: false,
            }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res && res.success) {
                _this.store.dispatch(new SaveComments(res.notes)).subscribe(function () {
                    _this.snackBar.open('Saved notes.', undefined, {
                        duration: 2500
                    });
                });
            }
        });
    };
    SubexerciseComponent.prototype.openEditSet = function (setIndex) {
        var _this = this;
        var tierIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex);
        var rowIndex = this.store.selectSnapshot(WorkoutState.currentRowIndex);
        var dialogRef = this.dialog.open(EditSetDialogComponent, {
            id: setIndex.toString(),
            maxWidth: '100vw',
            width: '80%',
            data: {
                tierIndex: tierIndex,
                rowIndex: rowIndex,
                setIndex: setIndex
            }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res && res.success) {
                _this.tierRow.executions[setIndex].parameter_values = res.setParamValues;
                _this.store.dispatch(new EditSet(tierIndex, rowIndex, setIndex, res.setParamValues, _this.tierRow.executions[setIndex].complete_time, true)).subscribe(function () {
                    _this.loadExercise();
                });
            }
        });
    };
    SubexerciseComponent.prototype.openCalc1RM = function () {
        var _this = this;
        var dialogRef = this.dialog.open(RMCalcDialogComponent, {
            maxWidth: '100vw',
            width: '80%',
            data: {
                hasRM: this.hasRM
            }
        });
        dialogRef.afterClosed().subscribe(function (res) {
            if (res && res.success) {
                if (res['1rm']) {
                    var new1RM = {
                        'exercise_id': _this.tierRow.exercise.exercise_program_id,
                        '1rm': res['1rm'],
                        'units': 'lbs'
                    };
                    if (!res['rmManual']) {
                        _this.dialog.open(RMCalcSetDialogComponent, {
                            maxWidth: '100vw',
                            width: '80%',
                            data: {
                                exerciseName: _this.tierRow.exercise.name,
                                results: res
                            }
                        });
                    }
                    _this.store.dispatch([
                        new SetExecutionsRM(new1RM, true),
                        new Set1RM(new1RM, _this.hasRM.index),
                    ]).subscribe(function () {
                        _this.loadExercise();
                    });
                }
            }
        });
    };
    SubexerciseComponent.prototype.changeExercise = function (prev) {
        var _this = this;
        if (prev === void 0) { prev = false; }
        // disable arrows if not checked
        if (prev || this.hasNext) {
            this.store.dispatch(new ChangeExercise(prev)).subscribe(function () {
                _this.toggleStatus = 0;
                _this.loadExercise();
            });
        }
    };
    SubexerciseComponent.prototype.changeTier = function (event) {
        this.loadExercise();
    };
    SubexerciseComponent.prototype.toggleAllSets = function (checked) {
        var _this = this;
        if (checked) {
            this.toggleStatus = 0;
        }
        else {
            this.toggleStatus = 2;
        }
        this.tierRow.executions.forEach(function (v, i) {
            if (!checked && !v.complete_time) {
                _this.onExecuteSet(i);
            }
            else if (checked && v.complete_time) {
                _this.onExecuteSet(i);
            }
        });
    };
    SubexerciseComponent.prototype.isValidLoad = function (number) {
        return !_.isNaN(_.parseInt(number)) && (_.parseInt(number) >= 0);
    };
    SubexerciseComponent.prototype.onToggleRowNote = function () {
        this.rowNoteVisible = !this.rowNoteVisible;
    };
    SubexerciseComponent.prototype.startTimer = function () {
        var _this = this;
        this.timerInterval = setInterval(function () {
            if (_this.timerOrientation === 'down' && _this.timerMillis >= _this.timerStart) {
                _this.timerFinished = true;
            }
            else {
                _this.timerMillis += 1000;
            }
        }, 1000);
        this.timerState = 'on';
    };
    SubexerciseComponent.prototype.pauseTimer = function () {
        // tslint:disable
        this.timerInterval && clearInterval(this.timerInterval);
        // tslint:enable
        this.timerState = 'off';
    };
    SubexerciseComponent.prototype.logTime = function () {
        var _this = this;
        this.timerState = 'off';
        // tslint:disable
        this.timerInterval && clearInterval(this.timerInterval);
        // tslint:enable
        var tierIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex);
        var rowIndex = this.store.selectSnapshot(WorkoutState.currentRowIndex);
        var param = this.setParams.find(function (p) { return p.param === 'time'; });
        var time = param.unit === 'minutes' ? this.timerMillis / 1000 / 60 : this.timerMillis / 1000;
        this.store.dispatch(new EditSet(tierIndex, rowIndex, 0, { time: time.toFixed(3) }, this.tierRow.executions.length > 0 ? this.tierRow.executions[0].complete_time : undefined, true)).subscribe(function () {
            _this.loadExercise();
        });
    };
    return SubexerciseComponent;
}());
export { SubexerciseComponent };
