<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#fff" type="ball-clip-rotate">
    <div class="spinner-text">
        <br>
        <p>{{ spinnerText }}</p>
    </div>
</ngx-spinner>
<app-header [hasRefresh]="true" [hasLogout]="true"></app-header>
<mat-tab-group [class.navbar-online]="isOnline" [class.navbar-offline]="!isOnline" mat-stretch-tabs color="accent" [selectedIndex]="tabIndex" (selectedTabChange)="onSelectedTabChange($event)">
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="tab-label">
                <mat-icon svgIcon="icon_workout"></mat-icon>
                <div>Workouts</div>
            </div>
        </ng-template>
        <ng-template matTabContent>
            <div fxLayout="column">
                <div class="calendar-header">
                    <div (click)="onToggleCalendar()" fxLayoutAlign="center center">
                        <button class="date-day" mat-button color="primary">
              {{ activeDayMonth | uppercase }}
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
                    </div>
                    <div fxFlex></div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button mat-icon-button (click)="onPreviousWeek()"><mat-icon>keyboard_arrow_left</mat-icon></button>
                        <button mat-button color="primary" (click)="onToday()" [disabled]="isToday">TODAY</button>
                        <button mat-icon-button (click)="onNextWeek()"><mat-icon>keyboard_arrow_right</mat-icon></button>
                    </div>
                </div>
            </div>
            <app-calendar-header #calendar [availableDates]="availableDates"></app-calendar-header>
            <mat-calendar [dateClass]="dateClass()" *ngIf="calendarVisible" [@slideInOut] (selectedChange)="onCalendarSelect($event)" [startAt]="activeDateDate"></mat-calendar>
            <div class="mat-tab-container">
                <div fxLayout="column" fxLayoutWrap class="cards">
                    <ng-container *ngIf="(organizedDays$ | async); let organizedDays">
                        <ng-container *ngIf="objectKeys(organizedDays).length > 0; else noPlanDays">
                            <div *ngFor="let group of organizedDays | keyvalue" fxLayout="column wrap">
                                <div class="workout-group-name">{{ group.value.group.name }}</div>
                                <div *ngFor="let workout of group.value.days">
                                    <mat-card (click)="onClickCard(group, workout)" [ngClass]="{
                      'workout-card': true,
                      'workout-card-default': workout.workoutSessions.length === 0,
                      'workout-card-unsynced': workout.workoutSessions.length > 0 && workout.hasUnsyncedSessions,
                      'workout-card-synced':  workout.workoutSessions.length > 0 && !workout.hasUnsyncedSessions,
                      'workout-card-restricted': workout.day.isRestricted
                    }" fxLayout="row" fxLayoutAlign="center center">
                                        <div fxFlex="1 1" fxLayout="column">
                                            <div class="workout-name">{{ workout.day.name }}</div>
                                            <div *ngIf="workout.phase" class="phase-name" fxLayoutAlign="left center">
                                                <!-- <mat-icon class="phase-dot" [ngStyle]="{color: workout.phase.color || 'white'}">fiber_manual_record</mat-icon> -->
                                                <mat-icon class="phase-dot" [ngClass]="getPhaseColor(workout)">fiber_manual_record</mat-icon>
                                                <div>{{ workout.phase.name }}</div>
                                            </div>
                                            <div class="plan-name">{{ workout.plan.name }}</div>
                                        </div>
                                        <div fxLayout="row" fxLayoutGap="10px" *ngIf="!workout.day.isRestricted">
                                            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
                                                <div class="card-footer-label">Duration</div>
                                                <div class="card-footer-value">{{ workout.day.est_completion_time_ms | msToTime }}</div>
                                            </div>
                                            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
                                                <div class="card-footer-label">TL</div>
                                                <div class="card-footer-value">{{ workout.totalLoad }}</div>
                                            </div>
                                        </div>
                                    </mat-card>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #noPlanDays>
                        <div class="no-plan-days">
                            <div fxLayoutAlign="center center">
                                <h4>Nothing Planned Today</h4>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="tab-label">
                <mat-icon svgIcon="icon_programs"></mat-icon>
                <div>Programs</div>
            </div>
        </ng-template>
        <ng-template matTabContent>
            <app-programs></app-programs>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="tab-label">
                <mat-icon svgIcon="icon_stats"></mat-icon>
                <div>Stats</div>
            </div>
        </ng-template>
        <ng-template matTabContent>
            <div class="mat-tab-container mat-tab-stats-container">
                <app-my-stats></app-my-stats>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab [disabled]="!isOnline">
        <ng-template mat-tab-label>
            <div class="tab-label">
                <mat-icon svgIcon="icon_resources"></mat-icon>
                <div>Resources</div>
            </div>
        </ng-template>
        <ng-template matTabContent>
            <app-resources></app-resources>
        </ng-template>
    </mat-tab>
</mat-tab-group>