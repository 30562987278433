
<div mat-dialog-title>Finished</div>
<mat-dialog-content *ngIf="totalRoundsCompleted >= 0">
  I completed the circuit
  <mat-form-field class="full-width">
    <input matInput
      placeholder="Rounds"
      [(ngModel)]="totalRoundsCompleted"
      type="number">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row">
  <button mat-button (click)="onClose()">Close</button>
  <div fxFlex></div>
  <button *ngIf="!tierIndex && !isCircuit" class="yes-button" (click)="onSave()" mat-button cdkFocusInitial>Next Exercise</button>
  <button *ngIf="tierIndex || isCircuit" class="yes-button" (click)="onSave()" mat-button cdkFocusInitial >Update Rounds</button>
</mat-dialog-actions>
