import * as _ from 'lodash'

import { Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog, MatPaginator, PageEvent } from '@angular/material'
import { Select, Store } from '@ngxs/store'

import { AppState } from 'app/state/app/app.state'
import { AuthState } from 'app/state/auth/auth.state'
import { DataService } from 'app/services/data.service'
import { Group } from 'app/models'
import { LeaveDialogComponent } from 'app/modules/group-management/leave-dialog/leave-dialog.component'
import { LeaveGroup } from 'app/state'
import { Observable } from 'rxjs'
import { debounce } from 'lodash';

@Component({
  selector: 'app-programs-joined',
  templateUrl: './programs-joined.component.html',
  styleUrls: ['./programs-joined.component.scss']
})
export class ProgramsJoinedComponent implements OnInit {
  pageSize = 10
  groups: Array<Group>
  filteredGroups: Array<Group>
  startIndex: number
  stopIndex: number
  userUUID: string
  isOnline = false
  searchText: string
  totalGroups: number

  pageEvent: PageEvent

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator

  @Select(AppState.isOnline)
  isOnline$: Observable<any>

  @Select(AppState.joinedGroups)
  joinedGroups$: Observable<any>

  constructor(
    private store: Store,
    private dataService: DataService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    const activeUser = this.store.selectSnapshot(AuthState.activeUser)
    this.userUUID = activeUser.uuid
    this.isOnline$.subscribe(isOnline => {
      this.isOnline = isOnline
    })

    this.joinedGroups$.subscribe(groups => {
      this.listGroups()
    })
  }

  listGroups() {
    this.groups = []
    this.initIndices()
    this.fetchJoinedGroups()
  }

  async fetchJoinedGroups() {
    const groups = await this.dataService.getGroups().toPromise()
    const sortedGroups = _.sortBy(groups, g => g.name.toLowerCase())
    _.each(sortedGroups, group => {
      this.groups.push(group)
    })
    this.groups = _.uniqBy(this.groups, (group) => group.key)
    this.totalGroups = this.groups.length
    this.filteredGroups = this.groups
    return this.groups
  }

  initIndices() {
    if (this.paginator) {
      this.paginator.pageIndex = 0
    }
    this.startIndex = 0
    this.stopIndex = this.pageSize
  }

  onPageEvent($event) {
    const pageIndex = $event.pageIndex
    if (pageIndex) {
      this.startIndex = pageIndex * this.pageSize
      this.stopIndex = this.startIndex + this.pageSize - 1
    } else {
      this.initIndices()
    }
  }

  onSearchEvent(searchText) {
    this.initIndices()
    this.searchText = searchText
    this.filteredGroups = this.searchText.trim() === '' ? this.groups
      : this.groups.filter(g => g.name.toLowerCase().includes(this.searchText.toLowerCase()))
    this.totalGroups = this.filteredGroups.length
  }

  openLeaveDialog(group: Group) {
    const dialogRef = this.dialog.open(LeaveDialogComponent, {
      panelClass: 'modal-leaveConfirm',
    })

    dialogRef.afterClosed().subscribe(isLeaving => {
      if (isLeaving) {
        this.requestLeave(group)
      }
    })
  }

  requestLeave(group: Group) {
    this.store.dispatch(new LeaveGroup(group)).subscribe(() => {
      this.searchText = ''
    })
  }
}
