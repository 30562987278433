import * as tslib_1 from "tslib";
var WorkoutStateUtils = /** @class */ (function () {
    function WorkoutStateUtils() {
    }
    WorkoutStateUtils.completionStats = function (workoutSession) {
        var e_1, _a, e_2, _b;
        var stats = {
            complete: false,
            attendance_count: workoutSession.attendees.length,
            total_tiers: 0,
            completed_tiers: 0,
            total_exercises: 0,
            completed_exercises: 0,
            training_load: 0,
            est_training_load: 0,
            completedTierIndces: []
        };
        for (var i = 0; i < workoutSession.workout.tiers.length; i++) {
            var tier = workoutSession.workout.tiers[i];
            stats.total_tiers++;
            if (tier.circuit && tier.circuit.executions) {
                if (tier.circuit.executions.length > 0) {
                    if (tier.circuit.executions[0].num_rounds >= tier.circuit.num_rounds) {
                        stats.completed_tiers++;
                        stats.completedTierIndces.push(i);
                    }
                }
                else {
                    stats.completed_tiers++;
                    stats.completedTierIndces.push(i);
                }
                // We don't care about total_exercises here
            }
            else {
                if (tier.rows.length > 0) {
                    var tierExercises = 0;
                    var tierExercisesComplete = 0;
                    try {
                        for (var _c = (e_1 = void 0, tslib_1.__values(tier.rows)), _d = _c.next(); !_d.done; _d = _c.next()) {
                            var row = _d.value;
                            stats.total_exercises++;
                            tierExercises++;
                            var totalSets = row.parameters && row.parameters.sets ? row.parameters.sets : 0;
                            var completedSets = 0;
                            if (row.executions.length > 0) {
                                try {
                                    for (var _e = (e_2 = void 0, tslib_1.__values(row.executions)), _f = _e.next(); !_f.done; _f = _e.next()) {
                                        var execution = _f.value;
                                        if (execution.complete_time) {
                                            completedSets++;
                                        }
                                    }
                                }
                                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                finally {
                                    try {
                                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                                    }
                                    finally { if (e_2) throw e_2.error; }
                                }
                            }
                            if (completedSets >= totalSets) {
                                stats.completed_exercises++;
                                tierExercisesComplete++;
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    if (tierExercisesComplete >= tierExercises) {
                        stats.completed_tiers++;
                        stats.completedTierIndces.push(i);
                    }
                }
                else {
                    stats.completed_tiers++;
                    stats.completedTierIndces.push(i);
                }
            }
        }
        if (stats.total_tiers === stats.completed_tiers) {
            stats.complete = true;
        }
        if (workoutSession.rpe !== undefined && workoutSession.completion_time_ms) {
            stats.training_load = workoutSession.rpe * workoutSession.completion_time_ms / 1000 / 60;
        }
        if (workoutSession.est_rpe !== undefined && workoutSession.est_rpe > -1
            && workoutSession.est_completion_time_ms && workoutSession.est_completion_time_ms > -1) {
            stats.est_training_load = workoutSession.est_rpe * workoutSession.est_completion_time_ms / 1000 / 60;
        }
        return stats;
    };
    return WorkoutStateUtils;
}());
export { WorkoutStateUtils };
