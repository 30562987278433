// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import { OfflineService as OfflineFirstOfflineService, DatabaseRecord, DatabaseModel, DatabaseRecordIdentifier, DatabaseQuery, DatabaseType } from 'fitforce-offline-first';
import { DocumentDatabaseService } from 'fitforce-document-sync';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil } from 'lodash';
// tslint:enable: max-line-length

@Injectable({
  providedIn: 'root'
})
export class OfflineService extends OfflineFirstOfflineService {
  private database(model: DatabaseModel) {
    if (model.databaseType === DatabaseType.Document) {
      return this.documentDatabaseService;
    } else {
      // TODO: add other databases
    }
  }

  constructor(private documentDatabaseService: DocumentDatabaseService) {
    super();

    this.documentDatabaseService.connect();
  }

  public getRecord<T extends DatabaseRecord>(model: DatabaseModel, where: DatabaseRecordIdentifier | DatabaseQuery): Observable<T> {
    return from((this.database(model)).connect().then(() => {
      return (this.database(model)).get<T>(model, where);
    }));
  }

  public getAll<T extends DatabaseRecord>(model: DatabaseModel): Observable<T[]> {
    return from(
      (this.database(model)).connect().then(() => {
        return (this.database(model)).getAll<T>(model);
      })
    );
  }

  public clear(model: DatabaseModel): Observable<void> {
    return from(
      (this.database(model)).connect().then(() => {
        return (this.database(model)).clear(model);
      })
    );
  }

  public query<T extends DatabaseRecord>(model: DatabaseModel, where: DatabaseQuery): Observable<T[]> {
    return from((this.database(model)).query(model, where) as Promise<T[]>);
  }

  public queryUndeleted<T extends DatabaseRecord>(model: DatabaseModel, where: DatabaseQuery): Observable<T[]> {
    return from((this.database(model)).query(model, where) as Promise<T[]>).pipe(map((records: T[]) => {
      return records.filter((record: T) => {
        return isNil(record.deletedAt);
      });
    }));
  }

  public save(model: DatabaseModel, records: DatabaseRecord | DatabaseRecord[]): Observable<void> {
    return from((this.database(model)).connect().then(() => {
      return (this.database(model)).insert(model, records, true);
    }));
  }

  public delete(model: DatabaseModel, record: DatabaseRecord): Observable<void> {
    return from((this.database(model)).connect().then(() => {
      return (this.database(model)).delete(model, record);
    }));
  }

  public deleteByKey(model: DatabaseModel, key: string): Observable<void> {
    return from((this.database(model)).connect().then(() => {
      return (this.database(model)).deleteByKey(model.objectStoreName, key);
    }));
  }

  public count(model: DatabaseModel): Observable<number> {
    return from((this.database(model)).connect().then(() => {
      return (this.database(model)).count(model.objectStoreName);
    }));
  }
}
