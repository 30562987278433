import { WorkoutSession } from 'app/models/workout-session-document.model'
import { Moment } from 'moment'

export class WorkoutStateModel {
  currentExerciseCount?: number // the current eX out of Total exercises
  currentWorkoutIndex?: number
  currentRowIndex?: number
  currentWorkoutSession?: WorkoutSession
  startTime?: Moment
  pauseTime?: Moment
  totalTime?: number // stored in milliseconds
}
