<div *ngIf="isOnline" fxLayout="column" class="programs-container">
  <h4>{{ totalGroups > 0 ? 'Available Groups' : 'No Available Groups Found' }}</h4>
  <app-programs-search [(searchText)]="searchText" (searchTextChanged)="debounceOnSearchEvent($event)"></app-programs-search>
  <mat-paginator [length]="totalGroups" [pageSize]="pageSize" (page)="onPageEvent($event)">
  </mat-paginator>
  <div *ngIf="totalPages > 0 && !isFetching">
    <mat-card class="group-cards" fxLayout="row" *ngFor="let group of groups">
      <div class="info" fxFlex>
        {{ group.name }}
      </div>
      <div class="arrowRight">
        <div *ngIf="isOnline" class="group-button join-group" fxLayout="row" fxLayoutAlign="center">
          <button mat-raised-button color="primary" (click)="onRequestJoin(group)">Join</button>
        </div>
      </div>
    </mat-card>
  </div>
  <div *ngIf="isFetching">
    <mat-card fxLayout="column" fxLayoutGap="10px">
      <div>Fetching Results</div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
  </div>
</div>
