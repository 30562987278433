/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pft-cft-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/angular-plotly.js/angular-plotly.js.ngfactory";
import * as i8 from "angular-plotly.js";
import * as i9 from "./pft-cft-chart.component";
import * as i10 from "../../services/data.service";
var styles_PftCftChartComponent = [i0.styles];
var RenderType_PftCftChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PftCftChartComponent, data: {} });
export { RenderType_PftCftChartComponent as RenderType_PftCftChartComponent };
function View_PftCftChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "margin: auto;"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PftCftChartComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "plotly-plot", [], null, null, null, i7.View_PlotComponent_0, i7.RenderType_PlotComponent)), i1.ɵprd(512, null, i8.PlotlyService, i8.PlotlyService, []), i1.ɵdid(3, 1032192, null, 0, i8.PlotComponent, [i8.PlotlyService, i1.IterableDiffers, i1.KeyValueDiffers], { data: [0, "data"], layout: [1, "layout"], config: [2, "config"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.graph.data; var currVal_1 = _co.graph.layout; var currVal_2 = _co.graph.config; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PftCftChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PFT/CFT Event Performance"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PftCftChartComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PftCftChartComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 4, 0, currVal_0); var currVal_1 = (!_co.loading && _co.graph); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_PftCftChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pft-cft-chart", [], null, null, null, View_PftCftChartComponent_0, RenderType_PftCftChartComponent)), i1.ɵdid(1, 245760, null, 0, i9.PftCftChartComponent, [i10.DataService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PftCftChartComponentNgFactory = i1.ɵccf("app-pft-cft-chart", i9.PftCftChartComponent, View_PftCftChartComponent_Host_0, {}, {}, []);
export { PftCftChartComponentNgFactory as PftCftChartComponentNgFactory };
