import { Observable, fromEvent, merge, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SERVER_DATE_FORMAT = 'MM/DD/YYYY';
var ResourcesRestService = /** @class */ (function () {
    function ResourcesRestService(http) {
        this.http = http;
    }
    ResourcesRestService.prototype.get = function (route, params, root) {
        return this.http.get(this.buildRoute(route, root), { params: params });
    };
    ResourcesRestService.prototype.post = function (route, data, root) {
        return this.http.post(this.buildRoute(route, root), data);
    };
    ResourcesRestService.prototype.delete = function (route, root) {
        return this.http.delete(this.buildRoute(route, root));
    };
    ResourcesRestService.prototype.buildRoute = function (route, root) {
        if (root === void 0) { root = false; }
        function applySubstitutions(url) {
            return url.replace(/{{HOSTNAME}}/gi, location.hostname);
        }
        if (root) {
            return applySubstitutions(environment.api_url) + 'api/' + route;
        }
        return applySubstitutions(environment.api_url) + 'api/Mobile/' + route;
    };
    ResourcesRestService.prototype.handleError = function (e) {
        return throwError(e.message || 'Server error');
    };
    ResourcesRestService.prototype.isOnline = function () {
        var online = !!window.navigator.onLine;
        // console.log('Currently marked as ' + (online ? 'online' : 'offline'))
        return online;
    };
    ResourcesRestService.prototype.isOnlineSubscription = function () {
        return merge(fromEvent(window, 'offline').pipe(map(function () { return false; })), fromEvent(window, 'online').pipe(map(function () { return true; })), Observable.create(function (sub) {
            sub.next(navigator.onLine);
            sub.complete();
        }));
    };
    ResourcesRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResourcesRestService_Factory() { return new ResourcesRestService(i0.ɵɵinject(i1.HttpClient)); }, token: ResourcesRestService, providedIn: "root" });
    return ResourcesRestService;
}());
export { ResourcesRestService };
