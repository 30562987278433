/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rm-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/angular-plotly.js/angular-plotly.js.ngfactory";
import * as i7 from "angular-plotly.js";
import * as i8 from "./rm-chart.component";
import * as i9 from "../../services/document.service";
import * as i10 from "@ngxs/store";
var styles_RmChartComponent = [i0.styles];
var RenderType_RmChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RmChartComponent, data: {} });
export { RenderType_RmChartComponent as RenderType_RmChartComponent };
function View_RmChartComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedRM.name; _ck(_v, 1, 0, currVal_0); }); }
function View_RmChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "legend"], ["fxLayout", "row"], ["fxLayoutGap", "10px"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RmChartComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "plotly-plot", [], null, null, null, i6.View_PlotComponent_0, i6.RenderType_PlotComponent)), i1.ɵprd(512, null, i7.PlotlyService, i7.PlotlyService, []), i1.ɵdid(8, 1032192, null, 0, i7.PlotComponent, [i7.PlotlyService, i1.IterableDiffers, i1.KeyValueDiffers], { data: [0, "data"], layout: [1, "layout"], config: [2, "config"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "10px"; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.selectedRM; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.graph.data; var currVal_4 = _co.graph.layout; var currVal_5 = _co.graph.config; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_RmChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RmChartComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.graph; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RmChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rm-chart", [], null, null, null, View_RmChartComponent_0, RenderType_RmChartComponent)), i1.ɵdid(1, 245760, null, 0, i8.RmChartComponent, [i9.DocumentService, i10.Actions], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RmChartComponentNgFactory = i1.ɵccf("app-rm-chart", i8.RmChartComponent, View_RmChartComponent_Host_0, {}, {}, []);
export { RmChartComponentNgFactory as RmChartComponentNgFactory };
