import { ResourcesComponent } from '../resources/resources/resources.component';
import { MainComponent } from './main.component';
var ɵ0 = { page: 'main' }, ɵ1 = { page: 'resources' };
export var MainRoutes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: MainComponent,
                data: ɵ0,
            },
        ]
    },
    {
        path: 'resources',
        children: [
            {
                path: '',
                component: ResourcesComponent,
                data: ɵ1
            }
        ]
    }
];
export { ɵ0, ɵ1 };
