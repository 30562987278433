<h2 mat-dialog-title>Select Plan</h2>
<mat-dialog-content>
  <div fxLayout="row" style="margin-top: 15px;">
    <mat-form-field fxFlex>
      <mat-select [(ngModel)]="selectedGroup" (ngModelChange)="loadPlans()" name="group" placeholder="Select a group">
        <mat-option (click)="openGroupManagement()">+/- Manage Groups</mat-option>
        <mat-option *ngFor="let group of groups" [value]="group">
          {{ group.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="plans && plans.length">
    <div fxLayout="row">
      <mat-form-field fxFlex>
        <mat-select [(ngModel)]="selectedPlan" name="plan" placeholder="Select a plan">
          <mat-option *ngFor="let plan of plans" [value]="plan">
            {{ plan.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="cancelSelected()" fxFlex>Cancel</button>
  <button class="yes-button" mat-button (click)="submitSelected()" cdkFocusInitial fxFlex>Submit</button>
</mat-dialog-actions>
