/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./programs-joined.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i9 from "@angular/material/card";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/common";
import * as i12 from "../programs-search/programs-search.component.ngfactory";
import * as i13 from "../programs-search/programs-search.component";
import * as i14 from "../../../../../node_modules/@angular/material/paginator/typings/index.ngfactory";
import * as i15 from "@angular/material/paginator";
import * as i16 from "./programs-joined.component";
import * as i17 from "@ngxs/store";
import * as i18 from "../../../services/data.service";
import * as i19 from "@angular/material/dialog";
var styles_ProgramsJoinedComponent = [i0.styles];
var RenderType_ProgramsJoinedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgramsJoinedComponent, data: {} });
export { RenderType_ProgramsJoinedComponent as RenderType_ProgramsJoinedComponent };
function View_ProgramsJoinedComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "group-button leave-group"], ["fxLayout", "row"], ["fxLayoutAlign", "center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["color", "warn"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLeaveDialog(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Leave"]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center"; _ck(_v, 2, 0, currVal_1); var currVal_4 = "warn"; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 4).disabled || null); var currVal_3 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_ProgramsJoinedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "mat-card", [["class", "group-cards mat-card"], ["fxLayout", "row"], ["fxLayoutGap", "10px"]], [[2, "_mat-animation-noopable", null]], null, null, i8.View_MatCard_0, i8.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i9.MatCard, [[2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i10.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "div", [["class", "info"], ["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(5, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "div", [["class", "arrowRight"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgramsJoinedComponent_3)), i1.ɵdid(9, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "row"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "10px"; _ck(_v, 3, 0, currVal_2); var currVal_3 = ""; _ck(_v, 5, 0, currVal_3); var currVal_5 = _co.isOnline; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); var currVal_4 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_4); }); }
function View_ProgramsJoinedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProgramsJoinedComponent_2)), i1.ɵdid(2, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i11.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.filteredGroups, _co.startIndex, _co.stopIndex)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProgramsJoinedComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { paginator: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "programs-container"], ["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-programs-search", [], null, [[null, "searchTextChange"], [null, "searchTextChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchTextChange" === en)) {
        var pd_0 = ((_co.searchText = $event) !== false);
        ad = (pd_0 && ad);
    } if (("searchTextChanged" === en)) {
        var pd_1 = (_co.onSearchEvent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_ProgramsSearchComponent_0, i12.RenderType_ProgramsSearchComponent)), i1.ɵdid(6, 114688, null, 0, i13.ProgramsSearchComponent, [], { searchText: [0, "searchText"] }, { searchTextChanged: "searchTextChanged" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "mat-paginator", [["class", "mat-paginator"]], null, [[null, "page"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPageEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_MatPaginator_0, i14.RenderType_MatPaginator)), i1.ɵdid(8, 245760, [[1, 4]], 0, i15.MatPaginator, [i15.MatPaginatorIntl, i1.ChangeDetectorRef], { length: [0, "length"], pageSize: [1, "pageSize"] }, { page: "page" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgramsJoinedComponent_1)), i1.ɵdid(10, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.searchText; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.totalGroups; var currVal_4 = _co.pageSize; _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = (_co.groups.length > 0); _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.totalGroups > 0) ? "Joined Groups" : "No Joined Groups Found"); _ck(_v, 4, 0, currVal_1); }); }
export function View_ProgramsJoinedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-programs-joined", [], null, null, null, View_ProgramsJoinedComponent_0, RenderType_ProgramsJoinedComponent)), i1.ɵdid(1, 114688, null, 0, i16.ProgramsJoinedComponent, [i17.Store, i18.DataService, i19.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgramsJoinedComponentNgFactory = i1.ɵccf("app-programs-joined", i16.ProgramsJoinedComponent, View_ProgramsJoinedComponent_Host_0, {}, {}, []);
export { ProgramsJoinedComponentNgFactory as ProgramsJoinedComponentNgFactory };
