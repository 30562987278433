import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { Store } from '@ngxs/store'
import { DocumentService } from 'app/services/document.service'
import { WorkoutState } from 'app/state/workout/workout.state'
import * as _ from 'lodash'
import { SaveComments, StartCircuit, StopCircuit, SetCircuitRounds, ChangeExercise, EditSet } from 'app/state'
import { VideoDialogComponent } from 'app/modules/video-dialog/video-dialog.component'
import { MatDialog, MatSnackBar } from '@angular/material'
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component'
import { WorkoutSession } from 'app/models/workout-session-document.model'
import { AppState } from 'app/state/app/app.state'
import { ExerciseProgramDocumentUtils, getDefaultParams, ResourceType } from 'fitforce-document-sync'
import { EditCircuitDialogComponent } from '../edit-circuit-dialog/edit-circuit-dialog.component'
import { SetsRepsLoadPipe } from 'app/pipes/sets-reps-load.pipe'
import { EditSetDialogComponent } from '../edit-set-dialog/edit-set-dialog.component'

@Component({
  selector: 'app-subexercise-circuit',
  templateUrl: './subexercise-circuit.component.html',
  styleUrls: ['./subexercise-circuit.component.scss']
})
export class SubexerciseCircuitComponent implements OnInit {
  @Output() changeTier = new EventEmitter<any>()

  currentWorkoutSession: WorkoutSession
  tier: any
  currentWorkoutIndex: number
  isCircuitComplete: boolean
  hasPrevTier: boolean
  hasNextTier: boolean
  numRoundsComplete: number
  numRoundsMax: number

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private documentService: DocumentService,
    private snackBar: MatSnackBar,
    private setRepsLoadPipe: SetsRepsLoadPipe,
  ) { }

  ngOnInit() {
    this.loadExercise()
  }

  async loadExercise() {
    // Reset circuits
    this.numRoundsComplete = 0
    this.numRoundsMax = 5
    await this.documentService.restoreData().toPromise()
    this.currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession)
    this.currentWorkoutIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex)
    this.tier = this.currentWorkoutSession.workout.tiers[this.currentWorkoutIndex]
    if (!this.tier.circuit) {
      return
    }
    this.onStartCircuit()
    if (this.tier.circuit.num_rounds && this.tier.circuit.num_rounds > 0) {
      this.numRoundsMax = this.tier.circuit.num_rounds
    }
    if (this.tier.circuit.executions.length > 0) {
      const numRounds = this.tier.circuit.executions[0].num_rounds
      if (numRounds >= 0) {
        if (numRounds && numRounds > -1) {
          this.numRoundsComplete = numRounds
        }
      }
    }
    this.updateArrows()
  }

  tierRunStats() {
    const stats = []
    if (this.tier.circuit.num_rounds !== -1) {
      stats.push(`${this.tier.circuit.num_rounds} Rounds`)
    }
    if (this.tier.circuit.rest_time_ms !== -1) {
      const restTime = this.tier.circuit.rest_time_ms / 1000
      stats.push(`${restTime}s Rest`)
    }
    return stats.join(' | ')
  }

  onStartCircuit() {
    this.store.dispatch(new StartCircuit())
  }

  onCompleteCircuit() {
    this.store.dispatch(new StopCircuit())
    this.dialog.open(EditCircuitDialogComponent).afterClosed().subscribe(result => {
      if (result && result.success) {
        this.isCircuitComplete = true
        this.onNextTier()
      }
    })
  }

  openVideo(video: any) {
    this.dialog.open(VideoDialogComponent, {
      panelClass: 'modal-workout-video',
      maxWidth: '100vw',
      width: '100%',
      data: {
        title: video.title,
        videoId: video.asset_id.replace('video:', ''),
      },
    })
  }

  getExerciseVideo(tierRow: any) {
    const exerciseId = tierRow.exercise.exercise_program_id
    const exerciseDetails = _.find(this.documentService.store.exerciseProgram.document.exercises, { id: exerciseId })
    if (exerciseDetails && exerciseDetails.resources && exerciseDetails.resources.length > 0) {
      return _.find(exerciseDetails.resources, { type: ResourceType.Video })
    }
    return
  }

  openCommentsDialog() {
    const dialogRef = this.dialog.open(NotesDialogComponent, {
      maxWidth: '100vw',
      width: '80%',
      data: {
        isTierNote: false,
        isCircuit: true,
      }
    })

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.success) {
        this.store.dispatch(new SaveComments(res.notes, this.currentWorkoutIndex, -1)).subscribe(() => {
          this.snackBar.open('Saved notes.', undefined, {
            duration: 2500
          })
        })
      }
    })
  }

  increaseNumberRoundsComplete() {
    if (!this.numRoundsComplete) {
      this.numRoundsComplete = 0
    }
    this.numRoundsComplete += 1
    if (this.numRoundsComplete > this.numRoundsMax) {
      this.numRoundsComplete = 0
    }
    this.store.dispatch(new SetCircuitRounds(this.numRoundsComplete, this.currentWorkoutIndex))
  }

  updateArrows() {
    this.hasPrevTier = true
    this.hasNextTier = true
    if (this.currentWorkoutIndex === 0) {
      this.hasPrevTier = false
    }
    if (this.currentWorkoutIndex >= this.currentWorkoutSession.workout.tiers.length - 1) {
      this.hasNextTier = false
    }
  }

  getSetsRepsLoad(tierRow: any, rowIndex: number) {
    const exercise = this.documentService.store.exerciseProgram.document.exercises.find(
      ex => ex.id === tierRow.exercise.exercise_program_id
    );
    let setParams = getDefaultParams()
    if (exercise) {
      setParams = ExerciseProgramDocumentUtils.getPossibleParamsForExercise(
        tierRow.exercise.exercise_program_id,
        this.documentService.store.exerciseProgram.document
      )
    }

    // Check if Load exists
    let index = -1
    const userProfile = this.store.selectSnapshot(AppState.userProfile)
    let oneRM: any
    let oneRMIndex: number

    if (tierRow) {
      index = userProfile.stats.exercises.findIndex(e => {
        return e.exercise_id === tierRow.exercise.exercise_program_id
      })
    }
    if (index !== -1) {
      oneRM = userProfile.stats.exercises[index]
      oneRMIndex = index
    }
    const parameterSelections = tierRow.parameters

    return this.setRepsLoadPipe.transform({
      setParams: setParams,
      currentWorkoutSession: this.currentWorkoutSession,
      currentWorkoutIndex: this.currentWorkoutIndex,
      currentRowIndex: rowIndex,
      oneRM: oneRM,
      oneRMIndex: oneRMIndex,
      loadPercentage: parameterSelections['load'],
      hasSet: false,
      joinCharacter: ' | ',
      execution: tierRow.executions[0]
    })
  }

  openEditSet(rowIndex: number) {
    const setIndex = 0;
    const tierIndex = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex)
    const dialogRef = this.dialog.open(EditSetDialogComponent, {
      id: setIndex.toString(),
      maxWidth: '100vw',
      width: '80%',
      data: {
        tierIndex,
        rowIndex,
        setIndex
      }
    })

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.success) {
        this.store.dispatch(new EditSet(tierIndex, rowIndex, setIndex, res.setParamValues, undefined, true)).subscribe(() => {
          this.loadExercise()
        })
      }
    })
  }


  onNextTier() {
    this.store.dispatch(new ChangeExercise(false)).subscribe(() => {
      this.loadExercise()
      this.changeTier.emit(false)
    })
  }

  onPrevTier() {
    this.store.dispatch(new ChangeExercise(true)).subscribe(() => {
      this.loadExercise()
      this.changeTier.emit(true)
    })
  }
}
