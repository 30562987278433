import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Store, Actions, ofActionSuccessful, Select } from '@ngxs/store';
import * as moment from 'moment';
import { DocumentService } from 'app/services/document.service';
import { WorkoutState } from 'app/state/workout/workout.state';
import { timer, Observable, Subscription, interval } from 'rxjs';
import { PauseWorkout, EndWorkout } from 'app/state';
import { Router } from '@angular/router';
import { PageTransitionService, PAGETRANSITIONS } from 'app/services/page-transition.service';
import { tap } from 'rxjs/operators';
var ExerciseComponent = /** @class */ (function () {
    function ExerciseComponent(store, documentService, router, actions$, pageTransitionService) {
        this.store = store;
        this.documentService = documentService;
        this.router = router;
        this.actions$ = actions$;
        this.pageTransitionService = pageTransitionService;
        this.timeElapsed = '00:00';
    }
    ExerciseComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession);
        this.totalTime = this.store.selectSnapshot(WorkoutState.totalTime);
        // 1 * 1000 === 1s
        this.timer$ = timer(1, 1 * 1000).subscribe(function (time) {
            // time represents total seconds elapsed
            _this.updateTimeElapsed();
        });
        this.actions$.pipe(ofActionSuccessful(EndWorkout)).subscribe(function () {
            _this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT);
            _this.router.navigate(['/exercise/complete']);
        });
        this.pollSub = interval(30 * 1000).pipe(tap(function () {
            _this.documentService.pollServer();
        })).subscribe();
    };
    ExerciseComponent.prototype.updateTimeElapsed = function () {
        // Add 1000 ms, (1 second)
        this.totalTime += 1000;
        var seconds = moment.duration(this.totalTime).seconds().toString().padStart(2, '0');
        var minutes = moment.duration(this.totalTime).minutes().toString().padStart(2, '0');
        this.timeElapsed = minutes + ":" + seconds;
    };
    ExerciseComponent.prototype.endWorkout = function () {
        this.store.dispatch(new EndWorkout(this.totalTime));
    };
    ExerciseComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new PauseWorkout(this.totalTime));
        this.timer$.unsubscribe();
        this.pollSub.unsubscribe();
    };
    ExerciseComponent.prototype.beforeUnloadHander = function ($event) {
        this.store.dispatch(new PauseWorkout(this.totalTime));
        // return $event.returnValue = true
    };
    tslib_1.__decorate([
        Select(WorkoutState.currentExerciseCount),
        tslib_1.__metadata("design:type", Observable)
    ], ExerciseComponent.prototype, "currentExerciseCount$", void 0);
    return ExerciseComponent;
}());
export { ExerciseComponent };
