<div class="component" fxLayout="column" fxLayoutGap="5px">
    <div *ngIf="activeUser" class="profile" fxLayout="row" fxLayoutGap="10px">
        <div *ngIf="activeUser.hasProfilePic">
            <!-- o -->
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
            <div>{{ activeUser.rank }}</div>
            <div class="profileName">{{ activeUser.firstName }} {{ activeUser.lastName }}</div>
            <div>{{ activeUser.email }}</div>
        </div>
    </div>
    <div *ngIf="selectedTab === 'workouts'">
        <div class="legend" fxLayout="row" fxLayoutGap="10px">
            <div>Training Load</div>
            <div fxLayoutAlign="center center">
                <mat-icon class="actualColor">stop</mat-icon>Actual</div>
            <div fxLayoutAlign="center center">
                <mat-icon class="targetColor">stop</mat-icon>Target</div>
        </div>
        <mat-divider></mat-divider>
        <app-training-load-chart></app-training-load-chart>
    </div>
    <div *ngIf="selectedTab === 'onerm'">
        <app-rm-chart></app-rm-chart>
    </div>
    <div *ngIf="selectedTab === 'pft'">
        <app-pft-cft-chart></app-pft-cft-chart>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-button-toggle-group name="statsView" aria-label="Stats View" [value]="selectedTab">
            <mat-button-toggle class="buttonToggle" value="workouts" (click)="toggleView('workouts')">Workouts</mat-button-toggle>
            <mat-button-toggle class="buttonToggle" value="pft" (click)="toggleView('pft')">PFT/CFT</mat-button-toggle>
            <mat-button-toggle class="buttonToggle" value="onerm" (click)="toggleView('onerm')">1RM</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div *ngIf="selectedTab === 'workouts'">
        <app-workout-history></app-workout-history>
    </div>
    <div *ngIf="selectedTab === 'pft'">
        <app-pft-cft-scores></app-pft-cft-scores>
    </div>
    <div *ngIf="selectedTab === 'onerm'">
        <app-tab-stats></app-tab-stats>
    </div>
</div>
