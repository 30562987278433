import * as i0 from "@angular/core";
// enum mapping to display names
export var Exercise;
(function (Exercise) {
    Exercise["PULLUPS"] = "Pull-ups";
    Exercise["PUSHUPS"] = "Push-ups";
    Exercise["CRUNCHES"] = "Crunches";
    Exercise["THREE_MILE_RUN"] = "Three-Mile Run";
    Exercise["THREE_MILE_RUN_ALTITUDE"] = "Three-Mile Run (Altitude)";
    Exercise["FIVE_K_ROW"] = "5K Row";
    Exercise["FIVE_K_ROW_ALTITUDE"] = "5K Row (Altitude)";
    Exercise["PLANK"] = "Plank";
    Exercise["MOVEMENT_TO_CONTACT"] = "Movement to Contact (MTC)";
    Exercise["MOVEMENT_TO_CONTACT_ALTITUDE"] = "Movement to Contact (MUC - Altitude)";
    Exercise["AMMUNITION_LIFT"] = "Ammunition Lift";
    Exercise["MANEUVER_UNDER_FIRE"] = "Maneuver Under Fire (MANUF)";
    Exercise["MANEUVER_UNDER_FIRE_ALTITUDE"] = "Manuever Under Fire (MANUF - Altitude)";
})(Exercise || (Exercise = {}));
export var Gender;
(function (Gender) {
    Gender["MALE"] = "m";
    Gender["FEMALE"] = "f";
})(Gender || (Gender = {}));
export var ScoringCategory;
(function (ScoringCategory) {
    ScoringCategory["PFT"] = "pft";
    ScoringCategory["CFT"] = "cft";
})(ScoringCategory || (ScoringCategory = {}));
export var AgeRange;
(function (AgeRange) {
    AgeRange["AGE_17_20"] = "17-20";
    AgeRange["AGE_21_25"] = "21-25";
    AgeRange["AGE_26_30"] = "26-30";
    AgeRange["AGE_31_35"] = "31-35";
    AgeRange["AGE_36_40"] = "36-40";
    AgeRange["AGE_41_45"] = "41-45";
    AgeRange["AGE_46_50"] = "46-50";
    AgeRange["AGE_51"] = "51+";
})(AgeRange || (AgeRange = {}));
var PftCftRubricService = /** @class */ (function () {
    function PftCftRubricService() {
        this.exerciseEnumKeys = [
            Exercise.PULLUPS,
            Exercise.PUSHUPS,
            Exercise.CRUNCHES,
            Exercise.THREE_MILE_RUN,
            Exercise.THREE_MILE_RUN_ALTITUDE,
            Exercise.FIVE_K_ROW
        ];
        this.rubric = {
            'pft': {
                'pullups': {
                    'm': {
                        '17-20': {
                            'max': 20,
                            'min': 4,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 23,
                            'min': 5,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 23,
                            'min': 5,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 23,
                            'min': 5,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 22,
                            'min': 5,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 21,
                            'min': 5,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 20,
                            'min': 4,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 18,
                            'min': 3,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 7,
                            'min': 1,
                            'min-pts': 61,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 9,
                            'min': 1,
                            'min-pts': 61,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 10,
                            'min': 1,
                            'min-pts': 61,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 9,
                            'min': 1,
                            'min-pts': 61,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 8,
                            'min': 1,
                            'min-pts': 61,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 6,
                            'min': 1,
                            'min-pts': 61,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 4,
                            'min': 1,
                            'min-pts': 61,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 3,
                            'min': 1,
                            'min-pts': 61,
                            'max-pts': 100
                        }
                    },
                },
                'pushups': {
                    'm': {
                        '17-20': {
                            'max': 82,
                            'min': 42,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '21-25': {
                            'max': 87,
                            'min': 40,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '26-30': {
                            'max': 84,
                            'min': 39,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '31-35': {
                            'max': 80,
                            'min': 36,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '36-40': {
                            'max': 76,
                            'min': 34,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '41-45': {
                            'max': 72,
                            'min': 30,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '46-50': {
                            'max': 68,
                            'min': 25,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '51+': {
                            'max': 64,
                            'min': 20,
                            'min-pts': 40,
                            'max-pts': 70
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 42,
                            'min': 19,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '21-25': {
                            'max': 48,
                            'min': 18,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '26-30': {
                            'max': 50,
                            'min': 18,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '31-35': {
                            'max': 46,
                            'min': 16,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '36-40': {
                            'max': 43,
                            'min': 14,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '41-45': {
                            'max': 41,
                            'min': 12,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '46-50': {
                            'max': 40,
                            'min': 11,
                            'min-pts': 40,
                            'max-pts': 70
                        },
                        '51+': {
                            'max': 38,
                            'min': 10,
                            'min-pts': 40,
                            'max-pts': 70
                        }
                    }
                },
                'crunches': {
                    'm': {
                        '17-20': {
                            'max': 105,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 110,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 115,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 115,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 110,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 105,
                            'min': 65,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 100,
                            'min': 50,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 100,
                            'min': 40,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 100,
                            'min': 50,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 105,
                            'min': 55,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 110,
                            'min': 60,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 105,
                            'min': 60,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 105,
                            'min': 60,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 100,
                            'min': 55,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 100,
                            'min': 50,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 100,
                            'min': 40,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                },
                '3_mile_run': {
                    'm': {
                        '17-20': {
                            'max': 1080,
                            'min': 1660,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 1080,
                            'min': 1660,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 1080,
                            'min': 1680,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 1080,
                            'min': 1700,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 1080,
                            'min': 1720,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 1110,
                            'min': 1760,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 1140,
                            'min': 1800,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 1170,
                            'min': 1980,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 1260,
                            'min': 1850,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 1260,
                            'min': 1850,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 1260,
                            'min': 1870,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 1260,
                            'min': 1890,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 1260,
                            'min': 1910,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 1290,
                            'min': 1950,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 1320,
                            'min': 2010,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 1350,
                            'min': 2160,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                },
                '3_mile_run_altitude': {
                    'm': {
                        '17-20': {
                            'max': 1170,
                            'min': 1750,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 1170,
                            'min': 1750,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 1170,
                            'min': 1770,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 1170,
                            'min': 1790,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 1170,
                            'min': 1810,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 1200,
                            'min': 1850,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 1230,
                            'min': 1890,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 1260,
                            'min': 2070,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 1350,
                            'min': 1940,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 1350,
                            'min': 1940,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 1350,
                            'min': 1960,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 1350,
                            'min': 1980,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 1350,
                            'min': 2000,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 1380,
                            'min': 2040,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 1410,
                            'min': 2100,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 1440,
                            'min': 2250,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                },
                '5k_row': {
                    'm': {
                        '17-20': {
                            'max': 1080,
                            'min': 1410,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 1095,
                            'min': 1430,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 1110,
                            'min': 1450,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 1125,
                            'min': 1470,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 1140,
                            'min': 1490,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 1155,
                            'min': 1510,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 1175,
                            'min': 1535,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 1200,
                            'min': 1560,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 1260,
                            'min': 1590,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 1275,
                            'min': 1610,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 1290,
                            'min': 1630,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 1305,
                            'min': 1650,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 1320,
                            'min': 1670,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 1335,
                            'min': 1690,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 1355,
                            'min': 1715,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 1380,
                            'min': 1740,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                },
                '5k_row_altitude': {
                    'm': {
                        '17-20': {
                            'max': 1120,
                            'min': 1450,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 1135,
                            'min': 1470,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 1150,
                            'min': 1490,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 1165,
                            'min': 1510,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 1180,
                            'min': 1530,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 1195,
                            'min': 1550,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 1215,
                            'min': 1575,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 1240,
                            'min': 1600,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 1300,
                            'min': 1630,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 1315,
                            'min': 1650,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 1330,
                            'min': 1670,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 1345,
                            'min': 1690,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 1360,
                            'min': 1710,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 1375,
                            'min': 1730,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 1395,
                            'min': 1755,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 1420,
                            'min': 1780,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    }
                },
                'plank': {
                    'm': {
                        '17-20': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 225,
                            'min': 70,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    }
                }
            },
            'cft': {
                'mtc': {
                    'm': {
                        '17-20': {
                            'max': 160,
                            'min': 225,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 158,
                            'min': 225,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 159,
                            'min': 228,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 162,
                            'min': 231,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 165,
                            'min': 238,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 172,
                            'min': 251,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 181,
                            'min': 268,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 185,
                            'min': 307,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 199,
                            'min': 276,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 193,
                            'min': 281,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 190,
                            'min': 285,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 192,
                            'min': 286,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 198,
                            'min': 295,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 205,
                            'min': 298,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 219,
                            'min': 326,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 235,
                            'min': 352,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    }
                },
                'acl': {
                    'm': {
                        '17-20': {
                            'max': 106,
                            'min': 62,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 115,
                            'min': 67,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 116,
                            'min': 67,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 120,
                            'min': 67,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 110,
                            'min': 67,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 106,
                            'min': 66,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 100,
                            'min': 65,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 95,
                            'min': 16,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 66,
                            'min': 30,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 74,
                            'min': 30,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 75,
                            'min': 30,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 72,
                            'min': 30,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 70,
                            'min': 30,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 62,
                            'min': 28,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 53,
                            'min': 26,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 44,
                            'min': 6,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    }
                },
                'manuf': {
                    'm': {
                        '17-20': {
                            'max': 127,
                            'min': 197,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 124,
                            'min': 198,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 125,
                            'min': 202,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 130,
                            'min': 210,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 136,
                            'min': 222,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 143,
                            'min': 239,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 160,
                            'min': 254,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 172,
                            'min': 369,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 175,
                            'min': 293,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 165,
                            'min': 274,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 162,
                            'min': 280,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 169,
                            'min': 284,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 173,
                            'min': 296,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 177,
                            'min': 301,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 215,
                            'min': 306,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 224,
                            'min': 393,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    }
                },
                'mtc_altitude': {
                    'm': {
                        '17-20': {
                            'max': 166,
                            'min': 231,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 164,
                            'min': 231,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 165,
                            'min': 234,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 168,
                            'min': 237,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 171,
                            'min': 244,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 178,
                            'min': 257,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 187,
                            'min': 274,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 191,
                            'min': 311,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 205,
                            'min': 282,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 199,
                            'min': 287,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 196,
                            'min': 291,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 198,
                            'min': 292,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 204,
                            'min': 301,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 211,
                            'min': 304,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 225,
                            'min': 332,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 241,
                            'min': 358,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    }
                },
                'manuf-altitude': {
                    'm': {
                        '17-20': {
                            'max': 135,
                            'min': 205,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 132,
                            'min': 206,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 133,
                            'min': 210,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 138,
                            'min': 218,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 144,
                            'min': 230,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 151,
                            'min': 247,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 168,
                            'min': 262,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 180,
                            'min': 377,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    },
                    'f': {
                        '17-20': {
                            'max': 183,
                            'min': 301,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '21-25': {
                            'max': 173,
                            'min': 282,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '26-30': {
                            'max': 170,
                            'min': 288,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '31-35': {
                            'max': 177,
                            'min': 292,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '36-40': {
                            'max': 181,
                            'min': 304,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '41-45': {
                            'max': 185,
                            'min': 309,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '46-50': {
                            'max': 223,
                            'min': 314,
                            'min-pts': 40,
                            'max-pts': 100
                        },
                        '51+': {
                            'max': 232,
                            'min': 401,
                            'min-pts': 40,
                            'max-pts': 100
                        }
                    }
                }
            }
        };
    }
    // score should be either the number of reps, or the time converted to seconds
    PftCftRubricService.prototype.getScore = function (record, scoringCategory, exercise, gender, age, isTime) {
        var _a = this.getRubricScoringKeys(scoringCategory, exercise, gender, age), max = _a.max, min = _a.min, maxPts = _a.maxPts, minPts = _a.minPts;
        if (!isTime || exercise === Exercise.PLANK) {
            if (record < min) {
                return 0;
            }
            else {
                if (record >= max) {
                    return maxPts;
                }
                else {
                    var step = this.bankersRound(((maxPts - minPts) / (max - min)), 3);
                    var rawScore = record - min;
                    return this.bankersRound(((step * rawScore) + minPts), 0);
                }
            }
        }
        else {
            if (record > min || record === 0) {
                return 0;
            }
            else {
                if (record <= max) {
                    return maxPts;
                }
                else {
                    var step = this.bankersRound(((maxPts - minPts) / (min - max)), 3);
                    var rawScore = min - record;
                    return this.bankersRound(((step * rawScore) + minPts), 0);
                }
            }
        }
    };
    PftCftRubricService.prototype.bankersRound = function (n, d) {
        var x = n * Math.pow(10, d);
        var r = Math.round(x);
        var br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
        return (br / Math.pow(10, d));
    };
    PftCftRubricService.prototype.getNumericRecord = function (str, isTime) {
        if (!str) {
            return undefined;
        }
        if (!isTime) {
            return +str;
        }
        var timeArray = str.split(':');
        if (timeArray.length > 1) {
            var minutes = Number.parseInt(timeArray[0], 10);
            var seconds = Number.parseInt(timeArray[1], 10);
            var totalSeconds = (minutes * 60) + seconds;
            return totalSeconds;
        }
        return +str;
    };
    PftCftRubricService.prototype.getRubricScoringKeys = function (scoringCategory, exercise, gender, age) {
        var pftObj = this.rubric[scoringCategory.valueOf()];
        if (pftObj !== null) {
            var pftEx = pftObj[this.getExerciseKey(exercise)];
            if (pftEx !== null) {
                var ageRanges = pftEx[gender.valueOf()];
                if (ageRanges != null) {
                    var scoreParams = ageRanges[age];
                    var scoringRubric = {
                        max: scoreParams['max'],
                        min: scoreParams['min'],
                        maxPts: scoreParams['max-pts'],
                        minPts: scoreParams['min-pts']
                    };
                    return scoringRubric;
                }
            }
        }
        console.log('error finding possible scores for given combination: ' + scoringCategory + ' exercise named ' + exercise + ' (' + gender + ').');
        return null;
    };
    PftCftRubricService.prototype.getAgeRange = function (age) {
        switch (age) {
            case AgeRange.AGE_17_20.valueOf():
                return AgeRange.AGE_17_20;
            case AgeRange.AGE_21_25.valueOf():
                return AgeRange.AGE_21_25;
            case AgeRange.AGE_26_30.valueOf():
                return AgeRange.AGE_26_30;
            case AgeRange.AGE_31_35.valueOf():
                return AgeRange.AGE_31_35;
            case AgeRange.AGE_36_40.valueOf():
                return AgeRange.AGE_36_40;
            case AgeRange.AGE_41_45.valueOf():
                return AgeRange.AGE_41_45;
            case AgeRange.AGE_46_50.valueOf():
                return AgeRange.AGE_46_50;
            case AgeRange.AGE_51.valueOf():
                return AgeRange.AGE_51;
            default:
                return AgeRange.AGE_17_20;
        }
    };
    PftCftRubricService.prototype.getExerciseKey = function (ex) {
        switch (ex) {
            case Exercise.PULLUPS:
                return 'pullups';
            case Exercise.PUSHUPS:
                return 'pushups';
            case Exercise.CRUNCHES:
                return 'crunches';
            case Exercise.THREE_MILE_RUN:
                return '3_mile_run';
            case Exercise.THREE_MILE_RUN_ALTITUDE:
                return '3_mile_run_altitude';
            case Exercise.FIVE_K_ROW:
                return '5k_row';
            case Exercise.FIVE_K_ROW_ALTITUDE:
                return '5k_row_altitude';
            case Exercise.PLANK:
                return 'plank';
            case Exercise.MOVEMENT_TO_CONTACT:
                return 'mtc';
            case Exercise.MOVEMENT_TO_CONTACT_ALTITUDE:
                return 'mtc_altitude';
            case Exercise.AMMUNITION_LIFT:
                return 'acl';
            case Exercise.MANEUVER_UNDER_FIRE:
                return 'manuf';
            case Exercise.MANEUVER_UNDER_FIRE_ALTITUDE:
                return 'manuf-altitude';
            default:
                console.log('Error! Exercise key not found in scoring rubric!');
                return 'Key not found';
        }
    };
    PftCftRubricService.prototype.getExerciseUnitLabel = function (ex) {
        switch (ex) {
            case Exercise.PULLUPS:
            case Exercise.PUSHUPS:
            case Exercise.CRUNCHES:
            case Exercise.AMMUNITION_LIFT: // CFT
                return 'Reps';
            case Exercise.THREE_MILE_RUN:
            case Exercise.THREE_MILE_RUN_ALTITUDE:
            case Exercise.FIVE_K_ROW:
            case Exercise.FIVE_K_ROW_ALTITUDE:
            case Exercise.PLANK:
            case Exercise.MOVEMENT_TO_CONTACT: // CFT
            case Exercise.MOVEMENT_TO_CONTACT_ALTITUDE: // CFT
            case Exercise.MANEUVER_UNDER_FIRE: // CFT
            case Exercise.MANEUVER_UNDER_FIRE_ALTITUDE: // CFT
                return 'Time';
            default:
                return 'Record';
        }
    };
    PftCftRubricService.prototype.getRubric = function () {
        return this.rubric;
    };
    PftCftRubricService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PftCftRubricService_Factory() { return new PftCftRubricService(); }, token: PftCftRubricService, providedIn: "root" });
    return PftCftRubricService;
}());
export { PftCftRubricService };
