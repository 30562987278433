import * as tslib_1 from "tslib";
import { DatabaseRecord } from '../../classes/database-record.class';
import { SurveyDocument } from './survey-document.model';
import { v4 as uuid } from 'uuid';
var SurveyTemplateDocument = /** @class */ (function (_super) {
    tslib_1.__extends(SurveyTemplateDocument, _super);
    function SurveyTemplateDocument(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        _this.uuid = data['uuid'] || uuid();
        _this.document = new SurveyDocument(data['document']);
        _this.version = data['version'];
        return _this;
    }
    SurveyTemplateDocument.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, [
            'uuid',
            'document',
            'version'
        ]);
    };
    SurveyTemplateDocument.prototype.getDocument = function () {
        return this.getRecords(SurveyDocument);
    };
    SurveyTemplateDocument.objectStoreName = 'surveyTemplateDocument';
    return SurveyTemplateDocument;
}(DatabaseRecord));
export { SurveyTemplateDocument };
