<div mat-dialog-title>Max Set</div>
<mat-dialog-content>
  <p>Notes: {{data.exercise.notes}}</p>
  <mat-form-field>
    <input type="text" matInput [(ngModel)]="data.exercise['1rm'].max_set" placeholder="Max Set" />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button matDialogClose fxFlex>Close</button>
  <button mat-button [matDialogClose]="data.exercise" fxFlex>Save</button>
</mat-dialog-actions>
