import { Component, OnInit } from '@angular/core'
import { Store } from '@ngxs/store'
import { StartWorkout } from 'app/state'
import { PAGETRANSITIONS, PageTransitionService } from 'app/services/page-transition.service'
import { Router } from '@angular/router'
import { DocumentService } from 'app/services/document.service'
import { AppState } from 'app/state/app/app.state'
import * as _ from 'lodash'
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms'
import { Measure, MeasureConfiguration } from 'fitforce-document-sync'
import { environment } from 'environments/environment'

// TODO: move to fitforce-shared
function addMeasures(measureConfiguration: MeasureConfiguration, configsRef: MeasureConfiguration[], measuresRef: Measure[]) {
  if (measureConfiguration.measure_configuration_ids) {
    return _.flatten(
      measureConfiguration.measure_configuration_ids
        .map(id => configsRef.find(mConfig => mConfig.id === id))
        .map(mConfig => addMeasures(mConfig, configsRef, measuresRef))
    )
  }

  if (measureConfiguration.measure_ids) {
    return measureConfiguration.measure_ids
      .map(id => measuresRef.find(m => m.id === id))
      .filter(m => m !== undefined)
  }
}

@Component({
  selector: 'app-preworkout-survey',
  templateUrl: './preworkout-survey.component.html',
  styleUrls: ['./preworkout-survey.component.scss'],
  providers: [],
})
export class PreworkoutSurveyComponent implements OnInit {
  activeWorkoutDocument: any
  measures: Array<any>
  form: FormGroup
  surveyConfiguration: any;

  constructor(
    private store: Store,
    private router: Router,
    private documentService: DocumentService,
    private pageTransitionService: PageTransitionService,
  ) { }

  ngOnInit() {
    this.init()
  }

  async init() {
    await this.documentService.restoreData().toPromise()
    this.setActiveWorkoutDocument()
    this.measures = []
    const surveyConfiguration = this.documentService.store.exerciseProgram.document.measure_configurations
    .find(mc => mc.id === environment.preWktSurveyConfig)
    const measureConfigurations = this.documentService.store.exerciseProgram.document.measure_configurations
    const measures = this.documentService.store.exerciseProgram.document.measures
    if (surveyConfiguration) {
      this.surveyConfiguration = surveyConfiguration;
      const _measures = []
      measures.forEach(measure => {
        if (surveyConfiguration.measure_ids.includes(measure.id)) {
          _measures.push(measure)
        }
      })
      this.measures = _measures;
    }
    this.form = this.buildForm()
  }

  buildForm() {
    const formGroup: FormGroup = new FormGroup({})
    _.each(this.measures, (measure, i) => {
      const formId = measure.id
      const formControl = new FormControl()
      formGroup.addControl(formId, formControl)
    })
    return formGroup
  }

  setActiveWorkoutDocument() {
    const snapshotDay = this.store.selectSnapshot(AppState.activeDaySelected)
    const planDocument = _.find(this.documentService.store.planDocuments, { key: snapshotDay.planUUID })
    this.activeWorkoutDocument = _.find(planDocument.document.workouts, { id: snapshotDay.key })
  }

  goBack() {
    this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.RIGHT)
    this.router.navigate(['/playcard-summary'])
  }

  async startExercise() {
    let measuredResponse: any
    if (this.measures) {
      measuredResponse = {
        measures: this.measures,
        response: this.form.value
      }
    }
    await this.store.dispatch(new StartWorkout(this.activeWorkoutDocument, measuredResponse)).toPromise()
    this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT)
    this.router.navigate(['/exercise'])
  }
}
