import { Component, OnInit, OnDestroy } from '@angular/core';
import { Resource, ResourceType } from 'app/modules/resources/models/resource';
import { ResourceService } from 'app/modules/resources/resource.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ResourcesRestService } from '../resources-rest.service';
import { Observable, Subscription } from 'rxjs';
import { DocumentService } from 'app/services/document.service';
import { PAGETRANSITIONS, PageTransitionService } from 'app/services/page-transition.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  public resources: Resource[] = [
    {
      name: 'Mental Health Care',
      description: 'Receive emergency and non-emergency mental health care now!',
      type: ResourceType.EXTERNAL,
      url: 'https://www.tricare.mil/CoveredServices/Mental/GettingMHCare',
      phone: 'tel:1-877-476-7734'
    }, {
      name: 'Injury Prevention',
      description: 'Learn how to optimize your physical health and decrease your risk for injury, and the recovery time when you do get injured.',
      type: ResourceType.EXTERNAL,
      url: 'https://www.hprc-online.org/physical-fitness/injury-prevention',
      phone: ''
    }, {
      name: 'Recommended Readings',
      description: 'These are recommended readings for Marines and Sailors to help increase physical, mental, social, and spiritual resilience.',
      type: ResourceType.EXTERNAL,
      url: 'https://www.fitness.marines.mil/Portals/211/Resiliency%20Reading%20List_updated%2014AUG2020_1.pdf',
      phone: ''
    }, {
      name: 'Spiritual Fitness',
      description: 'Def: The ability to adhere to beliefs, principles, and values needed to persevere and prevail. Learn more today.',
      type: ResourceType.EXTERNAL,
      url: 'https://www.fitness.marines.mil/Resilience/Spiritual-Fitness/',
      phone: ''
    }, {
      name: 'Online Crisis Chat',
      description: 'Having a crisis? Chat online today! Free and confidential 24/7. The Veterans Crisis Line can help even if you’re not enrolled in VA benefits or health care.',
      type: ResourceType.EXTERNAL,
      url: 'https://www.veteranscrisisline.net/get-help-now/chat/',
      phone: ''
    }, {
      name: 'DHA Meditation Podcast',
      description: 'Meditation has been scientifically proven to reduce stress and anxiety, promote emotional health, and enhance self-awareness.',
      type: ResourceType.EXTERNAL,
      url: 'https://podcasts.apple.com/us/podcast/military-meditation-coach-podcast/id1313813296?mt=2',
      phone: ''
    }, {
      name: 'Total Force Fitness',
      description: 'Are you feeling stressed, angry, sleepy, anxious, or depressed? Know your state and learn how to manage it.',
      type: ResourceType.EXTERNAL,
      url: 'https://health.mil/Military-Health-Topics/Total-Force-Fitness/Psychological-Fitness',
      phone: ''
    }, {
      name: 'DHA Sleep Podcast',
      description: 'Tune into the DHA\'s sleep podcast and be on your way to a better night\'s sleep.',
      type: ResourceType.EXTERNAL,
      url: 'https://podcasts.apple.com/us/podcast/a-better-nights-sleep/id1309347648?mt=2',
      phone: ''
    }, {
      name: 'Become a Volunteer',
      description: 'Get involved in the volunteer opportunities available for active duty personnel and their family members, retirees, veterans, and civilians.',
      type: ResourceType.EXTERNAL,
      url: 'https://usmc-mccs.org/index.cfm/services/activities/volunteering/',
      phone: ''
    }
  ];


  constructor(private resourceService: ResourceService,
    private router: Router,
    private pageTransitionService: PageTransitionService) {}

  ngOnInit() {

  }

  navigateToResources(item: Resource): void {
    if (item.url) {
      this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT);
      this.router.navigate(['/main/' + item.url]);
    }
  }

}
