import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';

import { AppState } from 'app/state/app/app.state';
import { AtpDataService } from 'app/services/atp-data.service';
import { AuthState } from 'app/state/auth/auth.state';
import { MicroUiService } from 'app/services/micro-ui.service';
import { SetActiveStatsTab } from 'app/state';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-my-stats',
  templateUrl: './my-stats.component.html',
  styleUrls: ['./my-stats.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyStatsComponent implements OnInit, OnDestroy {
  @Select(AppState.activeStatsTab)
  activeStatsTab$: Observable<any>;
  _subscription: Subscription;
  atpSub: Subscription = new Subscription();

  selectedTab = 'workouts';
  activeUser: any;

  atpUrl = environment.atpUrl;

  constructor(
    private store: Store,
    private microUiService: MicroUiService,
    private atpService: AtpDataService,
  ) {}

  ngOnInit() {
    this.activeUser = this.store.selectSnapshot(AuthState.activeUser);
    this._subscription = this.activeStatsTab$.subscribe((tab) => {
      this.selectedTab = tab || 'workouts';
    });

    this.atpSub = this.microUiService
      .listenForEvents('Initialized', false)
      .subscribe((ev) => {
        console.log('activeUser', this.activeUser)
        const detail = {
          participantId: this.activeUser.uuid,
          planId: this.atpService.planId ? this.atpService.planId : localStorage.getItem('atpPlanId'),
          gender: this.activeUser.profile && this.activeUser.profile.sex ? this.activeUser.profile.sex : 'Male',
        };
        this.microUiService.dispatchEvent('RenderChart', false, detail);
      });
  }

  toggleView(view: string) {
    if (view === this.selectedTab) {
      return;
    }
    this.store.dispatch(new SetActiveStatsTab(view));
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
    this.atpSub.unsubscribe();
  }
}
