import { Routes } from '@angular/router'
import { GroupManagementComponent } from './group-management.component'
import { GroupViewComponent } from './group-view/group-view.component';

export const GroupManagementRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: GroupManagementComponent,
        data: { page: 'groups' },
      }, {
        path: ':groupId',
        component: GroupViewComponent,
        data: { page: 'groupView' },
      },
    ]
  }
]
