import { Component, OnInit, OnDestroy, HostListener } from '@angular/core'
import { Store, Actions, ofActionSuccessful, Select } from '@ngxs/store'
import * as _ from 'lodash'
import * as moment from 'moment'
import { DocumentService } from 'app/services/document.service'
import { WorkoutState } from 'app/state/workout/workout.state'
import { timer, BehaviorSubject, Observable, Subscription, interval } from 'rxjs'
import { PauseWorkout, EndWorkout } from 'app/state'
import { Router } from '@angular/router'
import { WorkoutSession } from 'app/models/workout-session-document.model'
import { PageTransitionService, PAGETRANSITIONS } from 'app/services/page-transition.service'
import { tap } from 'rxjs/operators'

@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss']
})
export class ExerciseComponent implements OnInit, OnDestroy {
  @Select(WorkoutState.currentExerciseCount) currentExerciseCount$: Observable<number>
  currentWorkoutSession: WorkoutSession
  totalTime: number
  timeElapsed = '00:00'
  timer$: Subscription
  pollSub: Subscription;

  constructor(
    private store: Store,
    private documentService: DocumentService,
    private router: Router,
    private actions$: Actions,
    private pageTransitionService: PageTransitionService,
  ) { }

  ngOnInit() {
    this.currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession)
    this.totalTime = this.store.selectSnapshot(WorkoutState.totalTime)
    // 1 * 1000 === 1s
    this.timer$ = timer(1, 1 * 1000).subscribe((time: number) => {
      // time represents total seconds elapsed
      this.updateTimeElapsed()
    })
    this.actions$.pipe(ofActionSuccessful(EndWorkout)).subscribe(() => {
      this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.LEFT)
      this.router.navigate(['/exercise/complete'])
    })
    this.pollSub = interval(30 * 1000).pipe(tap(() => {
      this.documentService.pollServer()
    })).subscribe()
  }

  updateTimeElapsed() {
    // Add 1000 ms, (1 second)
    this.totalTime += 1000
    const seconds = moment.duration(this.totalTime).seconds().toString().padStart(2, '0')
    const minutes = moment.duration(this.totalTime).minutes().toString().padStart(2, '0')

    this.timeElapsed = `${minutes}:${seconds}`
  }

  endWorkout() {
    this.store.dispatch(new EndWorkout(this.totalTime))
  }

  ngOnDestroy() {
    this.store.dispatch(new PauseWorkout(this.totalTime))
    this.timer$.unsubscribe()
    this.pollSub.unsubscribe()
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHander($event) {
    this.store.dispatch(new PauseWorkout(this.totalTime))
    // return $event.returnValue = true
  }

}
