import { ActivatedRoute, Router } from '@angular/router'
import { Observable, throwError as observableThrowError } from 'rxjs'
import { catchError, map, mergeMap, share, tap } from 'rxjs/operators';

import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SetActiveUser } from 'app/state/auth/auth.actions'
import { Store } from '@ngxs/store'
import { environment } from '../../environments/environment'
import { of } from 'rxjs';

export function applySubstitutions(url) {
  return url.replace(/{{HOSTNAME}}/gi, location.hostname)
}

@Injectable()
export class AuthService {
  currentUser: any = {}
  userUUID: string
  username: string
  authToken: string
  isLoggedIn = false
  approvedEmailDomains: string[] = [];


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private store: Store,
    private sanitizer: DomSanitizer,
  ) {}

  public isAuthenticated(): boolean {
    if (localStorage.getItem('midas-token')) {
      this.isLoggedIn = true
      return true
    }
    return false
  }

  public getToken(): string {
    return localStorage.getItem('midas-token')
  }

  public getUsername(): string {
    return localStorage.getItem('midas-username')
  }

  public getUserUUID(): string {
    return localStorage.getItem('midas-userUUID')
  }

  public getCurrentUser(): any {
    const storedUser = JSON.parse(localStorage.getItem('midas-currentUser'))
    if (storedUser !== null) {
      return storedUser
    }
    return this.currentUser
  }

  async login (username, password) {
    const results: any = await this.httpClient.post(this.buildRoute('api/Auth/login'), {
      email: username,
      password: password
    }).toPromise()

    if (!results.error) {
      this.setMidasTokens(username, results)
      await this.fetchLoginStatus()
    }

    return results
  }

  setMidasTokens(username: string, results: any) {
    this.username = username
    this.authToken = results.auth_token
    this.userUUID = results.user_uuid
    this.isLoggedIn = true
    if (this.isDifferentUser(username)) {
      localStorage.removeItem('last-sync')
    }
    localStorage.setItem('midas-userUUID', this.userUUID)
    localStorage.setItem('midas-username', username)
    localStorage.setItem('midas-token', results.auth_token)
  }

  isDifferentUser(username) {
    return this.getUsername() !== username
  }

  logout () {
    const logoutObs = this.httpClient.post(this.buildRoute('/api/Auth/logout'), {
      responseType: 'json'
    }).pipe(share(), catchError(err => of({})))

    logoutObs.subscribe(() => {
      this.setLoggedOut()
    })

    return logoutObs
  }

  setLoggedOut(): Observable<any> {
    this.isLoggedIn = false
    localStorage.removeItem('last-sync')
    localStorage.removeItem('last-sync-attempt')
    localStorage.removeItem('midas-username')
    localStorage.removeItem('midas-token')
    localStorage.removeItem('midas-userUUID')
    localStorage.removeItem('midas-currentUser')
    return of({})
  }

  async fetchLoginStatus(): Promise<boolean> {
    const token = this.getToken()
    if (token) {
      if (window.navigator.onLine) {
        try {
          const {user, auth_token}: any = await this.httpClient.get(this.buildRoute('/api/Auth/status'), {
            responseType: 'json'
          }).toPromise()
          if (user['email'] && user['email'].toLowerCase() === this.getUsername().toLowerCase()) {
            this.isLoggedIn = true
            localStorage.setItem('midas-token', auth_token)
            this.setActiveUser(user)
            return true
          } else {
            return false
          }
        } catch (e) {
          return false
        }
      } else {
        // Can't check status online, assume logged in
        return true
      }
    }
    return false
  }

  async setActiveUser(status) {
    this.currentUser = {
      uuid: status.uuid || this.currentUser.uuid,
      confirmed: status.confirmed || this.currentUser.confirmed,
      email_domain_approved: status.email_domain_approved || this.currentUser.email_domain_approved,
      email: status.email || this.currentUser.email,
      firstName: status.firstname || this.currentUser.firstName,
      hasProfilePic: !!status.image_name || this.currentUser.hasProfilePic,
      profilePicture: status.profilePicture || this.currentUser.profilePicture,
      image_name: status.image_name || this.currentUser.image_name,
      image_content_type: status.image_content_type || this.currentUser.image_content_type,
      lastName: status.lastname || this.currentUser.lastName,
      rank: status.rank,
      profile: (this.currentUser && this.currentUser.profile && this.currentUser.uuid === status.uuid) ? this.currentUser.profile : {},
    }

    localStorage.setItem('midas-currentUser', JSON.stringify(this.currentUser))
    await this.store.dispatch(new SetActiveUser(this.currentUser)).toPromise()
  }

  sendVerificationEmail(email: string) {
    return this.httpClient.post(this.buildRoute('/api/Auth/sendVerificationEmail'), { email: email });
  }

  saveActiveUser(route: string, userData: any) {
    return this.httpClient.put(this.buildRoute(route), userData).pipe(
      mergeMap(() => this.saveUserImage(userData.profilePicture)),
      tap(() => this.setActiveUser(userData))
    );
  }

  saveUserImage(profilePicture) {
    if (profilePicture) {
      const formData: FormData = new FormData();
      formData.append('file', profilePicture.requestData);
      return this.httpClient.post(
        this.buildRoute('/api/User/' + this.currentUser.uuid + '/image'),
        formData
      )
    } else {
      return of([]);
    }
  }

  /**
   * Get the profile picture for the user from server.
   *
   * @param  {} userUUID - id of the user
   */
  getUserImage(userUUID) {
    return this.httpClient.get(this.buildRoute('/api/User/' + userUUID + '/image'), {
      responseType: 'blob'
    }).pipe(
      map(data => {
        const status = {
          profilePicture: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(data)),
        }
        return status;
    }));
  }

  getApprovedDomains() {
    const url = this.buildRoute('api/Auth/approveddomains');
    return this.httpClient.get(url)
    .pipe(
      map((res: string[]) => {
        this.approvedEmailDomains = res;
      })
    );
  }

  private buildRoute (route: string) {
    route = route.replace(/^\//, '')

    return applySubstitutions(environment.api_url) + route
  }

    /**
   * Change the password of the account.
   *
   * @param  {string} old - old password
   * @param  {string} newPW - new password
   */
    changePassword(old: string, newPW: string) {
      return this.httpClient.post(applySubstitutions(environment.api_url) + 'api/Auth/password', {
        oldPassword: old,
        password: newPW
      }).pipe(
        map(res => {
          localStorage.setItem('midas-token', res['auth_token']);
          return res;
        }),
        catchError((e) => {
          return observableThrowError(e.message || 'Server error');
        }));
    }
}
