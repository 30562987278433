import * as moment from 'moment'
import { Group, Plan, Day } from 'app/models'
import { UserProfile } from 'app/models/user-profile.model'

export class AppStateModel {
  isOnline?: boolean
  tabView?: String
  activeGroup?: Group
  activePlan?: Plan
  activeDate?: moment.Moment
  activeDay?: Day
  activeWorkout?: any
  joinedGroups?: Array<any>
  availableGroupPlans?: Array<any>
  availablePlanDays?: Array<any>
  organizedDays?: any
  dayToWorkout?: any
  lastSyncAttempt?: moment.Moment
  lastSyncDate?: moment.Moment
  isSyncing?: boolean
  userProfile?: UserProfile
  unsyncedWorkoutSessions?: Array<string>
  sycnedWorkoutSessionsForPlan?: Array<string>
  activeStatsTab: string
  mainTabIndex: number
  selectedRM?: any
  showNotification: boolean
}
