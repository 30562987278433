<div fxFlexFill fxLayout="column">
    <h1 mat-dialog-title fxFlex>Today's Alert</h1>
    <div *ngIf="alert" fxFlex mat-dialog-content fxLayout="column">
        <span fxFlex>
         {{ alert.message }}
        </span>
    </div>
    <div mat-dialog-actions fxLayout="row" fxFlex>
        <div fxFlex></div>
        <div fxFlex></div>
        <button mat-raised-button fxFlex color="primary" fxLayoutAlign="center end" mat-dialog-close (click)="open()">Open</button>
        <button mat-raised-button fxFlex color="secondary" fxLayoutAlign="center end" mat-dialog-close (click)="close()">Close</button>
    </div>
</div>