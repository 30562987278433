import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { AppState } from 'app/state/app/app.state';
import { AtpDataService } from 'app/services/atp-data.service';
import { AuthState } from 'app/state/auth/auth.state';
import { MicroUiService } from 'app/services/micro-ui.service';
import { SetActiveStatsTab } from 'app/state';
import { environment } from 'environments/environment';
var MyStatsComponent = /** @class */ (function () {
    function MyStatsComponent(store, microUiService, atpService) {
        this.store = store;
        this.microUiService = microUiService;
        this.atpService = atpService;
        this.atpSub = new Subscription();
        this.selectedTab = 'workouts';
        this.atpUrl = environment.atpUrl;
    }
    MyStatsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activeUser = this.store.selectSnapshot(AuthState.activeUser);
        this._subscription = this.activeStatsTab$.subscribe(function (tab) {
            _this.selectedTab = tab || 'workouts';
        });
        this.atpSub = this.microUiService
            .listenForEvents('Initialized', false)
            .subscribe(function (ev) {
            console.log('activeUser', _this.activeUser);
            var detail = {
                participantId: _this.activeUser.uuid,
                planId: _this.atpService.planId ? _this.atpService.planId : localStorage.getItem('atpPlanId'),
                gender: _this.activeUser.profile && _this.activeUser.profile.sex ? _this.activeUser.profile.sex : 'Male',
            };
            _this.microUiService.dispatchEvent('RenderChart', false, detail);
        });
    };
    MyStatsComponent.prototype.toggleView = function (view) {
        if (view === this.selectedTab) {
            return;
        }
        this.store.dispatch(new SetActiveStatsTab(view));
    };
    MyStatsComponent.prototype.ngOnDestroy = function () {
        this._subscription.unsubscribe();
        this.atpSub.unsubscribe();
    };
    tslib_1.__decorate([
        Select(AppState.activeStatsTab),
        tslib_1.__metadata("design:type", Observable)
    ], MyStatsComponent.prototype, "activeStatsTab$", void 0);
    return MyStatsComponent;
}());
export { MyStatsComponent };
