import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '../custom-material/shared.module'
import { RMCalcDialogComponent } from './rmcalc-dialog.component'

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    RMCalcDialogComponent
  ],
  entryComponents: [RMCalcDialogComponent]
})
export class RMCalcDialogModule { }
