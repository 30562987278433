import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Day, Group, Plan, PlanBlock, PlanExercise, User, WorkoutSession, } from 'app/models';
import { DocumentDatabaseService, ExerciseProgramDocument, PlanDocument, PlanDocumentCache, PlanMetadata, UserLibraryDocument, UserLibraryDocumentCache, WorkoutSessionDocument, } from 'fitforce-document-sync';
import { InitMain, Signout } from 'app/state';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Subscription, forkJoin, from } from 'rxjs';
import { PAGETRANSITIONS, PageTransitionService, } from 'app/services/page-transition.service';
import { Select, Store } from '@ngxs/store';
import { concatMap } from 'rxjs/operators';
import { AboutDialogComponent } from '../dashboard/components/about-dialog/about-dialog.component';
import { AdaptationsStatusComponent } from './adaptations-status/adaptations-status.component';
import { NotificationDialogComponent } from './dialogs/notification-dialog/notification-dialog.component';
import { AppState } from 'app/state/app/app.state';
import { AuthService } from 'app/services/auth.service';
import { AuthState } from 'app/state/auth/auth.state';
import { DataService } from 'app/services/data.service';
import { DatabaseService } from 'app/services/database.service';
import { DocumentService } from 'app/services/document.service';
import { FeedbackDialogComponent } from '../dashboard/components/feedback-dialog/feedback-dialog.component';
import { Location } from '@angular/common';
import { RestService } from 'app/services/rest.service';
import { Router } from '@angular/router';
import { StateReset } from 'ngxs-reset-plugin';
import { WorkoutSessionDBDocument } from 'app/models/workout-session-document.model';
import { WorkoutState } from 'app/state/workout/workout.state';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';
import { ProfileDialogComponent } from './dialogs/profile-dialog/profile-dialog.component';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(router, store, location, pageTransitionService, databaseService, documentDatabaseService, dialog, authService, restService, snackBar, dataService, documentService) {
        this.router = router;
        this.store = store;
        this.location = location;
        this.pageTransitionService = pageTransitionService;
        this.databaseService = databaseService;
        this.documentDatabaseService = documentDatabaseService;
        this.dialog = dialog;
        this.authService = authService;
        this.restService = restService;
        this.snackBar = snackBar;
        this.dataService = dataService;
        this.documentService = documentService;
        this.subs = [];
        this.marineLogo = (environment.base === '/' ? '' : environment.base) +
            '/assets/images/bwGlobeAnchor.svg';
        this.isOnline = true;
        this.applyingAdaptations = localStorage.getItem('applyingAdaptations') ? JSON.parse(localStorage.getItem('applyingAdaptations')) : false;
        this.approvedDomains = [];
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onlineSub = this.restService
            .isOnlineSubscription()
            .subscribe(function (isOnline) {
            _this.isOnline = isOnline;
        });
        this.subs.push(this.authService.getApprovedDomains().pipe(concatMap(function () { return _this.activeUser$; })).subscribe(function (value) {
            var e_1, _a;
            _this.approvedDomains = _this.authService.approvedEmailDomains;
            _this.activeUser = value;
            if (_this.activeUser && _this.activeUser.email) {
                var isValidEmail = false;
                if (_this.activeUser.email_domain_approved) {
                    isValidEmail = true;
                }
                else {
                    try {
                        for (var _b = tslib_1.__values(_this.approvedDomains), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var e = _c.value;
                            if (_this.activeUser.email.includes(e)) {
                                isValidEmail = true;
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }
                if (!isValidEmail) {
                    if (_this.dialog.openDialogs.length === 0) {
                        _this.openProfileDialog(true);
                    }
                }
            }
        }));
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.subs.forEach(function (sub) { return sub.unsubscribe(); });
    };
    HeaderComponent.prototype.onGoBack = function () {
        this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.RIGHT);
        this.location.back();
    };
    HeaderComponent.prototype.onLogout = function () {
        var _this = this;
        this.store.dispatch(new Signout()).subscribe(function () {
            _this.databaseService.connect().then(function () {
                _this.databaseService.clear(Group);
                _this.databaseService.clear(Plan);
                _this.databaseService.clear(Day);
                _this.databaseService.clear(PlanBlock);
                _this.databaseService.clear(PlanExercise);
                _this.databaseService.clear(User);
                _this.databaseService.clear(WorkoutSession);
                _this.databaseService.clear(WorkoutSessionDBDocument);
            });
            _this.documentDatabaseService.connect().then(function () {
                _this.documentDatabaseService.clear(ExerciseProgramDocument);
                _this.documentDatabaseService.clear(PlanDocument);
                _this.documentDatabaseService.clear(PlanDocumentCache);
                _this.documentDatabaseService.clear(PlanMetadata);
                _this.documentDatabaseService.clear(PlanDocumentCache);
                _this.documentDatabaseService.clear(WorkoutSessionDocument);
                _this.documentDatabaseService.clear(UserLibraryDocument);
                _this.documentDatabaseService.clear(UserLibraryDocumentCache);
            });
            _this.store
                .dispatch(new StateReset(AuthState, AppState, WorkoutState))
                .subscribe(function () {
                _this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.RIGHT);
                _this.router.navigate(['login']);
            });
        });
    };
    HeaderComponent.prototype.onRefreshData = function () {
        this.store.dispatch(new InitMain(true));
    };
    HeaderComponent.prototype.openAboutDialog = function () {
        this.dialog.open(AboutDialogComponent);
    };
    HeaderComponent.prototype.openAdaptationsDialog = function () {
        this.dialog.open(AdaptationsStatusComponent, {
            data: { applyingAdaptations: this.applyingAdaptations },
        });
    };
    HeaderComponent.prototype.openSupport = function () {
        window.open(environment.externalSupportHelpUrl, '_blank');
    };
    HeaderComponent.prototype.openFeedbackDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(FeedbackDialogComponent, {
            autoFocus: false,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result.success) {
                var data = {
                    feedback: result.message,
                    feedbackUUID: uuid(),
                };
                forkJoin([
                    _this.restService.post('Mobile/submitFeedback', data, true),
                ]).subscribe(function (res) {
                    var snackBarRef = _this.snackBar.open('Thanks for your feedback!', 'Close', {
                        duration: 3 * 1000,
                    });
                    snackBarRef.onAction().subscribe(function () {
                        snackBarRef.dismiss();
                    });
                });
            }
        });
    };
    HeaderComponent.prototype.openChangePasswordDialog = function () {
        var _this = this;
        this.dialog
            .open(ChangePasswordDialogComponent, {
            width: '400px',
        })
            .afterClosed()
            .subscribe(function (result) {
            if (result) {
                console.log('dialog result', result);
                _this.authService.changePassword(result.current, result.newPW).subscribe(function (response) {
                    if (response['status'].indexOf('success') > -1) {
                        _this.dialog.open(NotificationDialogComponent, {
                            data: {
                                title: 'Success',
                                content: 'Password successfully changed.',
                            },
                        });
                    }
                }, function (e) {
                    _this.dialog.open(NotificationDialogComponent, {
                        data: {
                            title: 'Unsuccessful',
                            content: 'The original password is incorrect or the new password must be at least 15 characters in length.',
                        },
                    });
                });
            }
        });
    };
    HeaderComponent.prototype.openProfileDialog = function (noCancel) {
        var _this = this;
        if (noCancel === void 0) { noCancel = false; }
        var options = {
            height: '98%'
        };
        if (noCancel) {
            options['disableClose'] = true;
        }
        var dialogRef = this.dialog.open(ProfileDialogComponent, options);
        dialogRef
            .afterClosed()
            .pipe(concatMap(function (result) {
            if (result) {
                return _this.dataService
                    .updateUserProfile(_this.authService.currentUser.uuid, result)
                    .pipe(concatMap(function () {
                    return from(_this.authService.fetchLoginStatus());
                }));
            }
            else {
                return of(null);
            }
        }))
            .subscribe();
    };
    tslib_1.__decorate([
        Select(AuthState.activeUser),
        tslib_1.__metadata("design:type", Object)
    ], HeaderComponent.prototype, "activeUser$", void 0);
    return HeaderComponent;
}());
export { HeaderComponent };
