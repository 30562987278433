import * as _ from 'lodash';
import * as moment from 'moment';
import { DatabaseRecord } from '../classes/database-record.class';
import { User } from './user.model';

export class WorkoutSession extends DatabaseRecord {
  static objectStoreName = 'workoutSessions'

  name: string
  groupUUID: string
  planUUID: string
  planDayUUID: string
  blocks: object
  isComplete: boolean
  isGroupWorkout: boolean
  isPreview: boolean
  isSynced: boolean
  isStarted: boolean
  isDeprecated: boolean
  sessionDateTime: string
  requestSurvey: boolean
  version: number

  get users() {
    return this.getForeignKeys(User)
  }

  set users(users: any[]) {
    this.setForiegnRecords(User, users)
  }

  constructor(data: {} = {}) {
    super(data)

    if (_.has(data, 'users')) {
      this.users = data['users']
    }

    if (_.has(data, 'blocks')) {
      this.blocks = data['blocks']
    }
    this.isComplete = data['isComplete'] || false
    this.isGroupWorkout = data['isGroupWorkout'] || false
    this.isSynced = data['isSynced'] || false
    this.isStarted = data['isStarted'] || false
    this.isPreview = data['isPreview'] || false
    this.isDeprecated = data['isDeprecated'] || false

    this.groupUUID = data['groupUUID']
    this.planUUID = data['planUUID']
    this.planDayUUID = data['planDayUUID']
    this.sessionDateTime = data['sessionDateTime'] || moment()
    this.requestSurvey = data['requestSurvey'] || false

    this.name = data['name']
    this.version = data['version']
  }

  toJSON() {
    return super.toJSON([
      'groupUUID', 'planUUID', 'planDayUUID', 'blocks', 'users', 'isComplete',
      'isGroupWorkout', 'isPreview', 'isSynced', 'isStarted', 'isDeprecated', 'sessionDateTime', 'requestSurvey', 'name', 'version'
    ])
  }

  getUsers() {
    return this.getRecords(User)
  }

  isUserCompleted(user) {
    return _.some(this.users, user);
  }
}
