import { Routes } from '@angular/router'
import { ResourcesComponent } from '../resources/resources/resources.component'
import { MainComponent } from './main.component'

export const MainRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: MainComponent,
        data: { page: 'main' },
      },

    ]
  },
  {
    path: 'resources',
    children: [
      {
        path: '',
        component: ResourcesComponent,
        data: { page: 'resources' }
      }
    ]
  }
]
