import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { DatabaseRecord } from '../classes/database-record.class';
var User = /** @class */ (function (_super) {
    tslib_1.__extends(User, _super);
    function User(props) {
        if (props === void 0) { props = {}; }
        var _this = _super.call(this, props) || this;
        _this.email = props['email'];
        _this.firstName = props['firstName'];
        _this.lastName = props['lastName'];
        _this.name = props['name'] || _.join([_this.firstName, _this.lastName], ' ');
        _this.rank = props['rank'] || '';
        _this.isAdmin = props['isAdmin'] || false;
        _this.midas_username = props['midas_username'];
        _this.midas_token = props['midas_token'];
        return _this;
    }
    User.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, ['name', 'email', 'firstName', 'lastName', 'rank', 'isAdmin', 'midas_username']);
    };
    User.objectStoreName = 'users';
    return User;
}(DatabaseRecord));
export { User };
