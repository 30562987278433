import * as _ from 'lodash';
import * as moment from 'moment';
import { DatabaseRecord } from '../classes/database-record.class';
import { Day } from './day.model';

export class Plan extends DatabaseRecord {
  static objectStoreName = 'plans'

  name: string
  description: string
  durationInDays: number
  revision: number
  createdAt: moment.Moment
  updatedAt: moment.Moment
  startDate: moment.Moment
  endDate: moment.Moment
  version: number

  get days () {
    return this.getForeignKeys(Day)
  }

  set days (records: any[]) {
    this.setForiegnRecords(Day,
      records)
  }

  constructor (data: {} = {}) {
    super(data)
    this.name = data['name'] || ''
    this.description = data['description']
    this.durationInDays = data['durationInDays']
    this.revision = data['revision'] || 1
    this.createdAt =  DatabaseRecord.parseDate(data['createdAt'])
    this.updatedAt =  DatabaseRecord.parseDate(data['updatedAt'])
    this.startDate = DatabaseRecord.parseDate(data['startDate'])
    this.endDate = DatabaseRecord.parseDate(data['endDate'])
    this.days = data['days']
    this.version = data['version']
  }

  toJSON () {
    return super.toJSON([
      'name',
      'description',
      'durationInDays',
      'revision',
      'createdAt',
      'updatedAt',
      'startDate',
      'endDate',
      'days',
      'version'
    ])
  }
}
