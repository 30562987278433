import * as tslib_1 from "tslib";
import { OfflineService } from 'fitforce-offline-first';
var DocumentOfflineService = /** @class */ (function (_super) {
    tslib_1.__extends(DocumentOfflineService, _super);
    function DocumentOfflineService() {
        return _super.call(this) || this;
    }
    return DocumentOfflineService;
}(OfflineService));
export { DocumentOfflineService };
