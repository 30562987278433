import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { AuthService } from 'app/services/auth.service';
import { AuthState } from 'app/state/auth/auth.state';
import { environment } from 'environments/environment';
import { last } from 'rxjs/operators';
var ProfileDialogComponent = /** @class */ (function () {
    function ProfileDialogComponent(data, dialogRef, authService, sanitizer, router) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.authService = authService;
        this.sanitizer = sanitizer;
        this.router = router;
        this.approvedDomains = '';
        this.requestAccountPath = environment.requestUrl || '/account/request';
        this.approvedEmailsRegex = new RegExp('[^\s@]+@(aptima.com)');
    }
    ProfileDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.getApprovedDomains().subscribe(function () {
            _this.approvedDomains = _this.authService.approvedEmailDomains.join(', ');
            _this.approvedEmailsRegex = new RegExp('[^\\s@]+@(' + _this.authService.approvedEmailDomains.join('|') + ')');
        });
        this.activeUser$.subscribe(function (u) {
            _this.activeUser = u;
            _this.firstName = u.firstName;
            _this.lastName = u.lastName;
            _this.rank = u.rank;
            _this.email = u.email;
            _this.hasProfilePic = u.hasProfilePic;
            _this.authService.getUserImage(_this.activeUser.uuid).pipe(last()).subscribe(function (pp) {
                if (pp && pp.profilePicture) {
                    _this.profilePicture = pp.profilePicture;
                }
            });
            _this.unapprovedEmail = !_this.isApprovedEmail(_this.email, u);
        });
    };
    ProfileDialogComponent.prototype.isApprovedEmail = function (email, user) {
        var e_1, _a;
        if (user && user.email_domain_approved) {
            return true;
        }
        var approved = false;
        try {
            for (var _b = tslib_1.__values(this.approvedDomains), _c = _b.next(); !_c.done; _c = _b.next()) {
                var e = _c.value;
                if (email.includes(e)) {
                    approved = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return approved;
    };
    ProfileDialogComponent.prototype.applySubstitutions = function (url) {
        // const myUrl = url.replace(/{{HOSTNAME}}/gi, 'midas.aptima.com');
        var myUrl = url.replace(/{{HOSTNAME}}/gi, location.hostname);
        return myUrl;
    };
    ProfileDialogComponent.prototype.onSave = function () {
        var _this = this;
        var result = {
            firstname: this.firstName,
            lastname: this.lastName,
            rank: this.rank ? this.rank : '',
            email: this.activeUser.email === this.email ? undefined : this.email
        };
        if (this.profilePictureRequest) {
            result['profilePicture'] = {
                requestData: this.profilePictureRequest,
                imageDataUrl: this.profilePicture
            };
        }
        this.saveProfileUpdates(result).subscribe(function () {
            _this.dialogRef.close();
            if (_this.email !== result.email) {
                _this.router.navigate(['login']);
            }
        });
    };
    ProfileDialogComponent.prototype.readUrl = function (event) {
        var _this = this;
        if (event.target.files && event.target.files[0]) {
            // console.log(event.target.files[0]);
            if (event.target.files[0].type !== 'image/jpg' &&
                event.target.files[0].type !== 'image/png' &&
                event.target.files[0].type !== 'image/jpeg') {
                this.imageError = 'The image must of of file type .jpg, .jpeg, or .png';
            }
            else {
                this.imageError = '';
                var reader = new FileReader();
                reader.onload = function (e) {
                    _this.profilePicture = _this.sanitizer.bypassSecurityTrustUrl(e.target.result);
                };
                reader.readAsDataURL(event.target.files[0]);
                this.profilePictureRequest = event.target.files[0];
            }
        }
    };
    /**
     * Update the profile info of the user, including profile picture.
     *
     * @param  {} userData - data to update
     */
    ProfileDialogComponent.prototype.saveProfileUpdates = function (userData) {
        return this.authService.saveActiveUser("/api/User/" + this.activeUser.uuid, userData);
    };
    tslib_1.__decorate([
        Select(AuthState.activeUser),
        tslib_1.__metadata("design:type", Object)
    ], ProfileDialogComponent.prototype, "activeUser$", void 0);
    return ProfileDialogComponent;
}());
export { ProfileDialogComponent };
