/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./about-dialog.component";
var styles_AboutDialogComponent = [];
var RenderType_AboutDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AboutDialogComponent, data: {} });
export { RenderType_AboutDialogComponent as RenderType_AboutDialogComponent };
export function View_AboutDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(-1, null, ["About FitForce"])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "mat-dialog-content", [["class", "mat-dialog-content"], ["fxLayout", "column"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.MatDialogContent, [], null, null), i0.ɵdid(5, 671744, null, 0, i2.DefaultLayoutDirective, [i0.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["Version: ", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 6, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 4, "button", [["color", "primary"], ["fxFlex", ""], ["mat-dialog-close", ""], ["mat-raised-button", ""]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).dialogRef.close(i0.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i0.ɵdid(11, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i0.ɵdid(12, 180224, null, 0, i5.MatButton, [i0.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i0.ɵdid(13, 671744, null, 0, i2.DefaultFlexDirective, [i0.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i0.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_1 = "column"; _ck(_v, 5, 0, currVal_1); var currVal_7 = ""; _ck(_v, 11, 0, currVal_7); var currVal_8 = "primary"; _ck(_v, 12, 0, currVal_8); var currVal_9 = ""; _ck(_v, 13, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.version; _ck(_v, 7, 0, currVal_2); var currVal_3 = (i0.ɵnov(_v, 11).ariaLabel || null); var currVal_4 = i0.ɵnov(_v, 11).type; var currVal_5 = (i0.ɵnov(_v, 12).disabled || null); var currVal_6 = (i0.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_AboutDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-about-dialog", [], null, null, null, View_AboutDialogComponent_0, RenderType_AboutDialogComponent)), i0.ɵdid(1, 49152, null, 0, i8.AboutDialogComponent, [i1.MatDialogRef, i1.MAT_DIALOG_DATA], null, null)], null, null); }
var AboutDialogComponentNgFactory = i0.ɵccf("app-about-dialog", i8.AboutDialogComponent, View_AboutDialogComponent_Host_0, {}, {}, []);
export { AboutDialogComponentNgFactory as AboutDialogComponentNgFactory };
