import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { ClearActiveGroup, InitMain } from 'app/state';
import { OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { DataService } from 'app/services/data.service';
import { LeaveDialogComponent } from '../leave-dialog/leave-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestService } from 'app/services/rest.service';
import { Store } from '@ngxs/store';
var GroupViewComponent = /** @class */ (function () {
    function GroupViewComponent(router, route, store, dataService, authService, restService, dialog, spinner) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.store = store;
        this.dataService = dataService;
        this.authService = authService;
        this.restService = restService;
        this.dialog = dialog;
        this.spinner = spinner;
        this.isOnline = false;
        this.isRequested = false;
        this.onlineSub = this.restService.isOnlineSubscription().subscribe(function (isOnline) { return _this.isOnline = isOnline; });
    }
    GroupViewComponent.prototype.ngOnInit = function () {
        this.loadData();
    };
    GroupViewComponent.prototype.loadData = function () {
        var _this = this;
        this.route.params.subscribe(function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var groupKey;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        groupKey = params.groupId;
                        return [4 /*yield*/, this.loadGroup(groupKey)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    GroupViewComponent.prototype.loadGroup = function (groupKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.dataService.getGroup(groupKey)];
                    case 1:
                        _a.group = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.dataService.getOwnersInfo(this.group.owners).toPromise()];
                    case 2:
                        _b.owners = _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GroupViewComponent.prototype.isJoined = function () {
        return _.includes(this.group.users, this.authService.getUserUUID());
    };
    GroupViewComponent.prototype.requestJoin = function () {
        var _this = this;
        this.spinner.show();
        this.dataService.groupRequestInvite(this.group.key, this.authService.getUsername()).subscribe(function (res) {
            var alreadyInvited = false;
            _this.group.users = _.map(res.members, function (v, i) {
                if (v.uuid === _this.authService.getUserUUID()) {
                    alreadyInvited = true;
                }
                return v.uuid;
            });
            _this.dataService.update(_this.group);
            // Resync data for the plans
            _this.store.dispatch(new InitMain(true)).subscribe(function () {
                _this.spinner.hide();
                _this.isRequested = true && !alreadyInvited;
            });
        });
    };
    GroupViewComponent.prototype.requestLeave = function () {
        var _this = this;
        this.spinner.show();
        this.store.dispatch(new ClearActiveGroup());
        this.dataService.groupRequestLeave(this.group.key, this.authService.getUserUUID()).subscribe(function (res) {
            _this.group.users = _.map(res.members, function (v, i) {
                return v.uuid;
            });
            _this.deleteFromGroup(_this.authService.getUserUUID());
        });
    };
    GroupViewComponent.prototype.deleteFromGroup = function (userId) {
        var _this = this;
        _.remove(this.group.users, function (user) {
            return user === userId;
        });
        this.dataService.update(this.group).then(function () {
            _this.spinner.hide();
        });
    };
    GroupViewComponent.prototype.openLeaveDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(LeaveDialogComponent, {
            panelClass: 'modal-leaveConfirm',
        });
        dialogRef.afterClosed().subscribe(function (isLeaving) {
            if (isLeaving) {
                _this.requestLeave();
            }
        });
    };
    GroupViewComponent.prototype.ngOnDestroy = function () {
        this.onlineSub.unsubscribe();
    };
    return GroupViewComponent;
}());
export { GroupViewComponent };
