import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { SharedModule } from '../custom-material/shared.module'
import { NgxSpinnerModule } from 'ngx-spinner';
import { PlayCardSummaryRoutes } from './playcard-summary.routing'
import { PlaycardSummaryComponent } from './playcard-summary.component'
import { VideoDialogComponent } from '../video-dialog/video-dialog.component'
import { VideoDialogModule } from '../video-dialog/video-dialog.module';
import { MStoTimePipe } from '../main/pipes/ms-to-time.pipe';
import { MainModule } from '../main/main.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(PlayCardSummaryRoutes),
    NgxSpinnerModule,
    VideoDialogModule,
    MainModule,
  ],
  declarations: [
    PlaycardSummaryComponent,
  ],
  entryComponents: []
})
export class PlaycardSummaryModule { }
