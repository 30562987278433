import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ProgramsComponent } from './programs.component'
import { SharedModule } from '../custom-material/shared.module'
import { RouterModule } from '@angular/router'
import { GroupManagementRoutes } from '../group-management/group-management.routing'
import { NgxSpinnerModule } from 'ngx-spinner'
import { HeaderModule } from '../header/header.module';
import { ProgramsJoinedComponent } from './programs-joined/programs-joined.component'
import { LeaveDialogComponent } from '../group-management/leave-dialog/leave-dialog.component';
import { ProgramsPublicComponent } from './programs-public/programs-public.component';
import { FilterGroupsPipe } from './pipes/filter-groups.pipe';
import { ProgramsSearchComponent } from './programs-search/programs-search.component'

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(GroupManagementRoutes),
    NgxSpinnerModule,
    HeaderModule,
  ],
  declarations: [
    ProgramsComponent,
    ProgramsJoinedComponent,
    LeaveDialogComponent,
    ProgramsPublicComponent,
    FilterGroupsPipe,
    ProgramsSearchComponent,
  ],
  entryComponents: [
    LeaveDialogComponent,
  ],
  exports: [
    ProgramsComponent
  ]
})
export class ProgramsModule { }
