import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { DatabaseRecord } from '../classes/database-record.class';
import { User } from './user.model';
var WorkoutSession = /** @class */ (function (_super) {
    tslib_1.__extends(WorkoutSession, _super);
    function WorkoutSession(data) {
        if (data === void 0) { data = {}; }
        var _this = _super.call(this, data) || this;
        if (_.has(data, 'users')) {
            _this.users = data['users'];
        }
        if (_.has(data, 'blocks')) {
            _this.blocks = data['blocks'];
        }
        _this.isComplete = data['isComplete'] || false;
        _this.isGroupWorkout = data['isGroupWorkout'] || false;
        _this.isSynced = data['isSynced'] || false;
        _this.isStarted = data['isStarted'] || false;
        _this.isPreview = data['isPreview'] || false;
        _this.isDeprecated = data['isDeprecated'] || false;
        _this.groupUUID = data['groupUUID'];
        _this.planUUID = data['planUUID'];
        _this.planDayUUID = data['planDayUUID'];
        _this.sessionDateTime = data['sessionDateTime'] || moment();
        _this.requestSurvey = data['requestSurvey'] || false;
        _this.name = data['name'];
        _this.version = data['version'];
        return _this;
    }
    Object.defineProperty(WorkoutSession.prototype, "users", {
        get: function () {
            return this.getForeignKeys(User);
        },
        set: function (users) {
            this.setForiegnRecords(User, users);
        },
        enumerable: true,
        configurable: true
    });
    WorkoutSession.prototype.toJSON = function () {
        return _super.prototype.toJSON.call(this, [
            'groupUUID', 'planUUID', 'planDayUUID', 'blocks', 'users', 'isComplete',
            'isGroupWorkout', 'isPreview', 'isSynced', 'isStarted', 'isDeprecated', 'sessionDateTime', 'requestSurvey', 'name', 'version'
        ]);
    };
    WorkoutSession.prototype.getUsers = function () {
        return this.getRecords(User);
    };
    WorkoutSession.prototype.isUserCompleted = function (user) {
        return _.some(this.users, user);
    };
    WorkoutSession.objectStoreName = 'workoutSessions';
    return WorkoutSession;
}(DatabaseRecord));
export { WorkoutSession };
