import * as moment from 'moment';

// tslint:disable-next-line: max-line-length
import {
  DocumentRestService,
  DocumentSyncService,
  ExerciseProgramDocument,
  ObservationDocument,
  Plan,
  PlanDocument,
  PlanMetadata,
  WorkoutSessionDocument,
} from 'fitforce-document-sync';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { AtpDataService } from './atp-data.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { OfflineService } from './offline.service';
import { SurveyTemplateDocument } from '../models/survey-template-document/survey-template-document.model';
import { SyncLoggingService } from 'fitforce-logging';
import { environment } from 'environments/environment';

interface AppDocumentStore {
  exerciseProgram: ExerciseProgramDocument;
  planDocuments: PlanDocument[];
  planMetadata: PlanMetadata[];
  workoutSessionDocuments: WorkoutSessionDocument[];
  observations: ObservationDocument[];
  surveyTemplateDocuments: SurveyTemplateDocument[];
}

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends DocumentSyncService {
  store: AppDocumentStore;
  planUpdates$: Subject<any> = new Subject<any>();

  constructor(
    documentRestService: DocumentRestService,
    offline: OfflineService,
    syncLog: SyncLoggingService,
    private authService: AuthService,
  ) {
    super(documentRestService, offline, syncLog);

    this.documentRestService.baseUrl = environment.api_url;

    this.store = {
      exerciseProgram: undefined,
      planDocuments: undefined,
      planMetadata: [],
      workoutSessionDocuments: [],
      observations: undefined,
      surveyTemplateDocuments: []
    };

    this.planUpdates.subscribe(res => {
      this.planUpdates$.next(res);
    });
  }

  async pollServer() {
    if (this.offline.isOnline()) {
      await this.authService.fetchLoginStatus()
    }
  }

  /**
   * Fetches all documents for the current user, saves to offline database, and restores service state.
   */
  fetchDocuments(groupUUIDs: string[]): Observable<void> {
    // use offline-first-data.service to pull exercise program & plan list for current user and save to offline db
    if (!groupUUIDs || groupUUIDs.length === 0) {
      return this.pullExercisePrograms().pipe(
        concatMap(() => this.pullWorkoutSessions()),
        concatMap(() => this.restoreData()),
      );
    }
    return this.pull(groupUUIDs).pipe(
      concatMap(() => {
        // get all plan metadata from offline db and if any metadata exists, pull plan documents from server
        return this.offline.getAll<PlanMetadata>(PlanMetadata).pipe(
          concatMap(metadata => {
            if (metadata && metadata.length > 0) {
              const requests = metadata.map(plan =>
                this.pullPlans([plan.uuid]),
              );
              return forkJoin(requests);
            } else {
              return of(null);
            }
          }),
        );
      }),
      concatMap(() => {
        // pull all workout session docs for the current user and save offline
        return forkJoin(this.pullWorkoutSessions(), this.pullObservations());
      }),
      // restore service state from offline db
      concatMap(() => this.restoreData()),
    );
  }

  pushDocuments(): Observable<void> {
    return forkJoin(this.pushWorkoutSessions(), this.pushObservations()).pipe(
      map(() => null),
    );
  }

  insertObservationDocument(
    observationDocument: ObservationDocument,
  ): Observable<void> {
    return this.offline.save(ObservationDocument, observationDocument);
  }

  insertWorkoutSessionDocument(workout: any) {
    return this.offline.save(
      WorkoutSessionDocument,
      workout as WorkoutSessionDocument,
    );
  }

  insertSurveyTemplateDocument(surveyTemplateDocument: SurveyTemplateDocument): void {
    this.store.surveyTemplateDocuments.push(surveyTemplateDocument);
  }

  // TODO: add more state management here

  /**
   * Restores service state from offline db
   */
  restoreData(): Observable<any> {
    return forkJoin(
      this.restoreExerciseProgram(),
      this.restorePlanMetadata(),
      this.restorePlans(),
      this.restoreWorkoutSessions(),
      this.restoreObservations(),
    );
  }

  /**
   * Restores exercise program document to service state.
   */
  private restoreExerciseProgram(): Observable<void> {
    return this.offline
      .getAll<ExerciseProgramDocument>(ExerciseProgramDocument)
      .pipe(
        map(exercisePrograms => {
          this.store.exerciseProgram =
            exercisePrograms || exercisePrograms.length > 0
              ? exercisePrograms[0]
              : null;

          return null;
        }),
      );
  }

  /**
   * Restores plan metadata to service state.
   */
  private restorePlanMetadata(): Observable<void> {
    return this.offline.getAll<PlanMetadata>(PlanMetadata).pipe(
      map(planMetadata => {
        this.store.planMetadata = planMetadata;
        return null;
      }),
    );
  }

  /**
   * Restores plan documents to service state.
   */
  private restorePlans(): Observable<void> {
    return this.offline.getAll<PlanDocument>(PlanDocument).pipe(
      map(planDocuments => {
        this.store.planDocuments = planDocuments;
        return null;
      }),
    );
  }

  private restoreWorkoutSessions(): Observable<void> {
    return this.offline
      .getAll<WorkoutSessionDocument>(WorkoutSessionDocument)
      .pipe(
        map(docs => {
          this.store.workoutSessionDocuments = docs;
          return null;
        }),
      );
  }

  private restoreObservations(): Observable<void> {
    return this.offline.getAll<ObservationDocument>(ObservationDocument).pipe(
      map(docs => {
        this.store.observations = docs;
        return null;
      }),
    );
  }
}
