import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Day,
  Group,
  Plan,
  PlanBlock,
  PlanExercise,
  User,
  WorkoutSession,
} from 'app/models';
import {
  DocumentDatabaseService,
  ExerciseProgramDocument,
  PlanDocument,
  PlanDocumentCache,
  PlanMetadata,
  UserLibraryDocument,
  UserLibraryDocumentCache,
  WorkoutSessionDocument,
} from 'fitforce-document-sync';
import { InitMain, Signout } from 'app/state';
import { MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { Observable, Subscription, forkJoin, from } from 'rxjs';
import {
  PAGETRANSITIONS,
  PageTransitionService,
} from 'app/services/page-transition.service';
import { Select, Store } from '@ngxs/store';
import { concatMap, map } from 'rxjs/operators';

import { AboutDialogComponent } from '../dashboard/components/about-dialog/about-dialog.component';
import { AdaptationsStatusComponent } from './adaptations-status/adaptations-status.component';
import { NotificationDialogComponent } from './dialogs/notification-dialog/notification-dialog.component';
import { AppState } from 'app/state/app/app.state';
import { AuthService } from 'app/services/auth.service';
import { AuthState } from 'app/state/auth/auth.state';
import { DataService } from 'app/services/data.service';
import { DatabaseService } from 'app/services/database.service';
import { DocumentService } from 'app/services/document.service';
import { FeedbackDialogComponent } from '../dashboard/components/feedback-dialog/feedback-dialog.component';
import { Location } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { RestService } from 'app/services/rest.service';
import { Router } from '@angular/router';
import { StateReset } from 'ngxs-reset-plugin';
import { WorkoutSessionDBDocument } from 'app/models/workout-session-document.model';
import { WorkoutState } from 'app/state/workout/workout.state';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog.component';
import { ProfileDialogComponent } from './dialogs/profile-dialog/profile-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  marineLogo =
    (environment.base === '/' ? '' : environment.base) +
    '/assets/images/bwGlobeAnchor.svg';

  @Select(AuthState.activeUser) activeUser$;
  activeUser;
  isOnline = true;
  onlineSub: Subscription;
  applyingAdaptations = localStorage.getItem('applyingAdaptations') ? JSON.parse(localStorage.getItem('applyingAdaptations')) : false;
  approvedDomains = [];

  @Input() hasGoBack: boolean;
  @Input() hasRefresh: boolean;
  @Input() hasLogout: boolean;

  constructor(
    private router: Router,
    private store: Store,
    private location: Location,
    private pageTransitionService: PageTransitionService,
    private databaseService: DatabaseService,
    private documentDatabaseService: DocumentDatabaseService,
    private dialog: MatDialog,
    private authService: AuthService,
    private restService: RestService,
    private snackBar: MatSnackBar,
    private dataService: DataService,
    private documentService: DocumentService,
  ) {}

  ngOnInit() {
    this.onlineSub = this.restService
      .isOnlineSubscription()
      .subscribe(isOnline => {
        this.isOnline = isOnline;
      });

    this.subs.push(
      this.authService.getApprovedDomains().pipe(concatMap(() => this.activeUser$)).subscribe(value => {
        this.approvedDomains = this.authService.approvedEmailDomains;

        this.activeUser = value;
        if (this.activeUser && this.activeUser.email) {
          let isValidEmail = false;
          if (this.activeUser.email_domain_approved) {
            isValidEmail = true;
          } else {
            for (const e of this.approvedDomains) {
              if ((this.activeUser.email as string).includes(e)) {
                isValidEmail = true;
              }
            }
          }
          if (!isValidEmail) {
            if (this.dialog.openDialogs.length === 0) {
              this.openProfileDialog(true)
            }
          }
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  onGoBack() {
    this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.RIGHT);
    this.location.back();
  }

  onLogout() {
    this.store.dispatch(new Signout()).subscribe(() => {
      this.databaseService.connect().then(() => {
        this.databaseService.clear(Group);
        this.databaseService.clear(Plan);
        this.databaseService.clear(Day);
        this.databaseService.clear(PlanBlock);
        this.databaseService.clear(PlanExercise);
        this.databaseService.clear(User);
        this.databaseService.clear(WorkoutSession);
        this.databaseService.clear(WorkoutSessionDBDocument);
      });
      this.documentDatabaseService.connect().then(() => {
        this.documentDatabaseService.clear(ExerciseProgramDocument);
        this.documentDatabaseService.clear(PlanDocument);
        this.documentDatabaseService.clear(PlanDocumentCache);
        this.documentDatabaseService.clear(PlanMetadata);
        this.documentDatabaseService.clear(PlanDocumentCache);
        this.documentDatabaseService.clear(WorkoutSessionDocument);
        this.documentDatabaseService.clear(UserLibraryDocument);
        this.documentDatabaseService.clear(UserLibraryDocumentCache);
      });
      this.store
        .dispatch(new StateReset(AuthState, AppState, WorkoutState))
        .subscribe(() => {
          this.pageTransitionService.setCurrentAnimation(PAGETRANSITIONS.RIGHT);
          this.router.navigate(['login']);
        });
    });
  }

  onRefreshData() {
    this.store.dispatch(new InitMain(true));
  }

  openAboutDialog() {
    this.dialog.open(AboutDialogComponent);
  }

  openAdaptationsDialog() {
    this.dialog.open(AdaptationsStatusComponent, {
      data: { applyingAdaptations: this.applyingAdaptations },
    });
  }

  openSupport() {
    window.open(environment.externalSupportHelpUrl, '_blank');
  }

  openFeedbackDialog() {
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        const data = {
          feedback: result.message,
          feedbackUUID: uuid(),
        };

        forkJoin([
          this.restService.post('Mobile/submitFeedback', data, true),
          // this.restService.submitFeedbackExternal(
          //   result.message,
          //   this.activeUser.firstName,
          //   this.activeUser.lastName,
          //   this.activeUser.email,
          //   this.activeUser.rank,
          // ),
        ]).subscribe(res => {
          const snackBarRef = this.snackBar.open(
            'Thanks for your feedback!',
            'Close',
            {
              duration: 3 * 1000,
            },
          );

          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
          });
        });
      }
    });
  }

  openChangePasswordDialog() {
    this.dialog
      .open(ChangePasswordDialogComponent, <MatDialogConfig> {
        width: '400px',
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          console.log('dialog result', result);
          this.authService.changePassword(result.current, result.newPW).subscribe(
            (response: any) => {
              if (response['status'].indexOf('success') > -1) {
                this.dialog.open(NotificationDialogComponent, <MatDialogConfig> {
                  data: {
                    title: 'Success',
                    content: 'Password successfully changed.',
                  },
                });
              }
            },
            e => {
              this.dialog.open(NotificationDialogComponent, <MatDialogConfig> {
                data: {
                  title: 'Unsuccessful',
                  content: 'The original password is incorrect or the new password must be at least 15 characters in length.',
                },
              });
            },
          );
        }
      });
  }

  openProfileDialog(noCancel = false) {
    const options = {
      height: '98%'
    };
    if (noCancel) {
      options['disableClose'] = true;
    }
    const dialogRef = this.dialog.open(ProfileDialogComponent, options);
    dialogRef
      .afterClosed()
      .pipe(
        concatMap(result => {
          if (result) {
            return this.dataService
              .updateUserProfile(this.authService.currentUser.uuid, result)
              .pipe(
                concatMap(() => {
                  return from(this.authService.fetchLoginStatus());
                }),
              );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }
}
