import * as _ from 'lodash';
import * as moment from 'moment';
import { DatabaseRecord } from '../classes/database-record.class'

export class PlanExercise extends DatabaseRecord {
  static objectStoreName = 'plannedExercises'

  name: string
  sets: string
  distance: string
  time: string
  load: string
  reps: string
  hold: string
  rowNum: number
  notes: string
  regression: string
  isComplete = false
  setsCompleted: string
  distanceCompleted: string
  timeCompleted: string
  loadCompleted: string
  repsCompleted: string
  video: object

  constructor (data: {} = {}) {
    super(data)
    this.name = data['name']
    this.sets = data['sets']
    this.distance = data['distance']
    this.time = data['time']
    this.load = data['load']
    this.reps = data['reps']
    this.hold = data['hold']
    this.rowNum = data['rowNum']
    this.notes = data['notes']
    this.regression = data['regression']
    this.video = data['video']
  }

  toJSON () {
    return super.toJSON([
      'name',
      'sets',
      'distance',
      'time',
      'load',
      'reps',
      'hold',
      'rowNum',
      'notes',
      'regression',
      'isComplete',
      'setsCompleted',
      'distanceCompleted',
      'timeCompleted',
      'loadCompleted',
      'repsCompleted',
      'video',
    ])
  }
}
