<form class="edit-profile-form" #profileForm="ngForm">
  <div fxLayout="column" fxLayoutGap="20px">
    <div [hidden]="unapprovedEmail">
      <img [src]="profilePicture" style="width: 100%;" *ngIf="profilePicture" />
      <div style="height: 200px; width: 200px;" *ngIf="!profilePicture" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon style="margin-bottom: 10px; width: 75px; height: 75px; font-size: 75px;">not_interested</mat-icon>
        <span>No profile picture</span>
      </div>
      <div style="text-align: center;">
        <input hidden="true" id="input-file-id" type="file" (change)="readUrl($event)"
          accept="image/png, image/jpeg" #fileInput/>
        <button mat-raised-button (click)="fileInput.click()">
          Change Picture
        </button>
        <div *ngIf="imageError" class="error">{{ imageError }}</div>
      </div>
    </div>
    <div class="unapproved-email-alert" *ngIf="unapprovedEmail">
      <p>For continued access, please update your email address to a USMC approved email domain:</p>
      <p>{{approvedDomains}}</p>
      <p>If you do not have an email address ending in the above domains and you require official use of the system, <a [href]="requestAccountPath" target="_blank">click here</a>.</p>
    </div>
    <mat-form-field>
      <input type="text" matInput placeholder="First Name" name="firstName" [(ngModel)]="firstName"
        #firstNameInput="ngModel" required>
      <mat-error *ngIf="firstNameInput.errors">
        First name required.
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Last Name" name="lastName" [(ngModel)]="lastName"
        #lastNameInput="ngModel" required>
      <mat-error *ngIf="lastNameInput.errors">
        Last name required.
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="Rank" name="rank" [(ngModel)]="rank">
    </mat-form-field>
    <mat-form-field>
      <input type="text" matInput placeholder="E-mail" name="email" [(ngModel)]="email"
        #emailInput="ngModel" required [pattern]="approvedEmailsRegex">
      <mat-error *ngIf="emailInput.errors && emailInput.errors.required && emailInput.touched">
        E-mail required.
      </mat-error>
      <mat-error *ngIf="emailInput.errors && emailInput.errors.pattern">
        Invalid e-mail address format.
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <button mat-raised-button color="primary" (click)="onSave()" [disabled]="profileForm.invalid">Save</button>
    <button *ngIf="!unapprovedEmail" mat-button mat-dialog-close>Cancel</button>
  </div>
</form>
