import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { environment } from 'environments/environment'

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
})
export class AboutDialogComponent {
  version = environment.version
  message: string

  constructor (
    public dialogRef: MatDialogRef<AboutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
}
