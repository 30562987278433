import { State, Action, StateContext, Selector } from '@ngxs/store'
import { AuthStateModel } from './auth.model'
import { AuthService } from 'app/services/auth.service'
import { Signin, Signout, SetActiveUser } from './auth.actions'
import { catchError, tap } from 'rxjs/operators'
import * as moment from 'moment'
import { of } from 'rxjs'

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    activeUser: undefined
  }
})
export class AuthState {

  @Selector()
  static token(state: AuthStateModel) {
    return state.midasToken
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.midasToken
  }

  @Selector()
  static activeUser(state: AuthStateModel) {
    return state.activeUser
  }

  constructor(private authService: AuthService) {}

  @Action(Signin)
  async login(
    { patchState, dispatch }: StateContext<AuthStateModel>,
    { email, password }: Signin
  ) {
    // Signout before attempting a login
    await dispatch(new Signout()).toPromise()
    const loginStatus = await this.authService.login(email, password)
    if (loginStatus && loginStatus.auth_token) {
      patchState({
        midasToken: loginStatus.auth_token,
        lastLoginDate: moment().format()
      })
    }
    return loginStatus
  }

  @Action(Signout)
  logout({ setState }: StateContext<AuthStateModel>) {
    return this.authService.logout().pipe(
      tap(_ => {
        setState({
          activeUser: undefined
        })
      })
    )
  }

  @Action(SetActiveUser)
  setActiveUser(
    { patchState }: StateContext<AuthStateModel>,
    { activeUser }: SetActiveUser
  ) {
    patchState({
      activeUser
    })
  }
}
