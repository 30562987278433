import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { WorkoutState } from 'app/state/workout/workout.state';
import { MatSnackBar } from '@angular/material';

const DAMPENING_FACTOR = (1 / 30)
@Component({
  selector: 'app-rmcalc-dialog',
  templateUrl: './rmcalc-dialog.component.html',
  styleUrls: ['./rmcalc-dialog.component.scss']
})
export class RMCalcDialogComponent implements OnInit {
  exercise: any
  isRMKnown: boolean
  isRMUnknown: boolean

  // Need to conver to `number`
  isRMKnownWeight = ''
  isRMUnknownWeight = ''
  isRMUnknownReps = ''

  constructor (
    private store: Store,
    public dialogRef: MatDialogRef<RMCalcDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    if (!this.data.justStats) {
      const currentWorkoutSession = this.store.selectSnapshot(WorkoutState.currentWorkoutSession)
      const cwi = this.store.selectSnapshot(WorkoutState.currentWorkoutIndex)
      const cri = this.store.selectSnapshot(WorkoutState.currentRowIndex)
      this.exercise = currentWorkoutSession.workout.tiers[cwi].rows[cri]
      if (this.data.hasRM.index >= 0) {
        this.isRMKnown = true
        this.isRMKnownWeight = this.data.hasRM.oneRM['1rm']
      }
    } else {
      this.exercise = {
        exercise: this.data.exercise
      }
      if (this.data.exercise['1rm']) {
        this.isRMKnown = true
        this.isRMKnownWeight = this.data.exercise['1rm']['1rm']
      }
    }
  }

  cancelSelected() {
    this.dialogRef.close({
      success: false
    })
  }

  checkKnownRMBox() {
    this.isRMKnown = true
    this.isRMUnknown = false
  }

  checkUnknownRMBox() {
    this.isRMUnknown = true
    this.isRMKnown = false
  }

  submitSelected() {
    const response = {
      success: true
    }
    if (this.isRMKnown) {
      if (this.isRMKnownWeight) {
        response['rmManual'] = true
        response['1rm'] = parseInt(this.isRMKnownWeight, 10)
        this.dialogRef.close(response)
      } else {
        this.snackBar.open('1RM is not provided.', undefined, {
          duration: 2500
        })
      }
    } else if (this.isRMUnknown) {
      if (this.isRMUnknownWeight && this.isRMUnknownReps) {
        response['rmManual'] = false
        response['1rm'] = (
          parseInt(this.isRMUnknownReps, 10) *
          parseInt(this.isRMUnknownWeight, 10) *
          DAMPENING_FACTOR
        ) + parseInt(this.isRMUnknownWeight, 10)
        this.dialogRef.close(response)
      } else {
        this.snackBar.open('Missing fields for unknown 1RM.', undefined, {
          duration: 2500
        })
      }
    } else {
      this.snackBar.open('Please choose an option.', undefined, {
        duration: 2500
      })
    }
  }

  onCheckboxChange($event, isRMKnown) {
    if ($event.checked) {
      if (isRMKnown) {
        this.isRMUnknown = false
      } else {
        this.isRMKnown = false
      }
    }
  }
}
