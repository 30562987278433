import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-maxset-dialog',
  templateUrl: './maxset-dialog.component.html',
  styleUrls: ['./maxset-dialog.component.css']
})
export class MaxsetDialogComponent implements OnInit {
  maxSet: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if (!this.data.exercise['1rm']) {
      this.data.exercise['1rm'] = {
        exercise_id: this.data.exercise,
        max_set: undefined,
      };
    } else if (!this.data.exercise['1rm'].max_set) {
      this.data.exercise['1rm'].maxSet = undefined;
    }
  }

}
