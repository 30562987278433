import { MockResourceService } from './mock-resource.service';
import { ResourcesRestService } from './resources-rest.service';
import { SurveyTemplateDocument, SurveyTemplate, SurveyChoice } from './models/survey-template-document';
import { SurveyResultColor } from './models/survey';
import { Observable } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "./mock-resource.service";
import * as i2 from "./resources-rest.service";
import * as i3 from "../../services/auth.service";
var ResourceService = /** @class */ (function () {
    function ResourceService(mockResourceService, resourcesRestService, authService) {
        this.mockResourceService = mockResourceService;
        this.resourcesRestService = resourcesRestService;
        this.authService = authService;
        this.surveyResults = [
            {
                id: SurveyResultColor.GOOD,
                label: 'Good',
                color: '#00ff00' // green
            },
            {
                id: SurveyResultColor.OKAY,
                label: 'Okay',
                color: 'yellow'
            },
            {
                id: SurveyResultColor.LOW,
                label: 'Low',
                color: 'orange'
            },
            {
                id: SurveyResultColor.VERY_LOW,
                label: 'Very Low',
                color: 'red'
            }
        ];
        this.surveyAdditionalResources = [
            {
                id: '892955d2-5b45-11ec-bf63-0242ac130002',
                label: 'Physical Fitness',
                text: [
                    "Physically resilient\n        Marines embrace\n        discomfort, knowing it\n        leads to greater\n        endurance, performance,\n        awareness of one's\n        current limits, and ability\n        to train and challenge\n        those current limits.\n        Includes balanced physical\n        training planning, as well\n        as nutrition and injury\n        prevention.",
                    "Having the strength, endurance, coordination,\n        and reserve capacity necessary for success\n        in any environment. Possessing the\n        knowledge, skills, attitudes, and resources\n        necessary to obtain, sustain, and regain\n        optimum personal physical performance."
                ],
                box1Title: 'Embrace Discomfort',
                photo1: 'assets/images/total-fitness-survey/medium-res/physical.jpg',
                box2Links: [
                    {
                        text: 'Force Fitness Facebook Page',
                        href: 'https://www.facebook.com/USMCFFD'
                    },
                    {
                        text: 'Force Fitness Instagram Page',
                        href: 'https://www.instagram/marinecorpsforcefitness'
                    },
                    {
                        text: 'PFT/CFT Preparation',
                        href: 'https://www.fitness.marines.mil/PFT-CFT_Standards17/'
                    },
                    {
                        text: 'Marine Corps Fitness Website',
                        href: 'https://www.fitness.marines.mil/'
                    },
                    {
                        text: 'FitForce mobile app',
                        href: 'https://usmcfitforce.com/'
                    },
                    {
                        text: 'USMC HIT Workout of the day',
                        href: 'https://www.facebook.com/pg/HighIntensityTacticalTraininghitt/posts/'
                    },
                    {
                        text: 'Performance Nutrition',
                        href: 'https://www.fitness.marines.mil/PerformanceNutrition/'
                    },
                    {
                        text: 'Physical Fitness Centers',
                        href: 'https://usmc-mccs.org/services/fitness/fitness-centers/'
                    },
                    {
                        text: 'Semper Fit Health Promotion',
                        href: 'https://usmc-mccs.org/services/fitness/health-promotion/'
                    },
                    {
                        text: 'High Intensity Tactical Training',
                        href: 'https://usmc-mccs.org/MCCS/index.cfm/locations/'
                    },
                    {
                        text: 'CHAMP Physical Fitness resources',
                        href: 'https://www.hprc-online.org/physical-fitness/training-performance'
                    }
                ],
                photo2: 'assets/images/total-fitness-survey/medium-res/physical_2.jpg',
                box3Quote: "\"Marines develop strong mental, moral, spiritual, and ethical understanding\n      because they are as important as physical skills when operating in the violence\n      of combat.\" \u2013 MCDP-7"
            },
            {
                id: '89295834-5b45-11ec-bf63-0242ac130002',
                label: 'Mental Fitness',
                text: [
                    "Mentally resilient Marines\n        embrace life-long learning\n        knowing it leads to faster\n        decision-making under\n        stress, a strong mindset,\n        and the ability to solve\n        complex problems.\n        Includes professional and\n        personal development.",
                    "Remaining engaged in psychologically healthy\n        thinking and behaviors. Includes one\u2019s mindset,\n        attitudes, and practices that help deal with\n        various stressors that impede overall\n        performance. Able to acknowledge stress, seek\n        help early, eliminate stigma around seeking\n        help, help other Marines/Sailors, have strong\n        relationships and friendships, have healthy\n        family relationships, balance family and work\n        requirements, and understand the principles of\n        Combat Operational Stress Control (COSC)."
                ],
                box1Title: 'Embrace Learning',
                photo1: 'assets/images/total-fitness-survey/medium-res/mental.jpg',
                box2Links: [
                    {
                        text: 'Mental Health Care',
                        href: 'https://www.tricare.mil/CoveredServices/Mental/GettingMHCare'
                    },
                    {
                        text: 'Community Counseling',
                        href: 'https://usmc-mccs.org/services/support/community-counseling/'
                    },
                    {
                        text: 'Military and Family Life Counselors',
                        href: 'https://usmc-mccs.org/services/support/military-family-life-counselors/'
                    },
                    {
                        text: 'National Suicide Prevention Hotline',
                        href: 'https://suicidepreventionlifeline.org/'
                    },
                    {
                        text: 'Sexual Assault Prevention',
                        href: 'https://usmc-mccs.org/services/support/sexual-assault-prevention/'
                    },
                    {
                        text: 'SAFE Helpline',
                        href: 'https://safehelpline.org/'
                    },
                    {
                        text: 'Substance Abuse',
                        href: 'https://usmc-mccs.org/services/support/substance-abuse/'
                    },
                    {
                        text: 'Military One Source',
                        href: 'https://www.militaryonesource.mil/'
                    },
                    {
                        text: 'CHAMP Mental Fitness Resources',
                        href: 'https://www.hprc-online.org/mental-fitness/mental-health'
                    },
                    {
                        text: 'Marine Corps Mental Fitness Website',
                        href: 'https://www.fitness.marines.mil/Resilience/Mental-Fitness/'
                    },
                    {
                        text: 'Family Advocacy',
                        href: 'https://www.usmc-mccs.org/services/support/family-advocacy/'
                    }
                ],
                photo2: 'assets/images/total-fitness-survey/medium-res/mental_2.jpg',
                box3Quote: "Development of the moral compass is required on and off today\u2019s complex battlefield. Marine Corps\n      Core Values/Leadership Traits and Principles and tie-ins assist in developing the Ethical Warrior. \u2013\n      MCO 1500.59A\n      "
            },
            {
                id: '8929594c-5b45-11ec-bf63-0242ac130002',
                label: 'Spiritual Fitness',
                box1Title: 'Embrace Values',
                text: [
                    "1) Spiritual fitness is being able to adhere to beliefs, principles, or values needed to persevere and\n      prevail in accomplishing missions (Chairman of the Joint Chiefs of Staff Instruction 3405.01).",
                    "2) Spiritual\n      Fitness is the identification of personal faith, foundational values, and moral living from a variety of sources\n      and traditions that help Marines live out Core Values of Honor, Courage, and Commitment, live the warrior ethos,\n      and exemplify the character expected of a United States Marine (Marine Corps Human Performance/Resilience website)",
                    "Spiritually\n      resilient Marines embrace\n      doing what\u2019s right, not\n      what\u2019s easy. Doing what\u2019s\n      right increases strength of\n      character and spirit.\n      \u201CEmbracing right\u201D starts with\n      embracing both personal\n      values and Marine Corps\n      Core Values of Honor,\n      Courage, and Commitment."
                ],
                photo1: 'assets/images/total-fitness-survey/medium-res/spiritual.jpg',
                box2Links: [
                    {
                        text: 'Marine Corps Spiritual Fitness website',
                        href: 'https://www.fitness.marines.mil/Resilience/Spiritual-Fitness/'
                    },
                    {
                        text: 'Spiritual Fitness Leaders Guide',
                        href: 'https://www.fitness.marines.mil/Portals/211/Resiliency%20Videos/SF%20Leaders%20Guide%20Notebook%20FEB2022.pdf?ver=Dh8gEr_1SuTaqzEtZ1IDlw%3d%3d'
                    },
                    {
                        text: 'Spiritual Fitness PowerPoint',
                        href: 'https://www.hqmc.marines.mil/Portals/61/Docs/Chaplain%20MC/SF/Spiritual%20Fitness%20FFI.pptx'
                    },
                    {
                        text: 'CHAMP Spiritual Fitness Resources',
                        href: 'https://www.hqmc.marines.mil/Portals/61/Docs/Chaplain%20MC/SF/Spiritual%20Fitness%20FFI.pptx'
                    },
                    {
                        text: 'MCCS Spiritual Fitness Website',
                        href: 'https://usmc-mccs.org/articles/strengthening-your-spiritual-fitness/'
                    }
                ],
                photo2: 'assets/images/total-fitness-survey/medium-res/spiritual_2.jpg',
                box3Quote: "Integrity. Uprightness of character and soundness of\n      moral principles. Integrity includes the qualities of\n      truthfulness and honesty. \u2013 MCRP 611D"
            },
            {
                id: '89295a50-5b45-11ec-bf63-0242ac130002',
                label: 'Social Fitness',
                text: [
                    "Socially resilient\n        Marines embrace trust\n        in others, knowing\n        there is strength in\n        numbers. Marines also\n        embrace the challenge\n        of proving themselves\n        trustworthy to their\n        fellow Marines by\n        practicing\n        accountability, follow-through, and integrity.",
                    "Giving and receiving support in positive relationships\n        with peers, families, and communities. Maintaining\n        strong relationships and friendships, having healthy\n        family relationships, being kind and respectful to\n        others, and seeking and enjoying companionship.\n        "
                ],
                box1Title: 'Embrace Trust',
                photo1: 'assets/images/total-fitness-survey/medium-res/social.jpg',
                box2Links: [
                    {
                        text: 'Cross Cultural Competence',
                        href: 'https://www.fitness.marines.mil/Portals/211/Resiliency%20Videos/Cross%20Cultural%20competency%20Resources_1.pdf?ver=lu5GDsamjfbkPBRtQCDjDg%3d%3d'
                    },
                    {
                        text: 'Leading Respectful Conversation',
                        href: 'https://www.fitness.marines.mil/Portals/211/Resiliency%20Videos/Leading%20Conversation%20Guidance_1.pdf?ver=5K9_02HtyeD4cs5W3FBQ-A%3d%3d'
                    },
                    {
                        text: 'Single Marine Program',
                        href: 'https://usmc-mccs.org/services/activities/single-marine-program/'
                    },
                    {
                        text: 'CREDO Programs',
                        href: 'https://www.usmc-mccs.org/services/relationships/chaplains-religious-enrichment-development-operation/#:~:text=Chaplain%20Religious%20Enrichment%20Development%20Operation%20%28CREDO%29%20provides%20regionally,Marines%20and%20their%20families%2C%20and%20enhance%20mission%20readiness.'
                    },
                    {
                        text: 'Family Resources',
                        href: 'https://usmc-mccs.org/topics/family/'
                    },
                    {
                        text: 'USO',
                        href: 'https://www.uso.org/locations/'
                    },
                    {
                        text: 'Volunteering',
                        href: 'https://usmc-mccs.org/index.cfm/services/activities/volunteering/'
                    },
                    {
                        text: 'CHAMP Social Fitness resources',
                        href: 'https://www.hprc-online.org/social-fitness/teams-leadership'
                    },
                    {
                        text: 'Marine Corps Social Fitness Website',
                        href: 'https://www.fitness.marines.mil/Resilience/Social-Fitness/'
                    }
                ],
                photo2: 'assets/images/total-fitness-survey/medium-res/social_2.jpg',
                box3Quote: ''
            },
            {
                id: 'd8af2c35-44d6-407b-8f4f-aa2b3d2ec86b',
                label: 'Resiliency',
                text: [
                    'Resiliency is an important aspect of a Marine\'s personal and professional life. It ensures that when faced with the challenges in and out of combat, Marines will be able to meet those challenges, drawing from internal and external sources of strength and support. Marines with a high degree of physical and cognitive performance build and maintain resiliency in the four areas of fitness: Physical, Mental, Social, and Spiritual'
                ],
                box1Title: '',
                photo1: '',
                box2Links: [
                    {
                        text: 'Marine Corps Resilience Website',
                        href: 'https://www.fitness.marines.mil/Resilience/'
                    },
                    {
                        text: 'Recommended Reading list 1',
                        href: 'https://www.fitness.marines.mil/Portals/211/Resiliency%20Reading%20List_updated%2014AUG2020_1.pdf'
                    },
                    {
                        text: 'Recommended Reading list 2',
                        href: 'https://www.fitness.marines.mil/Portals/211/Fuller%20recommended%20resiliency%20resources.pdf'
                    },
                    {
                        text: 'Recommended Reading list 3',
                        href: 'https://www.fitness.marines.mil/Portals/211/Resiliency%20Resource%20Review-Slyman.pdf'
                    },
                    {
                        text: 'CMC Professional Reading Program 2020',
                        href: 'https://grc-usmcu.libguides.com/usmc-reading-list-2020'
                    }
                ],
                photo2: 'assets/images/total-fitness-survey/medium-res/resiliency.jpg',
                box3Quote: "\"Fitness\" is a holistic\n      approach to physical,\n      mental, spiritual, and\n      social fitness\u2026Ensuring\n      holistic wellbeing boosts\n      morale, cohesiveness, and\n      resiliency - enabling\n      Marines to execute the\n      toughest challenges and\n      recuperate in shorter time.\n      \u2013 Marine Leader\n      Development"
            }
        ];
    }
    ResourceService.prototype.getSurveyTemplateDocumentIds = function () {
        var jwt = 'Bearer ' + this.authService.getToken();
        return this.resourcesRestService.get("SurveyTemplateDocument/", { 'Authorization': jwt }, true);
    };
    ResourceService.prototype.getSurveyTemplateDocument = function (templateUuid) {
        var jwt = 'Bearer ' + this.authService.getToken();
        return this.resourcesRestService.get("SurveyTemplateDocument/" + templateUuid, { 'Authorization': jwt }, true);
    };
    ResourceService.prototype.getSurveyResponseDocumentIds = function () {
        var jwt = 'Bearer ' + this.authService.getToken();
        return this.resourcesRestService.get("SurveyResponseDocument/", { 'Authorization': jwt }, true);
    };
    ResourceService.prototype.getSurveyResponseDocument = function (documentUuid) {
        var jwt = 'Bearer ' + this.authService.getToken();
        return this.resourcesRestService.get("SurveyResponseDocument/" + documentUuid, { 'Authorization': jwt }, true);
    };
    ResourceService.prototype.getLegendSurveyResultArray = function () {
        return this.surveyResults;
    };
    ResourceService.prototype.findSurveyResultbyEnum = function (color) {
        return this.surveyResults.find(function (result) { return result.id === color; });
    };
    ResourceService.prototype.findSurveyAdditionalResourcesByLabel = function (label) {
        return this.surveyAdditionalResources.find(function (resource) { return resource.label === label; });
    };
    ResourceService.prototype.convertToSurveyResponse = function (form, surveyTemplate, surveyChoices) {
        var surveyQuestions = surveyTemplate.questions.sort(function (a, b) { return a.order < b.order ? -1 : 1; });
        var answers = [];
        var _loop_1 = function (i) {
            var questionId = surveyQuestions[i].id.toString();
            var choiceId = form.value[questionId];
            var choiceScore = 0;
            if (choiceId) {
                var choice = surveyChoices.find(function (surveyChoice) { return surveyChoice.id === choiceId; });
                if (choice) {
                    choiceScore = choice.score_weight;
                }
                var surveyAnswer = {
                    question_id: questionId,
                    choice_id: choiceId,
                    choice_score: choiceScore
                };
                answers.push(surveyAnswer);
            }
        };
        for (var i = 0; i < surveyQuestions.length; ++i) {
            _loop_1(i);
        }
        var totalScore = this.sumSectionAnswerScores(answers);
        var surveyResponse = {
            category_id: surveyTemplate.id,
            category_name: surveyTemplate.category,
            answers: answers,
            category_score: totalScore
        };
        return surveyResponse;
    };
    ResourceService.prototype.escapeQuotes = function (str) {
        return str.replace(new RegExp('["]'), '\"');
    };
    ResourceService.prototype.submitSurveyResponseDocument = function (surveyTemplateDocumentUuid, surveyResponses) {
        var jwt = 'Bearer ' + this.authService.getToken();
        var userUuid = this.authService.getUserUUID();
        var surveyResponseDocument = this.createSurveyResponseDocument(surveyTemplateDocumentUuid, userUuid, surveyResponses);
        var documentUuid = surveyResponseDocument.id;
        var surveyResponseDocumentStr = this.convertSurveyResponseDocument(surveyResponseDocument);
        var stringVersion = this.escapeQuotes(surveyResponseDocumentStr);
        var payload = {
            'document': stringVersion
        };
        var headers = new HttpHeaders({ 'Authorization': jwt });
        var data = {
            'headers': headers,
            'payload': payload
        };
        return this.resourcesRestService.post("SurveyResponseDocument/" + documentUuid, { 'headers': { 'Authorization': jwt }, 'document': stringVersion }, true);
    };
    ResourceService.prototype.convertSurveyResponseDocument = function (surveyResponseDocument) {
        return JSON.stringify(surveyResponseDocument);
    };
    ResourceService.prototype.createSurveyResponseDocument = function (surveyTemplateDocumentUuid, userUuid, surveyResponses) {
        var surveyResponseUuid = uuid();
        var surveyTotalScore = this.sumSurveySectionScores(surveyResponses);
        var surveyResponseDocument = {
            id: surveyResponseUuid,
            type: 'SurveyResponseDocument',
            user_uuid: userUuid,
            survey_template_id: surveyTemplateDocumentUuid,
            survey_response: surveyResponses,
            total_score: surveyTotalScore,
            timestamp: moment(Date.now())
        };
        return surveyResponseDocument;
    };
    ResourceService.prototype.sumSurveySectionScores = function (surveyResponses) {
        var total = 0;
        if (!surveyResponses) {
            return total;
        }
        for (var i = 0; i < surveyResponses.length; ++i) {
            total += surveyResponses[i].category_score;
        }
        return total;
    };
    ResourceService.prototype.sumSectionAnswerScores = function (answers) {
        var total = 0;
        if (!answers) {
            return total;
        }
        for (var i = 0; i < answers.length; ++i) {
            total += answers[i].choice_score;
        }
        return total;
    };
    ResourceService.prototype.isOnlineSubscription = function () {
        return this.resourcesRestService.isOnlineSubscription();
    };
    ResourceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResourceService_Factory() { return new ResourceService(i0.ɵɵinject(i1.MockResourceService), i0.ɵɵinject(i2.ResourcesRestService), i0.ɵɵinject(i3.AuthService)); }, token: ResourceService, providedIn: "root" });
    return ResourceService;
}());
export { ResourceService };
