<div fxLayout="column" class="programs-container">
  <h4>{{ totalGroups > 0 ? 'Joined Groups' : 'No Joined Groups Found' }}</h4>
  <app-programs-search [(searchText)]="searchText" (searchTextChanged)="onSearchEvent($event)"></app-programs-search>
  <mat-paginator [length]="totalGroups" [pageSize]="pageSize" (page)="onPageEvent($event)">
  </mat-paginator>
  <div *ngIf="groups.length > 0">
    <mat-card class="group-cards" fxLayout="row" fxLayoutGap="10px"
      *ngFor="let group of filteredGroups | slice:startIndex:stopIndex">
      <div class="info" fxFlex>
        {{ group.name }}
      </div>
      <div class="arrowRight">
        <div *ngIf="isOnline" class="group-button leave-group" fxLayout="row" fxLayoutAlign="center">
          <button mat-raised-button color="warn" (click)="openLeaveDialog(group)">Leave</button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
