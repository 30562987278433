import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OnlineStatusChange, SyncWorkoutSessions } from './state';
import { Select, Store } from '@ngxs/store';
import { animate, group, query, style } from '@angular/animations';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { AuthService } from './services/auth.service';
import { AuthState } from './state/auth/auth.state';
import { DatabaseService } from './services/database.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { PageTransitionService } from './services/page-transition.service';
import { RestService } from './services/rest.service';
import { ServiceWorkerService } from 'fitforce-offline-first';
import { environment } from '../environments/environment';
var slideToRight = [
    query(':enter, :leave', style({ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0 }), { optional: true }),
    query(':leave', style({ transform: 'translateX(0%)' }), { optional: true }),
    query(':enter', style({ transform: 'translateX(-100%)' }), { optional: true }),
    group([
        query(':leave', [
            animate('500ms ease-in-out', style({ transform: 'translateX(100%)' })),
        ], { optional: true }),
        query(':enter', [
            animate('500ms ease-in-out', style({ transform: 'translateX(0%)' })),
        ], { optional: true })
    ])
];
var slideToLeft = [
    query(':enter, :leave', style({ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0 }), { optional: true }),
    query(':leave', style({ transform: 'translateX(0%)' }), { optional: true }),
    query(':enter', style({ transform: 'translateX(100%)' }), { optional: true }),
    group([
        query(':leave', [
            animate('500ms ease-in-out', style({ transform: 'translateX(-100%)' })),
        ], { optional: true }),
        query(':enter', [
            animate('500ms ease-in-out', style({ transform: 'translateX(0%)' })),
        ], { optional: true })
    ])
];
var slideToTop = [
    query(':enter, :leave', style({ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0 }), { optional: true }),
    query(':leave', style({ transform: 'translateY(0%)' }), { optional: true }),
    query(':enter', style({ transform: 'translateY(100%)' }), { optional: true }),
    group([
        query(':leave', [
            animate('500ms ease-in-out', style({ transform: 'translateY(-100%)' })),
        ], { optional: true }),
        query(':enter', [
            animate('500ms ease-in-out', style({ transform: 'translateY(0%)' })),
        ], { optional: true })
    ])
];
var slideToBottom = [
    query(':enter, :leave', style({ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0 }), { optional: true }),
    query(':leave', style({ transform: 'translateY(0%)' }), { optional: true }),
    query(':enter', style({ transform: 'translateY(-100%)' }), { optional: true }),
    group([
        query(':leave', [
            animate('500ms ease-in-out', style({ transform: 'translateY(100%)' })),
        ], { optional: true }),
        query(':enter', [
            animate('500ms ease-in-out', style({ transform: 'translateY(0%)' })),
        ], { optional: true })
    ])
];
var AppComponent = /** @class */ (function () {
    function AppComponent(restService, router, authService, databaseService, serviceWorkerService, store, matIconRegistry, domSanitizer, pageTransitionService, deviceService, dialog) {
        var _this = this;
        this.restService = restService;
        this.router = router;
        this.authService = authService;
        this.databaseService = databaseService;
        this.serviceWorkerService = serviceWorkerService;
        this.store = store;
        this.matIconRegistry = matIconRegistry;
        this.domSanitizer = domSanitizer;
        this.pageTransitionService = pageTransitionService;
        this.deviceService = deviceService;
        this.dialog = dialog;
        this.subs = [];
        this.tabSelection = '';
        this.version = environment.version;
        this.inProd = environment.production;
        this.isOnline = true;
        this.initIcons();
        this.onlineSub = this.restService.isOnlineSubscription().subscribe(function (isOnline) {
            _this.isOnline = isOnline;
            _this.store.dispatch(new OnlineStatusChange(isOnline));
            if (isOnline) {
                // We also sync observations
                _this.store.dispatch(new SyncWorkoutSessions());
            }
        });
        // Support changing tab on load
        router.events.subscribe(function (val) {
            if (val instanceof NavigationEnd) {
                var url = val.url;
                // Currently used to display nav footer
                // Maybe we can reverse it to hide from login and survey
                if (url.indexOf('dashboard') !== -1) {
                    _this.tabSelection = 'main';
                }
                if (url.indexOf('history') !== -1) {
                    _this.tabSelection = 'history';
                }
                if (url.indexOf('group') !== -1) {
                    _this.tabSelection = 'group';
                }
                if (url.indexOf('resources') !== -1) {
                    _this.tabSelection = 'resources';
                }
            }
        });
        this.serviceWorkerService.init(1000 * 60 * 60); // will poll for updates every 1 hour
        this.serviceWorkerService.checkForUpdate();
    }
    AppComponent.prototype.initIcons = function () {
        this.matIconRegistry.addSvgIcon('icon_calendar', this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
            '/assets/images/iconCalendar.svg'));
        // For Tab
        this.matIconRegistry.addSvgIcon('icon_workout', this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
            '/assets/images/iconWorkout.svg'));
        // For Tab
        this.matIconRegistry.addSvgIcon('icon_stats', this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
            '/assets/images/iconStats.svg'));
        // For Tab
        this.matIconRegistry.addSvgIcon('icon_programs', this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
            '/assets/images/iconPrograms.svg'));
        this.matIconRegistry.addSvgIcon('icon_workout_stats', this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
            '/assets/images/iconStatsInWorkout.svg'));
        this.matIconRegistry.addSvgIcon('icon_right_arrow', this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
            '/assets/images/rightArrow.svg'));
        this.matIconRegistry.addSvgIcon('icon_left_arrow', this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
            '/assets/images/leftArrow.svg'));
        this.matIconRegistry.addSvgIcon('check_done', this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
            '/assets/images/checkDone.svg'));
        this.matIconRegistry.addSvgIcon('check_empty', this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
            '/assets/images/checkEmpty.svg'));
        this.matIconRegistry.addSvgIcon('check_incomplete', this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
            '/assets/images/checkIncomplete.svg'));
        this.matIconRegistry.addSvgIcon('marine_logo_white', this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base) +
            '/assets/images/wGlobeAnchor.svg'));
        this.matIconRegistry.addSvgIcon('icon_resources', this.domSanitizer.bypassSecurityTrustResourceUrl((environment.base === '/' ? '' : environment.base)
            + '/assets/images/iconSupport.svg'));
    };
    AppComponent.prototype.isActive = function (name) {
        return this.router.url.indexOf(name) > -1;
    };
    AppComponent.prototype.changeTab = function (name) {
        this.tabSelection = name;
        this.router.navigate([name]);
    };
    AppComponent.prototype.loggedIn = function () {
        return this.authService.isLoggedIn && this.router.url.indexOf('login') < 0 && this.router.url !== '/';
    };
    AppComponent.prototype.logout = function () {
        var _this = this;
        if (this.loggedIn()) {
            this.authService.logout().subscribe(function () {
                // this.databaseService.connect().then(() => {
                //   this.databaseService.clear(Group)
                //   this.databaseService.clear(Plan)
                //   this.databaseService.clear(Day)
                //   this.databaseService.clear(PlanBlock)
                //   this.databaseService.clear(PlanExercise)
                //   this.databaseService.clear(User)
                //   this.databaseService.clear(WorkoutSession)
                // })
                localStorage.removeItem('midas-token');
                localStorage.removeItem('midas-username');
                localStorage.removeItem('midas-userUUID');
                localStorage.removeItem('midas-currentUser');
                _this.router.navigate(['login']);
            });
        }
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subs.push(this.activeUser$.subscribe(function (value) {
            _this.activeUser = value;
        }));
        var devInfo = this.deviceService.getDeviceInfo();
        var browser = devInfo.browser;
        if (this.deviceService.isTablet() || this.deviceService.isDesktop()) {
            this.dialog.open(AlertDialogComponent, {
                data: {
                    title: 'Tablet or Desktop Detected',
                    content: 'Looking for fitness program planning tools? Head to FitForce Planner to get started!',
                    redirectUrl: this.getPlannerLink(),
                    button: 'Launch Planner',
                    cancelButton: 'Close',
                }
            });
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.onlineSub.unsubscribe();
        this.subs.forEach(function (sub) { return sub.unsubscribe(); });
    };
    AppComponent.prototype.getPage = function (outlet) {
        return outlet.activatedRouteData['page'] || 'login';
    };
    AppComponent.prototype.getAnimation = function () {
        return this.pageTransitionService.getCurrentAnimation();
    };
    AppComponent.prototype.getPlannerLink = function () {
        return environment.plannerUrl === '{{PLANNER_URL}}' ? "https://" + window.location.host + "/" : environment.plannerUrl;
    };
    tslib_1.__decorate([
        Select(AuthState.activeUser),
        tslib_1.__metadata("design:type", Object)
    ], AppComponent.prototype, "activeUser$", void 0);
    return AppComponent;
}());
export { AppComponent };
